import React from "react";
import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import { ReactUseState } from "../containers/App";

interface LabelInputProps {
  label: string;
  input_str?: ReactUseState<string>;
  input_num?: {
    value: ReactUseState<number>;
    min?: number;
    max?: number;
    step?: number;
    precision?: number;
  };
  [key: string]: any;
}

const LabelInput: React.FC<LabelInputProps> = ({ label, input_str, input_num, ...props }) => {
  return (
    <InputGroup>
      <FormControl flexDirection="column" layerStyle="input" {...props}>
        <FormLabel textStyle="inputLabel" margin="0">
          {label}
        </FormLabel>
        {input_str && (
          <Input
            variant="underline"
            layerStyle="inputUnderline"
            padding="0px"
            height="auto"
            value={input_str.data}
            onChange={(e) => input_str.setData(e.target.value)}
          />
        )}
        {input_num && (
          <NumberInput
            variant="underline"
            layerStyle="inputUnderline"
            allowMouseWheel
            value={input_num.value.data}
            onChange={(e) => input_num.value.setData(parseInt(e))}
            precision={input_num?.precision}
            step={input_num?.step}
            min={input_num?.min || 1}
            max={input_num?.max || 180}
          >
            <NumberInputField padding="0px" height="auto" />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        )}
      </FormControl>
    </InputGroup>
  );
};

export default LabelInput;
