import { InMemoryCache } from "@apollo/client";
import { loggedInUserVar } from "./reactiveVars";

const cache = new InMemoryCache({
  typePolicies: {
    Sample: {
      fields: {
        name: {
          read(name = "UNKNOWN NAME") {
            return name;
          },
        },
        _createdAt: {
          read(_createdAt) {
            // Return the cached _createdAt, transformed to locale date string
            return new Date(_createdAt).toLocaleDateString();
          },
        },
        birthYear: {
          // local-only field
          read(_, { variables }) {
            return localStorage.getItem("Sample"); // NOT WORKING
          },
        },
      },
      //   Query: {
      //     fields: {
      //       cartItems: {
      //         read() {
      //           return cartItemsVar();
      //         }
      //       }
      //     }
      //   }
    },
    Query: {
      fields: {
        getSamples: {
          // Don't cache separate results based on
          // any of this field's arguments.
          keyArgs: false,
          merge(existing = [], incoming) {
            return mergeObjectsKeepFirst(existing, incoming);
          },
        },
        getLoggedInUser: {
          read() {
            return loggedInUserVar();
          },
        },
        getCompanies: {
          keyArgs: false,
          merge(existing = [], incoming) {
            return incoming;
          },
        },
      },
    },
  },
});

const mergeObjectsKeepFirst = (existing: any[], incoming: any[]): any[] => {
  // CURRENT: Kept the first one if duplicated
  // TODO: Keep the last one if duplicated
  const arr = [...existing, ...incoming];
  return [...new Map(arr.map((item) => [item.__ref, item])).values()];
};

export default cache;
