import React, { useState, useEffect } from "react";
import { JobAppOfferStatus, PayBasis, ToastStatus } from "../../../constants/constants";
import { IJobAppOffer } from "../../../schemas/app.schema";
import JobAppOfferDisplay from "../../../components/jobAppOffer/jobAppOfferDisplay";
import AccordionItem from "../../../components/accordion/accordionItem";
import JobAppOfferHeader from "../../../components/jobAppOffer/jobAppOfferHeader";
import { useToast } from "@chakra-ui/react";
import { ApolloCache, StoreObject, useMutation } from "@apollo/client";
import * as JobAppOfferTypes from "../../../graphql/types/jobAppOffer.type";
import JobAppOfferServices from "../../../graphql/services/jobAppOffer.services";
import {
  JobAppOfferUpdateFields,
  JOB_APP_OFFER_UPDATE_FIELDS_FRAGMENT,
} from "../../../graphql/fragments/jobAppOffer.fragment";
import { JobAppOfferToastType, showToast } from "../../../utils/updateHelper";
import { safe_delete_cached_data } from "../../../cache/helpers";

interface ViewJobAppOfferDetailProps {
  title: string;
  _id: string;
  _status: JobAppOfferStatus;
  _position: string;
  _deadline: string;
  _startDate: string;
  _endDate: string;
  _payRate: number;
  _payBasis: PayBasis;
  _performanceBonus: number;
  _stock: number;
  _vestingSchedules: number[];
  _signOnBonus: number;
  _relocation: number;
  _location: string;
  _benefits: string[];
  _otherCompensation: number;
  _description: string;
  offer: IJobAppOffer;
  itemProps?: any;
  [key: string]: any;
}

const ViewJobAppOfferDetail: React.FC<ViewJobAppOfferDetailProps> = ({
  title,
  _id,
  _status,
  _position,
  _deadline,
  _startDate,
  _endDate,
  _payRate,
  _payBasis,
  _performanceBonus,
  _stock,
  _vestingSchedules,
  _signOnBonus,
  _relocation,
  _location,
  _benefits,
  _otherCompensation,
  _description,
  offer,
  itemProps,
  ...props
}) => {
  const toast = useToast();

  const [status, setStatus] = useState(_status);
  const [position, setPosition] = useState(_position);
  const [deadline, setDeadline] = useState(_deadline);
  const [startDate, setStartDate] = useState(_startDate);
  const [endDate, setEndDate] = useState(_endDate);
  const [payRate, setPayRate] = useState(_payRate);
  const [payBasis, setPayBasis] = useState(_payBasis);
  const [stock, setStock] = useState(_stock);
  const [signOnBonus, setSignOnBonus] = useState(_signOnBonus);
  const [performanceBonus, setPerformanceBonus] = useState(_performanceBonus);
  const [vestingSchedules, setVestingSchedules] = useState(_vestingSchedules);
  const [location, setLocation] = useState(_location);
  const [otherCompensation, setOtherCompensation] = useState(_otherCompensation);
  const [relocation, setRelocation] = useState(_relocation);
  const [benefits, setBenefits] = useState(_benefits);
  const [description, setDescription] = useState(_description);

  const [isDirty, setIsDirty] = useState(false);
  const [statusDirty, setStatusDirty] = useState(false);
  const [positionDirty, setPositionDirty] = useState(false);
  const [deadlineDirty, setDeadlineDirty] = useState(false);
  const [startDateDirty, setStartDateDirty] = useState(false);
  const [endDateDirty, setEndDateDirty] = useState(false);
  const [payRateDirty, setPayRateDirty] = useState(false);
  const [payBasisDirty, setPayBasisDirty] = useState(false);
  const [stockDirty, setStockDirty] = useState(false);
  const [signOnBonusDirty, setSignOnBonusDirty] = useState(false);
  const [performanceBonusDirty, setPerformanceBonusDirty] = useState(false);
  const [vestingSchedulesDirty, setVestingSchedulesDirty] = useState(false);
  const [locationDirty, setLocationDirty] = useState(false);
  const [otherCompensationDirty, setOtherCompensationDirty] = useState(false);
  const [relocationDirty, setRelocationDirty] = useState(false);
  const [benefitsDirty, setBenefitsDirty] = useState(false);
  const [descriptionDirty, setDescriptionDirty] = useState(false);

  const [schedulesError, setSchedulesError] = useState(false);

  const [firstYear, setFirstYear] = useState(0);
  const [firstTwoYearsAverage, setFirstTwoYearsAverage] = useState(0);
  const [allYearsAverage, setAllYearsAverage] = useState(0);

  useEffect(() => {
    setStatusDirty(status !== _status);
  }, [status, _status]);

  useEffect(() => {
    setPositionDirty(position !== _position);
  }, [position, _position]);

  useEffect(() => {
    setDeadlineDirty(deadline !== _deadline);
  }, [deadline, _deadline]);

  useEffect(() => {
    setStartDateDirty(startDate !== _startDate);
  }, [startDate, _startDate]);

  useEffect(() => {
    setEndDateDirty(endDate !== _endDate);
  }, [endDate, _endDate]);

  useEffect(() => {
    setPayRateDirty(payRate !== _payRate);
  }, [payRate, _payRate]);

  useEffect(() => {
    setPayBasisDirty(payBasis !== _payBasis);
  }, [payBasis, _payBasis]);

  useEffect(() => {
    setStockDirty(stock !== _stock);
  }, [stock, _stock]);

  useEffect(() => {
    setSignOnBonusDirty(signOnBonus !== _signOnBonus);
  }, [signOnBonus, _signOnBonus]);

  useEffect(() => {
    setPerformanceBonusDirty(performanceBonus !== _performanceBonus);
  }, [performanceBonus, _performanceBonus]);

  useEffect(() => {
    setVestingSchedulesDirty(vestingSchedules !== _vestingSchedules);
  }, [vestingSchedules, _vestingSchedules]);

  useEffect(() => {
    setLocationDirty(location !== _location);
  }, [location, _location]);

  useEffect(() => {
    setOtherCompensationDirty(otherCompensation !== _otherCompensation);
  }, [otherCompensation, _otherCompensation]);

  useEffect(() => {
    setRelocationDirty(relocation !== _relocation);
  }, [relocation, _relocation]);

  useEffect(() => {
    setBenefitsDirty(benefits !== _benefits);
  }, [benefits, _benefits]);

  useEffect(() => {
    setDescriptionDirty(description !== _description);
  }, [description, _description]);

  useEffect(() => {
    setIsDirty(
      statusDirty ||
        positionDirty ||
        deadlineDirty ||
        startDateDirty ||
        endDateDirty ||
        payRateDirty ||
        payBasisDirty ||
        stockDirty ||
        signOnBonusDirty ||
        performanceBonusDirty ||
        vestingSchedulesDirty ||
        locationDirty ||
        otherCompensationDirty ||
        relocationDirty ||
        benefitsDirty ||
        descriptionDirty
    );
  }, [
    statusDirty,
    positionDirty,
    deadlineDirty,
    startDateDirty,
    endDateDirty,
    payRateDirty,
    payBasisDirty,
    stockDirty,
    signOnBonusDirty,
    performanceBonusDirty,
    vestingSchedulesDirty,
    locationDirty,
    otherCompensationDirty,
    relocationDirty,
    benefitsDirty,
    descriptionDirty,
  ]);

  const calcYearlyBase = () => {
    switch (payBasis) {
      case PayBasis.HOURLY:
        return payRate * 52 * 40;
      case PayBasis.MONTHLY:
        return payRate * 12;
      case PayBasis.WEEKLY:
        return payRate * 52;
      case PayBasis.YEARLY:
        return payRate;
      default:
        return payRate;
    }
  };

  const calculateSalary = () => {
    const years = vestingSchedules.length;
    const bonus = performanceBonus / 100 + 1;
    const base = calcYearlyBase();

    const baseWithBonus = bonus * base;
    const currStock = stock ? stock : 0;

    const year1Stock = currStock * vestingSchedules[0];
    const year2Stock = currStock * vestingSchedules[1];

    let restYearStock = 0;
    for (let i = 2; i < years; i++) {
      restYearStock += currStock * vestingSchedules[i];
    }

    const year1Salary = baseWithBonus + signOnBonus + year1Stock + otherCompensation;
    const year2Salary = baseWithBonus + year2Stock;
    const allYearsSalary = baseWithBonus * years + stock + signOnBonus + otherCompensation;

    setFirstYear(year1Salary);
    setFirstTwoYearsAverage((year1Salary + year2Salary) / 2);
    setAllYearsAverage(allYearsSalary / years);
  };

  useEffect(() => {
    calculateSalary();
  }, [
    startDateDirty,
    endDateDirty,
    payRateDirty,
    payBasisDirty,
    stockDirty,
    signOnBonusDirty,
    performanceBonusDirty,
    vestingSchedulesDirty,
    otherCompensationDirty,
  ]);

  const [updateJobAppOffer] = useMutation<
    JobAppOfferTypes.UpdateJobAppOfferData,
    JobAppOfferTypes.UpdateJobAppOfferArgs
  >(
    JobAppOfferServices.UPDATE_JOB_APP_OFFER({
      status: statusDirty,
      position: positionDirty,
      deadline: deadlineDirty,
      startDate: startDateDirty,
      endDate: endDateDirty,
      payRate: payRateDirty,
      payBasis: payBasisDirty,
      stock: stockDirty,
      signOnBonus: signOnBonusDirty,
      performanceBonus: performanceBonusDirty,
      vestingSchedules: vestingSchedulesDirty,
      location: locationDirty,
      otherCompensation: otherCompensationDirty,
      relocation: relocationDirty,
      benefits: benefitsDirty,
      description: descriptionDirty,
    })
  );

  const [deleteJobAppOffer] = useMutation<
    JobAppOfferTypes.DeleteJobAppOfferData,
    JobAppOfferTypes.DeleteJobAppOfferArgs
  >(JobAppOfferServices.DELETE_JOB_APP_OFFER);

  const clearState = () => {
    setStatusDirty(false);
    setPositionDirty(false);
    setDeadlineDirty(false);
    setStartDateDirty(false);
    setEndDateDirty(false);
    setPayRateDirty(false);
    setPayBasisDirty(false);
    setStockDirty(false);
    setSignOnBonusDirty(false);
    setPerformanceBonusDirty(false);
    setVestingSchedulesDirty(false);
    setLocationDirty(false);
    setOtherCompensationDirty(false);
    setRelocationDirty(false);
    setBenefitsDirty(false);
    setDescriptionDirty(false);
    setIsDirty(false);
  };

  const cache_updateJobAppOffer = (
    cache: ApolloCache<any>,
    data: JobAppOfferTypes.UpdateJobAppOfferData | null | undefined
  ) => {
    const updateData: JobAppOfferUpdateFields = {};
    const entityToast: JobAppOfferToastType = {};
    if (statusDirty) {
      if (data?.updateJobAppOfferByStatus?.ok) {
        updateData.status = status;
        entityToast.status = true;
      } else {
        entityToast.status = false;
      }
    }
    if (positionDirty) {
      if (data?.updateJobAppOfferByPosition?.ok) {
        updateData.position = position;
        entityToast.position = true;
      } else {
        entityToast.position = false;
      }
    }
    if (deadlineDirty) {
      if (data?.updateJobAppOfferByDeadline?.ok) {
        updateData.deadline = new Date(deadline).valueOf();
        entityToast.deadline = true;
      } else {
        entityToast.deadline = false;
      }
    }
    if (startDateDirty) {
      if (data?.updateJobAppOfferByStartDate?.ok) {
        updateData.startDate = new Date(startDate).valueOf();
        entityToast.startDate = true;
      } else {
        entityToast.startDate = false;
      }
    }
    if (endDateDirty) {
      if (data?.updateJobAppOfferByEndDate?.ok) {
        updateData.endDate = new Date(endDate).valueOf();
        entityToast.endDate = true;
      } else {
        entityToast.endDate = false;
      }
    }
    if (payRateDirty) {
      if (data?.updateJobAppOfferByPayRate?.ok) {
        updateData.payRate = payRate;
        entityToast.payRate = true;
      } else {
        entityToast.payRate = false;
      }
    }
    if (payBasisDirty) {
      if (data?.updateJobAppOfferByPayBasis?.ok) {
        updateData.payBasis = payBasis;
        entityToast.payBasis = true;
      } else {
        entityToast.payBasis = false;
      }
    }
    if (stockDirty) {
      if (data?.updateJobAppOfferByStock?.ok) {
        updateData.stock = stock;
        entityToast.stock = true;
      } else {
        entityToast.stock = false;
      }
    }
    if (signOnBonusDirty) {
      if (data?.updateJobAppOfferBySignOnBonus?.ok) {
        updateData.signOnBonus = signOnBonus;
        entityToast.signOnBonus = true;
      } else {
        entityToast.signOnBonus = false;
      }
    }
    if (performanceBonusDirty) {
      if (data?.updateJobAppOfferByPerformanceBonus?.ok) {
        updateData.performanceBonus = performanceBonus;
        entityToast.performanceBonus = true;
      } else {
        entityToast.performanceBonus = false;
      }
    }
    if (vestingSchedulesDirty) {
      if (data?.updateJobAppOfferByVestingSchedules?.ok) {
        updateData.vestingSchedules = vestingSchedules;
        entityToast.vestingSchedules = true;
      } else {
        entityToast.vestingSchedules = false;
      }
    }
    if (locationDirty) {
      if (data?.updateJobAppOfferByLocation?.ok) {
        updateData.location = location;
        entityToast.location = true;
      } else {
        entityToast.location = false;
      }
    }
    if (otherCompensationDirty) {
      if (data?.updateJobAppOfferByOtherCompensation?.ok) {
        updateData.otherCompensation = otherCompensation;
        entityToast.otherCompensation = true;
      } else {
        entityToast.otherCompensation = false;
      }
    }
    if (relocationDirty) {
      if (data?.updateJobAppOfferByRelocation?.ok) {
        updateData.relocation = relocation;
        entityToast.relocation = true;
      } else {
        entityToast.relocation = false;
      }
    }
    if (benefitsDirty) {
      if (data?.updateJobAppOfferByBenefits?.ok) {
        updateData.benefits = benefits;
        entityToast.benefits = true;
      } else {
        entityToast.benefits = false;
      }
    }
    if (descriptionDirty) {
      if (data?.updateJobAppOfferByDescription?.ok) {
        updateData.description = description;
        entityToast.description = true;
      } else {
        entityToast.description = false;
      }
    }
    // update cache for the updated job app offer
    if (Object.entries(updateData).length > 0) {
      cache.writeFragment({
        id: `JobAppOffer:${_id}`,
        fragment: JOB_APP_OFFER_UPDATE_FIELDS_FRAGMENT(updateData),
        data: {
          ...updateData,
        },
      });
    }
    // show error or success toast to indicate operation status
    showToast("Offer", entityToast, toast);
  };

  const cache_deleteJobAppOffer = (
    cache: ApolloCache<any>,
    data: JobAppOfferTypes.DeleteJobAppOfferData | null | undefined
  ) => {
    const result = data?.deleteJobAppOffer;
    if (result?.ok === true) {
      cache.modify({
        fields: {
          getJobAppOffersByJobAppId(existing: StoreObject[] = [], { readField }) {
            return existing.filter((offerRef) => _id !== readField("_id", offerRef));
          },
        },
      });
      // delete entire object
      safe_delete_cached_data(cache, _id, "JobAppOffer");
    }
  };

  const onUpdateOffer = async () => {
    const updateArgs: JobAppOfferTypes.UpdateJobAppOfferArgs = {};
    if (statusDirty) {
      updateArgs.updateJobAppOfferByStatus = {
        _id: _id,
        status: status,
      };
    }
    if (positionDirty) {
      updateArgs.updateJobAppOfferByPosition = {
        _id: _id,
        position,
      };
    }
    if (deadlineDirty) {
      updateArgs.updateJobAppOfferByDeadline = {
        _id: _id,
        deadline: new Date(deadline).valueOf(),
      };
    }
    if (startDateDirty) {
      updateArgs.updateJobAppOfferByStartDate = {
        _id: _id,
        startDate: new Date(startDate).valueOf(),
      };
    }
    if (endDateDirty) {
      updateArgs.updateJobAppOfferByEndDate = {
        _id: _id,
        endDate: new Date(endDate).valueOf(),
      };
    }
    if (payRateDirty) {
      updateArgs.updateJobAppOfferByPayRate = {
        _id: _id,
        payRate,
      };
    }
    if (payBasisDirty) {
      updateArgs.updateJobAppOfferByPayBasis = {
        _id: _id,
        payBasis,
      };
    }
    if (stockDirty) {
      updateArgs.updateJobAppOfferByStock = {
        _id: _id,
        stock,
      };
    }
    if (signOnBonusDirty) {
      updateArgs.updateJobAppOfferBySignOnBonus = {
        _id: _id,
        signOnBonus,
      };
    }
    if (performanceBonusDirty) {
      updateArgs.updateJobAppOfferByPerformanceBonus = {
        _id: _id,
        performanceBonus,
      };
    }
    if (vestingSchedulesDirty) {
      updateArgs.updateJobAppOfferByVestingSchedules = {
        _id: _id,
        vestingSchedules,
      };
    }
    if (locationDirty) {
      updateArgs.updateJobAppOfferByLocation = {
        _id: _id,
        location,
      };
    }
    if (otherCompensationDirty) {
      updateArgs.updateJobAppOfferByOtherCompensation = {
        _id: _id,
        otherCompensation,
      };
    }
    if (relocationDirty) {
      updateArgs.updateJobAppOfferByRelocation = {
        _id: _id,
        relocation,
      };
    }
    if (benefitsDirty) {
      updateArgs.updateJobAppOfferByBenefits = {
        _id: _id,
        benefits,
      };
    }
    if (descriptionDirty) {
      updateArgs.updateJobAppOfferByDescription = {
        _id: _id,
        description,
      };
    }
    console.log("updateArgs", updateArgs);
    await updateJobAppOffer({
      variables: updateArgs,
      update(cache, { data }) {
        cache_updateJobAppOffer(cache, data);
        clearState();
      },
    }).catch((error) => {
      toast({
        description: `${error.message}`,
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
    });
  };

  const onDeleteOffer = async () => {
    await deleteJobAppOffer({
      variables: { id: _id },
      update(cache, { data }) {
        cache_deleteJobAppOffer(cache, data);
      },
    }).catch((error) => {
      toast({
        description: `${error.message}`,
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
    });
  };

  return (
    <AccordionItem
      header={<JobAppOfferHeader title={title} status={offer.status} _draft={isDirty} />}
      panel={
        <JobAppOfferDisplay
          offer={offer}
          status={{ data: status, setData: setStatus }}
          deadline={{ data: deadline, setData: setDeadline }}
          details={{
            startDate: { data: startDate, setData: setStartDate },
            endDate: { data: endDate, setData: setEndDate },
            position: { data: position, setData: setPosition },
            location: { data: location, setData: setLocation },
          }}
          benefitDetails={{
            relocation: { data: relocation, setData: setRelocation },
            benefits: { data: benefits, setData: setBenefits },
          }}
          compensations={{
            payRate: { data: payRate, setData: setPayRate },
            payBasis: { data: payBasis, setData: setPayBasis },
            stock: { data: stock, setData: setStock },
            vestingSchedules: { data: vestingSchedules, setData: setVestingSchedules },
            signOnBonus: { data: signOnBonus, setData: setSignOnBonus },
            performanceBonus: { data: performanceBonus, setData: setPerformanceBonus },
            otherCompensation: { data: otherCompensation, setData: setOtherCompensation },
            schedulesError: { data: schedulesError, setData: setSchedulesError },
          }}
          salaryEstimates={{
            firstYear: firstYear,
            firstTwoYearsAverage: firstTwoYearsAverage,
            allYearsAverage: allYearsAverage,
          }}
          description={{ description: { data: description, setData: setDescription } }}
          isDirty={isDirty}
          isError={false}
          handlers={{
            onSave: onUpdateOffer,
            onDelete: onDeleteOffer,
            new: undefined,
          }}
        />
      }
      itemProps={itemProps}
      {...props}
    />
  );
};

export default ViewJobAppOfferDetail;
