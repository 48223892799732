import textStyles, { fontWeights, TextStyles } from "../textStyles";

export const FormStyles = {
  floatingBox: "floatingBox",
};

const Form = {
  variants: {
    floating: {
      container: {
        margin: "auto",
        maxWidth: "24rem",
        pb: "0.75rem",
        label: {
          top: 0,
          left: 0,
          zIndex: 2,
          position: "absolute",
          backgroundColor: "clear",
          pointerEvents: "none",
          mx: 3,
          px: 1,
          my: 2,
          transformOrigin: "top",
          ...textStyles.inputLabel,
        },
        input: {
          // maxWidth: "24rem",
          height: "60px",
          pt: 5,
          ...textStyles.inputValue,
          borderWidth: "2px",
          borderRadius: "10px",
          _focus: { borderColor: "primary.solid", boxShadow: "none" },
        },
      },
    },
    [FormStyles.floatingBox]: {
      container: {
        backgroundColor: "clear",
        margin: "auto",
        maxWidth: "24rem",
        label: {
          top: 0,
          left: 0,
          zIndex: 2,
          position: "absolute",
          backgroundColor: "clear",
          pointerEvents: "none",
          mx: 3,
          px: 1,
          my: 2,
          transformOrigin: "top",
          textStyle: "inputLabel",
        },
        input: {
          // maxWidth: "24rem",
          height: "60px",
          pt: 5,
          border: "none",
          textStyle: TextStyles.inputValue,
          _placeholder: { color: "grey.500", fontWeight: fontWeights.regular },
          _focus: { borderColor: "none", boxShadow: "none" },
          _hover: {
            outline: "none",
            boxShadow: "none",
            _disabled: {
              pointerEvents: "none",
            },
          },
        },
      },
    },
    eventNote: {
      container: {
        margin: "auto",
        maxWidth: "24rem",
        label: {
          top: 0,
          left: 0,
          zIndex: 2,
          position: "absolute",
          backgroundColor: "clear",
          pointerEvents: "none",
          mx: 3,
          px: 1,
          my: 2,
          transformOrigin: "top",
          ...textStyles.inputLabel,
        },
        input: {
          // maxWidth: "24rem",
          height: "60px",
          pt: 5,
          border: "none",
          ...textStyles.inputValue,
          _focus: { borderColor: "none", boxShadow: "none" },
          _hover: {
            outline: "none",
            boxShadow: "none",
            _disabled: {
              pointerEvents: "none",
            },
          },
        },
      },
    },
  },
};

export default Form;
