import { Box, Flex, Spacer, Text } from "@chakra-ui/react";
import React from "react";
import { JobAppOfferStatus } from "../../constants/constants";
import { ReactUseState } from "../../containers/App";
import { IJobAppOffer } from "../../schemas/app.schema";
import { ButtonVariants } from "../../theme/components/button";
import { fontSizes, fontWeights, TextStyles } from "../../theme/textStyles";
import Divider from "../divider";
import { JobAppOfferForm } from "./jobAppOfferForm";
import JobAppOfferBenefitDetails, { JobAppOfferBenefitDetailsProps } from "./offerBenefitDetails";
import JobAppOfferCompensations, { JobAppOfferCompensationsProps } from "./offerCompensations";
import JobAppOfferDetails, { JobAppOfferDetailsProps } from "./offerDetail";
import JobAppOfferSalaryEstimates, {
  JobAppOfferSalaryEstimatesProps,
} from "./offerSalaryEstimates";
import Button from "../button";
import AlertDialog from "../alertDialog";
import JobAppOfferDescription, { JobAppOfferDescriptionProps } from "./offerDescription";

interface JobAppOfferDisplayProps {
  offer: IJobAppOffer;
  status: ReactUseState<JobAppOfferStatus>;
  deadline: ReactUseState<string>;
  details: JobAppOfferDetailsProps;
  benefitDetails: JobAppOfferBenefitDetailsProps;
  compensations: JobAppOfferCompensationsProps;
  salaryEstimates: JobAppOfferSalaryEstimatesProps;
  description: JobAppOfferDescriptionProps;
  isDirty: boolean;
  isError: boolean;
  handlers: {
    onDelete(): void;
    onSave(): void;
    new?: { onCreate(newOffer: IJobAppOffer): void };
  };
}

const JobAppOfferDisplay: React.FC<JobAppOfferDisplayProps> = ({
  offer,
  status,
  deadline,
  details,
  benefitDetails,
  compensations,
  salaryEstimates,
  description,
  isDirty,
  isError,
  handlers,
}) => {
  return (
    <Box width="100%" textAlign="start">
      <Flex direction="row" mb="0.75rem" justifyContent="center">
        <Text textStyle={TextStyles.noteSubHeader}>Event Reminder</Text>
        <Spacer />
        <Button
          variant={ButtonVariants.text}
          fontSize={fontSizes[8]}
          fontWeight={fontWeights.regular}
          textDecoration="underline"
          color="primary.solid"
          textAlign="end"
          text={offer.status === JobAppOfferStatus.ACCEPT ? "Pending" : "Accept"}
          px="0px"
          py="0px"
          onClick={
            offer.status === JobAppOfferStatus.ACCEPT
              ? () => status.setData(JobAppOfferStatus.PENDING)
              : () => status.setData(JobAppOfferStatus.ACCEPT)
          }
        />
        <Text textStyle={TextStyles.thin}>/</Text>
        <Button
          variant={ButtonVariants.text}
          textStyle={TextStyles.thin}
          textDecoration="underline"
          textAlign="end"
          text={offer.status === JobAppOfferStatus.DECLINE ? "Pending" : "Decline"}
          px="0px"
          py="0px"
          onClick={
            offer.status === JobAppOfferStatus.DECLINE
              ? () => status.setData(JobAppOfferStatus.PENDING)
              : () => status.setData(JobAppOfferStatus.DECLINE)
          }
        />
      </Flex>
      <JobAppOfferForm
        input={{
          label: "Offer Deadline",
          value: deadline,
          type: "datetime-local",
          isRequired: true,
        }}
      />
      <Divider mb="0.75rem" />
      <JobAppOfferDetails {...details} />
      <Divider mb="0.75rem" />
      <Box>
        <JobAppOfferCompensations {...compensations} />
        <JobAppOfferSalaryEstimates {...salaryEstimates} />
      </Box>
      <Divider mb="0.75rem" />
      <JobAppOfferBenefitDetails {...benefitDetails} />
      <Divider mb="0.75rem" />
      <JobAppOfferDescription {...description} />
      <Flex direction="row" alignItems="center" justifyContent="end">
        {handlers.new ? (
          <Button
            text="Create"
            variant={
              isDirty && !isError ? ButtonVariants.primaryLineSquare : ButtonVariants.greyLineSquare
            }
            textStyle={TextStyles.thinText}
            px="0.75rem"
            py="0.25rem"
            isDisabled={!isDirty && isError}
            onClick={() => handlers.onSave()}
          />
        ) : (
          <>
            <AlertDialog
              triggerBtnProps={{
                text: "Delete",
                variant: ButtonVariants.alertLineSquare,
                textStyle: TextStyles.thinText,
                px: "0.25rem",
                py: "0.25rem",
                mr: "1rem",
              }}
              header={{ text: "Delete Event" }}
              body={<Text textStyle={TextStyles.text}>Are you sure to delete this event?</Text>}
              deleteHandler={() => handlers.onDelete()}
            />
            <Button
              text="Save"
              variant={
                isDirty && !isError
                  ? ButtonVariants.primaryLineSquare
                  : ButtonVariants.greyLineSquare
              }
              textStyle={TextStyles.thinText}
              px="0.75rem"
              py="0.25rem"
              isDisabled={!isDirty && isError}
              onClick={() => handlers.onSave()}
            />
          </>
        )}
      </Flex>
    </Box>
  );
};

export default JobAppOfferDisplay;
