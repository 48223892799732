import React, { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { DocTitleSuffix } from "../../constants/constants";
import AppRoutes from "../AppRoutes.constant";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {AppRoutes.about.title}
          {DocTitleSuffix}
        </title>
      </Helmet>
      <Box>
        <h1>About Page</h1>
      </Box>
    </>
  );
};

export default About;
