import React from "react";
import { IMergedJobApp } from "../../../utils/mergedJobApp";
import { mockDataLoader } from "../../App";
import CardSquare from "../../../components/cards/cardSquare";
import { getPopularity } from "../../../utils";
import CardLong from "../../../components/cards/cardLong";
import { JobAppStatus } from "../../../constants/constants";
import { IReminder } from "../../../utils/jobApp";

interface JobAppCardProps {
  item: IMergedJobApp;
  xMark: boolean;
  upcomingReminder?: IReminder;
  viewApp: boolean;
  handlers: {
    setXMark(_id: string): void;
    updateJobAppStatus(_id: string, status: JobAppStatus): void;
  };
  props?: {
    [key: string]: any;
  };
}

export const JobAppCardSquare: React.FC<JobAppCardProps> = ({
  item,
  xMark,
  upcomingReminder,
  viewApp,
  handlers,
  props,
}) => {
  return (
    <CardSquare
      jobId={item.job._id}
      jobAppId={item.app?._id}
      company={item.job.company || mockDataLoader.getAllCompanies()[0]}
      job={item.job}
      xMark={xMark}
      viewApp={viewApp}
      appStatus={item.app?.status}
      pill={
        upcomingReminder
          ? { text: upcomingReminder.text, icon: { name: "notification-fill" } }
          : { text: getPopularity(item.job.totalApplied), icon: { name: "fire-fill" } }
      }
      handlers={handlers}
      props={props}
    />
  );
};

export const JobAppCardLong: React.FC<JobAppCardProps> = ({
  item,
  xMark,
  upcomingReminder,
  viewApp,
  handlers,
  props,
}) => {
  return (
    <CardLong
      jobId={item.job._id}
      jobAppId={item.app?._id}
      company={item.job.company || mockDataLoader.getAllCompanies()[0]}
      job={item.job}
      xMark={xMark}
      viewApp={viewApp}
      appStatus={item.app?.status}
      upcomingReminder={upcomingReminder}
      handlers={handlers}
      props={props}
    />
  );
};
