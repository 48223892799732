import { Box, Center, Divider, Grid, GridItem, Text } from "@chakra-ui/react";
import React from "react";
import { TextStyles } from "../../theme/textStyles";
import { numberInThousands } from "../../utils";

export interface JobAppOfferSalaryEstimatesProps {
  firstYear: number;
  firstTwoYearsAverage: number;
  allYearsAverage: number;
}

const JobAppOfferSalaryEstimates: React.FC<JobAppOfferSalaryEstimatesProps> = ({
  firstYear,
  firstTwoYearsAverage,
  allYearsAverage,
}) => {
  return (
    <>
      <Text textStyle={TextStyles.noteSubHeader} mb="1rem">
        Salary Estimates
      </Text>
      <Grid
        templateColumns="repeat(10, 1fr)"
        gap={0}
        alignItems="center"
        alignContent="center"
        textAlign="center"
        mb="0.75rem"
        textStyle={TextStyles.greyText}
      >
        <GridItem colSpan={2} display="flex" dir="row" alignItems="center">
          <Text>Year 1 @ ${numberInThousands(firstYear)}</Text>
        </GridItem>
        <GridItem colSpan={1}>
          <Center>
            <Divider height="15px" orientation="vertical" borderColor="grey.700" />
          </Center>
        </GridItem>
        <GridItem colSpan={3}>
          <Text>Year 1&amp;2 average @ ${numberInThousands(firstTwoYearsAverage)}</Text>
        </GridItem>
        <GridItem colSpan={1}>
          <Center>
            <Divider height="15px" orientation="vertical" borderColor="grey.700" />
          </Center>
        </GridItem>
        <GridItem colSpan={3}>
          <Text>All-year average @ ${numberInThousands(allYearsAverage)}</Text>
        </GridItem>
      </Grid>
    </>
  );
};

export default JobAppOfferSalaryEstimates;
