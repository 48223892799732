import React, { ReactElement } from "react";
import { ReactComponent as AddCircleLine } from "./icons/add-circle-line.svg";
import { ReactComponent as AddLine } from "./icons/add-line.svg";
import { ReactComponent as ArrowDownLine } from "./icons/arrow-down-line.svg";
import { ReactComponent as ArrowLeftSLine } from "./icons/arrow-left-s-line.svg";
import { ReactComponent as ArrowRightSLine } from "./icons/arrow-right-s-line.svg";
import { ReactComponent as ArrowUp } from "./icons/arrow-up.svg";
import { ReactComponent as BarChartLine } from "./icons/bar-chart-line.svg";
import { ReactComponent as BookmarkFill } from "./icons/bookmark-fill.svg";
import { ReactComponent as BookmarkLine } from "./icons/bookmark-line.svg";
import { ReactComponent as BrandLogo } from "./icons/brand-logo.svg";
import { ReactComponent as BriefcaseLine } from "./icons/briefcase-2-line.svg";
import { ReactComponent as BuildingLine } from "./icons/building-2-line.svg";
import { ReactComponent as CalendarLineCheck } from "./icons/calendar-line.svg";
import { ReactComponent as CalendarLine } from "./icons/calendar-2-line.svg";
import { ReactComponent as CheckboxBlankCircleLine } from "./icons/checkbox-blank-circle-line.svg";
import { ReactComponent as CheckboxCircleFill } from "./icons/checkbox-circle-fill.svg";
import { ReactComponent as CheckboxCircleLine } from "./icons/checkbox-circle-line.svg";
import { ReactComponent as CircleFillGrey } from "./icons/circle-fill-grey.svg";
import { ReactComponent as CircleFill } from "./icons/circle-fill.svg";
import { ReactComponent as CircleHollowGrey } from "./icons/circle-hollow-grey.svg";
import { ReactComponent as CircleHollow } from "./icons/circle-hollow.svg";
import { ReactComponent as CircleLine } from "./icons/circle-line.svg";
import { ReactComponent as CloseFill } from "./icons/close-fill.svg";
import { ReactComponent as DeleteBinLine } from "./icons/delete-bin-line.svg";
import { ReactComponent as EarthLine } from "./icons/earth-line.svg";
import { ReactComponent as EmotionHappyLine } from "./icons/emotion-happy-line.svg";
import { ReactComponent as ExternalLinkLine } from "./icons/external-link-line.svg";
import { ReactComponent as ExclamationMark } from "./icons/exclamation-mark.svg";
import { ReactComponent as EyeFill } from "./icons/eye-fill.svg";
import { ReactComponent as FileListFill } from "./icons/file-list-3-fill.svg";
import { ReactComponent as FireFill } from "./icons/fire-fill.svg";
import { ReactComponent as FireLine } from "./icons/fire-line.svg";
import { ReactComponent as FilterDropdown } from "./icons/filter-dropdown.svg";
import { ReactComponent as Github } from "./icons/github.svg";
import { ReactComponent as HeartFill } from "./icons/heart-fill.svg";
import { ReactComponent as HeartLine } from "./icons/heart-line.svg";
import { ReactComponent as HeartLineCustom } from "./icons/heart-line-custom.svg";
import { ReactComponent as LinkedinLogo } from "./icons/linkedin-logo.svg";
import { ReactComponent as MapPinLine } from "./icons/map-pin-2-line.svg";
import { ReactComponent as MoneyDollarCircleLine } from "./icons/money-dollar-circle-line.svg";
import { ReactComponent as MyApps } from "./icons/my-apps.svg";
import { ReactComponent as NotificationFill } from "./icons/notification-fill.svg";
import { ReactComponent as OverflowMenu } from "./icons/overflow-menu.svg";
import { ReactComponent as PassportLine } from "./icons/passport-line.svg";
import { ReactComponent as PencilLine } from "./icons/pencil-line.svg";
import { ReactComponent as PencilLineEdit } from "./icons/pencil-line-edit.svg";
import { ReactComponent as ProfileLine } from "./icons/user-3-line.svg";
import { ReactComponent as SaveLine } from "./icons/save-line.svg";
import { ReactComponent as SearchLine } from "./icons/search-line.svg";
import { ReactComponent as SettingLine } from "./icons/setting-line.svg";
import { ReactComponent as StickyNoteFill } from "./icons/sticky-note-fill.svg";
import { ReactComponent as StickyNoteLine } from "./icons/sticky-note-line.svg";
import { ReactComponent as SubstractCircleLine } from "./icons/substract-circle-line.svg";
import { ReactComponent as ThumbUpFill } from "./icons/thumb-up-fill.svg";
import { ReactComponent as UserLocationLine } from "./icons/user-location-line.svg";
import { Box } from "@chakra-ui/react";
// import { ReactComponent as CalendarLine } from "./icons/calendar-line.svg";

export interface SVGIconProps {
  name: string;
  fill?: string;
  size?: string | string[];
  [key: string]: any;
}

const SVGIcon: React.FC<SVGIconProps> = ({
  name,
  fill = "primary.solid",
  size = "1rem",
  sx,
  ...props
}) => {
  const properties = {
    fill,
    width: "100%",
    height: "100%",
    ...props,
  };
  let svgElement: ReactElement = <></>;

  switch (name) {
    case "add-circle-line":
      svgElement = <AddCircleLine {...properties} />;
      break;
    case "add-line":
      svgElement = <AddLine {...properties} />;
      break;
    case "arrow-down-line":
      svgElement = <ArrowDownLine {...properties} />;
      break;
    case "arrow-left-line":
      svgElement = <ArrowLeftSLine {...properties} />;
      break;
    case "arrow-right-line":
      svgElement = <ArrowRightSLine {...properties} />;
      break;
    case "arrow-up-line":
      svgElement = <ArrowUp {...properties} />;
      break;
    case "bar-chart":
      svgElement = <BarChartLine {...properties} />;
      break;
    case "bookmark-fill":
      svgElement = <BookmarkFill {...properties} />;
      break;
    case "bookmark-line":
      svgElement = <BookmarkLine {...properties} />;
      break;
    case "brand-logo":
      svgElement = <BrandLogo {...properties} />;
      break;
    case "briefcase-line":
      svgElement = <BriefcaseLine {...properties} />;
      break;
    case "building-line":
      svgElement = <BuildingLine {...properties} />;
      break;
    case "calendar-check":
      svgElement = <CalendarLineCheck {...properties} />;
      break;
    case "calendar":
      svgElement = <CalendarLine {...properties} />;
      break;
    case "checkbox-blank-circle":
      svgElement = <CheckboxBlankCircleLine {...properties} />;
      break;
    case "check-circle-fill":
      svgElement = <CheckboxCircleFill {...properties} />;
      break;
    case "check-circle-line":
      svgElement = <CheckboxCircleLine {...properties} />;
      break;
    case "circle-fill-grey":
      svgElement = <CircleFillGrey {...properties} />;
      break;
    case "circle-fill":
      svgElement = <CircleFill {...properties} />;
      break;
    case "circle-hollow-grey":
      svgElement = <CircleHollowGrey {...properties} />;
      break;
    case "circle-hollow":
      svgElement = <CircleHollow {...properties} />;
      break;
    case "circle-line":
      svgElement = <CircleLine {...properties} />;
      break;
    case "close-fill":
      svgElement = <CloseFill {...properties} />;
      break;
    case "close":
      svgElement = <CloseFill {...properties} />;
      break;
    case "delete-bin-line":
      svgElement = <DeleteBinLine {...properties} />;
      break;
    case "trash":
      svgElement = <DeleteBinLine {...properties} />;
      break;
    case "earth-line":
      svgElement = <EarthLine {...properties} />;
      break;
    case "happy-face":
      svgElement = <EmotionHappyLine {...properties} />;
      break;
    case "external-link-line":
      svgElement = <ExternalLinkLine {...properties} />;
      break;
    case "exclamation-mark":
      svgElement = <ExclamationMark {...properties} />;
      break;
    case "eye-fill":
      svgElement = <EyeFill {...properties} />;
      break;
    case "file-list-fill":
      svgElement = <FileListFill {...properties} />;
      break;
    case "fire-fill":
      svgElement = <FireFill {...properties} />;
      break;
    case "fire-line":
      svgElement = <FireLine {...properties} />;
      break;
    case "filter-dropdown":
      svgElement = <FilterDropdown {...properties} />;
      break;
    case "github":
      svgElement = <Github {...properties} />;
      break;
    case "heart-fill":
      svgElement = <HeartFill {...properties} />;
      break;
    case "heart-line":
      svgElement = <HeartLine {...properties} />;
      break;
    case "heart-line-custom":
      svgElement = <HeartLineCustom {...properties} />;
      break;
    case "linkedin-logo":
      svgElement = <LinkedinLogo {...properties} />;
      break;
    case "map-pin":
      svgElement = <MapPinLine {...properties} />;
      break;
    case "money-dollar-circle-line":
      svgElement = <MoneyDollarCircleLine {...properties} />;
      break;
    case "my-apps":
      svgElement = <MyApps {...properties} />;
      break;
    case "notification-fill":
      svgElement = <NotificationFill {...properties} />;
      break;
    case "overflow-menu":
      svgElement = <OverflowMenu {...properties} />;
      break;
    case "passport-line":
      svgElement = <PassportLine {...properties} />;
      break;
    case "pencil-line-edit":
      svgElement = <PencilLineEdit {...properties} />;
      break;
    case "pencil":
      svgElement = <PencilLine {...properties} />;
      break;
    case "profile":
      svgElement = <ProfileLine {...properties} />;
      break;
    case "save-line":
      svgElement = <SaveLine {...properties} />;
      break;
    case "search":
      svgElement = <SearchLine {...properties} />;
      break;
    case "setting":
      svgElement = <SettingLine {...properties} />;
      break;
    case "sticky-note-fill":
      svgElement = <StickyNoteFill {...properties} />;
      break;
    case "sticky-note-line":
      svgElement = <StickyNoteLine {...properties} />;
      break;
    case "substract-circle-line":
      svgElement = <SubstractCircleLine {...properties} />;
      break;
    case "thumb-up-fill":
      svgElement = <ThumbUpFill {...properties} />;
      break;
    case "user-location-line":
      svgElement = <UserLocationLine {...properties} />;
      break;
    default:
      svgElement = <></>;
      break;
  }
  return (
    <Box width={size} height={size}>
      {svgElement}
    </Box>
  );
};

export default SVGIcon;
