import { DocumentNode, gql } from "@apollo/client";
import { OPERATION_STATUS_FRAGMENT } from "../../fragments/common.fragment";
import { JOB_APP_EVENT_CORE_FRAGMENT } from "../../fragments/jobAppEvent.fragment";
import {
  UpdateMutationHelper,
  UpdateMutationHelperProps,
  UpdateMutationParamQueryPairProps,
  UpdateMutationPlaceholderProps,
} from "../serviceHelper";

const CREATE_JOB_APP_EVENT = gql`
  ${JOB_APP_EVENT_CORE_FRAGMENT}
  ${OPERATION_STATUS_FRAGMENT}
  mutation CreateJobAppEvent($newJobAppEvent: CreateJobAppEventInput!) {
    createJobAppEvent(newJobAppEvent: $newJobAppEvent) {
      ... on JobAppEvent {
        ...JobAppEventCoreFragment
      }
      ... on OperationStatus {
        ...OperationStatusFragment
      }
    }
  }
`;

const UPDATE_JOB_APP_EVENT_BY_XCOMPLETED = `
updateJobAppEventByXCompleted(updateJobAppEvent: $updateJobAppEventByXCompleted) {
    ...OperationStatusFragment
  }
`;

const UPDATE_JOB_APP_EVENT_BY_TITLE = `
updateJobAppEventByTitle(updateJobAppEvent: $updateJobAppEventByTitle) {
    ...OperationStatusFragment
  }
`;

const UPDATE_JOB_APP_EVENT_BY_TIME = `
updateJobAppEventByTime(updateJobAppEvent: $updateJobAppEventByTime) {
    ...OperationStatusFragment
  }
`;

const UPDATE_JOB_APP_EVENT_BY_DURATION = `
updateJobAppEventByDuration(updateJobAppEvent: $updateJobAppEventByDuration) {
    ...OperationStatusFragment
  }
`;

const UPDATE_JOB_APP_EVENT_BY_INVITATION = `
updateJobAppEventByInvitation(updateJobAppEvent: $updateJobAppEventByInvitation) {
    ...OperationStatusFragment
  }
`;

export interface UpdaterJobAppEventMutationProps {
  title?: boolean;
  xCompleted?: boolean;
  time?: boolean;
  duration?: boolean;
  invitation?: boolean;
}

const updateJobAppEventMutationParamQueryPair: UpdateMutationParamQueryPairProps = {
  xCompleted: {
    param: `$updateJobAppEventByXCompleted: UpdateJobAppEventByXCompletedInput!`,
    query: UPDATE_JOB_APP_EVENT_BY_XCOMPLETED,
  },
  title: {
    param: `$updateJobAppEventByTitle: UpdateJobAppEventByTitleInput!`,
    query: UPDATE_JOB_APP_EVENT_BY_TITLE,
  },
  time: {
    param: `$updateJobAppEventByTime: UpdateJobAppEventByTimeInput!`,
    query: UPDATE_JOB_APP_EVENT_BY_TIME,
  },
  duration: {
    param: `$updateJobAppEventByDuration: UpdateJobAppEventByDurationInput!`,
    query: UPDATE_JOB_APP_EVENT_BY_DURATION,
  },
  invitation: {
    param: `$updateJobAppEventByInvitation: UpdateJobAppEventByInvitationInput!`,
    query: UPDATE_JOB_APP_EVENT_BY_INVITATION,
  },
};

const UPDATE_JOB_APP_EVENT = (props: UpdaterJobAppEventMutationProps): DocumentNode => {
  const mutationName = "UpdateJobAppEventMutation";
  const placeholder: UpdateMutationPlaceholderProps = {
    params: `$placeholder: UpdateJobAppEventByXCompletedInput!`,
    queries: `placeholder(placeholder: $placeholder) {_id}`,
  };
  return UpdateMutationHelper(
    mutationName,
    placeholder,
    props as UpdateMutationHelperProps,
    updateJobAppEventMutationParamQueryPair
  );
};

const DELETE_JOB_APP_EVENT = gql`
  ${OPERATION_STATUS_FRAGMENT}
  mutation DeleteJobAppEventMutation($id: ObjectId!) {
    deleteJobAppEvent(_id: $id) {
      ...OperationStatusFragment
    }
  }
`;

const JobAppEventMutations = {
  CREATE_JOB_APP_EVENT,
  UPDATE_JOB_APP_EVENT,
  DELETE_JOB_APP_EVENT,
};

export default JobAppEventMutations;
