import {
  Tooltip,
  FormControl,
  Grid,
  GridItem,
  FormLabel,
  Divider,
  InputGroup,
  Input,
  NumberInput,
  NumberInputField,
  Text,
} from "@chakra-ui/react";
import { ReactUseState } from "../../containers/App";
import { TextStyles } from "../../theme/textStyles";
import { InputProps } from "../form";
import PayBasisMenu from "../payBasisMenu";

const MAX_NUMBER_INPUT = 99999999;
const MIN_NUMBER_INPUT = 0;

export interface JobAppOfferFormProps {
  input: InputProps;
  inputLeftElement?: string;
  inputRightElement?: string;
  dropDownSelect?: ReactUseState<any>;
  [key: string]: any;
}

export const JobAppOfferForm: React.FC<JobAppOfferFormProps> = ({
  input,
  dropDownSelect,
  inputLeftElement,
  inputRightElement,
  ...props
}) => {
  const validateInput = (num: number) => {
    if (num > MAX_NUMBER_INPUT) {
      return MAX_NUMBER_INPUT;
    }
    if (num < MIN_NUMBER_INPUT) {
      return MIN_NUMBER_INPUT;
    }
  };

  return (
    <FormControl
      variant=""
      maxWidth="30rem"
      mb="0.5rem"
      isRequired={input?.isRequired}
      alignContent="center"
    >
      <Grid templateColumns="repeat(10, 1fr)" gap={0} alignItems="center" alignContent="center">
        <GridItem colSpan={4} alignItems="center" alignContent="center">
          <Tooltip label={input?.labelDescriptionTooltip}>
            <FormLabel display="flex" flexDirection="row" alignItems="end" margin="0">
              <Text textStyle={TextStyles.greyText}>{input.label}</Text>
            </FormLabel>
          </Tooltip>
        </GridItem>
        <GridItem colSpan={1}>
          <Divider orientation="vertical" height="15px" borderColor="grey.600" />
        </GridItem>
        <GridItem colSpan={5} display="flex" dir="row" alignItems="center">
          <InputGroup
            p="0.5rem"
            _hover={{ bg: "grey.200", borderRadius: "0.2rem" }}
            width="fit-content"
          >
            {inputLeftElement && (
              <Text textStyle={TextStyles.greyText} textColor="black">
                {inputLeftElement}
              </Text>
            )}
            {input?.type === "number" ? (
              <NumberInput
                size="sm"
                width="100%"
                variant="unstyled"
                textStyle={TextStyles.greyText}
                value={input?.value.data ? input.value.data : 0}
                precision={input?.precision}
                min={input?.min ? input.min : MIN_NUMBER_INPUT}
                max={input?.max ? input.max : MAX_NUMBER_INPUT}
                onChange={(valueAsString: string, valueAsNumber: number) =>
                  input.value.setData(valueAsNumber)
                }
                height="18px"
                pl={inputLeftElement ? "1rem" : ""}
                {...props}
              >
                <NumberInputField pr="0.5rem" width={inputRightElement ? "3rem" : "5rem"} />
              </NumberInput>
            ) : (
              <Input
                variant="unstyled"
                id={input.label}
                type={input?.type}
                value={input.value.data}
                size="sm"
                width="100%"
                textStyle={TextStyles.greyText}
                placeholder={input?.placeholder}
                maxLength={input?.maxLength}
                onChange={(e) => input.value.setData(e.target.value)}
                {...props}
              />
            )}
            {inputRightElement && <Text textStyle={TextStyles.greyText}>{inputRightElement}</Text>}
          </InputGroup>
          {dropDownSelect && (
            <>
              <Text textStyle={TextStyles.thin} textColor="black">
                /
              </Text>
              <PayBasisMenu selected={dropDownSelect} />
            </>
          )}
        </GridItem>
      </Grid>
    </FormControl>
  );
};

export default JobAppOfferForm;
