import { useToast } from "@chakra-ui/react";
import React, { ReactElement, useEffect, useState } from "react";
import { IJobAppTerm } from "../../../schemas/user.schema";
import { ApolloCache, useMutation, useQuery } from "@apollo/client";
import JobAppTermServices from "../../../graphql/services/jobAppTerm.services";
import * as JobAppTermTypes from "../../../graphql/types/jobAppTerm.type";
import { get_selected_job_app_term_from_cache } from "../../../cache/helpers";
import * as UserTypes from "../../../graphql/types/user.type";
import UserServices from "../../../graphql/services/user.services";
import { ToastStatus } from "../../../constants/constants";
import { USER_UPDATE_LAST_OPEN_TERM_FRAGMENT } from "../../../graphql/fragments/user.fragment";
import { OperationStatus } from "../../../graphql/types/common.type";
import { JOB_APP_TERM_DISPLAY_FRAGMENT } from "../../../graphql/fragments/jobAppTerm.fragment";
import JobTermMenu from "../../../components/jobTermMenu";
import JobAppTermEditModal from "./jobAppTermEditModal";
import JobAppTermCreateModal from "./jobAppTermCreateModal";
import useCurrentJobAppTermHook from "../../../hooks/useCurrentJobAppTerm";
import { currentJobAppTermVar } from "../../../cache/reactiveVars";

interface JobAppTermMenuProps {
  viewOnly?: Boolean;
  uid: string;
  [key: string]: any;
}

const JobAppTermMenu: React.FC<JobAppTermMenuProps> = ({ viewOnly = false, uid, ...props }) => {
  const toast = useToast();
  const TERM_TITLE_MAX_LENGTH = 25;
  const { setCurrentJobAppTerm } = useCurrentJobAppTermHook(currentJobAppTermVar);

  const { client, data } = useQuery<
    JobAppTermTypes.GetAllTermsByUidData,
    JobAppTermTypes.GetAllTermsByUidArgs
  >(JobAppTermServices.GET_ALL_TERMS_BY_UID, {
    variables: { uid: uid },
  });

  const terms = data?.getAllTermsByUid || [];
  const [selectedTerm, setSelectedTerm] = useState<IJobAppTerm>(
    get_selected_job_app_term_from_cache(client, uid, terms)
  );

  useEffect(() => {
    setCurrentJobAppTerm(selectedTerm);
  }, [selectedTerm]);

  const [updateUserByLastOpenTerm] = useMutation<
    UserTypes.UpdateUserByLastOpenTermData,
    UserTypes.UpdateUserByLastOpenTermArgs
  >(UserServices.UPDATE_USER_BY_LAST_OPEN_TERM);

  const cache_updateUserByLastOpenTerm = (
    cache: ApolloCache<any>,
    data: UserTypes.UpdateUserByLastOpenTermData | null | undefined,
    termId: string
  ) => {
    const status: OperationStatus | undefined = data?.updateUserByLastOpenTerm;
    if (status?.ok) {
      cache.writeFragment({
        id: `User:${uid}`,
        fragment: USER_UPDATE_LAST_OPEN_TERM_FRAGMENT,
        data: { lastOpenTerm: termId },
      });
    } else {
      toast({
        title: `${status?.errorTitle}`,
        description: `${status?.errorMessage}`,
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
    }
  };

  const onUpdateUserByLastOpenTerm = async (termId: string) => {
    if (termId !== selectedTerm._id) {
      const updateUserByLastOpenTermArgs: UserTypes.UpdateUserByLastOpenTermArgs = {
        updateUserByLastOpenTerm: {
          _id: uid,
          lastOpenTerm: termId,
        },
      };
      await updateUserByLastOpenTerm({
        variables: updateUserByLastOpenTermArgs,
        update(cache, { data }) {
          cache_updateUserByLastOpenTerm(cache, data, termId);
          const openTerm: IJobAppTerm | null = cache.readFragment({
            id: `JobAppTerm:${termId}`,
            fragment: JOB_APP_TERM_DISPLAY_FRAGMENT,
          });
          setSelectedTerm(openTerm || selectedTerm);
          localStorage.setItem("lastOpenTermId", termId);
        },
      }).catch((error) => {
        toast({
          description: `${error.message}`,
          position: "bottom",
          status: ToastStatus.ERROR,
          isClosable: true,
        });
      });
    }
  };

  const selectLatestAvailableTerm = () => {
    onUpdateUserByLastOpenTerm(terms[0]._id);
  };

  const jobAppTermEditModalComponentHelper = (editingTerm: IJobAppTerm): ReactElement => {
    return (
      <JobAppTermEditModal
        editingTerm={editingTerm}
        deletable={terms.length > 1}
        termTitleMaxLength={TERM_TITLE_MAX_LENGTH}
        selectedTerm={{ data: selectedTerm, setData: setSelectedTerm }}
        selectLatestAvailableTerm={selectLatestAvailableTerm}
      />
    );
  };

  const jobTermCreateModalComponent = () => {
    return <JobAppTermCreateModal uid={uid} termTitleMaxLength={TERM_TITLE_MAX_LENGTH} />;
  };

  return (
    <JobTermMenu
      viewOnly={viewOnly}
      uid={uid}
      terms={terms}
      selectedTerm={selectedTerm}
      termTitleMaxLength={TERM_TITLE_MAX_LENGTH}
      onUpdateUserByLastOpenTerm={onUpdateUserByLastOpenTerm}
      jobAppTermEditModalComponent={jobAppTermEditModalComponentHelper}
      jobTermCreateModalComponent={jobTermCreateModalComponent}
      {...props}
    />
  );
};

export default JobAppTermMenu;
