import colors from "./colors";

export const fontSizes = {
  0: "30px",
  1: "26px",
  2: "24px",
  3: "22px",
  4: "20px",
  5: "18px",
  6: "16px",
  7: "14px",
  8: "12px",
  9: "10px",
};

export const fontWeights = {
  light: "300",
  regular: "400",
  medium: "500",
  semiBold: "600",
  bold: "700",
  extraBold: "800",
};

export const TextStyles = {
  navlink: "navlink",
  primaryFillBtn_sm: "primaryFillBtn_sm",
  primaryFillBtn_md: "primaryFillBtn_md",
  primaryFillBtn_lg: "primaryFillBtn_lg",
  primaryLinePill: "primaryLinePill",
  primaryText: "primaryText",
  greyText: "greyText",
  text: "text",
  textUnderline: "textUnderline",
  thin: "thin",
  tiny: "tiny",
  thinText: "thinText",
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h6: "h6",
  description: "description",
  sectionHeader: "sectionHeader",
  pill: "pill",
  noteSubHeader: "noteSubHeader",
  h6Underline: "h6Underline",
  inputValue: "inputValue",
  footer: "footer",
};

const textStyles = {
  [TextStyles.navlink]: {
    fontSize: fontSizes[5],
    fontWeight: fontWeights.light,
    bgClip: "text",
    bgGradient: colors.primary.text,
    _hover: { bgClip: "text", bgGradient: colors.primary.text },
  },
  [TextStyles.sectionHeader]: {
    fontSize: fontSizes[7],
    fontWeight: fontWeights.medium,
  },
  [TextStyles.h1]: {
    fontSize: fontSizes[3],
    fontWeight: fontWeights.regular,
    mb: "1rem",
  },
  [TextStyles.h2]: {
    fontSize: fontSizes[5],
    fontWeight: fontWeights.regular,
  },
  [TextStyles.h3]: {
    fontSize: fontSizes[6],
    fontWeight: fontWeights.medium,
  },
  h4: {
    fontSize: [fontSizes[7], fontSizes[6], fontSizes[5]],
    fontWeight: "600",
  },
  h5: {
    fontSize: fontSizes[6],
    fontWeight: "600",
  },
  [TextStyles.h6]: {
    fontSize: fontSizes[7],
    fontWeight: fontWeights.medium,
  },
  [TextStyles.h6Underline]: {
    fontSize: fontSizes[7],
    fontWeight: fontWeights.medium,
    textDecoration: "underline",
  },
  [TextStyles.primaryText]: {
    bgClip: "text",
    bgGradient: colors.primary.text,
    fontSize: [fontSizes[7]],
    fontWeight: fontWeights.medium,
  },
  [TextStyles.greyText]: {
    fontSize: fontSizes[7],
    fontWeight: fontWeights.regular,
    color: "grey.700",
  },
  [TextStyles.text]: {
    fontSize: fontSizes[7],
    fontWeight: fontWeights.regular,
  },
  [TextStyles.textUnderline]: {
    fontSize: fontSizes[7],
    fontWeight: fontWeights.regular,
    textDecoration: "underline",
  },
  [TextStyles.thinText]: {
    fontSize: fontSizes[8],
    fontWeight: fontWeights.regular,
  },
  [TextStyles.thin]: {
    fontSize: fontSizes[8],
    fontWeight: fontWeights.regular,
    color: "grey.700",
  },
  [TextStyles.tiny]: {
    fontSize: fontSizes[9],
    fontWeight: fontWeights.regular,
    color: colors.grey[700],
  },
  [TextStyles.pill]: {
    fontSize: fontSizes[9],
    fontWeight: fontWeights.medium,
  },
  [TextStyles.noteSubHeader]: {
    fontSize: fontSizes[7],
    fontWeight: fontWeights.semiBold,
    color: "grey.600",
  },
  inputLabel: {
    fontSize: fontSizes[8],
    fontWeight: "100",
    color: colors.grey[500],
  },
  [TextStyles.inputValue]: {
    fontSize: fontSizes[7],
    fontWeight: fontWeights.regular,
    color: "text",
    _placeholder: { color: "grey.500", fontWeight: fontWeights.regular },
  },
  [TextStyles.footer]: {
    fontSize: [fontSizes[9], fontSizes[8]],
    fontWeight: fontWeights.light,
  },
  actionBtn: {
    color: "white",
    fontSize: [fontSizes[7], fontSizes[6]],
    fontWeight: "600",
    height: "max-content",
    margin: "0.5rem 0.25rem",
  },
  [TextStyles.primaryFillBtn_sm]: {
    color: "white",
    width: "100%",
    fontSize: fontSizes[8],
    fontWeight: fontWeights.regular,
  },
  [TextStyles.primaryFillBtn_md]: {
    color: "white",
    width: "100%",
    fontSize: fontSizes[7],
    fontWeight: fontWeights.regular,
  },
  [TextStyles.primaryFillBtn_lg]: {
    color: "white",
    width: "100%",
    fontSize: fontSizes[6],
    fontWeight: fontWeights.regular,
  },
  primaryLineBtn: {
    bgClip: "text",
    bgGradient: colors.primary.text,
  },
  primaryFillBtn: {
    color: "white",
    width: "100%",
    height: "max-content",
    // margin: "0.3rem 0.5rem",
  },
  [TextStyles.primaryLinePill]: {
    bgClip: "text",
    bgGradient: colors.primary.text,
    fontSize: [fontSizes[8]], // tiny
    fontWeight: fontWeights.medium,
  },
  primaryFillPill: {
    color: "white",
    fontSize: [fontSizes[8]], // tiny
    fontWeight: "400",
  },
  secondaryBtn: { color: "grey.700" },
  notificationBanner: {
    color: "white",
    fontWeight: "600",
    fontSize: "24px",
    letterSpacing: "0.1rem",
  },
  netPromoterIcon: {
    fontSize: [fontSizes[3], fontSizes[2], fontSizes[1]],
  },
  btn_sm: {
    color: colors.primary.solid,
    fontSize: fontSizes[8],
  },
  coming_soon: {
    fontSize: [fontSizes[8], fontSizes[7], fontSizes[6]],
  },
};

export default textStyles;
