import React from "react";
import Button from "./button";
import { ITab } from "../containers/pages/myApps";
import { Flex } from "@chakra-ui/react";
import { ReactUseState } from "../containers/App";
import { ButtonVariants } from "../theme/components/button";
import { TextStyles } from "../theme/textStyles";
import { capitalizeStr } from "../utils";

export interface StatusTabBarProps {
  tabs: ITab[];
  selectedTab: ReactUseState<ITab>;
  [key: string]: any;
}

const StatusTabBar: React.FC<StatusTabBarProps> = ({ tabs, selectedTab, ...props }) => {
  return (
    <Flex dir="row" justifyContent="center" alignItems="center" {...props}>
      {tabs.map((tab) => {
        const noOfApps = tab.noOfApps === 0 ? "" : `(${tab.noOfApps})`;
        return (
          <Button
            key={tab.status}
            variant={
              selectedTab.data.status === tab.status
                ? ButtonVariants.primaryFillRound
                : ButtonVariants.text
            }
            textStyle={
              selectedTab.data.status === tab.status
                ? TextStyles.primaryFillBtn_md
                : TextStyles.text
            }
            onClick={() => selectedTab.setData(tab)}
            text={`${capitalizeStr(tab.status)}${noOfApps}`}
            px="0.5rem"
            py="0.25rem"
          />
        );
      })}
    </Flex>
  );
};

export default StatusTabBar;
