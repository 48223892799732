import React, { useState } from "react";
import { IMergedJobApp } from "../../../utils/mergedJobApp";
import { JobAppCardSquare, JobAppCardLong } from "./jobAppCard";
import useJobListingMarksHook from "../../../hooks/useJobListingMarks";
import { currentJobAppTermVar, jobListingMarksVar } from "../../../cache/reactiveVars";
import { ApolloCache, useMutation } from "@apollo/client";
import JobAppTermServices from "../../../graphql/services/jobAppTerm.services";
import * as JobAppTermTypes from "../../../graphql/types/jobAppTerm.type";
import * as JobAppTypes from "../../../graphql/types/jobApp.type";
import JobAppServices from "../../../graphql/services/jobApp.services";
import useCurrentJobAppTermHook from "../../../hooks/useCurrentJobAppTerm";
import { useToast } from "@chakra-ui/react";
import { JobAppStatus, ToastStatus } from "../../../constants/constants";
import { JOB_APP_UPDATE_STATUS_FRAGMENT } from "../../../graphql/fragments/jobApp.fragment";
import { IReminder } from "../../../utils/jobApp";

export enum JobAppCardShape {
  SQUARE = "SQUARE",
  LONG = "LONG",
}

interface JobAppCardDisplayProps {
  shape: JobAppCardShape;
  item: IMergedJobApp;
  reminder?: IReminder;
  viewApp: boolean;
  props?: {
    [key: string]: any;
  };
}

const JobAppCardDisplay: React.FC<JobAppCardDisplayProps> = ({
  shape,
  item,
  reminder,
  viewApp,
  props,
}) => {
  const toast = useToast();

  const { isJobListingMarked, addJobListingMark, deleteJobListingMark } =
    useJobListingMarksHook(jobListingMarksVar);
  const { getCurrentJobAppTerm } = useCurrentJobAppTermHook(currentJobAppTermVar);

  const termId: string = getCurrentJobAppTerm()?._id || "";

  const [xMarked, setXMarked] = useState(isJobListingMarked(item.job._id));

  const [deleteJobMark] = useMutation<
    JobAppTermTypes.DeleteJobListingMarkData,
    JobAppTermTypes.DeleteJobListingMarkArgs
  >(JobAppTermServices.DELETE_JOB_LISTING_MARK);

  const [addJobMark] = useMutation<
    JobAppTermTypes.AddJobListingMarkData,
    JobAppTermTypes.AddJobListingMarkArgs
  >(JobAppTermServices.CREATE_JOB_LISTING_MARK);

  const [updateJobAppByStatus] = useMutation<
    JobAppTypes.UpdateJobAppByStatusData,
    JobAppTypes.UpdateJobAppByStatusArgs
  >(JobAppServices.UPDATE_JOB_APP_BY_STATUS);

  const cache_addJobListingMark = (
    cache: ApolloCache<any>,
    data: JobAppTermTypes.AddJobListingMarkData | null | undefined
  ) => {
    const result = data?.addJobListingMark;
    if (result?.ok === true) {
    }
  };

  const cache_deleteJobListingMark = (
    cache: ApolloCache<any>,
    data: JobAppTermTypes.DeleteJobListingMarkData | null | undefined
  ) => {
    const result = data?.deleteJobListingMark;
    if (result?.ok === true) {
    }
  };

  const cache_updateJobAppByStatus = async (
    cache: ApolloCache<any>,
    data: JobAppTypes.UpdateJobAppByStatusData | null | undefined,
    args: JobAppTypes.UpdateJobAppByStatusArgs
  ) => {
    const result = data?.updateJobAppByStatus;
    if (result?.ok === true) {
      cache.writeFragment({
        id: `JobApp:${args.updateJobApp._id}`,
        fragment: JOB_APP_UPDATE_STATUS_FRAGMENT,
        data: {
          status: args.updateJobApp.status,
        },
      });
    } else {
      toast({
        title: `Something wrong`,
        description: result?.errorMessage,
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
    }
  };

  const onAddJobListingMark = async (jobId: string) => {
    await addJobMark({
      variables: { termId: termId, jobListingId: jobId },
      update(cache, { data }) {
        cache_addJobListingMark(cache, data);
        addJobListingMark(jobId);
        setXMarked(true);
      },
    }).catch((error) => {
      toast({
        description: `${error.message}`,
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
    });
  };

  const onDeleteJobListingMark = async (jobId: string) => {
    await deleteJobMark({
      variables: { termId: termId, jobListingId: jobId },
      update(cache, { data }) {
        cache_deleteJobListingMark(cache, data);
        deleteJobListingMark(jobId);
        setXMarked(false);
      },
    }).catch((error) => {
      toast({
        description: `${error.message}`,
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
    });
  };

  const setXMark = (jobId: string) => {
    if (xMarked) {
      onDeleteJobListingMark(jobId);
    } else {
      onAddJobListingMark(jobId);
    }
  };

  const updateJobAppStatus = async (_id: string, status: JobAppStatus) => {
    const updateJobAppArgs: JobAppTypes.UpdateJobAppByStatusArgs = {
      updateJobApp: { _id: _id, status: status },
    };
    await updateJobAppByStatus({
      variables: updateJobAppArgs,
      update(cache, { data }) {
        cache_updateJobAppByStatus(cache, data, updateJobAppArgs);
      },
    }).catch((error) => {
      toast({
        description: `${error.message}`,
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
    });
  };

  if (shape === JobAppCardShape.SQUARE) {
    return (
      <JobAppCardSquare
        item={item}
        xMark={xMarked}
        upcomingReminder={reminder}
        viewApp={viewApp}
        handlers={{
          setXMark,
          updateJobAppStatus,
        }}
        props={props}
      />
    );
  }
  if (shape === JobAppCardShape.LONG) {
    return (
      <JobAppCardLong
        item={item}
        xMark={xMarked}
        upcomingReminder={reminder}
        viewApp={viewApp}
        handlers={{
          setXMark,
          updateJobAppStatus,
        }}
        props={props}
      />
    );
  }
  return <></>;
};

export default JobAppCardDisplay;
