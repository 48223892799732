import { useQuery } from "@apollo/client";
import { Box, Text } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { DocTitleSuffix } from "../../constants/constants";
import { LayerStyles } from "../../theme/layerStyles";
import { TextStyles } from "../../theme/textStyles";
import SampleServices from "../../graphql/services/sample.service";
import * as SampleTypes from "../../graphql/types/sample.type";
import SearchHeaderRow from "../../components/searchHeaderRow";
import PageNotFound from "./pageNotFound";
import ViewSampleDetail from "../adapters/sample/viewSampleDetail";

const Sample = () => {
  const { sampleId } = useParams();
  const { data, loading } = useQuery<SampleTypes.GetSampleByIdData, SampleTypes.GetSampleByIdArgs>(
    SampleServices.GET_SAMPLE_BY_ID,
    { variables: { id: sampleId } }
  );

  if (loading) {
    return <Text>Loading</Text>;
  }

  if (!data?.getSampleById) {
    return <PageNotFound />;
  }

  return (
    <Box layerStyle={LayerStyles.mainPanelChild}>
      <Helmet>
        <title>Sample {DocTitleSuffix}</title>
      </Helmet>
      <SearchHeaderRow goBack={true} />
      <Text as="h1" textStyle={TextStyles.h1} pb="0rem">
        Sample Page
      </Text>
      {data?.getSampleById && <ViewSampleDetail sample={data.getSampleById} />}
    </Box>
  );
};

export default Sample;
