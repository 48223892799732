import dotenv from "dotenv";

dotenv.config({ path: `${process.cwd()}/.env` });

export enum NodeEnv {
  DEVELOPMENT = "DEVELOPMENT",
  STAGING = "STAGING",
  PRODUCTION = "PRODUCTION",
}

const config = {
  BUILD_ENV: process.env.REACT_APP_BUILD_ENV || "unknown",
  API: {
    APOLLO_URI: process.env.REACT_APP_API_APOLLO_URI || "unknown",
  },
};

export default config;
