import { Spacer, Text, LinkBox } from "@chakra-ui/react";
import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { ISample } from "../../schemas/sample.schema";
import { TextStyles } from "../../theme/textStyles";

const SampleCard: React.FC<{ sample: ISample }> = ({ sample }) => {
  return (
    <LinkBox
      display="flex"
      flexDirection="row"
      bg="white"
      borderRadius="1rem"
      margin="auto"
      padding="1rem"
      mb="1rem"
      width="100%"
      textStyle={TextStyles.text}
      boxShadow="0 2px 3px 0 rgba(0, 0, 0, 0.2)"
      as={ReactRouterLink}
      to={`/sample/${sample._id}`}
    >
      <Text>{sample._id}</Text>
      <Spacer />
      <Text>{sample._createdAt}</Text>
      <Spacer />
      <Text>{sample.name}</Text>
      <Spacer />
      <Text>
        {sample.age} year-old {sample.birthYear}
        {sample?.birthYear && `(born in ${sample.birthYear})`}
      </Text>
      <Spacer />
      <Text>
        {sample.isStudent ? "✅" : "❌"}
        Student
      </Text>
    </LinkBox>
  );
};

export default SampleCard;
