import React from "react";
import { Link as ReactRouterLink, useLocation } from "react-router-dom";
import { Badge, Box, Flex, Link, Spacer, Text } from "@chakra-ui/react";
import AppRoutes from "./AppRoutes.constant";
import Button from "../components/button";
import SVGIcon, { SVGIconProps } from "../assets/icons";
import colors from "../theme/colors";
import { ReactUseState } from "./App";
import { LayerStyles } from "../theme/layerStyles";
import { ButtonVariants } from "../theme/components/button";
import LogNewJobModal from "./adapters/jobListing/logNewJobModal";
import { useHistory } from "react-router-dom";
import { TextStyles } from "../theme/textStyles";

interface NavbarProps {
  xLogin: ReactUseState<boolean>;
  logOut(): Promise<any>;
}

const Navbar: React.FC<NavbarProps> = ({ xLogin, logOut }) => {
  const pathname = useLocation().pathname;
  const history = useHistory();

  return (
    <Flex layerStyle={LayerStyles.navbar} direction="column">
      <Box mb="2rem">
        <Box mb="1rem">
          <Link variant="navlink" as={ReactRouterLink} to={AppRoutes.home.route}>
            <Flex direction="row" alignItems="center" height="3rem">
              <SVGIcon name="brand-logo" size="9rem" />
              <Badge
                ml="1.5"
                colorScheme="red"
                height="fit-content"
                borderRadius="md"
                textTransform="none"
              >
                Alpha v0.1.0
              </Badge>
            </Flex>
          </Link>
        </Box>
        <Box mb="2rem">
          {navlinks?.map((link, key) => {
            return (
              (link.anonymousView || xLogin.data) && (
                <Link
                  key={key}
                  variant="navlink"
                  className="navlink-hover"
                  as={ReactRouterLink}
                  to={link.path}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <SVGIcon
                    {...link.icon}
                    fill={link.path === pathname ? colors.primary.solid : "text"}
                    size="1.2rem"
                    sx={{
                      ".navlink-hover:hover &": { fill: colors.primary.solid },
                    }}
                  />
                  <Text
                    textStyle={TextStyles.navlink}
                    paddingLeft="1rem"
                    color={link.path === pathname ? undefined : "text"}
                  >
                    {link.text}
                  </Text>
                </Link>
              )
            );
          })}
        </Box>
        {xLogin.data && (
          <LogNewJobModal
            triggerBtnProps={{
              variant: ButtonVariants.primaryFillRound,
              textStyle: "primaryFillBtn_lg",
              text: "LOG NEW APPLICATION",
            }}
          />
        )}
      </Box>
      {xLogin.data && (
        <>
          <Spacer />
          <Button
            text="Log Out"
            textStyle="secondaryBtn"
            variant={ButtonVariants.greyLineRound}
            padding="0.2rem"
            onClick={() => logOut().then(history.push(AppRoutes.home.route))}
          ></Button>
        </>
      )}
    </Flex>
  );
};

interface NavLinkProps {
  icon: SVGIconProps;
  text: string;
  path: string;
  anonymousView: boolean;
}

const navlinks: NavLinkProps[] = [
  {
    text: "Explore",
    path: AppRoutes.explore.route,
    icon: { name: "earth-line" },
    anonymousView: true,
  },
  {
    text: "Companies",
    path: AppRoutes.companies.route,
    icon: { name: "building-line" },
    anonymousView: true,
  },
  {
    text: "My Applications",
    path: AppRoutes.myApps.route,
    icon: { name: "my-apps" },
    anonymousView: false,
  },
  {
    text: "My Profile",
    path: AppRoutes.profile.route,
    icon: { name: "happy-face" },
    anonymousView: false,
  },
  // {
  //   text: "Prep Notes",
  //   path: AppRoutes.prep.route,
  //   icon: { name: "sticky-note-line" },
  //   anonymousView: false,
  // },
];

export default Navbar;
