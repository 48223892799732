import React from "react";
import { Flex, Spacer } from "@chakra-ui/react";
import Footer from "../components/footer";
import ProgressStats from "../components/progressStats";
import { LayerStyles } from "../theme/layerStyles";
import { IJobApp } from "../schemas/app.schema";

interface InfoBarProps {
  jobAppsByTerm: IJobApp[];
  xLogin: boolean;
  [key: string]: any;
}

const InfoBar: React.FC<InfoBarProps> = ({ jobAppsByTerm, xLogin, ...props }) => {
  return (
    <Flex direction="column" layerStyle={LayerStyles.infoBar} {...props}>
      {xLogin && (
        <>
          <ProgressStats allApps={jobAppsByTerm} mb="2rem" />
          <Spacer />
        </>
      )}
      <Footer />
    </Flex>
  );
};

export default InfoBar;
