import React from "react";
import { Flex, Spacer } from "@chakra-ui/layout";
import {
  Checkbox,
  CircularProgress,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import ButtonIcon from "../buttonIcon";
import colors from "../../theme/colors";
import { LayerStyles } from "../../theme/layerStyles";
import { ReactUseState } from "../../containers/App";

interface CreateSampleCardProps {
  name: ReactUseState<string>;
  age: ReactUseState<number>;
  isStudent: ReactUseState<boolean>;
  loading: boolean;
  onCreateHandler(): void;
}

const CreateSampleCard: React.FC<CreateSampleCardProps> = ({
  name,
  age,
  isStudent,
  loading,
  onCreateHandler,
}) => {
  return (
    <Flex
      direction="row"
      layerStyle={LayerStyles.cardGeneral}
      alignItems="center"
      justifyContent="center"
      width="100%"
      py="0.5rem"
      mb="1rem"
    >
      <label htmlFor="sample-name">Name</label>
      <Input
        id="sample-name"
        value={name.data}
        onChange={(e) => name.setData(e.target.value)}
        placeholder="Enter a name"
        border="none"
        boxShadow="none"
        _focus={{ boxShadow: "none" }}
        maxWidth="15rem"
      />
      <Spacer />
      <label htmlFor="sample-age">Age</label>
      <NumberInput
        ml="0.5rem"
        id="sample-age"
        size="sm"
        maxW={100}
        value={age.data}
        onChange={(e) => age.setData(parseInt(e))}
        min={0}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <Spacer />
      <Checkbox isChecked={isStudent.data} onChange={(e) => isStudent.setData(e.target.checked)}>
        Is Student
      </Checkbox>
      <Spacer />
      {loading ? (
        <CircularProgress size="1.75rem" isIndeterminate color="green.300" />
      ) : (
        <ButtonIcon
          icon={{ name: "add-circle-line", size: "1.75rem", fill: colors.primary.solid }}
          onClick={() => onCreateHandler()}
        />
      )}
    </Flex>
  );
};

export default CreateSampleCard;
