import React, { useEffect, useState } from "react";
import { Box, Text, Flex, Spacer, useToast } from "@chakra-ui/react";
import { ToastStatus } from "../../constants/constants";
import StepBar from "../../components/stepBar";
import Form, { InputProps } from "../../components/form";
import { TextStyles } from "../../theme/textStyles";
import Button from "../../components/button";
import { validateEmailAddress } from "../../utils";
import { ButtonVariants } from "../../theme/components/button";
import { ReactUseState } from "../App";

export interface EmailSubmitPorps {
  step: ReactUseState<number>;
}

const EmailInfo: React.FC<EmailSubmitPorps> = ({ step }) => {
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [isFormFilled, setIsFormFilled] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (email) {
      setIsFormFilled(true);
    } else {
      setIsFormFilled(false);
    }
  }, [email]);

  const emailSubmitHandler = () => {
    if (!validateEmailAddress(email)) {
      toast({
        description: "Please enter valid email address",
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
    } else {
      //email request verification
      step.setData(2);
    }
  };

  const emailInfoInput: InputProps[] = [
    {
      label: "Email",
      value: { data: email, setData: setEmail },
      isRequired: true,
      type: "email",
      placeholder: "Enter email",
    },
  ];

  return (
    <Box>
      <StepBar steps={3} currentStep={1} />
      <Text mb="1.5rem" textStyle={TextStyles.text} color="grey.600">
        Enter the email associated with your account
      </Text>
      <Form inputs={emailInfoInput} mb="1.5rem" />
      <Flex mb="2rem">
        <Spacer />
        <Button
          variant={ButtonVariants.primaryFillSquare}
          text="Submit"
          textStyle={TextStyles.primaryFillBtn_lg}
          margin="auto"
          onClick={emailSubmitHandler}
          isDisabled={!isFormFilled}
          padding="0.25rem 0.5rem"
          justify="end"
        />
      </Flex>
    </Box>
  );
};

export default EmailInfo;
