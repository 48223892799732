import React, { ReactNode } from "react";
import { Grid } from "@chakra-ui/react";

export interface GridLayoutProps {
  columns: number | number[];
  spacing: number | number[];
  items?: ReactNode;
  [key: string]: any;
}

const GridLayout: React.FC<GridLayoutProps> = ({ columns, spacing, items, ...props }) => {
  return (
    <Grid
      templateColumns={`repeat(${columns}, 1fr)`}
      gap={spacing}
      justifyItems="center"
      mb="3rem"
      {...props}
    >
      {items}
    </Grid>
  );
};

export default GridLayout;
