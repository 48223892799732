import {
  AlertDialog as ChakraAlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React, { ReactNode, useRef } from "react";
import { TextStyles } from "../theme/textStyles";
import Button, { ButtonProps } from "./button";

export interface AlertDialogProps {
  triggerBtnProps: ButtonProps;
  header: { textStyle?: string; text: string };
  body: ReactNode;
  deleteHandler(): void;
}

const AlertDialog: React.FC<AlertDialogProps> = ({
  triggerBtnProps,
  header,
  body,
  deleteHandler,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);

  const onDelete = () => {
    deleteHandler();
    onClose();
  };

  return (
    <>
      <Button {...triggerBtnProps} onClick={onOpen} />
      <ChakraAlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader textStyle={header?.textStyle ? header.textStyle : TextStyles.h2}>
              {header.text}
            </AlertDialogHeader>
            <AlertDialogBody>{body}</AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} text="Cancel" onClick={onClose} />
              <Button text="Delete" onClick={() => onDelete()} ml={3} />
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </ChakraAlertDialog>
    </>
  );
};

export default AlertDialog;
