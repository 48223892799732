import { DocumentNode, gql } from "@apollo/client";
import { OPERATION_STATUS_FRAGMENT } from "../fragments/common.fragment";

export interface UpdateMutationHelperProps {
  [key: string]: boolean | undefined;
}

export interface UpdateMutationPlaceholderProps {
  params: string;
  queries: string;
}

export interface UpdateMutationParamQueryPairProps {
  [key: string]: { param: string; query: string };
}

export const UpdateMutationHelper = (
  mutationName: string,
  placeholder: UpdateMutationPlaceholderProps,
  props: UpdateMutationHelperProps,
  paramQueryPair: UpdateMutationParamQueryPairProps
): DocumentNode => {
  const paramArr: string[] = [];
  const queryArr: string[] = [];
  Object.entries(props).forEach(([key, value]) => {
    if (value === true) {
      const pair = paramQueryPair[key];
      paramArr.push(pair.param);
      queryArr.push(pair.query);
    }
  });
  const params = paramArr.length > 0 ? paramArr.join(",") : placeholder.params;
  const queries = queryArr.length > 0 ? queryArr.join("\n") : placeholder.queries;
  return gql`
      ${OPERATION_STATUS_FRAGMENT}
      mutation ${mutationName}(
          ${params}
      ) {
          ${queries}
      }`;
};
