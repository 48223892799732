import { Box, Flex, Menu, MenuButton, MenuItem, MenuList, Spacer, Text } from "@chakra-ui/react";
import { FC } from "react";
import { PayBasis } from "../constants/constants";
import { TextStyles } from "../theme/textStyles";
import { ReactUseState } from "../containers/App";
import SVGIcon from "../assets/icons";
import { capitalizeStr } from "../utils";

interface PayBasisMenuProps {
  selected: ReactUseState<PayBasis>;
  [key: string]: any;
}

const PayBasisMenu: FC<PayBasisMenuProps> = ({ selected, ...props }) => {
  return (
    <Menu matchWidth>
      <MenuButton height="30px" maxWidth="8rem" justifyContent="start" {...props}>
        <Flex dir="row" alignItems="end" minWidth="6rem" pl="1rem">
          <Text isTruncated width="100%" textStyle={TextStyles.thin} textAlign="left">
            {capitalizeStr(selected.data)}
          </Text>
          <Spacer />
          <Box pt="0.2rem">
            <SVGIcon name="arrow-down-line" size="1.15rem" />
          </Box>
        </Flex>
      </MenuButton>
      <MenuList zIndex="3">
        {Object.keys(PayBasis).map((item = "", key) => (
          <MenuItem key={key} onClick={() => selected.setData(item as PayBasis)}>
            <Text noOfLines={1} textStyle={TextStyles.thin}>
              {capitalizeStr(item)}
            </Text>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default PayBasisMenu;
