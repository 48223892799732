import React from "react";
import { ReactUseState } from "../../containers/App";
import { ButtonVariants } from "../../theme/components/button";
import { LayerStyles } from "../../theme/layerStyles";
import { TextStyles } from "../../theme/textStyles";
import Dropdown, { DropdownProps, OptionProps } from "../dropdown/";

interface EventNoteTypeDropdownProps {
  selected: ReactUseState<OptionProps>;
  options: OptionProps[];
}

const EventNoteTypeDropdown: React.FC<EventNoteTypeDropdownProps> = ({ selected, options }) => {
  const newEventNoteDropdown: DropdownProps = {
    options: options,
    menuButtonProps: {
      variant: ButtonVariants.eventNotePicker,
      textStyle: "",
      isTextHidden: true,
      rightIcon: { name: "arrow-down-line", size: "1rem" },
      props: {
        _hover: { borderRadius: "0.25rem", backgroundColor: "grey.200" },
      },
    },
    menuListProps: { layerStyle: LayerStyles.cardGeneral, zIndex: 2 },
    menuItemProps: {
      variant: ButtonVariants.text,
      textStyle: TextStyles.h6,
      textStyleSelected: TextStyles.h6Underline,
      props: {
        _focus: { outline: "none", boxShadow: "none" },
        _hover: {
          outline: "none",
          boxShadow: "none",
          borderRadius: "0.2rem",
          backgroundColor: "grey.200",
        },
        width: "fit-content",
        padding: "0.45rem 0.5rem",
        justifyContent: "start",
      },
    },
    selectedMenuItem: selected,
  };
  return <Dropdown {...newEventNoteDropdown} />;
};

export default EventNoteTypeDropdown;
