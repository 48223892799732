import { ApolloCache, StoreObject, useMutation } from "@apollo/client";
import { Flex, Input, Spinner, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { JobAppEventType, JobAppStatus, ToastStatus } from "../../../constants/constants";
import { ReactUseState } from "../../App";
import { IJobAppEvent } from "../../../schemas/app.schema";
import colors from "../../../theme/colors";
import { LayerStyles } from "../../../theme/layerStyles";
import ButtonIcon from "../../../components/buttonIcon";
import { OptionProps } from "../../../components/dropdown";
import EventNoteTypeDropdown from "../../../components/eventNote/eventNoteTypeDropdown";
import * as JobAppEventTypes from "../../../graphql/types/jobAppEvent.type";
import JobAppEventServices from "../../../graphql/services/jobAppEvent.services";
import { capitalizeStr } from "../../../utils";
import { JOB_APP_EVENT_CORE_FRAGMENT } from "../../../graphql/fragments/jobAppEvent.fragment";
import { OperationStatus } from "../../../graphql/types/common.type";

interface NewEventNoteProps {
  appId: string;
  stage: JobAppStatus;
  events: ReactUseState<IJobAppEvent[]>;
}

const options: OptionProps[] = [
  {
    text: capitalizeStr(JobAppEventType.REMINDER),
    leftIcon: { name: "notification-fill", size: "1rem", fill: colors.primary.solid },
    value: JobAppEventType.REMINDER,
  },
  // FEATURES CURRENTLY NOT SUPPORTED
  // {
  //   text: capitalizeStr(JobAppEventType.EXPERIENCE),
  //   leftIcon: { name: "file-list-fill", size: "1rem", fill: colors.primary.solid },
  //   value: JobAppEventType.EXPERIENCE,
  // },
  // {
  //   text: capitalizeStr(JobAppEventType.NOTE),
  //   leftIcon: { name: "sticky-note-fill", size: "1rem", fill: colors.primary.solid },
  //   value: JobAppEventType.NOTE,
  // },
];

const NewEventNote: React.FC<NewEventNoteProps> = ({ appId, stage, events }) => {
  const toast = useToast();

  const DEFAULT_TITLE = "";
  const DEFAULT_DURATION = 60;
  const TOMORROW_MILLSECONDS = 1000 * 60 * 60 * 24;
  const [title, setTitle] = useState(DEFAULT_TITLE);
  // const [time, setTime] = useState<string>(timestamp2ISO(Date.now() + TOMORROW_MILLSECONDS));
  const [selected, setSelected] = useState(options[0]);
  const [type, setType] = useState(selected.value);
  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    setType(selected.value);
  }, [selected]);

  const clearInputs = () => {
    setTitle(DEFAULT_TITLE);
    setIsInvalid(false);
  };

  const [createJobAppEvent, { loading }] = useMutation<
    JobAppEventTypes.CreateJobAppEventData,
    JobAppEventTypes.CreateJobAppEventArgs
  >(JobAppEventServices.CREATE_JOB_APP_EVENT);

  const cache_createJobAppEvent = (
    cache: ApolloCache<any>,
    data: JobAppEventTypes.CreateJobAppEventData | null | undefined
  ) => {
    const result = data?.createJobAppEvent;
    if ((result as IJobAppEvent)._id) {
      const newEvent: IJobAppEvent = result as IJobAppEvent;
      toast({
        title: `Event created successfully`,
        position: "bottom",
        status: ToastStatus.SUCCESS,
        isClosable: true,
      });
      const newEventRef = cache.writeFragment({
        data: newEvent,
        fragment: JOB_APP_EVENT_CORE_FRAGMENT,
      });
      cache.modify({
        fields: {
          getJobAppEventsByJobAppId(existing: StoreObject[] = [], { readField }) {
            if (existing.some((ref) => readField("_id", ref) === newEvent._id)) {
              return existing;
            }
            return [...existing, newEventRef];
          },
        },
      });
      events.setData([...events.data, newEvent]);
      clearInputs();
    } else {
      const status: OperationStatus = result as OperationStatus;
      toast({
        title: status?.errorTitle,
        description: status?.errorMessage,
        position: "bottom",
        status: ToastStatus.ERROR,
        variant: "subtle",
        isClosable: true,
      });
    }
  };

  const onCreateReminder = async () => {
    const newEvent: JobAppEventTypes.CreateJobAppEventInput = {
      jobAppId: appId,
      status: stage,
      title: title,
      time: Date.now() + TOMORROW_MILLSECONDS,
      duration: DEFAULT_DURATION,
    };
    const newEventArgs: JobAppEventTypes.CreateJobAppEventArgs = {
      newJobAppEvent: newEvent,
    };
    await createJobAppEvent({
      variables: newEventArgs,
      update(cache, { data }) {
        cache_createJobAppEvent(cache, data);
      },
    });
  };

  const onCreateNote = async () => {
    // TODO
  };

  const onCreateHandler = async () => {
    if (title.length === 0) {
      toast({
        description: "Please enter a valid title",
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
        // variant: "subtle",
      });
      setIsInvalid(true);
      return;
    }
    setIsInvalid(false);
    if (type === JobAppEventType.REMINDER) {
      await onCreateReminder();
    } else if (type === JobAppEventType.NOTE) {
      await onCreateNote();
    }
  };

  return (
    <Flex
      direction="row"
      layerStyle={LayerStyles.cardGeneral}
      alignItems="center"
      justifyContent="center"
      width="100%"
      py="0.5rem"
      mb="1rem"
    >
      <EventNoteTypeDropdown
        selected={{ data: selected, setData: setSelected }}
        options={options}
      />
      <Input
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder={
          selected.value === JobAppEventType.REMINDER ? "Create an event" : "Create a note"
        }
        border="none"
        boxShadow="none"
        _focus={{ boxShadow: "none" }}
        isInvalid={isInvalid}
      />
      {loading ? (
        <Spinner thickness="2px" size="md" color="primary.solid" />
      ) : (
        <ButtonIcon
          icon={{ name: "add-circle-line", size: "1.75rem", fill: colors.primary.solid }}
          onClick={() => onCreateHandler()}
        />
      )}
    </Flex>
  );
};

export default NewEventNote;
