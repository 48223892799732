import Modal from "../components/modal";
import React, { useEffect, useState } from "react";
import { ButtonVariants } from "../theme/components/button";
import { TextStyles } from "../theme/textStyles";
import { useToast } from "@chakra-ui/react";
import { ToastStatus } from "../constants/constants";
import StudentInfo from "../components/studentVerificationSubs/studentInfo";
import ConfirmDigitField from "../components/studentVerificationSubs/confirmDigitField";
import { IStudentVerificaton } from "../schemas/user.schema";
import StepBar from "./stepBar";
import { getMonthDateYear, validateEmailAddress } from "../utils";
import { ReactUseState } from "../containers/App";

interface StudentVerificationModalProps {
  studentVerification?: ReactUseState<IStudentVerificaton | undefined>;
}

const StudentVerificationModal: React.FC<StudentVerificationModalProps> = ({
  studentVerification,
}) => {
  const [step, setStep] = useState(1);
  const [verified, setVerified] = useState<boolean>(studentVerification?.data ? true : false);
  const [verifyBtnText, setVerifyBtnText] = useState("Verify Me");
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [univerisityName, setUniversityName] = useState("");
  const [universityEmail, setUniversityEmail] = useState("");
  const [graduationYear, setGraduationYear] = useState(2023);
  const [graduationMonth, setGraduationMonth] = useState(5);
  const [token, setToken] = useState("");

  const toast = useToast();

  useEffect(() => {
    verified
      ? setVerifyBtnText(
          "Verified until " + getMonthDateYear(studentVerification?.data?.expirationDate)
        )
      : setVerifyBtnText("Verify Me");
  }, [verified]);

  useEffect(() => {
    setVerified(studentVerification?.data ? true : false);
  }, [studentVerification?.data]);

  useEffect(() => {
    if (step === 1 && univerisityName && universityEmail && graduationYear) {
      setIsFormFilled(true);
    } else if (step === 2 && token?.length === 6) {
      setIsFormFilled(true);
    } else {
      setIsFormFilled(false);
    }
  }, [univerisityName, universityEmail, graduationYear, graduationMonth, token]);

  const createStudentVerficationToken = (): Boolean => {
    // TODO : graphql request
    // const newStudentVerification: IStudentVerificaton = {
    //   university: univerisityName,
    //   universityEmail: universityEmail,
    //   graduationYear: graduationYear,
    //   graduationMonth: graduationMonth,
    // };
    // console.log(newStudentVerification);
    // send request to generate token
    return true;
  };

  const verifyHandler = (): boolean => {
    if (!validateEmailAddress(universityEmail) || !universityEmail.endsWith(".edu")) {
      toast({
        description: "Please enter a valid university email address",
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
      return false;
    }
    //send verification code
    createStudentVerficationToken();
    setStep(2);
    return false; // do not close modal
  };

  const verifyToken = (): boolean => {
    // TODO: graphql request
    // send request to match token
    const mockToken = "123456";
    if (token !== mockToken) {
      toast({
        description: "Incorrect token",
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
      return false;
    }
    // if token matched, return new studen verification
    // const newStudentVerification: IStudentVerificaton = {
    //   university: univerisityName,
    //   universityEmail: universityEmail,
    //   graduationYear: graduationYear,
    //   graduationMonth: graduationMonth,
    //   expirationDate: Date.now() + 31536000000, // a year from now
    // };
    // console.log(newStudentVerification);
    // studentVerification?.setData(newStudentVerification);
    toast({
      description: "Student verified successfully",
      position: "bottom",
      status: ToastStatus.SUCCESS,
      isClosable: true,
    });
    return true;
  };

  const onSubmit = async (): Promise<boolean> => {
    if (step === 1) {
      return verifyHandler();
    } else if (step === 2) {
      return verifyToken();
    }
    return true;
  };

  return (
    <Modal
      btnProps={{
        variant: ButtonVariants.primaryLineRound,
        text: verifyBtnText,
        margin: "auto",
        isDisabled: verified,
      }}
      header="Student Verification"
      body={
        <>
          <StepBar steps={2} currentStep={step} mt="0rem" mb="2rem" />
          {step === 1 ? (
            <StudentInfo
              step={{ data: step, setData: setStep }}
              univerisityName={{ data: univerisityName, setData: setUniversityName }}
              universityEmail={{ data: universityEmail, setData: setUniversityEmail }}
              graduationYear={{ data: graduationYear, setData: setGraduationYear }}
              graduationMonth={{ data: graduationMonth, setData: setGraduationMonth }}
            />
          ) : (
            <ConfirmDigitField token={{ data: token, setData: setToken }} />
          )}
        </>
      }
      goBack={
        step === 1
          ? undefined
          : {
              text: "Back",
              variant: ButtonVariants.text,
              textStyle: TextStyles.text,
              onClick: () => setStep(1),
              leftIcon: { name: "arrow-left-line", fill: "black" },
              pl: "0",
            }
      }
      nextBtn={
        step === 1
          ? {
              text: "Verify",
              variant: ButtonVariants.primaryFillSquare,
              textStyle: TextStyles.primaryFillBtn_lg,
              padding: "0.25rem 0.5rem",
              margin: "auto",
              width: "80%",
              isDisabled: !isFormFilled,
              mt: "1rem",
            }
          : {
              text: "Submit",
              variant: ButtonVariants.primaryFillSquare,
              textStyle: TextStyles.primaryFillBtn_lg,
              padding: "0.25rem 0.5rem",
              width: "50%",
              isDisabled: !isFormFilled,
            }
      }
      onSubmit={onSubmit}
    ></Modal>
  );
};

export default StudentVerificationModal;
