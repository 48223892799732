import { Box, Text, Flex, HStack } from "@chakra-ui/react";
import { Chart, registerables } from "chart.js";
import { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { IJobApp } from "../schemas/app.schema";
import { JobAppStatus } from "../constants/constants";
import { TextStyles } from "../theme/textStyles";
import SVGIcon from "../assets/icons";
import { LayerStyles } from "../theme/layerStyles";

Chart.register(...registerables);

const options: any = {
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 0.8,
  plugins: {
    legend: {
      margin: 10,
      fullsize: false,
      maxWidth: 10,
      position: "bottom",
      align: "center",
      labels: {
        usePointStyle: true,
        pointStyle: "circle",
        textAlign: "left",
        padding: 15,
        boxWidth: 100,
      },
      onClick: false,
    },
  },
};

interface ProgressStatsProps {
  allApps: IJobApp[];
  [key: string]: any;
}

export interface PieChartLegendProps {
  data: number;
  label: string;
}

const ProgressStats: React.FC<ProgressStatsProps> = ({ allApps, ...props }) => {
  const defaultLegends: PieChartLegendProps[] = [
    { data: 0, label: "% Just applied" },
    { data: 0, label: "% Assessment" },
    { data: 0, label: "% Intervew" },
    { data: 0, label: "% Offer" },
  ];

  const [totalApps, setTotalApps] = useState(allApps.length);
  const backgroundColor = ["#FCD4A0", "#FBBDA0", "#FB7660", "#ED4540"];
  const hoverBackgroundColor = ["#FCD4A0", "#FBBDA0", "#FB7660", "#ED4540"];
  const [totalForwarded, setTotalForwarded] = useState(0);
  const [totalActive, setTotalActive] = useState(0);
  const [legends, setLegends] = useState<PieChartLegendProps[]>(defaultLegends);

  const calcLegends = () => {
    return {
      labels: legends.map((legend) => {
        return `${Math.round((legend.data * 1000) / (totalApps === 0 ? 1 : totalApps)) / 10}${
          legend.label
        }`;
      }),
      datasets: [
        {
          data: legends.map((legend) => legend.data),
          backgroundColor: backgroundColor,
          hoverBackgroundColor: hoverBackgroundColor,
        },
      ],
    };
  };
  const [data, setData] = useState<{ labels: any; datasets: any }>(calcLegends());

  const calcChartData = () => {
    let offer = 0;
    let interview = 0;
    let applied = 0;
    let assessment = 0;
    let forwarded = allApps.length;
    let active = allApps.length;

    allApps.map((app) => {
      switch (app.status) {
        case JobAppStatus.OFFER:
          active--;
          break;
        case JobAppStatus.REJECT:
          active--;
          break;
        case JobAppStatus.WITHDRAW:
          active--;
          break;
        case JobAppStatus.APPLIED:
          forwarded--;
          break;
        default:
          break;
      }
      if (app?.statusHistory?.offer) {
        offer++;
      } else if (app.statusHistory?.interview) {
        interview++;
      } else if (app.statusHistory?.assessment) {
        assessment++;
      } else {
        if (app.statusHistory?.withdraw || app.statusHistory?.reject) {
          forwarded--;
        }
        applied++;
      }
    });

    const newLegends: PieChartLegendProps[] = [
      { data: applied, label: "% Just applied" },
      { data: assessment, label: "% Assessment" },
      { data: interview, label: "% Intervew" },
      { data: offer, label: "% Offer" },
    ];

    setTotalForwarded(forwarded);
    setTotalActive(active);
    setLegends(newLegends);
  };

  useEffect(() => {
    setTotalApps(allApps.length);
    calcChartData();
  }, [allApps]);

  useEffect(() => {
    const newData = calcLegends();
    setData(newData);
  }, [legends]);

  return (
    <Box {...props}>
      <Flex dir="row" px="1rem" align="baseline" mb="0.75rem">
        <SVGIcon name="bar-chart" size="1rem" />
        <Text textStyle={TextStyles.h3} ml="0.6rem">
          Your Progress
        </Text>
      </Flex>
      <Box layerStyle={LayerStyles.greyComponent}>
        <HStack spacing="0.5rem" justifyContent="center" align="baseline">
          <Text textStyle={TextStyles.h1} mb="0px" color="primary.solid">
            {totalForwarded}
          </Text>
          <Text textStyle={TextStyles.h3} color="grey.600">
            / {totalApps}
          </Text>
        </HStack>
        <Text textStyle={TextStyles.h6} mb="0.35rem">
          applications forwarded
        </Text>
        <Box maxW="12rem" width="100%" height="fit-content" margin="auto" mb="0.5rem">
          <Doughnut data={data} options={options} />
        </Box>
        <Text textStyle={TextStyles.thin} mb="0.25rem">
          Data to be explained
        </Text>
        <Text
          textStyle={TextStyles.text}
          position="absolute"
          top="0"
          margin="10.45rem 7.65rem"
          zIndex="2"
        >
          {totalActive} <br /> Active
        </Text>
      </Box>
    </Box>
  );
};

export default ProgressStats;
