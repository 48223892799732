import { gql } from "@apollo/client";

export const SAMPLE_UPDATE_FIELDS_FRAGMENT = gql`
  fragment SampleUpdateFieldsFragment on Sample {
    name
    age
    isStudent
  }
`;

export const SAMPLE_CORE_FRAGMENT = gql`
  fragment SampleCoreFragment on Sample {
    _id
    _createdAt
    _deleted
    name
    age
    isStudent
  }
`;
