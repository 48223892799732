import { IJobAppEvent, IJobAppOffer } from "../schemas/app.schema";

export interface IReminder {
  text: string;
  time: number;
}
export const eventOffer2Reminder = (
  item: IJobAppEvent | IJobAppOffer | undefined
): IReminder | undefined => {
  const now: number = Date.now();
  if (item) {
    const event: IJobAppEvent = item as IJobAppEvent;
    const offer: IJobAppOffer = item as IJobAppOffer;
    if (event?.time && event.time > now) {
      return { text: event.title, time: event.time };
    } else if (offer?.deadline && offer.deadline > now) {
      return { text: "Offer Deadline", time: offer.deadline };
    }
  }
  return undefined;
};

const getMostRecentReminder = (
  r1: IReminder | undefined,
  r2: IReminder | undefined
): IReminder | undefined => {
  if (!r1 && !r2) {
    return undefined;
  } else if (!r2) {
    return r1;
  } else if (!r1) {
    return r2;
  }
  const timeDiff = r1.time - r2.time;
  if (timeDiff <= 0) {
    return r1;
  } else {
    return r2;
  }
};

export const getUpcomingEventReminder = (
  events: IJobAppEvent[],
  offers: IJobAppOffer[]
): IReminder | undefined => {
  const now: number = Date.now();
  const sortedEvents: IJobAppEvent[] = sortnull(events, "time", true);
  const sortedOffers: IJobAppOffer[] = sortnull(offers, "deadline", true);
  const upcomingEvent =
    sortedEvents.length > 0 ? sortedEvents.filter((event) => event.time > now)[0] : undefined;
  const upcomingOffer =
    sortedOffers.length > 0 ? sortedOffers.filter((offer) => offer.deadline > now)[0] : undefined;
  const event2Reminder = eventOffer2Reminder(upcomingEvent);
  const offer2Reminder = eventOffer2Reminder(upcomingOffer);
  return getMostRecentReminder(event2Reminder, offer2Reminder);
};

function sortnull(arr: any[], field: string, ascending: boolean) {
  if (typeof ascending === "undefined") ascending = true;

  const multiplier = ascending ? 1 : -1;

  function isNumber(n: any) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  const sorter = function (a: any, b: any) {
    if (a[field] === b[field])
      // identical? return 0
      return 0;
    else if (a[field] === null)
      // a is null? last
      return 1;
    else if (b[field] === null)
      // b is null? last
      return -1;
    else if (isNumber(a[field]) && isNumber(b[field]))
      return parseInt(a[field], 10) - parseInt(b[field], 10);
    // compare, negate if descending
    else return a[field].localeCompare(b[field]) * multiplier;
  };
  return [...arr].sort(sorter);
}
