import React from "react";
import { Button, Flex, Text } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import AppRoutes from "../containers/AppRoutes.constant";
import { TextStyles } from "../theme/textStyles";
import { LayerStyles } from "../theme/layerStyles";

const notificationBanner = () => {
  return (
    <Flex layerStyle={LayerStyles.notificationBanner}>
      <Text textStyle={TextStyles.h1} color="white" mb="0" mr="3rem">
        Keep track of your job applications today
      </Text>
      <Button
        as={ReactRouterLink}
        to={AppRoutes.login.route}
        minWidth="7rem"
        backgroundColor="#EE8169 !important"
        border="0.2rem solid white"
        mr="1rem"
      >
        <Text textStyle={TextStyles.h3} color="white">
          Log In
        </Text>
      </Button>
      <Button as={ReactRouterLink} to={AppRoutes.register.route} minWidth="7rem">
        <Text textStyle={TextStyles.h3} color="#EE8169">
          Register
        </Text>
      </Button>
    </Flex>
  );
};

export default notificationBanner;
