export const ButtonVariants = {
  primaryLineRound: "primaryLineRound",
  greyLineRound: "greyLineRound",
  primaryFillRound: "primaryFillRound",
  primaryFillSquare: "primaryFillSquare",
  primaryLineSquare: "primaryLineSquare",
  greyLineSquare: "greyLineSquare",
  greyFillSquare: "greyFillSquare",
  squareShadow: "squareShadow",
  text: "text",
  icon: "icon",
  progressBarIcon: "progressBarIcon",
  alertLineSquare: "alertLineSquare",
  eventNotePicker: "eventNotePicker",
};

const Button = {
  baseStyle: {
    display: "flex",
    align: "center",
    justifyContent: "center",
    borderRadius: "1.2rem",
    outline: "none !important",
    border: "none",
    borderImageWidth: "0px !important",
    backgroundColor: "white !important",
    width: "max-content",
    padding: "0px !important",
    _focus: { outline: "none", boxShadow: "none" },
    _hover: {
      outline: "none",
      boxShadow: "none",
      _disabled: {
        pointerEvents: "none",
      },
    },
    _disabled: {
      pointerEvents: "none",
    },
  },
  sizes: {},
  variants: {
    action: {
      background: "action.gradient",
      color: "white",
      padding: "0.1rem 0.25rem",
    },
    [ButtonVariants.primaryLineRound]: {
      border: "2px solid",
      borderColor: "primary.solid",
      background: "white",
      padding: "0.2rem",
      color: "primary.solid",
      backgroundColor: "transparent",
    },
    [ButtonVariants.primaryLineSquare]: {
      borderRadius: "0.5rem",
      border: "1px solid",
      borderColor: "primary.solid",
      color: "primary.solid",
      padding: "0.5rem",
      backgroundColor: "transparent",
    },
    [ButtonVariants.primaryFillRound]: {
      background: "primary.gradient",
      color: "white",
      padding: "0.5rem 1rem",
    },
    [ButtonVariants.primaryFillSquare]: {
      borderRadius: "0.5rem",
      background: "primary.gradient",
      color: "white",
      padding: "0.25rem",
    },
    [ButtonVariants.greyLineRound]: {
      border: "2px solid",
      borderColor: "grey.700",
      padding: "0.2rem",
      color: "grey.700",
      backgroundColor: "transparent",
    },
    [ButtonVariants.greyLineSquare]: {
      borderRadius: "0.5rem",
      border: "1px solid",
      borderColor: "grey.500",
      color: "grey.700",
      padding: "0.5rem 1rem",
      backgroundColor: "transparent",
    },
    [ButtonVariants.greyFillSquare]: {
      borderRadius: "0.5rem",
      backgroundColor: "grey.500",
      padding: "0.25rem",
    },
    [ButtonVariants.squareShadow]: {
      borderRadius: "0.5rem",
      padding: "0.5rem",
      boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.2)",
    },
    [ButtonVariants.text]: {
      backgroundColor: "transparent",
      padding: "0.5rem 1rem",
    },
    secondary: {
      border: "1px solid",
      borderColor: "grey.700",
      padding: "0.2rem",
    },
    modalFull: {
      padding: "1rem",
      background: "primary.gradient",
      color: "white",
      borderRadius: "0.5rem",
      width: "100%",
    },
    [ButtonVariants.progressBarIcon]: {
      mx: "0.2rem",
      backgroundColor: "transparent !important",
      boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.2)",
      height: "fit-content",
      width: "fit-content",
      minWidth: "",
    },
    [ButtonVariants.alertLineSquare]: {
      borderRadius: "0.5rem",
      border: "1px solid",
      borderColor: "alert",
      color: "alert",
      padding: "0.5rem",
      backgroundColor: "transparent",
    },
    [ButtonVariants.eventNotePicker]: {
      background: "transparent",
      borderRadius: "0.25rem",
      _hover: { backgroundColor: "grey.200" },
    },
    iconFill: {
      background: "primary.gradient",
      borderRadius: "100%",
      height: "100%",
      width: "100%",
      padding: "1rem",
      boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.2)",
      _hover: { boxShadow: "none" },
    },
    iconLine: {
      background: "white",
      borderRadius: "100%",
      // height: "100%",
      // width: "100%",
      // padding: "0.5rem",
      border: "1px !important",
      borderColor: "primary.solid",
      boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.2)",
      _hover: { boxShadow: "none" },
    },
    netPromoter: {
      display: "flex",
      flexDirection: "column",
      height: "auto",
      padding: "0.5rem",
      _hover: { backgroundColor: "#F2F2F2 !important" },
    },
    iconBtn: {
      boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.2)",
      borderRadius: "100%",
      border: "!important",
      borderWidth: "4px !important",
      borderColor: "primary.solid",
    },
    iconBtnFull: {
      backgroundColor: "transparent !important",
      // boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.2)",
      height: "fit-content",
      width: "fit-content",
      borderRadius: "100%",
      minWidth: "",
      minHeight: "",
    },
    [ButtonVariants.icon]: {
      height: "fit-content",
      justifyContent: "end",
      backgroundColor: "transparent",
      userSelect: "none",
    },
    text: {
      backgroundColor: "transparent",
      padding: "0.5rem 1rem",
    },
  },
};

export default Button;
