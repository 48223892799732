import { IJobApp } from "../schemas/app.schema";
import { IJobListing } from "../schemas/job.schema";

export interface IMergedJobApp {
  job: IJobListing;
  app?: IJobApp;
}

export const initMergedJobApps = (jobs?: IJobListing[]): IMergedJobApp[] => {
  const data = jobs
    ? jobs.map((job) => {
        return { job: job };
      })
    : [];
  return data;
};

export const mergeJobWithApp = (app: IJobApp, job: IJobListing): IMergedJobApp => {
  return { app: app, job: job };
};

export const mergeJobsWithApps = (apps: IJobApp[], jobs: IJobListing[]): IMergedJobApp[] => {
  let mergedJobsApps: IMergedJobApp[] = jobs.map((job) => {
    return { job: job };
  });
  const hashmap_allApps: Map<string, IJobApp> = new Map(); // jobId : JobApp
  apps?.map((item) => {
    hashmap_allApps.set(item.job?._id || item.jobid || "000", item);
  });
  mergedJobsApps = mergedJobsApps.map((item) => {
    const app = hashmap_allApps.get(item.job._id);
    return app ? { ...item, app: app } : item;
  });
  return mergedJobsApps;
};
