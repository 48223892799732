import { DocumentNode, gql } from "@apollo/client";
import { OPERATION_STATUS_FRAGMENT } from "../../fragments/common.fragment";
import { JOB_APP_TERM_DISPLAY_FRAGMENT } from "../../fragments/jobAppTerm.fragment";
import {
  UpdateMutationParamQueryPairProps,
  UpdateMutationHelper,
  UpdateMutationHelperProps,
  UpdateMutationPlaceholderProps,
} from "../serviceHelper";

const CREATE_JOB_APP_TERM = gql`
  ${JOB_APP_TERM_DISPLAY_FRAGMENT}
  ${OPERATION_STATUS_FRAGMENT}
  mutation CreateJobAppTermMutation($newJobAppTerm: CreateJobAppTermInput!) {
    createJobAppTerm(newJobAppTerm: $newJobAppTerm) {
      ... on JobAppTerm {
        ...JobAppTermDisplayFragment
      }
      ... on OperationStatus {
        ...OperationStatusFragment
      }
    }
  }
`;

const UDPATE_JOB_APP_TERM_BY_TITLE = `
updateJobAppTermByTitle(updateJobAppTerm: $updateJobAppTermByTitle) {
  ...OperationStatusFragment
}
`;

const UDPATE_JOB_APP_TERM_BY_XCOMPLTED = `
updateJobAppTermByXCompleted(updateJobAppTerm: $updateJobAppTermByXCompleted) {
  ...OperationStatusFragment
}
`;

export interface UpdaterJobAppTermMutationProps {
  title?: boolean;
  xCompleted?: boolean;
}

const updateJobAppTermMutationParamQueryPair: UpdateMutationParamQueryPairProps = {
  title: {
    param: "$updateJobAppTermByTitle: UpdateJobAppTermByTitleInput!",
    query: UDPATE_JOB_APP_TERM_BY_TITLE,
  },
  xCompleted: {
    param: "$updateJobAppTermByXCompleted: UpdateJobAppTermByXCompletedInput!",
    query: UDPATE_JOB_APP_TERM_BY_XCOMPLTED,
  },
};

const UPDATE_JOB_APP_TERM = (props: UpdaterJobAppTermMutationProps): DocumentNode => {
  const mutationName = "UpdateJobAppTermMutation";
  const placeholder: UpdateMutationPlaceholderProps = {
    params: `$placeholder: UpdateJobAppTermByTitleInput!`,
    queries: `placeholder(placeholder: $placeholder) {_id}`,
  };
  return UpdateMutationHelper(
    mutationName,
    placeholder,
    props as UpdateMutationHelperProps,
    updateJobAppTermMutationParamQueryPair
  );
};

const DELETE_JOB_APP_TERM = gql`
  ${OPERATION_STATUS_FRAGMENT}
  mutation DeleteJobAppTermMutation($id: ObjectId!) {
    deleteJobAppTerm(_id: $id) {
      ...OperationStatusFragment
    }
  }
`;

const CREATE_JOB_LISTING_MARK = gql`
  ${OPERATION_STATUS_FRAGMENT}
  mutation CreateJobListingMarkMutation($termId: ID!, $jobListingId: ID!) {
    createJobListingMark(termId: $termId, jobListingId: $jobListingId) {
      ...OperationStatusFragment
    }
  }
`;

const DELETE_JOB_LISTING_MARK = gql`
  ${OPERATION_STATUS_FRAGMENT}
  mutation DeleteJobListingMarkMutation($termId: ID!, $jobListingId: ID!) {
    deleteJobListingMark(termId: $termId, jobListingId: $jobListingId) {
      ...OperationStatusFragment
    }
  }
`;

const JobAppTermMutations = {
  CREATE_JOB_APP_TERM,
  UPDATE_JOB_APP_TERM,
  DELETE_JOB_APP_TERM,
  CREATE_JOB_LISTING_MARK,
  DELETE_JOB_LISTING_MARK,
};

export default JobAppTermMutations;
