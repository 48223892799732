import React from "react";
import { Badge } from "@chakra-ui/react";
import { JobListingType } from "../../constants/constants";

interface JobTypeBadgeForCardProps {
  type: JobListingType | undefined;
}

const JobTypeBadgeForCard: React.FC<JobTypeBadgeForCardProps> = ({ type }) => {
  const palettes: { [type: string]: { background: string; color: string; text: string } } = {
    [JobListingType.NEWGRAD]: { background: "#E7F9FC", color: "#176C78", text: "NewGrad" },
    [JobListingType.INTERNSHIP]: { background: "#EFFBDC", color: "#4B7905", text: "Intern" },
    [JobListingType.EXPERIENCED]: { background: "#FBEBDC", color: "#DF6F08", text: "Experienced" },
  };

  if (!type) {
    return <></>;
  }

  let selectedPalette = palettes[type];

  return (
    <Badge
      background={selectedPalette.background}
      color={selectedPalette.color}
      fontSize="12px"
      fontWeight="400"
      left="-5%"
      top="-5%"
      width="max-content"
      borderRadius="15px"
      position="absolute"
      textTransform="none"
      px="0.5rem"
    >
      {selectedPalette.text}
    </Badge>
  );
};

export default JobTypeBadgeForCard;
