import { gql } from "@apollo/client";
import { OPERATION_STATUS_FRAGMENT } from "../../fragments/common.fragment";

const CREATE_SUBSCRIBER = gql`
  ${OPERATION_STATUS_FRAGMENT}
  mutation CreateSubscriber($newSubscriber: CreateSubscriberInput!) {
    createSubscriber(newSubscriber: $newSubscriber) {
      ...OperationStatusFragment
    }
  }
`;

const UNSUBSCRIBE = gql`
  ${OPERATION_STATUS_FRAGMENT}
  mutation Unsubscribe($email: String!) {
    unsubscribe(email: $email) {
      ...OperationStatusFragment
    }
  }
`;

const SubscriberMutations = {
  CREATE_SUBSCRIBER,
  UNSUBSCRIBE,
};

export default SubscriberMutations;
