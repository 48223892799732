import React, { useEffect, useState } from "react";
import { Box, Flex, Spacer, Text } from "@chakra-ui/react";
import StatusTabBar from "../../components/statusTabBar";
import AppRoutes from "../AppRoutes.constant";
import { DocTitleSuffix, JobFilterType, MyAppCategory, Sorting } from "../../constants/constants";
import { Helmet } from "react-helmet";
import { IJobApp } from "../../schemas/app.schema";
import { LayerStyles } from "../../theme/layerStyles";
import SectionHeader from "../../components/sectionHeader";
import { TextStyles } from "../../theme/textStyles";
import SearchHeaderRow from "../../components/searchHeaderRow";
import { OptionProps } from "../../components/dropdown/";
import JobAppTermMenu from "../adapters/jobAppTerm/jobAppTermMenu";
import SortJobAppMenu from "../adapters/jobApp/sortJobAppMenu";
import FilterJobAppMenu from "../adapters/jobApp/filterJobAppMenu";
import ViewJobAppsGrid from "../adapters/jobApp/viewJobAppsGrid";
import { currentJobAppTermVar, CurrentJobAppTermVarType } from "../../cache/reactiveVars";
import { useReactiveVar } from "@apollo/client";

interface MyAppsProps {
  jobAppsByTerm: IJobApp[];
}

export interface ITab {
  status: MyAppCategory;
  noOfApps?: number;
  apps?: IJobApp[];
}

const MyApps: React.FC<MyAppsProps> = ({ jobAppsByTerm }) => {
  const uid = localStorage.getItem("uid") || "";
  const currentTerm: CurrentJobAppTermVarType = useReactiveVar(currentJobAppTermVar);

  const options: OptionProps[] = [
    // { text: "Marked", checkbox: false },
    // { text: "INTERN", checkbox: false },
    // { text: "NEW GRAD", checkbox: false },
    // { text: "EXPERIENCED", checkbox: false },
  ];

  const sortedTabs: ITab[] = [
    { status: MyAppCategory.ALL },
    { status: MyAppCategory.APPLIED },
    { status: MyAppCategory.ARCHIVED },
    { status: MyAppCategory.ASSESSMENT },
    { status: MyAppCategory.INTERVIEW },
    { status: MyAppCategory.OFFER },
  ];

  const [tabs, setTabs] = useState(sortedTabs);
  const [selectedTab, setSelectedTab] = useState<ITab>(sortedTabs[0]);
  const [jobMarks, setJobMarks] = useState<Set<string>>(new Set());
  const [selectedFilters, setSelectedFilters] = useState<OptionProps[]>(options);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box layerStyle={LayerStyles.mainPanelChild} pt="0">
      <Helmet>
        <title>
          {AppRoutes.myApps.title}
          {DocTitleSuffix}
        </title>
      </Helmet>
      <SearchHeaderRow />
      <Text as="h1" textStyle={TextStyles.h1}>
        My Applications
      </Text>
      <JobAppTermMenu uid={uid} mb="1rem" />
      <Flex dir="row" alignItems="center" mb="1.5rem" px="0.5rem">
        {/* <SortJobAppMenu sorting={{ data: sorting, setData: setSorting }} />
        <Spacer />
        <FilterJobAppMenu filters={{ data: filters, setData: setFilters }} /> */}
      </Flex>
      <StatusTabBar
        tabs={tabs}
        selectedTab={{ data: selectedTab, setData: setSelectedTab }}
        mb="1.75rem"
      />
      <SectionHeader text="Upcomings" textStyle="sectionHeader" />
      {currentTerm?._id && (
        <ViewJobAppsGrid
          jobAppsByTerm={jobAppsByTerm}
          termId={currentTerm._id}
          tabs={{ data: tabs, setData: setTabs }}
          selectedTab={{ data: selectedTab, setData: setSelectedTab }}
          selectedFilters={{ data: selectedFilters, setData: setSelectedFilters }}
          jobMarks={jobMarks}
        />
      )}
    </Box>
  );
};

export default MyApps;
