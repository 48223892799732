import React, { useEffect } from "react";
import { Box, Text } from "@chakra-ui/react";
import AppRoutes from "../AppRoutes.constant";
import { Helmet } from "react-helmet";
import { DocTitleSuffix } from "../../constants/constants";
import { LayerStyles } from "../../theme/layerStyles";
import { TextStyles } from "../../theme/textStyles";
import { loggedInUserVar } from "../../cache/reactiveVars";
import { useLogIn } from "../../hooks/useLogIn";
import Divider from "../../components/divider";
import AccountProfile from "../adapters/account/profile";
import AccountCredentials from "../adapters/account/credentials";
import AccountSetting from "../adapters/account/setting";

const Account = () => {
  const { getUser } = useLogIn(loggedInUserVar);

  const thisUser = getUser();
  const uid = thisUser?._id || "";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!thisUser) {
    return <></>;
  }

  return (
    <Box layerStyle={LayerStyles.mainPanelChild} mt="3.5rem" maxW="24rem">
      <Helmet>
        <title>
          {AppRoutes.profile.title}
          {DocTitleSuffix}
        </title>
      </Helmet>
      <Text as="h1" textStyle={TextStyles.h1}>
        My Profile
      </Text>
      <AccountProfile
        uid={uid}
        _email={thisUser.email}
        _firstName={thisUser.firstName}
        _lastName={thisUser.lastName}
        _username={thisUser.username}
        _currentStudentVerfication={
          thisUser.studentVerifications
            ? thisUser.studentVerifications[thisUser.studentVerifications.length - 1]
            : undefined
        }
      />
      <Divider my="2rem" />
      <AccountSetting
        uid={uid}
        _autoArchive={thisUser.autoArchive}
        _daysToArchive={thisUser.daysToArchive}
        _shareOffer={thisUser.shareOffer}
        _shareProgress={thisUser.shareProgress}
      />
      <Divider my="2rem" />
      <AccountCredentials uid={uid} _email={thisUser.email} />
    </Box>
  );
};

export default Account;
