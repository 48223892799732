import {
  AccordionItem as ChakraAccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";

export interface AccordionItemProps {
  header: ReactNode;
  panel: ReactNode;
  itemProps?: any;
  [key: string]: any;
}

const AccordionItem: React.FC<AccordionItemProps> = ({ header, panel, itemProps, ...props }) => {
  return (
    <ChakraAccordionItem {...itemProps} {...props}>
      <h3>
        <AccordionButton
          border="none"
          _hover={{ background: "transparent" }}
          _focus={{ boxShadow: "none" }}
        >
          <Box width="100%" textAlign="left">
            {header}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h3>
      <AccordionPanel mt="0.5rem">{panel}</AccordionPanel>
    </ChakraAccordionItem>
  );
};

export default AccordionItem;
