import { gql } from "@apollo/client";
import { JOB_LISTING_CARD_DISPLAY_FRAGMENT } from "./jobListing.fragment";

export const COMPANY_CARD_DISPLAY_FRAGMENT = gql`
  fragment CompanyCardDisplayFragment on Company {
    _id
    name
    logo
    activeJobs {
      _id
    }
    numberOfActiveJobs
  }
`;

export const COMPANY_CORE_FRAGMENT = gql`
  ${JOB_LISTING_CARD_DISPLAY_FRAGMENT}
  fragment CompanyCoreFragment on Company {
    _id
    verified
    name
    logo
    linkedin
    industry
    headquarter
    activeJobs {
      ...JobListingCardDisplayFragment
    }
  }
`;
