import { ButtonProps } from "./button";
import { ButtonVariants } from "../theme/components/button";
import Form, { InputProps } from "./form";
import Modal from "./modal";
import { Box } from "@chakra-ui/react";
import React from "react";
import { TextStyles } from "../theme/textStyles";
import { ReactUseState } from "../containers/App";

interface JobTermCreateModalProps {
  title: ReactUseState<string>;
  termTitleMaxLength: number;
  onCreateHandler(): Promise<boolean>;
}

const JobTermCreateModal: React.FC<JobTermCreateModalProps> = ({
  title,
  termTitleMaxLength,
  onCreateHandler,
}) => {
  const termTitleInput: InputProps[] = [
    {
      label: "Term title",
      value: title,
      placeholder: "Enter job term title",
      maxLength: termTitleMaxLength,
    },
  ];

  const createBtn: ButtonProps = {
    variant: ButtonVariants.text,
    text: "✨ Create New Season",
    textStyle: TextStyles.h6,
    px: "0px",
    py: "0.25rem",
    justifyContent: "start",
  };

  const saveBtn: ButtonProps = {
    text: "Save",
    variant: ButtonVariants.primaryFillSquare,
    textStyle: TextStyles.primaryFillBtn_md,
    padding: "0.5rem",
    isDisabled: !title,
    margin: "auto",
  };

  return (
    <Modal
      btnProps={createBtn}
      header={"Add New Job Term"}
      body={
        <Box textAlign="center" mb="0.5rem">
          <Form inputs={termTitleInput} />
        </Box>
      }
      nextBtn={saveBtn}
      onSubmit={() => onCreateHandler()}
    />
  );
};

export default JobTermCreateModal;
