import React, { useEffect, useState } from "react";
import { Box, Text, Flex, Spacer, useToast } from "@chakra-ui/react";
import { ToastStatus } from "../../constants/constants";
import StepBar from "../../components/stepBar";
import Form, { InputProps } from "../../components/form";
import { TextStyles } from "../../theme/textStyles";
import Button from "../../components/button";
import { validateEmailAddress } from "../../utils";
import { ButtonVariants } from "../../theme/components/button";
import { ReactUseState } from "../App";

export interface VerificationCodePorps {
  step: ReactUseState<number>;
}

const VerificationCode: React.FC<VerificationCodePorps> = ({ step }) => {
  const toast = useToast();
  const [code, setCode] = useState("");
  const [isFormFilled, setIsFormFilled] = useState(false);
  const fakeCode = "123456";

  useEffect(() => {
    window.scrollTo(0, 0);
    if (code) {
      setIsFormFilled(true);
    } else {
      setIsFormFilled(false);
    }
  }, [code]);

  const emailSubmitHandler = () => {
    if (code !== fakeCode) {
      toast({
        description: "Verification Failed",
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
    } else {
      //email request verification
      step.setData(3);
    }
  };

  const codeInput: InputProps[] = [
    {
      label: "Veridication Code",
      value: { data: code, setData: setCode },
      isRequired: true,
      type: "password",
      placeholder: "xxx-xxx-xxx",
    },
  ];

  return (
    <Box>
      <StepBar steps={3} currentStep={2} />
      <Text mb="1.5rem" textStyle={TextStyles.text} color="grey.600">
        Enter the verification code we just sent you on your email address
      </Text>
      <Form inputs={codeInput} mb="1.5rem" />
      <Flex mb="2rem">
        <Button
          variant={ButtonVariants.text}
          fontWeight="400"
          text="Back"
          onClick={() => step.setData(1)}
          leftIcon={{ name: "arrow-left-line", fill: "black" }}
          pl="0"
        />
        <Spacer />
        <Button
          variant={ButtonVariants.primaryFillSquare}
          text="Verify"
          textStyle={TextStyles.primaryFillBtn_lg}
          margin="auto"
          onClick={emailSubmitHandler}
          isDisabled={!isFormFilled}
          padding="0.25rem 0.5rem"
          justify="end"
        />
      </Flex>
    </Box>
  );
};

export default VerificationCode;
