import { gql } from "@apollo/client";
import { USER_CORE_FRAGMENT } from "../fragments/user.fragment";

export const GET_LOGGED_IN_USER = gql`
  ${USER_CORE_FRAGMENT}
  query GetLoggedInUserQuery {
    getLoggedInUser @client {
      ...UserCoreFragment
    }
  }
`;
