import React from "react";
import { HStack, GridItem } from "@chakra-ui/react";

interface StepBarProps {
  steps: number;
  currentStep: number;
  [key: string]: any;
}

const StepBar: React.FC<StepBarProps> = ({ steps, currentStep, ...props }) => {
  return (
    <HStack spacing="0.25rem" justify="center" my="2rem" {...props}>
      {[...Array(currentStep)].map((item, key) => (
        <GridItem key={key} w="5rem" h="0.25rem" bg="primary.gradient" />
      ))}
      {[...Array(steps - currentStep)].map((item, key) => (
        <GridItem key={key} w="5rem" h="0.25rem" bg="grey.300" />
      ))}
    </HStack>
  );
};

export default StepBar;
