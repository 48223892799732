import { gql } from "@apollo/client";
import {
  COMPANY_CARD_DISPLAY_FRAGMENT,
  COMPANY_CORE_FRAGMENT,
} from "../../fragments/company.fragment";

const GET_COMPANIES = gql`
  ${COMPANY_CARD_DISPLAY_FRAGMENT}
  query GetCompanies($verified: Boolean, $offset: Int!, $limit: Int!, $sort: String) {
    getCompanies(limit: $limit, verified: $verified, offset: $offset, sort: $sort) {
      ...CompanyCardDisplayFragment
    }
  }
`;

const GET_COMPANY_BY_ID = gql`
  ${COMPANY_CORE_FRAGMENT}
  query GetCompanyById($id: ObjectId!) {
    getCompanyById(_id: $id) {
      ...CompanyCoreFragment
    }
  }
`;

const CompanyQueries = {
  GET_COMPANIES,
  GET_COMPANY_BY_ID,
};

export default CompanyQueries;
