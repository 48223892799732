import React, { ReactNode, useEffect, useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import { TextStyles } from "../../../theme/textStyles";
import { ReactUseState } from "../../App";
import { IJobAppOffer } from "../../../schemas/app.schema";
import { LayerStyles } from "../../../theme/layerStyles";
import ViewJobAppOfferDetail from "./viewJobAppOfferDetail";
import { JobAppOfferStatus } from "../../../constants/constants";
import CreateJobAppOffer from "./createJobAppOffer";
import { timestamp2ISO, timestamp2ISO16 } from "../../../utils";
import AccordionWrapper from "../../../components/accordion/accordionWrapper";

interface ViewJobAppOffersProps {
  appId: string;
  offers: ReactUseState<IJobAppOffer[]>;
}

const ViewJobAppOffers: React.FC<ViewJobAppOffersProps> = ({ appId, offers }) => {
  const calcDefaultOpenIndex = (): number[] => {
    const pendingIndex: number[] = [];
    offers.data.map((offer, key) => {
      if (offer.status === JobAppOfferStatus.PENDING) pendingIndex.push(key);
    });
    return pendingIndex;
  };

  const [defaultOpenIndex, setDefaultOpenIndex] = useState<number[]>(calcDefaultOpenIndex());

  useEffect(() => {
    setDefaultOpenIndex(calcDefaultOpenIndex());
  }, [offers.data]);

  const accordionItems: ReactNode = offers.data.map((offer, key) => {
    const title = key === 0 ? "Offer" : `Competing Offer #${key}`;
    return (
      <ViewJobAppOfferDetail
        key={key}
        title={title}
        _id={offer._id}
        _status={offer.status}
        _position={offer?.position || ""}
        _startDate={timestamp2ISO(offer?.startDate)}
        _deadline={timestamp2ISO16(offer.deadline)}
        _endDate={timestamp2ISO(offer?.endDate)}
        _payBasis={offer.payBasis}
        _payRate={offer.payRate}
        _performanceBonus={offer?.performanceBonus || 0}
        _stock={offer?.stock || 0}
        _vestingSchedules={offer?.vestingSchedules || [25, 25, 25, 25]}
        _signOnBonus={offer?.signOnBonus || 0}
        _relocation={offer?.relocation || 0}
        _location={offer?.location || ""}
        _benefits={offer?.benefits || []}
        _otherCompensation={offer?.otherCompensation || 0}
        _description={offer?.description || ""}
        offer={offer}
        itemProps={{
          layerStyle: LayerStyles.cardGeneral,
          py: "0.5rem",
          px: "0.5rem",
          mb: "1rem",
        }}
      />
    );
  });

  return (
    <Box>
      <Text textStyle={TextStyles.h2} as="h2" mb="1.5rem">
        🎉 Congrats on your offer
      </Text>
      {offers.data.length < 1 && (
        <Text textStyle={TextStyles.text} mb="0.75rem">
          Keep track of your offer info here!
        </Text>
      )}
      <AccordionWrapper
        items={accordionItems}
        defaultIndex={defaultOpenIndex}
        allowMultiple
        allowToggle
      />
      <CreateJobAppOffer appId={appId} offers={offers} />
    </Box>
  );
};

export default ViewJobAppOffers;
