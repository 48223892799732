import { gql } from "@apollo/client";

export const JOB_APP_TERM_DISPLAY_FRAGMENT = gql`
  fragment JobAppTermDisplayFragment on JobAppTerm {
    _id
    title
    xCompleted
    jobMarks
  }
`;

export const JOB_APP_TERM_UPDATE_FIELDS_FRAGMENT = gql`
  fragment JobAppTermUpdateFieldsFragment on JobAppTerm {
    title
    xCompleted
  }
`;

export const JOB_APP_TERM_CORE_FRAGMENT = gql`
  fragment JobAppTermCoreFragment on JobAppTerm {
    _id
    title
    xCompleted
    apps
    jobMarks
  }
`;

export const JOB_APP_TERM_JOB_LISTING_CARD_FRAGMENT = gql`
  fragment JobAppTermJobListingCardFragment on JobAppTerm {
    apps {
      _id
      job {
        _id
      }
      status
    }
    jobMarks
  }
`;
