import { gql } from "@apollo/client";
import { JOB_APP_TERM_DISPLAY_FRAGMENT } from "../../fragments/jobAppTerm.fragment";
import { JOB_APP_CARD_DISPLAY_FRAGMENT } from "../../fragments/jobApp.fragment";

const GET_ALL_TERMS_BY_UID = gql`
  ${JOB_APP_TERM_DISPLAY_FRAGMENT}
  query GetAllTermsByUidQuery($uid: ObjectId!) {
    getAllTermsByUid(uid: $uid) {
      ...JobAppTermDisplayFragment
    }
  }
`;

const GET_JOB_APP_BY_TERM_ID = gql`
  ${JOB_APP_CARD_DISPLAY_FRAGMENT}
  query GetJobAppsByTermIdQuery($id: ObjectId!) {
    getJobAppsByTermId(_id: $id) {
      ...JobAppCardDisplayFragment
    }
  }
`;

const JobAppTermQueries = {
  GET_ALL_TERMS_BY_UID,
  GET_JOB_APP_BY_TERM_ID,
};

export default JobAppTermQueries;
