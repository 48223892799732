import { Box, Text, PinInput, PinInputField, HStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ButtonVariants } from "../../theme/components/button";
import { TextStyles } from "../../theme/textStyles";
import Button from "../button";
import { ReactUseState } from "../../containers/App";

interface ConfirmDigitFieldProps {
  token: ReactUseState<string>;
}

const ConfirmDigitField: React.FC<ConfirmDigitFieldProps> = ({ token }) => {
  const [allowSendAgain, setAllowSendAgain] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [sendAgainBtnText, setSendAgainBtnText] = useState("");

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
      setSendAgainBtnText(`Resend in ${seconds}s`);
      setAllowSendAgain(false);
    } else {
      setSendAgainBtnText("Send again");
      setAllowSendAgain(true);
    }
  }, [seconds]);

  const sendAgainHandler = () => {
    //send again
    setSeconds(60);
    setAllowSendAgain(false);
  };

  return (
    <Box textAlign="center" justifyContent="center" mb="1rem">
      <Text textStyle={TextStyles.text} mb="1rem">
        Enter 6-digit code sent to your .edu email
      </Text>
      <HStack justifyContent="center" mb="2rem">
        <PinInput
          type="alphanumeric"
          size="lg"
          variant="outline"
          onChange={(e) => token.setData(e)}
        >
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
        </PinInput>
      </HStack>
      <Button
        variant={ButtonVariants.greyLineRound}
        text={sendAgainBtnText}
        margin="auto"
        isDisabled={!allowSendAgain}
        onClick={sendAgainHandler}
      />
    </Box>
  );
};

export default ConfirmDigitField;
