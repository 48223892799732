import React, { ReactNode, useEffect, useState } from "react";
import AccordionWrapper from "../../../components/accordion/accordionWrapper";
import { IJobAppEvent } from "../../../schemas/app.schema";
import { LayerStyles } from "../../../theme/layerStyles";
import { timestamp2ISO, timestamp2ISO16 } from "../../../utils";
import { ReactUseState } from "../../App";
import ViewEventDetail from "./viewEventDetail";

interface ViewEventsNotesProps {
  events: ReactUseState<IJobAppEvent[]>;
}

const ViewEventsNotes: React.FC<ViewEventsNotesProps> = ({ events }) => {
  const isCompleted = (xCompleted?: boolean, timestamp?: number) => {
    if (xCompleted) {
      return true;
    }
    if (!timestamp) {
      return false;
    }
    return timestamp < Date.now();
  };

  const calcDefaultOpenIndex = (): number[] => {
    const incompleteIndex: number[] = [];
    events.data.map((event, key) => {
      if (!isCompleted(event.xCompleted, event.time)) incompleteIndex.push(key);
    });
    return incompleteIndex;
  };

  const [defaultOpenIndex, setDefaultOpenIndex] = useState<number[]>(calcDefaultOpenIndex());

  useEffect(() => {
    setDefaultOpenIndex(calcDefaultOpenIndex());
  }, [events.data]);

  const accordionItems: ReactNode = events.data.map((event) => {
    return (
      <ViewEventDetail
        key={event._id}
        _id={event._id}
        _title={event.title}
        _time={timestamp2ISO16(event.time)}
        _duration={event.duration}
        _invitation={event?.invitation ? timestamp2ISO(event.invitation) : ""}
        event={event}
        _xCompleted={isCompleted(event.xCompleted, event.time)}
        itemProps={{
          layerStyle: LayerStyles.cardGeneral,
          py: "0.5rem",
          px: "0.5rem",
          mb: "1rem",
        }}
      />
    );
  });

  return (
    <AccordionWrapper
      items={accordionItems}
      defaultIndex={defaultOpenIndex}
      allowMultiple
      allowToggle
    />
  );
};

export default ViewEventsNotes;
