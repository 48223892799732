import React from "react";
import RouterDOM from "react-dom";
import { ApolloClient, ApolloProvider, HttpLink, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { persistCache, LocalStorageWrapper } from "apollo3-cache-persist";

import App from "./containers/App";
import config from "./config";
import cache from "./cache/cache";

const httpLink = new HttpLink({
  uri: config.API.APOLLO_URI, // url of graphql server,
  // credentials: ;
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const init = async () => {
  await persistCache({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
    // debug: true,
    // trigger: 'write',
  });

  const client = new ApolloClient({
    link: from([errorLink, httpLink]), // The `from` function combines an array of individual links into a link chain
    cache: cache,
    name: "ForwardyWebClient",
    version: "0.1",
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network", // optimize for quick response with update-to-date server data - read cache first but will always execute network call
        // nextFetchPolicy: "cache-only",
      },
    },
  });

  RouterDOM.render(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
};

init().catch(console.error);
