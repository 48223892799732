import React from "react";
import { FormControl, FormLabel, Input, Box } from "@chakra-ui/react";
import { InputProps } from "../form";
import { TextStyles } from "../../theme/textStyles";
import { ReactUseState } from "../../containers/App";
import { FormStyles } from "../../theme/components/form";

interface StudentInfoProps {
  step: ReactUseState<number>;
  univerisityName: ReactUseState<string>;
  universityEmail: ReactUseState<string>;
  graduationYear: ReactUseState<number>;
  graduationMonth: ReactUseState<number>;
}

const StudentInfo: React.FC<StudentInfoProps> = ({
  step,
  univerisityName,
  universityEmail,
  graduationYear,
  graduationMonth,
}) => {
  const inputs: InputProps[] = [
    {
      label: "University Name",
      value: univerisityName,
      placeholder: "Enter University Name",
    },
    {
      label: "University Email",
      type: "email",
      value: universityEmail,
      placeholder: "Enter University Email",
      isInvalid: universityEmail.data.length > 0 && !universityEmail.data.endsWith(".edu"),
    },
    {
      label: "Graduation Term",
      type: "number",
      value: graduationYear,
      placeholder: "2023",
    },
  ];

  return (
    <Box boxShadow="xl" rounded="lg" bg="white" width="80%" margin="auto">
      {inputs.map((input, key) => {
        return (
          <Box
            key={key}
            pt="0.4rem"
            pb="0.3rem"
            backgroundColor={key % 2 == 0 ? "white" : "grey.100"}
            borderRadius={key === 0 || key === inputs.length - 1 ? "0.75rem" : "none"}
          >
            <FormControl
              variant={FormStyles.floatingBox}
              isRequired={input?.isRequired}
              isDisabled={input?.isDisabled}
            >
              <Input
                textStyle={TextStyles.text}
                type={input?.type}
                value={input.value.data}
                placeholder={input?.placeholder}
                isDisabled={input?.isDisabled}
                onChange={(e) => {
                  typeof input.value.data === "number"
                    ? input.value.setData(parseInt(e.target.value))
                    : input.value.setData(e.target.value);
                }}
              />
              <FormLabel textStyle={TextStyles.thin}>{input.label}</FormLabel>
            </FormControl>
          </Box>
        );
      })}
    </Box>
  );
};

export default StudentInfo;
