import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { JobAppStatus } from "../../../constants/constants";
import { capitalizeStr } from "../../../utils";
import { TextStyles } from "../../../theme/textStyles";
import { IJobAppEvent } from "../../../schemas/app.schema";
import { ReactUseState } from "../../App";
import NewEventNote from "./newEventNote";
import ViewEventsNotes from "./viewEventsNotes";

interface EventNoteTabProps {
  appId: string;
  stage: JobAppStatus;
  events: ReactUseState<IJobAppEvent[]>;
}

const EventNoteTab: React.FC<EventNoteTabProps> = ({ appId, stage, events }) => {
  return (
    <Box>
      <Text textStyle={TextStyles.h2} as="h2" mb="1.5rem">
        {capitalizeStr(stage)} Events
      </Text>
      <ViewEventsNotes events={events} />
      <NewEventNote appId={appId} stage={stage} events={events} />
    </Box>
  );
};

export default EventNoteTab;
