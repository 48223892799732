import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { ReactUseState } from "../../containers/App";
import { TextStyles } from "../../theme/textStyles";
import JobOfferInput from "./jobAppOfferForm";

export interface JobAppOfferBenefitDetailsProps {
  relocation: ReactUseState<number>;
  benefits: ReactUseState<string[]>;
}

const JobAppOfferBenefitDetails: React.FC<JobAppOfferBenefitDetailsProps> = ({
  relocation,
  benefits,
}) => {
  return (
    <Box>
      <Text textStyle={TextStyles.noteSubHeader} mb="0.75rem">
        Benefit Details
      </Text>
      <JobOfferInput
        input={{
          label: "Relocation",
          value: relocation,
          type: "number",
        }}
        inputLeftElement="$"
      />
    </Box>
  );
};

export default JobAppOfferBenefitDetails;
