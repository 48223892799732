import { DocumentNode, gql } from "@apollo/client";
import { OPERATION_STATUS_FRAGMENT } from "../../fragments/common.fragment";
import { JOB_APP_OFFER_CORE_FRAGMENT } from "../../fragments/jobAppOffer.fragment";
import {
  UpdateMutationHelper,
  UpdateMutationHelperProps,
  UpdateMutationParamQueryPairProps,
  UpdateMutationPlaceholderProps,
} from "../serviceHelper";

const CREATE_JOB_APP_OFFER = gql`
  ${JOB_APP_OFFER_CORE_FRAGMENT}
  ${OPERATION_STATUS_FRAGMENT}
  mutation CreateJobAppOffer($newJobAppOffer: CreateJobAppOfferInput!) {
    createJobAppOffer(newJobAppOffer: $newJobAppOffer) {
      ... on JobAppOffer {
        ...JobAppOfferCoreFragment
      }
      ... on OperationStatus {
        ...OperationStatusFragment
      }
    }
  }
`;

const UPDATE_JOB_APP_OFFER_BY_STATUS = `
updateJobAppOfferByStatus(updateJobAppOffer: $updateJobAppOfferByStatus) {
    ...OperationStatusFragment
  }
`;

const UPDATE_JOB_APP_OFFER_BY_DEADLINE = `
updateJobAppOfferByDeadline(updateJobAppOffer: $updateJobAppOfferByDeadline) {
    ...OperationStatusFragment
  }
`;

const UPDATE_JOB_APP_OFFER_BY_START_DATE = `
updateJobAppOfferByStartDate(updateJobAppOffer: $updateJobAppOfferByStartDate) {
    ...OperationStatusFragment
  }
`;

const UPDATE_JOB_APP_OFFER_BY_END_DATE = `
updateJobAppOfferByEndDate(updateJobAppOffer: $updateJobAppOfferByEndDate) {
    ...OperationStatusFragment
  }
`;

const UPDATE_JOB_APP_OFFER_BY_POISTION = `
updateJobAppOfferByPosition(updateJobAppOffer: $updateJobAppOfferByPosition) {
    ...OperationStatusFragment
  }
`;

const UPDATE_JOB_APP_OFFER_BY_PAY_BASIS = `
updateJobAppOfferByPayBasis(updateJobAppOffer: $updateJobAppOfferByPayBasis) {
    ...OperationStatusFragment
  }
`;

const UPDATE_JOB_APP_OFFER_BY_PAY_RATE = `
updateJobAppOfferByPayRate(updateJobAppOffer: $updateJobAppOfferByPayRate) {
    ...OperationStatusFragment
  }
`;

const UPDATE_JOB_APP_OFFER_BY_STOCK = `
updateJobAppOfferByStock(updateJobAppOffer: $updateJobAppOfferByStock) {
    ...OperationStatusFragment
  }
`;

const UPDATE_JOB_APP_OFFER_BY_VESTING_SCHEDULES = `
updateJobAppOfferByVestingSchedules(updateJobAppOffer: $updateJobAppOfferByVestingSchedules) {
    ...OperationStatusFragment
  }
`;

const UPDATE_JOB_APP_OFFER_BY_SIGN_ON_BONUS = `
updateJobAppOfferBySignOnBonus(updateJobAppOffer: $updateJobAppOfferBySignOnBonus) {
    ...OperationStatusFragment
  }
`;

const UPDATE_JOB_APP_OFFER_BY_PERFORMANCE_BONUS = `
updateJobAppOfferByPerformanceBonus(updateJobAppOffer: $updateJobAppOfferByPerformanceBonus) {
    ...OperationStatusFragment
  }
`;

const UPDATE_JOB_APP_OFFER_BY_OTHER_COMPENSATION = `
updateJobAppOfferByOtherCompensation(updateJobAppOffer: $updateJobAppOfferByOtherCompensation) {
    ...OperationStatusFragment
  }
`;

const UPDATE_JOB_APP_OFFER_BY_RELOCATION = `
updateJobAppOfferByRelocation(updateJobAppOffer: $updateJobAppOfferByRelocation) {
    ...OperationStatusFragment
  }
`;

const UPDATE_JOB_APP_OFFER_BY_LOCATION = `
updateJobAppOfferByLocation(updateJobAppOffer: $updateJobAppOfferByLocation) {
    ...OperationStatusFragment
  }
`;

const UPDATE_JOB_APP_OFFER_BY_BENEFITS = `
updateJobAppOfferByBenefits(updateJobAppOffer: $updateJobAppOfferByBenefits) {
    ...OperationStatusFragment
  }
`;

const UPDATE_JOB_APP_OFFER_BY_DESCRIPTION = `
updateJobAppOfferByDescription(updateJobAppOffer: $updateJobAppOfferByDescription) {
    ...OperationStatusFragment
  }
`;

export interface UpdaterJobAppOfferMutationProps {
  status?: boolean;
  deadline?: boolean;
  startDate?: boolean;
  endDate?: boolean;
  position?: boolean;
  payBasis?: boolean;
  payRate?: boolean;
  stock?: boolean;
  vestingSchedules?: boolean;
  signOnBonus?: boolean;
  performanceBonus?: boolean;
  otherCompensation?: boolean;
  relocation?: boolean;
  location?: boolean;
  benefits?: boolean;
  description?: boolean;
}

const updateJobAppOfferMutationParamQueryPair: UpdateMutationParamQueryPairProps = {
  status: {
    param: `$updateJobAppOfferByStatus: UpdateJobAppOfferByStatusInput!`,
    query: UPDATE_JOB_APP_OFFER_BY_STATUS,
  },
  deadline: {
    param: `$updateJobAppOfferByDeadline: UpdateJobAppOfferByDeadlineInput!`,
    query: UPDATE_JOB_APP_OFFER_BY_DEADLINE,
  },
  startDate: {
    param: `$updateJobAppOfferByStartDate: UpdateJobAppOfferByStartDateInput!`,
    query: UPDATE_JOB_APP_OFFER_BY_START_DATE,
  },
  endDate: {
    param: `$updateJobAppOfferByEndDate: UpdateJobAppOfferByEndDateInput!`,
    query: UPDATE_JOB_APP_OFFER_BY_END_DATE,
  },
  position: {
    param: `$updateJobAppOfferByPosition: UpdateJobAppOfferByPositionInput!`,
    query: UPDATE_JOB_APP_OFFER_BY_POISTION,
  },
  payBasis: {
    param: `$updateJobAppOfferByPayBasis: UpdateJobAppOfferByPayBasisInput!`,
    query: UPDATE_JOB_APP_OFFER_BY_PAY_BASIS,
  },
  payRate: {
    param: `$updateJobAppOfferByPayRate: UpdateJobAppOfferByPayRateInput!`,
    query: UPDATE_JOB_APP_OFFER_BY_PAY_RATE,
  },
  stock: {
    param: `$updateJobAppOfferByStock: UpdateJobAppOfferByStockInput!`,
    query: UPDATE_JOB_APP_OFFER_BY_STOCK,
  },
  vestingSchedules: {
    param: `$updateJobAppOfferByVestingSchedules: UpdateJobAppOfferByVestingSchedulesInput!`,
    query: UPDATE_JOB_APP_OFFER_BY_VESTING_SCHEDULES,
  },
  signOnBonus: {
    param: `$updateJobAppOfferBySignOnBonus: UpdateJobAppOfferBySignOnBonusInput!`,
    query: UPDATE_JOB_APP_OFFER_BY_SIGN_ON_BONUS,
  },
  performanceBonus: {
    param: `$updateJobAppOfferByPerformanceBonus: UpdateJobAppOfferByPerformanceBonusInput!`,
    query: UPDATE_JOB_APP_OFFER_BY_PERFORMANCE_BONUS,
  },
  otherCompensation: {
    param: `$updateJobAppOfferByOtherCompensation: UpdateJobAppOfferByOtherCompensationInput!`,
    query: UPDATE_JOB_APP_OFFER_BY_OTHER_COMPENSATION,
  },
  relocation: {
    param: `$updateJobAppOfferByRelocation: UpdateJobAppOfferByRelocationInput!`,
    query: UPDATE_JOB_APP_OFFER_BY_RELOCATION,
  },
  location: {
    param: `$updateJobAppOfferByLocation: UpdateJobAppOfferByLocationInput!`,
    query: UPDATE_JOB_APP_OFFER_BY_LOCATION,
  },
  benefits: {
    param: `$updateJobAppOfferByBenefits: UpdateJobAppOfferByBenefitsInput!`,
    query: UPDATE_JOB_APP_OFFER_BY_BENEFITS,
  },
  description: {
    param: `$updateJobAppOfferByDescription: UpdateJobAppOfferByDescriptionInput!`,
    query: UPDATE_JOB_APP_OFFER_BY_DESCRIPTION,
  },
};

const UPDATE_JOB_APP_OFFER = (props: UpdaterJobAppOfferMutationProps): DocumentNode => {
  const mutationName = "UpdateJobAppOfferMutation";
  const placeholder: UpdateMutationPlaceholderProps = {
    params: `$placeholder: UpdateJobAppOfferByStatusInput!`,
    queries: `placeholder(placeholder: $placeholder) {_id}`,
  };
  return UpdateMutationHelper(
    mutationName,
    placeholder,
    props as UpdateMutationHelperProps,
    updateJobAppOfferMutationParamQueryPair
  );
};

const DELETE_JOB_APP_OFFER = gql`
  ${OPERATION_STATUS_FRAGMENT}
  mutation DeleteJobAppOfferMutation($id: ObjectId!) {
    deleteJobAppOffer(_id: $id) {
      ...OperationStatusFragment
    }
  }
`;

const JobAppOfferMutations = {
  CREATE_JOB_APP_OFFER,
  UPDATE_JOB_APP_OFFER,
  DELETE_JOB_APP_OFFER,
};

export default JobAppOfferMutations;
