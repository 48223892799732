import { ReactNode } from "react";
import { Flex, Text, Box } from "@chakra-ui/react";
import Divider from "./divider";
import SVGIcon, { SVGIconProps } from "../assets/icons";
import { TextStyles } from "../theme/textStyles";

export interface SectionHeaderProps {
  text: string;
  textStyle?: string | string[];
  xCenter?: boolean;
  icon?: SVGIconProps;
  Button?: ReactNode;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ text, icon, Button, ...props }) => {
  return (
    <Flex
      align="center"
      width="100%"
      mb="1.5rem"
      textStyle={TextStyles.sectionHeader}
      color="grey.500"
      {...props}
    >
      <Divider />
      {icon && <SVGIcon {...icon} />}
      <Box width="fit-content" px="1rem">
        <Text textStyle={TextStyles.sectionHeader} color="grey.600" width="max-content">
          {text}
        </Text>
      </Box>
      <Divider />
    </Flex>
  );
};

export default SectionHeader;
