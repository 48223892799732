import { Box, Text, Flex, Spacer } from "@chakra-ui/react";
import React from "react";
import StepBar from "../../components/stepBar";
import Button from "../../components/button";
import StudentVerificationModal from "../../components/studentVerificationModal";
import { ButtonVariants } from "../../theme/components/button";
import { TextStyles } from "../../theme/textStyles";
import { ReactUseState } from "../App";

export interface StudentVerificationProps {
  step: ReactUseState<number>;
  onSubmit(): any;
}

const StudentVerification: React.FC<StudentVerificationProps> = ({ step, onSubmit }) => {
  const startForwardyingHandler = () => {
    //save user info
    onSubmit();
  };

  return (
    <Box>
      <StepBar steps={3} currentStep={3} />
      <Box mb="1rem">
        <Text as="h2" textStyle={TextStyles.h2} mb="1rem">
          Student Verification
        </Text>
      </Box>
      <Text textStyle={TextStyles.thin} mb="2rem">
        Verify your student status today to access exclusive insights!
      </Text>
      <StudentVerificationModal />
      <Flex my="2rem">
        <Button
          variant={ButtonVariants.text}
          textStyle={TextStyles.text}
          text="Back"
          onClick={() => step.setData(2)}
          leftIcon={{ name: "arrow-left-line", fill: "black" }}
          pl="0"
        />
        <Spacer />
        <Button
          variant={ButtonVariants.primaryFillSquare}
          text="Start forwardying"
          textStyle={TextStyles.primaryFillBtn_lg}
          onClick={startForwardyingHandler}
          padding="0.35rem 0.5rem"
        />
      </Flex>
    </Box>
  );
};

export default StudentVerification;
