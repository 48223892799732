import { Box, Text } from "@chakra-ui/layout";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import CreateSample from "../adapters/sample/createSample";
import ViewSamples from "../adapters/sample/viewSamples";
import { DocTitleSuffix } from "../../constants/constants";
import { LayerStyles } from "../../theme/layerStyles";
import { TextStyles } from "../../theme/textStyles";

const Samples = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box layerStyle={LayerStyles.mainPanelChild}>
      <Helmet>
        <title>Samples {DocTitleSuffix}</title>
      </Helmet>
      <Text as="h1" textStyle={TextStyles.h1} pb="0rem">
        Samples Page
      </Text>
      <Box>
        <ViewSamples />
        <CreateSample />
      </Box>
    </Box>
  );
};

export default Samples;
