import React, { useEffect, useState } from "react";
import { Flex, Text } from "@chakra-ui/react";
import Divider from "./divider";
import { ReactUseState } from "../containers/App";
import { JobAppStatus } from "../constants/constants";
import { capitalizeStr } from "../utils";
import ButtonIcon from "./buttonIcon";
import { StageProps } from "../containers/adapters/jobApp/jobAppDetailTabs";
import { ButtonVariants } from "../theme/components/button";
import { SVGIconProps } from "../assets/icons";

interface ProgressBarProps {
  stages: StageProps[];
  onStage: string;
  openStageTab: ReactUseState<JobAppStatus>;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ stages, onStage, openStageTab, ...props }) => {
  const [currentStage, setCurrentStage] = useState<StageProps>(stages[0]);

  useEffect(() => {
    setCurrentStage(stages.find((stage) => stage.title === onStage) || stages[0]);
  }, [onStage]);

  return (
    <Flex direction="row" width="100%" mb="2rem" align="start" {...props}>
      {stages.map((stage, key) => {
        const stageButtonIconProps: SVGIconProps = {
          name:
            stage.index > currentStage.index
              ? stage.title === openStageTab.data
                ? "circle-hollow-grey"
                : "circle-fill-grey"
              : stage.title === openStageTab.data
              ? "circle-hollow"
              : "circle-fill",
          size: "1.2rem",
        };
        const stageTextColor =
          stage.index > currentStage.index
            ? stage.title === openStageTab.data
              ? "grey.600"
              : "grey.500"
            : stage.title === openStageTab.data
            ? "primary.solid"
            : "text";
        const stageDate: string = stage?.date
          ? `${stage.date.getMonth() + 1}/${stage.date.getDate()}`
          : "--";
        return (
          <Flex direction="row" width={key !== 0 ? "100%" : ""} key={key}>
            {key !== 0 && (
              <Divider
                backgroundColor={stage.index <= currentStage.index ? "primary.solid" : "grey.400"}
                mt="0.52rem"
              />
            )}
            <Flex
              direction="column"
              align="center"
              justifyContent="center"
              width="1rem"
              ml={stage.index === 0 ? "0" : "0.75rem"}
              mr={stage.index === stages.length - 1 ? "0" : "0.75rem"}
            >
              <ButtonIcon
                variant={ButtonVariants.progressBarIcon}
                onClick={() => openStageTab.setData(stage.title)}
                icon={stageButtonIconProps}
                mb="0.5rem"
              />
              <Text textStyle="tiny" textAlign="center" color={stageTextColor}>
                {capitalizeStr(stage.title)}&nbsp;
                {stage.noOfEvents > 0 && `(${stage.noOfEvents})`}
                <br />
                {stageDate}
              </Text>
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
};

export default ProgressBar;
