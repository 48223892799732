import React from "react";
import { Divider as ChakraDivider } from "@chakra-ui/react";

interface DividerProps {
  color?: string;
  orientation?: "horizontal" | "vertical" | undefined;
  height?: string;
  [key: string]: any;
}

const Divider: React.FC<DividerProps> = ({
  color = "grey.400",
  orientation = "horizontal",
  height = "1px",
  ...props
}) => {
  return (
    <ChakraDivider
      backgroundColor={color}
      borderColor={color}
      orientation={orientation}
      height={height}
      border="0px!important"
      borderRadius="sm"
      {...props}
    />
  );
};

export default Divider;
