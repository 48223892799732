export interface AppRouteProps {
  route: string;
  title: string;
}

interface AppRoutesProps {
  [path: string]: AppRouteProps;
}

const AppRoutes: AppRoutesProps = {
  about: { route: "/about", title: "About" },
  companies: { route: "/companies", title: "Companies" },
  company: { route: "/company/:companyId", title: "Company" },
  error: { route: "*", title: "404 Not Found" },
  explore: { route: "/explore", title: "Explore New Jobs" },
  resetPassword: { route: "/account/password", title: "Reset Password" },
  home: { route: "/", title: "Home" },
  job: { route: "/job/:jobId", title: "Job Detail" },
  login: { route: "/login", title: "Log In" },
  myApps: { route: "/myapps", title: "My Applications" },
  myAppDetail: { route: "/app/:appId", title: "Application Detail" },
  prep: { route: "/prep", title: "Prep Notes" },
  profile: { route: "/account/profile", title: "Profile" },
  register: { route: "/register", title: "Register" },
  setting: { route: "/account/setting", title: "Setting" },
};

export default AppRoutes;
