import { extendTheme, ThemeOverride } from "@chakra-ui/react";

import colors from "./colors";
import fonts from "./fonts";
import layerStyles from "./layerStyles";
import textStyles from "./textStyles";
import Button from "./components/button";
import Heading from "./components/heading";
import Input from "./components/input";
import Link from "./components/link";
import Tag from "./components/tag";
import Form from "./components/form";
import Checkbox from "./components/checkbox";

const theme = {
  colors,
  fonts,
  layerStyles,
  textStyles,
  components: {
    Button,
    Heading,
    Input,
    Link,
    Tag,
    Form,
    Checkbox,
  },
};

export default extendTheme(theme as ThemeOverride);
