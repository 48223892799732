export const getPopularity = (num: number | undefined): string => {
  if (!num) {
    return "0";
  }
  let data: string = "" + num;
  if (num >= 1000) {
    switch (true) {
      case num >= 10000:
        data = "10k+";
        break;
      case num >= 8000:
        data = "8k+";
        break;
      case num >= 5000:
        data = "5k+";
        break;
      case num >= 3000:
        data = "3k+";
        break;
      case num >= 2000:
        data = "2k+";
        break;
      case num >= 1500:
        data = "1.5k+";
        break;
      case num >= 1000:
        data = "1k+";
        break;
    }
  } else {
    switch (true) {
      case num >= 800:
        data = "800+";
        break;
      case num >= 500:
        data = "500+";
        break;
      case num >= 300:
        data = "300+";
        break;
      case num >= 100:
        data = "100+";
        break;
    }
  }
  return data;
};

export const getActiveJobs = (num: number): string => {
  if (num === 0) {
    return "--";
  } else if (num === 1) {
    return "1 job";
  } else if (num < 100) {
    return num + " jobs";
  } else {
    return "100+ jobs";
  }
};

export const getLocation = (location: string[] | undefined) => {
  if (!location) {
    return "N/A";
  }
  let data: string = location[0];
  if (location.length > 1) {
    data = location[0] + " +";
  }
  return data;
};

export const capitalizeStr = (str: string | undefined) => {
  if (!str) {
    return "";
  }
  str = str.toLowerCase();
  const newStr = str.charAt(0).toUpperCase() + str.slice(1);
  return newStr;
};

export const validateEmailAddress = (email: string): boolean => {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
};

export const getMonthDate = (timestamp: number | undefined): string => {
  if (!timestamp) {
    return "";
  }
  const date = new Date(timestamp);
  return "" + (date.getMonth() + 1) + "/" + date.getDate();
};

export const getMonthDateYear = (timestamp: number | undefined): string => {
  if (!timestamp) {
    return "";
  }
  const date = new Date(timestamp);
  return "" + (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
};

export const areSetsEqual = (a: Set<string>, b: Set<string>) =>
  a.size === b.size && [...a].every((value) => b.has(value));

export const timestamp2ISO16 = (timestamp?: number): string => {
  if (!timestamp) {
    return "";
  }
  const offset = new Date(timestamp).getTimezoneOffset() * 60 * 1000;
  return new Date(timestamp - offset).toISOString().slice(0, 16);
};

export const timestamp2ISO = (timestamp?: number): string => {
  if (!timestamp) {
    return "";
  }
  return new Date(timestamp).toISOString().split("T")[0];
};

export const numberInThousands = (num: number) => {
  if (num > 1000) {
    return Math.floor(num / 1000).toFixed(1) + "k";
  }
  return num;
};

export const getDaysCountDown = (time: string): number => {
  return Math.round((new Date(time).getTime() - new Date().getTime()) / (1000 * 3600 * 24));
};
