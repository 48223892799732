import { ReactiveVar } from "@apollo/client";
import { CurrentJobAppTermVarType } from "../cache/reactiveVars";
import { IJobAppTerm } from "../schemas/user.schema";

function useCurrentJobAppTermHook(currentJobAppTermVar: ReactiveVar<CurrentJobAppTermVarType>) {
  const getCurrentJobAppTerm = (): CurrentJobAppTermVarType => {
    return currentJobAppTermVar();
  };

  const setCurrentJobAppTerm = (term: IJobAppTerm): Promise<any> => {
    return new Promise(() => {
      currentJobAppTermVar(term);
    });
  };

  return { getCurrentJobAppTerm, setCurrentJobAppTerm };
}

export default useCurrentJobAppTermHook;
