import { useEffect, useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import { LayerStyles } from "../../theme/layerStyles";
import { Helmet } from "react-helmet";
import AppRoutes from "../AppRoutes.constant";
import { DocTitleSuffix, Sorting } from "../../constants/constants";
import { TextStyles } from "../../theme/textStyles";
import SearchHeaderRow from "../../components/searchHeaderRow";
import SortCompanyMenu from "../adapters/company/sortCompanyMenu";
import BatchLogJobAppsByCompany from "../adapters/company/batchLogJobAppsByCompany";
import ViewCompaniesGrid from "../adapters/company/viewCompaniesGrid";
import useActiveHiringCompaniesHook from "../../hooks/useActiveHiringCompanies";

const Companies = () => {
  const { getNumberOfActiveHiringCompanies } = useActiveHiringCompaniesHook();

  const [sorting, setSorting] = useState<Sorting>(Sorting.POPULARITY);
  const [selectedCompanyIds, setSelectedCompanyIds] = useState(new Set<string>());
  const [selectMode, setSelectMode] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box layerStyle={LayerStyles.mainPanelChild} pt="0">
      <Helmet>
        <title>
          {AppRoutes.companies.title}
          {DocTitleSuffix}
        </title>
      </Helmet>
      <SearchHeaderRow />
      <Text as="h1" textStyle={TextStyles.h1}>
        Companies
      </Text>
      <Text textStyle={TextStyles.text} color="grey.700" mb="1rem">
        {getNumberOfActiveHiringCompanies()} companies are actively hiring
      </Text>
      {/* <Flex direction="row" alignItems="center" mb="1.75rem" px="0.5rem">
        <SortCompanyMenu sorting={{ data: sorting, setData: setSorting }} />
        <Spacer />
        <BatchLogJobAppsByCompany
          selectMode={{ data: selectMode, setData: setSelectMode }}
          selectedCompanyIds={{ data: selectedCompanyIds, setData: setSelectedCompanyIds }}
        />
      </Flex> */}
      <ViewCompaniesGrid
        selectMode={{ data: selectMode, setData: setSelectMode }}
        selectedCompanyIds={{ data: selectedCompanyIds, setData: setSelectedCompanyIds }}
        sorting={sorting}
      />
    </Box>
  );
};

export default Companies;
