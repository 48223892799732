import { Accordion as ChakraAccordion } from "@chakra-ui/react";
import React, { ReactNode } from "react";

export interface AccordionProps {
  items: ReactNode;
  [key: string]: any;
}

const AccordionWrapper: React.FC<AccordionProps> = ({ items, ...props }) => {
  return <ChakraAccordion {...props}>{items}</ChakraAccordion>;
};

export default AccordionWrapper;
