import React from "react";
import { Box, useToast } from "@chakra-ui/react";

interface AccountSettingProps {
  uid: string;
  _autoArchive: boolean;
  _daysToArchive: number;
  _shareProgress: boolean;
  _shareOffer: boolean;
}

const AccountSetting: React.FC<AccountSettingProps> = ({
  uid,
  _autoArchive,
  _daysToArchive,
  _shareOffer,
  _shareProgress,
}) => {
  const toast = useToast();

  // ❓ TODO: mutation updateUser

  return <Box>Setting section to be redone.</Box>;
};

export default AccountSetting;
