const Input = {
  baseStyle: {
    boxShadow: "none !important",
    outline: "none !important",
  },
  variants: {
    underline: {
      border: "none",
      padding: "0px !important",
      _focus: {
        borderBottom: "1px solid primary.solid",
      },
    },
    switch: { _focus: { borderColor: "primary.gradient" } },
  },
};

export default Input;
