import { gql } from "@apollo/client";
import { JOB_APP_EVENT_CORE_FRAGMENT } from "../../fragments/jobAppEvent.fragment";

const GET_JOB_APP_EVENTS_JOB_APP_ID = gql`
  ${JOB_APP_EVENT_CORE_FRAGMENT}
  query GetJobAppEventsByJobAppId($jobAppId: ObjectId!) {
    getJobAppEventsByJobAppId(jobAppId: $jobAppId) {
      ...JobAppEventCoreFragment
    }
  }
`;

const JobAppEventQueries = { GET_JOB_APP_EVENTS_JOB_APP_ID };

export default JobAppEventQueries;
