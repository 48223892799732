import { gql } from "@apollo/client";
import {
  JOB_LISTING_CARD_DISPLAY_FRAGMENT,
  JOB_LISTING_WITH_COMPANY_CORE_FRAGMENT,
} from "../../fragments/jobListing.fragment";

const GET_JOB_LISTING_BY_ID = gql`
  ${JOB_LISTING_WITH_COMPANY_CORE_FRAGMENT}
  query GetJobListingById($id: ObjectId!) {
    getJobListingById(_id: $id) {
      ...JobListingWithCompanyCoreFragment
    }
  }
`;

const GET_JOB_LISTINGS = gql`
  ${JOB_LISTING_CARD_DISPLAY_FRAGMENT}
  query GetJobListings($offset: Int!, $limit: Int!, $sort: String) {
    getJobListings(offset: $offset, limit: $limit, sort: $sort) {
      ...JobListingCardDisplayFragment
    }
  }
`;

const JobListingQueries = {
  GET_JOB_LISTING_BY_ID,
  GET_JOB_LISTINGS,
};

export default JobListingQueries;
