import { useEffect, useState } from "react";
import {
  Badge,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Spacer,
  Text,
  useToast,
} from "@chakra-ui/react";
import { fontSizes, fontWeights } from "../../theme/textStyles";
import SVGIcon from "../../assets/icons";
import Button from "../../components/button";
import colors from "../../theme/colors";
import { ButtonVariants } from "../../theme/components/button";
import { validateEmailAddress } from "../../utils";
import { useMutation } from "@apollo/client";
import SubscriberServices from "../../graphql/services/subscriberServices";
import * as SubscriberTypes from "../../graphql/types/subscriber.type";
import { ToastStatus } from "../../constants/constants";
import Footer from "../../components/footer";

const ComingSoon = () => {
  const toast = useToast();
  const [email, setEmail] = useState<string>("");
  const [isDirty, setIsDirty] = useState<boolean>(false);

  useEffect(() => {
    setIsDirty(email.length > 0);
  }, [email]);

  const [createSubscriber] = useMutation<
    SubscriberTypes.CreateSubscriberData,
    SubscriberTypes.CreateSubscriberArgs
  >(SubscriberServices.CREATE_SUBSCRIBER);

  const clearInputs = () => {
    setEmail("");
  };

  const onSubscribe = async (email: string) => {
    await createSubscriber({ variables: { newSubscriber: { email: email } } })
      .then(({ data }) => {
        toast({
          description: `Thank you for subscribing to forwardy.app`,
          position: "bottom",
          status: ToastStatus.SUCCESS,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          description: `${error.message}`,
          position: "bottom",
          status: ToastStatus.ERROR,
          isClosable: true,
        });
      });
    clearInputs();
  };

  const onSubmit = async () => {
    if (!validateEmailAddress(email)) {
      alert("Please enter a valid email address");
      return;
    }
    await onSubscribe(email);
  };

  return (
    <Center
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      margin="auto 0"
      padding="2% 5%"
    >
      <Flex height="5rem" width="100%" direction="row" alignItems="center">
        <SVGIcon name="brand-logo" size={["7rem", "9.5rem", "12rem"]} fill={colors.text} />
        <Badge
          ml={["0.25rem", "0.5rem"]}
          colorScheme="red"
          height="fit-content"
          borderRadius="md"
          textTransform="none"
          fontSize={["0.5rem", "0.75rem"]}
        >
          Alpha v0.1.0
        </Badge>
        <Spacer />
        <Link href="https://www.linkedin.com/company/forwardy/" isExternal>
          <SVGIcon
            name="linkedin-logo"
            size={["1.25rem", "1.75rem", "2.5rem"]}
            fill={colors.text}
          />
        </Link>
      </Flex>
      <Flex width="100%" direction="column" alignItems="center" py="10%" px="10%" mb="2rem">
        <Text
          fontSize={["2.5rem", "4rem", "6rem"]}
          fontWeight={fontWeights.semiBold}
          bgClip="text"
          bgGradient={colors.primary.text}
        >
          Coming Soon
        </Text>
        <Text
          fontSize={["1rem", "1.25rem", "1.75rem"]}
          fontWeight={fontWeights.regular}
          color={colors.grey[700]}
          mt={["0.75rem", "0.85rem", "1rem"]}
        >
          Built for early career job seekers.
        </Text>
        <Text
          fontSize={["0.75rem", "1rem", "1.5rem"]}
          fontWeight={fontWeights.regular}
          color={colors.grey[700]}
          mt={["0.75rem", "0.85rem", "1rem"]}
        >
          Releasing in July 2022
        </Text>
        <FormControl
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt={["2rem", "3rem", "5rem"]}
        >
          <FormLabel
            fontSize={["0.75rem", "0.9rem", "1.15rem"]}
            fontWeight={fontWeights.regular}
            color={colors.grey[600]}
            mb="1rem"
          >
            🔔 Get notified as soon as we go live
          </FormLabel>
          <InputGroup width="max-content" height={["2rem", "3rem"]}>
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter email address"
              maxW={["13rem", "16rem", "20rem"]}
              height="100%"
              pr="1rem"
              textStyle="coming_soon"
              fontSize={[fontSizes[8], fontSizes[7], fontSizes[6]]}
              _focus={{ borderColor: colors.primary.solid, borderWidth: "2px" }}
              mr={["6rem", "7rem", "8rem"]}
            />
            <InputRightElement width="max-content" maxW={["7rem"]} height="100%">
              <Button
                text={"Subscribe"}
                variant={ButtonVariants.primaryFillSquare}
                textStyle="coming_soon"
                height="100%"
                maxW={["4.5rem", "5.5rem", "7rem"]}
                isDisabled={!isDirty}
                onClick={() => onSubmit()}
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>
      </Flex>
      <Spacer />
      <Footer />
    </Center>
  );
};

export default ComingSoon;
