import {
  Box,
  Circle,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
} from "@chakra-ui/react";
import SVGIcon from "../assets/icons";
import React, { ReactElement } from "react";
import Divider from "./divider";
import { IJobAppTerm } from "../schemas/user.schema";
import { TextStyles } from "../theme/textStyles";

interface JobTermMenuProps {
  viewOnly: Boolean;
  uid: string;
  termTitleMaxLength: number;
  terms: IJobAppTerm[];
  selectedTerm: IJobAppTerm;
  onUpdateUserByLastOpenTerm(termId: string): void;
  jobAppTermEditModalComponent(editingTerm: IJobAppTerm): ReactElement;
  jobTermCreateModalComponent(): ReactElement;
  [key: string]: any;
}

const JobTermMenu: React.FC<JobTermMenuProps> = ({
  viewOnly,
  uid,
  termTitleMaxLength,
  terms,
  selectedTerm,
  onUpdateUserByLastOpenTerm,
  jobAppTermEditModalComponent,
  jobTermCreateModalComponent,
  ...props
}) => {
  return (
    <Menu matchWidth>
      <MenuButton
        maxWidth="16rem"
        width="100%"
        padding="0px"
        bg="white"
        boxShadow="0 2px 3px 0 rgba(0, 0, 0, 0.2)"
        borderRadius="0.3rem"
        userSelect="none"
        {...props}
      >
        <Flex direction="row" padding="0.45rem 0.75rem" alignItems="center" justifyContent="center">
          <Circle size="0.4rem" mr="0.5rem" bg={selectedTerm.xCompleted ? "grey.400" : "active"} />
          <Text isTruncated textStyle={TextStyles.text}>
            {selectedTerm.title}
          </Text>
          <Spacer />
          <Box>
            <SVGIcon name="arrow-down-line" size="1.25rem" />
          </Box>
        </Flex>
      </MenuButton>
      <MenuList maxWidth="16rem" width="100%" zIndex="3" padding="0px">
        {terms.map((term, key) => (
          <Flex
            key={term._id}
            direction="row"
            pt={key === 0 ? "0.1rem" : "0px"}
            pb="0.05rem"
            align="center"
            onClick={() => onUpdateUserByLastOpenTerm(term._id)}
          >
            <MenuItem>
              <Circle size="0.4rem" mr="0.5rem" bg={term.xCompleted ? "grey.400" : "green.300"} />
              <Text
                noOfLines={1}
                textStyle={TextStyles.text}
                color={term._id === selectedTerm?._id ? TextStyles.text : "grey.500"}
              >
                {term.title}
              </Text>
            </MenuItem>
            {!viewOnly && (
              <>
                <Spacer />
                {jobAppTermEditModalComponent(term)}
              </>
            )}
          </Flex>
        ))}
        {!viewOnly && (
          <>
            <Divider />
            <MenuItem>{jobTermCreateModalComponent()}</MenuItem>
          </>
        )}
      </MenuList>
    </Menu>
  );
};

export default JobTermMenu;
