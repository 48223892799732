import { gql } from "@apollo/client";
import { JOB_APP_EVENT_CALENDAR_DISPLAY_FRAGMENT } from "./jobAppEvent.fragment";

export const USER_DAILY_EVENT_CORE_FRAGMENT = gql`
  ${JOB_APP_EVENT_CALENDAR_DISPLAY_FRAGMENT}
  fragment UserDailyEventCoreFragment on UserDailyEvent {
    _id
    year
    month
    day
    events {
      ...JobAppEventCalendarDisplayFragment
    }
  }
`;
