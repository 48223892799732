import { makeVar } from "@apollo/client";
import { IJobAppTerm, IUser } from "../schemas/user.schema";

export type LoggedInUserVarType = IUser | undefined;
export const loggedInUserVar = makeVar<LoggedInUserVarType>(undefined);

export type ActiveHiringCompaniesVarType = number;
export const activeHiringCompaniesVar = makeVar<ActiveHiringCompaniesVarType>(0);

export type JobListingMarksVarType = Set<string>;
export const jobListingMarksVar = makeVar<JobListingMarksVarType>(new Set<string>());

export type CurrentJobAppTermVarType = IJobAppTerm | undefined;
export const currentJobAppTermVar = makeVar<CurrentJobAppTermVarType>(undefined);
