import { gql } from "@apollo/client";
import { OPERATION_STATUS_FRAGMENT } from "../../fragments/common.fragment";
import { JOB_APP_CORE_FRAGMENT } from "../../fragments/jobApp.fragment";

const GET_JOB_APPS_BY_TERM_ID = gql`
  ${JOB_APP_CORE_FRAGMENT}
  ${OPERATION_STATUS_FRAGMENT}
  query GetJobAppsByTermIdQuery($id: ObjectId!) {
    getJobAppsByTermId(_id: $id) {
      ... on JobAppList {
        jobApps {
          ...JobAppCoreFragment
        }
      }
      ... on OperationStatus {
        ...OperationStatusFragment
      }
    }
  }
`;

const GET_JOB_APP_BY_ID = gql`
  ${JOB_APP_CORE_FRAGMENT}
  query GetJobAppById($id: ObjectId!) {
    getJobAppById(_id: $id) {
      ...JobAppCoreFragment
    }
  }
`;

const JobAppQueries = {
  GET_JOB_APPS_BY_TERM_ID,
  GET_JOB_APP_BY_ID,
};

export default JobAppQueries;
