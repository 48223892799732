import { Box, Button, Text } from "@chakra-ui/react";
import { ISample } from "../../schemas/sample.schema";
import { TextStyles } from "../../theme/textStyles";
import SampleCard from "./sampleCard";

interface SamplesProps {
  samples: ISample[];
  hasMore: boolean;
  fetchMoreHandler(): void;
}
const Samples: React.FC<SamplesProps> = ({ samples, hasMore, fetchMoreHandler }) => {
  return (
    <Box mb="1rem">
      <Box alignItems="center" mb="1rem">
        <Text textStyle={TextStyles.description} mb="0.5rem">
          Viewing {samples.length} samples
        </Text>
        <Text textStyle={TextStyles.greyText} mb="1.5rem">
          Sample name needs to be unique.
        </Text>
        {samples.map((sample) => (
          <SampleCard key={sample._id} sample={sample} />
        ))}
      </Box>
      {hasMore ? (
        <Button
          margin="auto"
          variant="primaryFillRound"
          onClick={() => fetchMoreHandler()}
          mb="1rem"
        >
          View all Samples
        </Button>
      ) : (
        <Text textStyle={TextStyles.greyText}>-- The End --</Text>
      )}
    </Box>
  );
};

export default Samples;
