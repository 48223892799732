import React, { useEffect, useState } from "react";
import {
  Badge,
  Box,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputLeftElement,
  Spacer,
} from "@chakra-ui/react";
import Modal from "../components/modal";
import colors from "../theme/colors";
import { ButtonVariants } from "../theme/components/button";
import Divider from "./divider";
import ApplyViaMenu from "../components/applyViaMenu";
import { ReactUseState } from "../containers/App";
import { InputProps } from "./form";
import { TextStyles } from "../theme/textStyles";
import { ICompany, IJobListing } from "../schemas/job.schema";
import { ApplyVia } from "../constants/constants";
import AutoComplete, { AutoCompleteOption } from "./autocomplete";
import { FormStyles } from "../theme/components/form";
import JobAppTermMenu from "../containers/adapters/jobAppTerm/jobAppTermMenu";
import { ButtonProps } from "./button";

interface LogAppModalProps {
  triggerBtnProps: ButtonProps;
  selectedCompany: ReactUseState<ICompany | undefined>;
  selectedJob: ReactUseState<IJobListing | undefined>;
  submittedOn: ReactUseState<string>;
  applyVia: ReactUseState<ApplyVia>;
  jobLink: ReactUseState<string>;
  companyOptions: AutoCompleteOption<ICompany>[];
  companyJobOptions: AutoCompleteOption<IJobListing>[];
  onSubmit(): Promise<boolean>;
}

const LogAppModal: React.FC<LogAppModalProps> = ({
  triggerBtnProps,
  selectedCompany,
  selectedJob,
  submittedOn,
  applyVia,
  jobLink,
  companyOptions,
  companyJobOptions,
  onSubmit,
}) => {
  const DEFAULT_COMPANY_LOGO = "https://via.placeholder.com/150";

  const uid = localStorage.getItem("uid") || "";
  const [isFormFilled, setIsFormFilled] = useState(false);

  useEffect(() => {
    if (selectedCompany.data && selectedJob.data && submittedOn) {
      setIsFormFilled(true);
    } else {
      setIsFormFilled(false);
    }
  }, [selectedCompany.data, selectedJob.data, submittedOn]);

  const inputs: InputProps[] = [
    {
      label: "Comapny",
      value: selectedCompany,
      placeholder: "Enter Comapny Name",
    },
    {
      label: "Job Title",
      value: selectedJob,
      placeholder: "Enter Job Title",
      isDisabled: !selectedCompany.data,
    },
    {
      label: "Submitted On",
      type: "date",
      max: new Date().toISOString().split("T")[0],
      value: submittedOn,
    },
    {
      label: "Job Link (optional)",
      value: jobLink,
    },
  ];

  type InputComponentProps = {
    input: InputProps;
  };

  const CompanyInput: React.FC<InputComponentProps> = ({ input }) => {
    return (
      <Flex width="100%" alignItems="center">
        <AutoComplete
          id="autocomplete-input"
          options={companyOptions}
          data={input.value}
          inputFieldValue={input.value.data?.name}
          placeholder={input?.placeholder}
          addNewText="Add new company"
        />
        <FormLabel textStyle={TextStyles.thin}>{input.label}</FormLabel>
        {/* <Badge variant="outline" colorScheme="green">
          New (show when create new)
        </Badge> */}
        <Spacer />
        <Image
          alt={`comapny-${input.value.data ? input.value.data.name : "undefined"}-logo`}
          src={input.value.data?.logo}
          fallbackSrc={DEFAULT_COMPANY_LOGO}
          boxSize="2.75rem"
        />
      </Flex>
    );
  };

  const JobTitleInput: React.FC<InputComponentProps> = ({ input }) => {
    return (
      <>
        <AutoComplete
          id="autocomplete-input"
          options={companyJobOptions}
          data={input.value}
          inputFieldValue={input?.value.data?.title || ""}
          placeholder={input?.placeholder}
          addNewText="Add new job title"
        />
        <FormLabel textStyle={TextStyles.thin}>
          {input.label}
          <Badge />
        </FormLabel>
      </>
    );
  };

  const SubmittedAndApplyViaInputs: React.FC<InputComponentProps> = ({ input }) => {
    return (
      <>
        <FormControl
          variant={FormStyles.floatingBox}
          isRequired={input?.isRequired}
          isDisabled={input?.isDisabled}
        >
          <Input
            type={input?.type}
            max={input?.max}
            value={input.value.data}
            placeholder={input?.placeholder}
            isDisabled={input?.isDisabled}
            onChange={(e) => {
              typeof input.value.data === "number"
                ? input.value.setData(parseInt(e.target.value))
                : input.value.setData(e.target.value);
            }}
          />
          <FormLabel textStyle={TextStyles.thin}>{input.label}</FormLabel>
        </FormControl>
        <Divider height="3rem" width="2px" orientation="vertical" />
        <FormControl
          variant={FormStyles.floatingBox}
          backgroundColor="white"
          textAlign="start"
          width="100%"
        >
          <ApplyViaMenu selected={applyVia} />
          <FormLabel textStyle={TextStyles.thin}>Apply Via (optional)</FormLabel>
        </FormControl>
      </>
    );
  };

  const JobLinkInput: React.FC<InputComponentProps> = ({ input }) => {
    return (
      <>
        <InputLeftElement
          pointerEvents="none"
          textStyle={TextStyles.text}
          children="https://"
          height="60px"
          pt="1rem"
          ml="1rem"
        />
        <Input
          type={input?.type}
          value={input.value.data}
          placeholder={input?.placeholder}
          isDisabled={input?.isDisabled}
          ml="3.5rem"
          onChange={(e) => {
            typeof input.value.data === "number"
              ? input.value.setData(parseInt(e.target.value))
              : input.value.setData(e.target.value);
          }}
        />
        <FormLabel textStyle={TextStyles.thin}>
          {input.label}
          <Badge />
        </FormLabel>
      </>
    );
  };

  return (
    <Modal
      btnProps={triggerBtnProps}
      preHeader={<JobAppTermMenu viewOnly={true} uid={uid} mt="0.5rem" zindex="3" />}
      header="Log New Application"
      description={`Viewing 1 of 1`}
      body={
        <Box boxShadow="xl" rounded="lg" bg="white" width="100%" margin="auto" mb="1rem">
          {inputs.map((input, key) => {
            return (
              <Flex
                key={key}
                px="0.5rem"
                py="0.4rem"
                direction="row"
                alignItems="center"
                justifyContent="center"
                backgroundColor={key % 2 === 0 ? "white" : "grey.100"}
                borderRadius={
                  key === 0
                    ? "0.75rem 0.75rem 0 0"
                    : key === inputs.length - 1
                    ? "0 0 0.75rem 0.75rem"
                    : "none"
                }
              >
                {key === 2 ? (
                  <SubmittedAndApplyViaInputs input={input} />
                ) : (
                  <FormControl
                    variant={FormStyles.floatingBox}
                    isRequired={input?.isRequired}
                    isDisabled={input?.isDisabled}
                  >
                    {key === 0 && <CompanyInput input={input} />}
                    {key === 1 && <JobTitleInput input={input} />}
                    {key === 3 && <JobLinkInput input={input} />}
                  </FormControl>
                )}
              </Flex>
            );
          })}
        </Box>
      }
      nextBtn={{
        text: "Keep Track Now!",
        variant: ButtonVariants.primaryFillSquare,
        textStyle: "primaryFillBtn",
        padding: "0.5rem 1rem",
        isDisabled: !isFormFilled,
        width: "60%",
      }}
      goBack={{
        text: "Skip",
        variant: ButtonVariants.greyLineSquare,
        color: colors.grey[600],
        textStyle: "h6",
        width: "20%",
      }}
      onSubmit={onSubmit}
    />
  );
};

export default LogAppModal;
