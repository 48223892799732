import React, { useEffect, useState } from "react";
import { Box, Text, Flex, Spacer, useToast } from "@chakra-ui/react";
import StepBar from "../../components/stepBar";
import Form, { InputProps } from "../../components/form";
import { ButtonVariants } from "../../theme/components/button";
import { TextStyles } from "../../theme/textStyles";
import { validateEmailAddress } from "../../utils";
import Button from "../../components/button";
import { ToastStatus } from "../../constants/constants";
import { ReactUseState } from "../App";

export interface AccountInfoProps {
  email: ReactUseState<string>;
  invitationCode: ReactUseState<string>;
  password: ReactUseState<string>;
  step: ReactUseState<number>;
}

const AccountInfo: React.FC<AccountInfoProps> = ({ email, invitationCode, password, step }) => {
  const toast = useToast();
  const [tempPassword, setTempPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isFormFilled, setIsFormFilled] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (email.data && invitationCode.data && tempPassword && confirmPassword) {
      setIsFormFilled(true);
    } else {
      setIsFormFilled(false);
    }
  }, [email, invitationCode, tempPassword, confirmPassword]);

  const accountInfoInputs: InputProps[] = [
    {
      label: "Invitation Code",
      value: invitationCode,
      isRequired: true,
      type: "text",
      placeholder: "Enter an invitation code to register",
    },
    {
      label: "Email",
      value: email,
      isRequired: true,
      type: "email",
      placeholder: "Enter email",
    },
    {
      label: "Password",
      value: { data: tempPassword, setData: setTempPassword },
      isRequired: true,
      type: "password",
      placeholder: "Enter password",
    },
    {
      label: "Confirm Password",
      value: { data: confirmPassword, setData: setConfirmPassword },
      isRequired: true,
      type: "password",
      placeholder: "Confirm password",
    },
  ];

  const accountInfoHandler = () => {
    if (!validateEmailAddress(email.data)) {
      toast({
        description: "Please enter valid email address",
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
    } else if (tempPassword !== confirmPassword) {
      toast({
        description: "Password not matched",
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
    } else {
      password.setData(tempPassword);
      step.setData(2);
    }
  };

  return (
    <Box>
      <StepBar steps={3} currentStep={1} />
      <Box mb="1rem">
        <Text as="h2" textStyle={TextStyles.h2} mb="1rem">
          Account Info
        </Text>
        <Form inputs={accountInfoInputs} />
      </Box>
      <Flex mb="2rem">
        <Spacer />
        <Button
          variant={ButtonVariants.primaryFillSquare}
          text="Next"
          textStyle={TextStyles.primaryFillBtn_lg}
          margin="auto"
          onClick={accountInfoHandler}
          isDisabled={!isFormFilled}
          padding="0.25rem 0.5rem"
          justify="end"
        />
      </Flex>
    </Box>
  );
};

export default AccountInfo;
