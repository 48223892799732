const colors = {
  action: {
    gradient: "linear-gradient(93.14deg, #9F03B8 -15.04%, #FF6666 43.25%, #FFE03B 108.73%)",
    text: "linear(to-r,  #9F03B8,#FF6666, #FFE03B)",
  },
  primary: {
    gradient: "linear-gradient(91.15deg, #ED456C 0%, #F4A362 98.45%)",
    solid: "#f07468",
    text: "linear(to-br,  #ED456C, #F4A362)",
    500: "linear-gradient(91.15deg, #ED456C 0%, #F4A362 98.45%)",
  },
  grey: {
    100: "#FAFAFA",
    200: "#F2F2F2",
    300: "#E5E5E5",
    400: "#C4C4C4",
    500: "#A6A6A6",
    600: "#707070",
    700: "#595959",
  },
  text: "#2D2D2D",
  active: "#66C693",
  alert: "#DC3838",
  neutral: { 100: "#EEF8FD", 200: "#D8EDF7", 300: "#3794BF" },
  positive: { 100: "#EFFBDC", 300: "#4B7905" },
};

export default colors;
