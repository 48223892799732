import { Box, Button, Flex, MenuButton, Text } from "@chakra-ui/react";
import SVGIcon, { SVGIconProps } from "../../assets/icons";
import React from "react";
import { ReactUseState } from "../../containers/App";
import { capitalizeStr } from "../../utils";
import { CheckBoxProps, OptionProps } from ".";

export interface MenuButtonProps {
  variant: string;
  isTextHidden?: boolean;
  textStyle?: string;
  leftIcon?: SVGIconProps;
  rightIcon?: SVGIconProps;
  props?: {
    [key: string]: any;
  };
}

interface DropdownMenuButtonProps {
  menuButtonProps: MenuButtonProps;
  checkBoxProps?: CheckBoxProps;
  selectedMenuItem?: ReactUseState<OptionProps>;
}

const DropdownMenuButton: React.FC<DropdownMenuButtonProps> = ({
  menuButtonProps,
  checkBoxProps,
  selectedMenuItem,
}) => {
  return (
    <MenuButton as={Button} variant={menuButtonProps.variant} {...menuButtonProps.props}>
      <Flex direction="row" alignItems="center" justifyContent="center">
        {menuButtonProps?.leftIcon && (
          <Box pr="0.3rem" pb="0.1rem">
            <SVGIcon {...menuButtonProps.leftIcon} />
          </Box>
        )}
        {selectedMenuItem?.data?.leftIcon && (
          <Box pr="0.15rem">
            <SVGIcon {...selectedMenuItem?.data.leftIcon} />
          </Box>
        )}
        {!menuButtonProps.isTextHidden && (
          <Text textStyle={menuButtonProps.textStyle}>
            {checkBoxProps
              ? capitalizeStr(checkBoxProps.buttonText)
              : capitalizeStr(selectedMenuItem?.data.text)}
          </Text>
        )}
        {selectedMenuItem?.data?.rightIcon && (
          <Box pl="0.15rem">
            <SVGIcon {...selectedMenuItem?.data.rightIcon} />
          </Box>
        )}
        {menuButtonProps?.rightIcon && (
          <Box pl="0.25rem">
            <SVGIcon {...menuButtonProps.rightIcon} />
          </Box>
        )}
      </Flex>
    </MenuButton>
  );
};

export default DropdownMenuButton;
