import { ToastStatus } from "../constants/constants";

export type JobAppToastType = {
  appliedOn?: boolean;
  applyVia?: boolean;
  associatedEmail?: boolean;
  recruiter?: boolean;
  jobLink?: boolean;
  [key: string]: any;
};

export type JobAppEventToastType = {
  title?: boolean;
  xCompleted?: boolean;
  time?: boolean;
  duration?: boolean;
  invitation?: boolean;
  [key: string]: any;
};

export type JobAppOfferToastType = {
  status?: boolean;
  deadline?: boolean;
  startDate?: boolean;
  endDate?: boolean;
  position?: boolean;
  payBasis?: boolean;
  payRate?: boolean;
  stock?: boolean;
  vestingSchedules?: boolean;
  signOnBonus?: boolean;
  performanceBonus?: boolean;
  otherCompensation?: boolean;
  relocation?: boolean;
  location?: boolean;
  benefits?: boolean;
  description?: boolean;
  [key: string]: any;
};

export const showToast = (
  entity: string,
  entityToast: JobAppToastType | JobAppEventToastType | JobAppOfferToastType | SampleToastType,
  chakraToast: (any: any) => any
) => {
  let error_message = `Failed to update field(s) - `;
  const error_fields: string[] = [];
  const objEntries = Object.entries(entityToast);
  objEntries.forEach(([key, value]) => {
    if (value === false) {
      error_fields.push(key);
    }
  });
  if (error_fields.length === 0) {
    chakraToast({
      title: `${entity} updated successfully`,
      position: "bottom",
      status: ToastStatus.SUCCESS,
      isClosable: true,
    });
  } else {
    error_message += error_fields.join(", ");
    chakraToast({
      title: "Failed to update data",
      description: error_message,
      position: "bottom",
      status: ToastStatus.ERROR,
      variant: "subtle",
      isClosable: true,
    });
  }
};

export type SampleToastType = {
  name?: boolean;
  age?: boolean;
  isStudet?: boolean;
  [key: string]: any;
};
