import { ApolloCache, StoreObject, useMutation } from "@apollo/client";
import { Flex, useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import ButtonIcon from "../../../components/buttonIcon";
import JobAppOfferHeader from "../../../components/jobAppOffer/jobAppOfferHeader";
import { JobAppOfferStatus, PayBasis, ToastStatus } from "../../../constants/constants";
import JobAppOfferServices from "../../../graphql/services/jobAppOffer.services";
import { IJobAppOffer } from "../../../schemas/app.schema";
import colors from "../../../theme/colors";
import { LayerStyles } from "../../../theme/layerStyles";
import { ReactUseState } from "../../App";
import * as JobAppOfferTypes from "../../../graphql/types/jobAppOffer.type";
import { JOB_APP_OFFER_CORE_FRAGMENT } from "../../../graphql/fragments/jobAppOffer.fragment";
import { OperationStatus } from "../../../graphql/types/common.type";

interface CreateJobAppOfferProps {
  appId: string;
  offers: ReactUseState<IJobAppOffer[]>;
}

const CreateJobAppOffer: React.FC<CreateJobAppOfferProps> = ({ appId, offers }) => {
  const ONE_WEEK_MILLSECONDS = 1000 * 60 * 60 * 24 * 7;

  let serialNumber: number = offers.data.length;
  const title = serialNumber > 0 ? `Competing Offer #${serialNumber}` : "Offer";
  const toast = useToast();

  useEffect(() => {
    serialNumber = offers.data.length;
  }, [offers.data.length]);

  const [createJobAppOffer, { loading }] = useMutation<
    JobAppOfferTypes.CreateJobAppOfferData,
    JobAppOfferTypes.CreateJobAppOfferArgs
  >(JobAppOfferServices.CREATE_JOB_APP_OFFER);

  const cache_createJobAppOffer = (
    cache: ApolloCache<any>,
    data: JobAppOfferTypes.CreateJobAppOfferData | null | undefined
  ) => {
    const result = data?.createJobAppOffer;
    if ((result as IJobAppOffer)._id) {
      const newOffer: IJobAppOffer = result as IJobAppOffer;
      toast({
        title: `Offer created successfully`,
        position: "bottom",
        status: ToastStatus.SUCCESS,
        isClosable: true,
      });
      const newOfferRef = cache.writeFragment({
        data: newOffer,
        fragment: JOB_APP_OFFER_CORE_FRAGMENT,
      });
      cache.modify({
        fields: {
          getJobAppOffersByJobAppId(existing: StoreObject[] = [], { readField }) {
            if (existing.some((ref) => readField("_id", ref) === newOffer._id)) {
              return existing;
            }
            return [...existing, newOfferRef];
          },
        },
      });
      offers.setData([...offers.data, newOffer]);
    } else {
      const status: OperationStatus = result as OperationStatus;
      toast({
        title: status?.errorTitle,
        description: status?.errorMessage,
        position: "bottom",
        status: ToastStatus.ERROR,
        variant: "subtle",
        isClosable: true,
      });
    }
  };

  const onCreate = async () => {
    const newOffer: JobAppOfferTypes.CreateJobAppOfferInput = {
      jobAppId: appId,
      status: JobAppOfferStatus.PENDING,
      deadline: Date.now() + ONE_WEEK_MILLSECONDS,
      payRate: 0,
      payBasis: PayBasis.YEARLY,
    };
    const newOfferArgs: JobAppOfferTypes.CreateJobAppOfferArgs = {
      newJobAppOffer: newOffer,
    };
    await createJobAppOffer({
      variables: newOfferArgs,
      update(cache, { data }) {
        cache_createJobAppOffer(cache, data);
      },
    });
  };

  return (
    <Flex
      direction="row"
      layerStyle={LayerStyles.cardGeneral}
      alignItems="center"
      justifyContent="center"
      width="100%"
      py="0.5rem"
      mb="1rem"
    >
      <JobAppOfferHeader title={title} _draft={false} />
      <ButtonIcon
        icon={{ name: "add-circle-line", size: "1.75rem", fill: colors.primary.solid }}
        onClick={() => onCreate()}
      />
    </Flex>
  );
};

export default CreateJobAppOffer;
