import React, { Dispatch, useEffect, useState } from "react";
import { Box, ChakraProvider, Grid, GridItem, Slide } from "@chakra-ui/react";
import theme from "../theme";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import NotificationBanner from "../components/notificationBanner";
import { Helmet } from "react-helmet";
import AppRoutes from "./AppRoutes.constant";
import Navbar from "./navbar";
import NetPromoterSlider from "../components/netPromoterSlider";
import About from "./pages/about";
import Explore from "./pages/explore";
import LogIn from "./pages/login";
import Register from "./pages/register";
import Account from "./pages/account";
import Setting from "./pages/setting";
import MyApps from "./pages/myApps";
import Sample from "./pages/sample";
import Samples from "./pages/samples";
import MyAppDetail from "./pages/myAppDetail";
import Companies from "./pages/companies";
import JobDetail from "./pages/jobDetail";
import Company from "./pages/company";
import PrepNotes from "./pages/prepNotes";
import PageNotFound from "./pages/pageNotFound";
import ResetPassword from "./pages/resetPassword";
// import ProtectedRoute, { PublicRoute } from "./protectedRoute";
import InfoBar from "./infoBar";
import MockDataLoader from "../assets/mockData";
import * as LocalTypes from "../graphql/types/local.type";
import { GET_LOGGED_IN_USER } from "../graphql/services/local.services";
import { useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import { useLogIn } from "../hooks/useLogIn";
import {
  currentJobAppTermVar,
  CurrentJobAppTermVarType,
  jobListingMarksVar,
  loggedInUserVar,
  LoggedInUserVarType,
} from "../cache/reactiveVars";
import JobAppServices from "../graphql/services/jobApp.services";
import * as JobAppTypes from "../graphql/types/jobApp.type";
import { IJobApp } from "../schemas/app.schema";
import { get_cached_logged_in_user, get_selected_job_app_term_from_cache } from "../cache/helpers";
import useCurrentJobAppTermHook from "../hooks/useCurrentJobAppTerm";
import useJobListingMarksHook from "../hooks/useJobListingMarks";
import config, { NodeEnv } from "../config";
import ComingSoon from "./pages/comingSoon";

export interface ReactUseState<T> {
  data: T;
  setData: Dispatch<React.SetStateAction<T>>;
}

export interface HandleDataChange<T> {
  data: T;
  handleData(newData: T): any;
}

export const mockDataLoader = new MockDataLoader();

const App = () => {
  const winHeight = window.screen.height * 0.9; // TODO: stretch height to full screen
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const maintenanceMode = config.BUILD_ENV.length > 0 && config.BUILD_ENV === NodeEnv.PRODUCTION;

  const { client, data } = useQuery<LocalTypes.GetLoggedInUserData>(GET_LOGGED_IN_USER, {
    // nextFetchPolicy: "cache-first",
  });
  const [getJobAppsByTermId, { data: jobAppsByTermQueryData }] = useLazyQuery<
    JobAppTypes.GetJobAppsByTermIdData,
    JobAppTypes.GetJobAppsByTermIdArgs
  >(JobAppServices.GET_JOB_APPS_BY_TERM_ID);

  const { login, logout, getUser } = useLogIn(loggedInUserVar);
  const { setCurrentJobAppTerm } = useCurrentJobAppTermHook(currentJobAppTermVar);
  const { setJobListingMarks } = useJobListingMarksHook(jobListingMarksVar);

  const [loggedInUser, setLoggedInUser] = useState<LoggedInUserVarType>(
    get_cached_logged_in_user(client, login, getUser)
  );
  const [xLogin, setXLogin] = useState(data?.getLoggedInUser || loggedInUser ? true : false);
  const currentTerm: CurrentJobAppTermVarType = useReactiveVar(currentJobAppTermVar);
  const [jobAppsByTerm, setJobAppsByTerm] = useState<IJobApp[]>([]);

  useEffect(() => {
    if (!maintenanceMode) {
      if (loggedInUser) {
        setXLogin(true);
        const currentTermObj = get_selected_job_app_term_from_cache(client, loggedInUser._id);
        setCurrentJobAppTerm(currentTermObj);
      }
    }
  }, [loggedInUser, maintenanceMode]);

  useEffect(() => {
    if (!maintenanceMode) {
      (async () => {
        if (currentTerm) {
          setJobListingMarks(new Set(currentTerm.jobMarks));
          await getJobAppsByTermId({ variables: { id: currentTerm._id } }).catch((err) => {
            console.log(err.message);
          });
        }
      })();
    }
  }, [currentTerm, maintenanceMode]);

  useEffect(() => {
    if (!maintenanceMode) {
      const jobApps = (jobAppsByTermQueryData?.getJobAppsByTermId as JobAppTypes.JobAppList)
        ?.jobApps;
      if (jobAppsByTermQueryData?.getJobAppsByTermId && jobApps) {
        setJobAppsByTerm(jobApps);
      }
    }
  }, [jobAppsByTermQueryData, maintenanceMode]);

  const clearState = () => {
    setLoggedInUser(undefined);
    setXLogin(false);
    setJobAppsByTerm([]);
    // setCurrentJobAppTerm(undefined);
  };

  const onLogoutHandler = (): Promise<any> => {
    return new Promise(() => {
      logout();
      clearState();
      localStorage.clear();
      client.clearStore().then(() => {
        console.log("bye from client.clearStore");
      });
    });
  };

  const ProtectedRoutes = () => {
    return (
      <>
        <Route path={AppRoutes.myApps.route} exact={true}>
          <MyApps jobAppsByTerm={jobAppsByTerm} />
        </Route>
        <Route path={AppRoutes.myAppDetail.route} exact={true} component={MyAppDetail} />
        <Route path={AppRoutes.prep.route} exact={true} component={PrepNotes} />
        <Route path={AppRoutes.profile.route} exact={true} component={Account} />
        <Route path={AppRoutes.setting.route} exact={true} component={Setting} />
      </>
    );
  };

  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Helmet>
          <title>Forwardy.app</title>
        </Helmet>
        {maintenanceMode ? (
          <ComingSoon />
        ) : (
          <Box maxW="1440px" marginInline="auto" height="100%">
            {xLogin && <NetPromoterSlider />}
            <Grid templateColumns="repeat(10, 1fr)" height={winHeight}>
              <GridItem colSpan={2}>
                <Navbar xLogin={{ data: xLogin, setData: setXLogin }} logOut={onLogoutHandler} />
              </GridItem>
              <GridItem colSpan={6} layerStyle="mainPanel">
                <Switch>
                  <Route path="/samples" exact={true} component={Samples} />
                  <Route path="/sample/:sampleId" exact={true} component={Sample} />
                  <Route path={AppRoutes.home.route} exact={true}>
                    <Explore currentTerm={currentTerm} jobAppsByTerm={jobAppsByTerm} />
                  </Route>
                  <Route path={AppRoutes.about.route} exact={true} component={About} />
                  <Route path={AppRoutes.companies.route} exact={true} component={Companies} />
                  <Route path={AppRoutes.company.route} exact={true}>
                    <Company jobAppsByTerm={jobAppsByTerm} />
                  </Route>
                  <Route path={AppRoutes.explore.route} exact={true} component={Explore}>
                    <Explore currentTerm={currentTerm} jobAppsByTerm={jobAppsByTerm} />
                  </Route>
                  <Route
                    path={AppRoutes.resetPassword.route}
                    exact={true}
                    component={ResetPassword}
                  />
                  <Route path={AppRoutes.job.route} exact={true}>
                    <JobDetail jobAppsByTerm={jobAppsByTerm} />
                  </Route>
                  <Route path={AppRoutes.login.route} exact={true}>
                    <LogIn xLogin={{ data: xLogin, setData: setXLogin }} />
                  </Route>
                  <Route path={AppRoutes.register.route} exact={true}>
                    <Register xLogin={{ data: xLogin, setData: setXLogin }} />
                  </Route>
                  {xLogin && <ProtectedRoutes />}
                  <Route path={AppRoutes.error.route} component={PageNotFound} />
                  {/* <PublicRoute
                  route={AppRoutes.home}
                  setTitlePrefix={setDocTitlePrefix}
                  exact={true}
                  component={Explore}
                /> */}
                  {/* <PublicRoute
                  route={AppRoutes.explore}
                  setTitlePrefix={setDocTitlePrefix}
                  exact={true}
                  component={Explore}
                />
                <PublicRoute
                  route={{ route: "/sample", title: "Sample" }}
                  setTitlePrefix={setDocTitlePrefix}
                  exact={true}
                  component={Sample}
                /> */}
                  {/* <PublicRoute
                  route={AppRoutes.about}
                  setTitlePrefix={setDocTitlePrefix}
                  exact={true}
                  component={About}
                /> */}
                  {/* <PublicRoute
                  route={AppRoutes.login}
                  setTitlePrefix={setDocTitlePrefix}
                  exact={true}
                  component={LogIn}
                />
                <PublicRoute
                  route={AppRoutes.register}
                  setTitlePrefix={setDocTitlePrefix}
                  exact={true}
                  component={Register}
                /> */}
                  {/* <ProtectedRoute
                  route={AppRoutes.myApps}
                  setTitlePrefix={setDocTitlePrefix}
                  xLogin={xLogin}
                  exact={true}
                  component={MyApps}
                />
                <ProtectedRoute
                  route={AppRoutes.myAppDetail}
                  setTitlePrefix={setDocTitlePrefix}
                  xLogin={xLogin}
                  exact={true}
                  component={MyAppDetail}
                />
                <ProtectedRoute
                  route={AppRoutes.profile}
                  setTitlePrefix={setDocTitlePrefix}
                  xLogin={xLogin}
                  exact={true}
                  component={Profile}
                />

                <ProtectedRoute
                  route={AppRoutes.setting}
                  setTitlePrefix={setDocTitlePrefix}
                  xLogin={xLogin}
                  exact={true}
                  component={Setting}
                /> */}
                  {/* <PublicRoute
                  route={AppRoutes.error}
                  setTitlePrefix={setDocTitlePrefix}
                  exact={false}
                  component={PageNotFound} 
                /> */}
                </Switch>
              </GridItem>
              <GridItem colSpan={2}>
                <InfoBar xLogin={xLogin} jobAppsByTerm={jobAppsByTerm} />
              </GridItem>
            </Grid>
            {!xLogin && (
              <Slide direction="bottom" in={!xLogin} style={{ zIndex: 5 }}>
                <NotificationBanner />
              </Slide>
            )}
          </Box>
        )}
      </BrowserRouter>
    </ChakraProvider>
  );
};

export default App;
