import { useMutation, ApolloCache, StoreObject } from "@apollo/client";
import { useToast } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { safe_delete_cached_data } from "../../../cache/helpers";
import SampleDetail from "../../../components/sample/sampleDetail";
import { ToastStatus } from "../../../constants/constants";
import SampleServices from "../../../graphql/services/sample.service";
import { ISample } from "../../../schemas/sample.schema";
import * as SampleTypes from "../../../graphql/types/sample.type";
import { SAMPLE_UPDATE_FIELDS_FRAGMENT } from "../../../graphql/fragments/sample.fragment";
import { SampleToastType, showToast } from "../../../utils/updateHelper";

interface ViewSampleDetailProps {
  sample: ISample;
}

const ViewSampleDetail: React.FC<ViewSampleDetailProps> = ({ sample }) => {
  const toast = useToast();
  const history = useHistory();

  const [name, setName] = useState<string>(sample.name);
  const [age, setAge] = useState<number>(sample.age);
  const [isStudent, setIsStudent] = useState<boolean>(sample.isStudent);
  const [isNameDirty, setIsNameDirty] = useState(false);
  const [isAgeDirty, setIsAgeDirty] = useState(false);
  const [isIsStudentDirty, setIsIsStudentDirty] = useState(false);

  useEffect(() => {
    setIsNameDirty(name !== sample.name);
  }, [name, sample.name]);

  useEffect(() => {
    setIsAgeDirty(age !== sample.age);
  }, [age, sample.age]);

  useEffect(() => {
    setIsIsStudentDirty(isStudent !== sample.isStudent);
  }, [isStudent, sample.isStudent]);

  const [updateSample] = useMutation<SampleTypes.UpdateSampleData, SampleTypes.UpdateSampleArgs>(
    SampleServices.UPDATE_SAMPLE({
      name: isNameDirty,
      age: isAgeDirty,
      isStudent: isIsStudentDirty,
    })
  );

  const [deleteSample] = useMutation<SampleTypes.DeleteSampleData, SampleTypes.DeleteSampleArgs>(
    SampleServices.DELETE_SAMPLE
  );

  const cache_updateSample = (
    cache: ApolloCache<any>,
    data: SampleTypes.UpdateSampleData | null | undefined
  ) => {
    const updateSample: Partial<ISample> = {};
    const sampleToast: SampleToastType = {};
    if (isNameDirty) {
      if (data?.updateSampleByName?.ok) {
        updateSample.name = name;
        sampleToast.name = true;
      } else {
        sampleToast.name = false;
      }
    }
    if (isAgeDirty) {
      if (data?.updateSampleByAge?.ok) {
        updateSample.age = age;
        sampleToast.age = true;
      } else {
        sampleToast.age = false;
      }
    }
    if (isIsStudentDirty) {
      if (data?.updateSampleByIsStudent?.ok) {
        updateSample.isStudent = isStudent;
        sampleToast.isStudet = true;
      } else {
        sampleToast.isStudet = false;
      }
    }
    // update cache for the updated sample in GetSamples query
    cache.writeFragment({
      id: `Sample:${sample._id}`,
      fragment: SAMPLE_UPDATE_FIELDS_FRAGMENT,
      data: {
        name: name,
        age: age,
        isStudent: isStudent,
      },
    });
    // show error or success toast to indicate operation status
    showToast("Sample", sampleToast, toast);
  };

  const onSubmit = async () => {
    const updateSampleArgs: SampleTypes.UpdateSampleArgs = {};
    if (isNameDirty) {
      updateSampleArgs.updateSampleByName = { _id: sample._id, name: name };
    }
    if (isAgeDirty) {
      updateSampleArgs.updateSampleByAge = { _id: sample._id, age: age };
    }
    if (isIsStudentDirty) {
      updateSampleArgs.updateSampleByIsStudent = { _id: sample._id, isStudent: isStudent };
    }
    await updateSample({
      variables: updateSampleArgs,
      update(cache, { data }) {
        cache_updateSample(cache, data);
      },
    }).catch((error) => {
      toast({
        description: `${error.message}`,
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
    });
  };

  const onUpdateHandler = () => {
    if (name && age) {
      onSubmit();
    } else {
      toast({
        description: "Please enter name and age",
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
    }
  };

  const cache_deleteSample = (
    cache: ApolloCache<any>,
    data: SampleTypes.DeleteSampleData | null | undefined
  ) => {
    const deleted = data?.deleteSample;
    if (deleted?.ok === true) {
      // remove from getSamples
      cache.modify({
        fields: {
          getSamples(existing: StoreObject[] = [], { readField }) {
            return existing.filter((sampleRef) => sample._id !== readField("_id", sampleRef));
          },
        },
      });
      // delete entire object
      safe_delete_cached_data(cache, sample._id, "Sample");
    }
  };

  const onDeleteHandler = async () => {
    const deleteSampleArgs: SampleTypes.DeleteSampleArgs = { id: sample._id };
    await deleteSample({
      variables: deleteSampleArgs,
      update(cache, { data }) {
        cache_deleteSample(cache, data);
        alert("Deleted sample");
        history.push("/samples");
      },
    }).catch((error) => {
      toast({
        description: `${error.message}`,
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
    });
  };

  return (
    <SampleDetail
      sample={sample}
      name={{ data: name, setData: setName }}
      age={{ data: age, setData: setAge }}
      isStudent={{ data: isStudent, setData: setIsStudent }}
      isNameDirty={isNameDirty}
      isAgeDirty={isAgeDirty}
      isIsStudentDirty={isIsStudentDirty}
      onUpdateHandler={onUpdateHandler}
      onDeleteHandler={onDeleteHandler}
    />
  );
};

export default ViewSampleDetail;
