import { useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import { ApolloCache, useMutation, StoreObject } from "@apollo/client";
import JobAppTermServices from "../../../graphql/services/jobAppTerm.services";
import * as JobAppTermTypes from "../../../graphql/types/jobAppTerm.type";
import { IJobAppTerm } from "../../../schemas/user.schema";
import { OperationStatus } from "../../../graphql/types/common.type";
import { JOB_APP_TERM_DISPLAY_FRAGMENT } from "../../../graphql/fragments/jobAppTerm.fragment";
import { ToastStatus } from "../../../constants/constants";
import JobTermCreateModal from "../../../components/jobTermCreateModal";

interface JobAppTermCreateModalProps {
  uid: string;
  termTitleMaxLength: number;
}

const JobAppTermCreateModal: React.FC<JobAppTermCreateModalProps> = ({
  uid,
  termTitleMaxLength,
}) => {
  const DEFAULT_TITLE = "";
  const [title, setTitle] = useState<string>(DEFAULT_TITLE);
  const toast = useToast();

  const clearInputs = () => {
    setTitle(DEFAULT_TITLE);
  };

  const [createJobAppTerm, { client, reset }] = useMutation<
    JobAppTermTypes.CreateJobAppTermData,
    JobAppTermTypes.CreateJobAppTermArgs
  >(JobAppTermServices.CREATE_JOB_APP_TERM);

  const cache_createJobAppTerm = (
    cache: ApolloCache<any>,
    data: JobAppTermTypes.CreateJobAppTermData | null | undefined
  ) => {
    const newTermResult = data?.createJobAppTerm;
    if ((newTermResult as IJobAppTerm)._id) {
      const newTerm: IJobAppTerm = newTermResult as IJobAppTerm;
      toast({
        title: `Term created successfully`,
        position: "bottom",
        status: ToastStatus.SUCCESS,
        isClosable: true,
      });
      clearInputs();
      // write new term
      const newTermRef = cache.writeFragment({
        id: `JobAppTerm:${newTerm._id}`,
        fragment: JOB_APP_TERM_DISPLAY_FRAGMENT,
        data: newTerm,
      });
      // update query getAllTermsByUid
      cache.modify({
        fields: {
          getAllTermsByUid(existing: StoreObject[] = [], { readField }) {
            if (existing.some((ref) => readField("_id", ref) === newTerm._id)) {
              return existing;
            }
            return [...existing, newTermRef];
          },
        },
      });
    } else {
      const newTerm: OperationStatus = newTermResult as OperationStatus;
      toast({
        title: newTerm?.errorTitle,
        description: newTerm?.errorMessage,
        position: "bottom",
        status: ToastStatus.ERROR,
        variant: "subtle",
        isClosable: true,
      });
    }
  };

  const onSubmit = async (): Promise<boolean> => {
    const createJobAppTermArgs: JobAppTermTypes.CreateJobAppTermArgs = {
      newJobAppTerm: { uid: uid, title: title },
    };
    let success: boolean = false;
    await createJobAppTerm({
      variables: createJobAppTermArgs,
      update(cache, { data }) {
        cache_createJobAppTerm(cache, data);
        success = true;
      },
    }).catch((error) => {
      toast({
        title: "Failed to create term",
        description: `${error.message}`,
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
      reset();
      success = false;
    });
    return success;
  };

  const onCreateHandler = async (): Promise<boolean> => {
    if (!title || title.length > termTitleMaxLength) {
      toast({
        description: "Term title should be 1 ~ 25 characters",
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
      return false;
    }
    return await onSubmit();
  };

  return (
    <JobTermCreateModal
      title={{ data: title, setData: setTitle }}
      termTitleMaxLength={termTitleMaxLength}
      onCreateHandler={onCreateHandler}
    />
  );
};

export default JobAppTermCreateModal;
