import React, { useEffect, useState } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { Box, Text, Link } from "@chakra-ui/react";
import AppRoutes from "../AppRoutes.constant";
import { DocTitleSuffix } from "../../constants/constants";
import { Helmet } from "react-helmet";
import { LayerStyles } from "../../theme/layerStyles";
import { TextStyles } from "../../theme/textStyles";
import EmailInfo from "../resetPasswordSubs/emailInfo";
import VerificationCode from "../resetPasswordSubs/verificationCode";
import EnterNewPassword from "../resetPasswordSubs/enterNewPassword";

const ResetPassword = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [step, setStep] = useState(1);

  return (
    <Box layerStyle={LayerStyles.mainPanelChild} mt="3.5rem" maxW="24rem">
      <Helmet>
        <title>
          {AppRoutes.resetPassword.title}
          {DocTitleSuffix}
        </title>
      </Helmet>
      <Text as="h1" textStyle={TextStyles.h1}>
        Reset Password
      </Text>
      {step === 1 && <EmailInfo step={{ data: step, setData: setStep }} />}
      {step === 2 && <VerificationCode step={{ data: step, setData: setStep }} />}
      {step === 3 && <EnterNewPassword step={{ data: step, setData: setStep }} />}
      <Link color="primary.solid" as={ReactRouterLink} to={AppRoutes.login.route}>
        Back to Log in
      </Link>
    </Box>
  );
};

export default ResetPassword;
