import React from "react";
import { Button as ChakraButton, Text } from "@chakra-ui/react";
import SVGIcon, { SVGIconProps } from "../assets/icons";

export interface ButtonProps {
  variant?: string;
  textStyle?: string;
  leftIcon?: SVGIconProps;
  rightIcon?: SVGIconProps;
  text?: string;
  xShadow?: boolean;
  onClick?(): any;
  [key: string]: any;
}

const Button: React.FC<ButtonProps> = React.forwardRef(
  (
    {
      variant = "primaryLineRound",
      textStyle,
      leftIcon,
      rightIcon,
      text,
      borderWidth,
      xShadow = false,
      onClick,
      ...props
    },
    ref
  ) => {
    return (
      <ChakraButton
        variant={variant}
        leftIcon={leftIcon ? <SVGIcon {...leftIcon} /> : undefined}
        rightIcon={rightIcon ? <SVGIcon {...rightIcon} /> : undefined}
        height="max-content"
        borderWidth={borderWidth}
        boxShadow={xShadow ? "0 2px 3px 0 rgba(0, 0, 0, 0.2)" : "none"}
        onClick={() => (onClick ? onClick() : {})}
        userSelect="none"
        {...props}
      >
        {text ? (
          <Text textStyle={textStyle} mx={leftIcon || rightIcon ? "0px" : "0.5rem"}>
            {text}
          </Text>
        ) : (
          <></>
        )}
      </ChakraButton>
    );
  }
);

export default Button;
