import { ButtonProps } from "./button";
import ButtonIcon, { ButtonIconProps } from "./buttonIcon";
import { ButtonVariants } from "../theme/components/button";
import colors from "../theme/colors";
import Form, { InputProps } from "./form";
import Modal from "./modal";
import { Box, Flex, Circle, Text } from "@chakra-ui/react";
import React from "react";
import { TextStyles } from "../theme/textStyles";
import { ReactUseState } from "../containers/App";

interface JobTermEditModalProps {
  title: ReactUseState<string>;
  xCompleted: ReactUseState<boolean>;
  deletable: boolean;
  termTitleMaxLength: number;
  handlers: {
    delete(): Promise<boolean>;
    update(): Promise<boolean>;
  };
}

const JobTermEditModal: React.FC<JobTermEditModalProps> = ({
  title,
  xCompleted,
  deletable,
  termTitleMaxLength,
  handlers,
}) => {
  const editBtnIcon: ButtonIconProps = {
    icon: { name: "overflow-menu", size: "1rem" },
    zIndex: "5",
    left: "-22%",
  };

  const editingTermTitleInput: InputProps[] = [
    {
      label: "Term title",
      value: title,
      placeholder: "Enter job term title",
      maxLength: termTitleMaxLength,
    },
  ];

  const saveBtn: ButtonProps = {
    text: "Save",
    variant: ButtonVariants.primaryFillSquare,
    textStyle: TextStyles.primaryFillBtn_md,
    padding: "0.5rem",
    isDisabled: !title.data,
    margin: "auto",
  };

  const deleteBtn: ButtonProps = {
    text: "Delete",
    variant: ButtonVariants.greyLineSquare,
    color: colors.grey[600],
    textStyle: TextStyles.h6,
    isDisabled: !deletable,
  };

  return (
    <Modal
      btnIconProps={editBtnIcon}
      header={"Edit Job Term"}
      body={
        <Box textAlign="center" mb="0.5rem" margin="auto">
          <Flex dir="row" align="center" justifyContent="center" mb="1rem">
            <Text textStyle={TextStyles.text}>Status</Text>
            <Circle size="0.4rem" bg={xCompleted.data ? "grey" : "active"} mx="0.4rem"></Circle>
            <Text textStyle={TextStyles.text}>{xCompleted.data ? "Inactive" : "Active"}</Text>
          </Flex>
          <Form inputs={editingTermTitleInput} />
          <Flex dir="row" align="center" justifyContent="center" mb="1rem">
            <Text textStyle={TextStyles.text}>Complete Job Term</Text>
            <ButtonIcon
              icon={
                xCompleted.data
                  ? { name: "check-circle-fill", size: "1.7rem", fill: colors.active }
                  : { name: "check-circle-line", size: "1.7rem", fill: colors.active }
              }
              onClick={() => xCompleted.setData(!xCompleted.data)}
            />
          </Flex>
          <Text textStyle={TextStyles.thin} maxWidth="17rem" justifyContent="center" margin="auto">
            If you complete this term, all of your active applications will be marked as "Withdraw".
          </Text>
        </Box>
      }
      goBack={deleteBtn}
      nextBtn={saveBtn}
      onSubmit={() => handlers.update()}
      onDelete={() => handlers.delete()}
    />
  );
};

export default JobTermEditModal;
