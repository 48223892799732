import { ReactiveVar } from "@apollo/client";
import { JobListingMarksVarType } from "../cache/reactiveVars";

const useJobListingMarksHook = (jobMarks: ReactiveVar<JobListingMarksVarType>) => {
  const setJobListingMarks = (marks: Set<string>) => {
    return jobMarks(marks);
  };

  const getJobListingMarks = (): JobListingMarksVarType => {
    return jobMarks();
  };

  const isJobListingMarked = (jobId: string): boolean => {
    return jobMarks().has(jobId);
  };

  const addJobListingMark = (id: string) => {
    jobMarks().add(id);
  };

  const deleteJobListingMark = (id: string) => {
    jobMarks().delete(id);
  };

  return {
    setJobListingMarks,
    getJobListingMarks,
    isJobListingMarked,
    addJobListingMark,
    deleteJobListingMark,
  };
};

export default useJobListingMarksHook;
