import { Box, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { DocTitleSuffix } from "../../constants/constants";
import { LayerStyles } from "../../theme/layerStyles";
import { TextStyles } from "../../theme/textStyles";
import AppRoutes from "../AppRoutes.constant";

export interface PrepNotesProps {}

const PrepNotes: React.FC<PrepNotesProps> = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box layerStyle={LayerStyles.mainPanelChild}>
      <Helmet>
        <title>
          {AppRoutes.prep.title}
          {DocTitleSuffix}
        </title>
      </Helmet>
      <Text as="h1" textStyle={TextStyles.h1}>
        Prep Notes
      </Text>
    </Box>
  );
};

export default PrepNotes;
