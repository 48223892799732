import React, { useEffect, useState } from "react";
import { Box, Text, Flex, Spacer } from "@chakra-ui/react";
import StepBar from "../../components/stepBar";
import Form, { InputProps } from "../../components/form";
import Button from "../../components/button";
import { ButtonVariants } from "../../theme/components/button";
import { TextStyles } from "../../theme/textStyles";
import { ReactUseState } from "../App";

export interface PersonalInfoProps {
  firstName: ReactUseState<string>;
  lastName: ReactUseState<string>;
  step: ReactUseState<number>;
}
const PersonalInfo: React.FC<PersonalInfoProps> = ({ firstName, lastName, step }) => {
  const [isFormFilled, setIsFormFilled] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (firstName.data && lastName.data) {
      setIsFormFilled(true);
    } else {
      setIsFormFilled(false);
    }
  }, [firstName.data, lastName.data]);

  const personalInfoInputs: InputProps[] = [
    {
      label: "First Name",
      value: firstName,
      isRequired: true,
      placeholder: "Enter first name",
    },
    {
      label: "Last Name",
      value: lastName,
      isRequired: true,
      placeholder: "Enter last name",
    },
  ];

  return (
    <Box>
      <StepBar steps={3} currentStep={2} />
      <Box mb="1rem">
        <Text as="h2" textStyle={TextStyles.h2} mb="1rem">
          Personal Info
        </Text>
        <Form inputs={personalInfoInputs} />
      </Box>
      <Flex mb="2rem">
        <Button
          variant={ButtonVariants.text}
          textStyle={TextStyles.text}
          text="Back"
          onClick={() => step.setData(1)}
          leftIcon={{ name: "arrow-left-line", fill: "black" }}
          pl="0"
        />
        <Spacer />
        <Button
          variant={ButtonVariants.primaryFillSquare}
          text="Next"
          textStyle={TextStyles.primaryFillBtn_lg}
          margin="auto"
          onClick={() => step.setData(3)}
          isDisabled={!isFormFilled}
          padding="0.25rem 0.5rem"
          justify="end"
        />
      </Flex>
    </Box>
  );
};

export default PersonalInfo;
