import { DocumentNode, gql } from "@apollo/client";
import { OPERATION_STATUS_FRAGMENT } from "../../fragments/common.fragment";
import { JOB_APP_CORE_FRAGMENT } from "../../fragments/jobApp.fragment";
import {
  UpdateMutationHelper,
  UpdateMutationHelperProps,
  UpdateMutationParamQueryPairProps,
  UpdateMutationPlaceholderProps,
} from "../serviceHelper";

const CREATE_JOB_APP_BY_JOB_ID = gql`
  ${JOB_APP_CORE_FRAGMENT}
  ${OPERATION_STATUS_FRAGMENT}
  mutation CreateJobAppByJobId(
    $jobId: ObjectId!
    $newJobApp: CreateJobAppByJobIdInput!
    $submittedOn: Timestamp
  ) {
    createJobAppByJobId(jobId: $jobId, newJobApp: $newJobApp, submittedOn: $submittedOn) {
      ... on JobApp {
        ...JobAppCoreFragment
      }
      ... on OperationStatus {
        ...OperationStatusFragment
      }
    }
  }
`;

const UPDATE_JOB_APP_BY_STATUS = gql`
  ${OPERATION_STATUS_FRAGMENT}
  mutation UpdateJobAppByStatus($updateJobApp: UpdateJobAppByStatusInput!) {
    updateJobAppByStatus(updateJobApp: $updateJobApp) {
      ...OperationStatusFragment
    }
  }
`;

const UPDATE_JOB_APP_BY_JOB_LINK = `
  updateJobAppByJobLink(updateJobApp: $updateJobAppByJobLink) {
    ...OperationStatusFragment
  }
`;

const UPDATE_JOB_APP_BY_APPLY_VIA = `
  updateJobAppByApplyVia(updateJobApp: $updateJobAppByApplyVia) {
    ...OperationStatusFragment
  }
`;

const UPDATE_JOB_APP_BY_ASSOCIATED_EMAIL = `
  updateJobAppByAssociatedEmail(updateJobApp: $updateJobAppByAssociatedEmail) {
    ...OperationStatusFragment
  }
`;

const UPDATE_JOB_APP_BY_RECRUITER = `
  updateJobAppByRecruiter(updateJobApp: $updateJobAppByRecruiter) {
    ...OperationStatusFragment
  }
`;

const UPDATE_JOB_APP_BY_APPLIED_ON = `
  updateJobAppByAppliedOn(updateJobApp: $updateJobAppByAppliedOn) {
    ...OperationStatusFragment
  }
`;

export interface UpdaterJobAppMutationProps {
  associatedEmail?: boolean;
  appliedOn?: boolean;
  applyVia?: boolean;
  jobLink?: boolean;
  recruiter?: boolean;
}

const updateJobAppMutationParamQueryPair: UpdateMutationParamQueryPairProps = {
  associatedEmail: {
    param: `$updateJobAppByAssociatedEmail: UpdateJobAppByAssociatedEmailInput!`,
    query: UPDATE_JOB_APP_BY_ASSOCIATED_EMAIL,
  },
  appliedOn: {
    param: `$updateJobAppByAppliedOn: UpdateJobAppByAppliedOnInput!`,
    query: UPDATE_JOB_APP_BY_APPLIED_ON,
  },
  applyVia: {
    param: `$updateJobAppByApplyVia: UpdateJobAppByApplyViaInput!`,
    query: UPDATE_JOB_APP_BY_APPLY_VIA,
  },
  jobLink: {
    param: `$updateJobAppByJobLink: UpdateJobAppByJobLinkInput!`,
    query: UPDATE_JOB_APP_BY_JOB_LINK,
  },
  recruiter: {
    param: `$updateJobAppByRecruiter: UpdateJobAppByRecruiterInput!`,
    query: UPDATE_JOB_APP_BY_RECRUITER,
  },
};

const UPDATE_JOB_APP = (props: UpdaterJobAppMutationProps): DocumentNode => {
  const mutationName = "UpdateJobAppMutation";
  const placeholder: UpdateMutationPlaceholderProps = {
    params: `$placeholder: UpdateJobAppByAssociatedEmailInput!`,
    queries: `placeholder(placeholder: $placeholder) {_id}`,
  };
  return UpdateMutationHelper(
    mutationName,
    placeholder,
    props as UpdateMutationHelperProps,
    updateJobAppMutationParamQueryPair
  );
};

const JobAppMutations = {
  CREATE_JOB_APP_BY_JOB_ID,
  UPDATE_JOB_APP_BY_STATUS,
  UPDATE_JOB_APP,
};

export default JobAppMutations;
