import React, { useEffect, useState } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { Box, Text, useToast, Link } from "@chakra-ui/react";
import AppRoutes from "../AppRoutes.constant";
import { DocTitleSuffix, ToastStatus } from "../../constants/constants";
import Form, { InputProps } from "../../components/form";
import Button from "../../components/button";
import { Helmet } from "react-helmet";
import { LayerStyles } from "../../theme/layerStyles";
import { ButtonVariants } from "../../theme/components/button";
import { TextStyles } from "../../theme/textStyles";
import { useHistory } from "react-router-dom";
import { validateEmailAddress } from "../../utils";
import * as UserTypes from "../../graphql/types/user.type";
import UserServices from "../../graphql/services/user.services";
import { ApolloCache, useMutation } from "@apollo/client";
import { useLogIn } from "../../hooks/useLogIn";
import { loggedInUserVar } from "../../cache/reactiveVars";
import { ReactUseState } from "../App";

interface LogInProps {
  xLogin: ReactUseState<boolean>;
}

const LogIn: React.FC<LogInProps> = ({ xLogin }) => {
  const history = useHistory();

  const toast = useToast();
  const { login } = useLogIn(loggedInUserVar);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isFormFilled, setIsFormFilled] = useState(false);
  const infos: InputProps[] = [
    {
      label: "Email",
      value: { data: email, setData: setEmail },
      placeholder: "Enter email",
      type: "email",
      isRequired: true,
    },
    {
      label: "Password",
      value: { data: password, setData: setPassword },
      placeholder: "Enter password",
      type: "password",
      isRequired: true,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (email && password) {
      setIsFormFilled(true);
    } else {
      setIsFormFilled(false);
    }
  }, [email, password]);

  const [getUserByLogin] = useMutation<UserTypes.GetUserByLoginData, UserTypes.GetUserByLoginArgs>(
    UserServices.GET_USER_BY_LOGIN
  );

  const cache_getUserByLogin = async (
    cache: ApolloCache<any>,
    data: UserTypes.GetUserByLoginData | null | undefined
  ) => {
    const user = data?.getUserByLogin;
    if (user?._id) {
      toast({
        description: "Log in successfully",
        position: "bottom",
        status: ToastStatus.SUCCESS,
        isClosable: true,
      });
      await login(user);
    } else {
      toast({
        title: "Failed to log in",
        description: `Incorrect email or password`,
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
    }
  };

  const onSubmit = async () => {
    const getUserByLoginArgs: UserTypes.GetUserByLoginArgs = {
      login: { email: email, password: password },
    };
    await getUserByLogin({
      variables: getUserByLoginArgs,
      update(cache, { data }) {
        cache_getUserByLogin(cache, data);
      },
    })
      .then(({ data }) => {
        if (data?.getUserByLogin?._id) {
          xLogin.setData(true);
          history.push(AppRoutes.profile.route); // TESTING
          // history.push(AppRoutes.myApps.route);
        }
      })
      .catch((error) => {
        toast({
          title: "Failed to log in",
          description: `Incorrect email or password`,
          position: "bottom",
          status: ToastStatus.ERROR,
          isClosable: true,
        });
      });
  };

  const onLoginHandler = () => {
    if (!validateEmailAddress(email)) {
      toast({
        description: "Please enter valid email address",
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
      return;
    }
    onSubmit();
  };

  return (
    <Box layerStyle={LayerStyles.mainPanelChild} mt="3.5rem" maxW="24rem">
      <Helmet>
        <title>
          {AppRoutes.login.title}
          {DocTitleSuffix}
        </title>
      </Helmet>
      <Text as="h1" textStyle={TextStyles.h1} pb="1rem">
        Log In
      </Text>
      <Box mb="1.5rem">
        <Form inputs={infos} />
        <Link as={ReactRouterLink} to={AppRoutes.resetPassword.route}>
          <Text textAlign="end" textStyle={TextStyles.text} color="primary.solid">
            Forgot Password?
          </Text>
        </Link>
        <Button
          variant={ButtonVariants.primaryFillSquare}
          text="Log In"
          textStyle={TextStyles.primaryFillBtn_lg}
          margin="auto"
          onClick={() => onLoginHandler()}
          isDisabled={!isFormFilled}
          padding="0.35rem 0.5rem"
          minWidth="7rem"
          my="2rem"
        />
        <Text textStyle={TextStyles.text}>
          Not yet have an account?&nbsp;
          <Link as={ReactRouterLink} to={AppRoutes.register.route} color="primary.solid">
            Create New Account
          </Link>
        </Text>
      </Box>
    </Box>
  );
};

export default LogIn;
