import { ApolloCache, StoreObject, useMutation } from "@apollo/client";
import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { ToastStatus } from "../../../constants/constants";
import SampleServices from "../../../graphql/services/sample.service";
import * as SampleTypes from "../../../graphql/types/sample.type";
import { OperationStatus } from "../../../graphql/types/common.type";
import { SAMPLE_CORE_FRAGMENT } from "../../../graphql/fragments/sample.fragment";
import { ISample } from "../../../schemas/sample.schema";
import CreateSampleCard from "../../../components/sample/createSampleCard";

const CreateSample = () => {
  const toast = useToast();

  const DEFAULT_NAME = "";
  const DEFAULT_AGE = 18;
  const DEFAULT_IS_STUDENT = false;
  const [name, setName] = useState<string>(DEFAULT_NAME);
  const [age, setAge] = useState<number>(DEFAULT_AGE);
  const [isStudent, setIsStudent] = useState<boolean>(DEFAULT_IS_STUDENT);

  const clearInputs = () => {
    setName(DEFAULT_NAME);
    setAge(DEFAULT_AGE);
    setIsStudent(DEFAULT_IS_STUDENT);
  };

  const [createSample, { loading, reset }] = useMutation<
    SampleTypes.CreateSampleData,
    SampleTypes.CreateSampleArgs
  >(SampleServices.CREATE_SAMPLE);

  const cache_createSample = (
    cache: ApolloCache<any>,
    data: SampleTypes.CreateSampleData | null | undefined
  ) => {
    const result = data?.createSample;
    if ((result as ISample)._id) {
      const newSample: ISample = result as ISample;
      toast({
        title: `Sample created successfully`,
        position: "bottom",
        status: ToastStatus.SUCCESS,
        isClosable: true,
      });
      clearInputs();
      const data: any = cache.readQuery({ query: SampleServices.GET_SAMPLES });
      cache.modify({
        fields: {
          getSamples(existing: StoreObject[] = data?.getSamples, { readField }) {
            const newSampleRef = cache.writeFragment({
              data: newSample,
              fragment: SAMPLE_CORE_FRAGMENT,
            });
            if (existing.some((ref) => readField("_id", ref) === newSample._id)) {
              return existing;
            }
            return [...existing, newSampleRef];
          },
        },
      });
    } else {
      const status: OperationStatus = result as OperationStatus;
      toast({
        title: status?.errorTitle,
        description: status?.errorMessage,
        position: "bottom",
        status: ToastStatus.ERROR,
        variant: "subtle",
        isClosable: true,
      });
    }
  };

  const onSubmit = async () => {
    const newSample: SampleTypes.CreateSampleInput = {
      name: name,
      age: age,
      isStudent: isStudent,
    };
    const newSampleArgs: SampleTypes.CreateSampleArgs = {
      newSample: newSample,
    };
    await createSample({
      variables: newSampleArgs,
      // Option 1 - Refetching queries
      // refetchQueries: [
      //   SampleServices.GET_SAMPLES,
      //   "GetSamples", // Query name
      // ],
      // Option 2 - Updating the cache directly
      update(cache, { data }) {
        cache_createSample(cache, data);
      },
    }).catch((error) => {
      toast({
        title: "Failed to load data",
        description: `Cannot create sample - ${error.message}`,
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
      reset();
    });
  };

  const onCreateHandler = () => {
    if (name && age) {
      onSubmit();
    } else {
      toast({
        description: "Please enter name and age",
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
    }
  };

  return (
    <CreateSampleCard
      name={{ data: name, setData: setName }}
      age={{ data: age, setData: setAge }}
      isStudent={{ data: isStudent, setData: setIsStudent }}
      loading={loading}
      onCreateHandler={onCreateHandler}
    />
  );
};

export default CreateSample;
