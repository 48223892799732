import { ApolloCache, ApolloClient } from "@apollo/client";
import { default_JobAppTerm } from "../constants/default.data";
import { JOB_APP_TERM_DISPLAY_FRAGMENT } from "../graphql/fragments/jobAppTerm.fragment";
import { USER_ACCOUNT_FRAGMENT } from "../graphql/fragments/user.fragment";
import { IJobAppTerm, IUser } from "../schemas/user.schema";
import { LoggedInUserVarType } from "./reactiveVars";

export const safe_delete_cached_data = (
  cache: ApolloCache<any>,
  id: string,
  __typename: string
): boolean => {
  const normalizedId = cache.identify({ id: id, __typename: __typename });
  const evicted = cache.evict({ id: normalizedId });
  cache.gc();
  return evicted;
};

export const get_cached_logged_in_user = (
  client: ApolloClient<any>,
  login: (user: IUser) => Promise<any>,
  getUser: () => LoggedInUserVarType
): LoggedInUserVarType => {
  const cache_uid = localStorage.getItem("uid");
  let loggedInUser: LoggedInUserVarType | null = undefined;
  if (cache_uid) {
    loggedInUser = client.readFragment<IUser>({
      id: `User:${cache_uid}`,
      fragment: USER_ACCOUNT_FRAGMENT,
    });
  }
  if (loggedInUser) {
    login(loggedInUser);
  } else {
    loggedInUser = getUser();
  }
  return loggedInUser;
};

export const get_selected_job_app_term_from_cache = (
  client: ApolloClient<any>,
  uid: string,
  terms?: IJobAppTerm[]
): IJobAppTerm => {
  let lastOpenTermId = localStorage.getItem("lastOpenTermId");
  if (!lastOpenTermId) {
    const currentUser: IUser | null = client.readFragment({
      id: `User:${uid}`,
      fragment: USER_ACCOUNT_FRAGMENT,
    });
    lastOpenTermId = currentUser?.lastOpenTerm || "";
    localStorage.setItem("lastOpenTermId", lastOpenTermId);
  }
  const foundSelectedTerm = client.readFragment({
    id: `JobAppTerm:${lastOpenTermId}`,
    fragment: JOB_APP_TERM_DISPLAY_FRAGMENT,
  });
  return foundSelectedTerm || (terms ? terms[0] : default_JobAppTerm);
};
