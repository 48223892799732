const Checkbox = {
  baseStyle: {
    control: {
      _focus: {
        boxShadow: "none",
      },
    },
  },
};

export default Checkbox;
