import { useMutation, ApolloCache, useLazyQuery, StoreObject } from "@apollo/client";
import { useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ButtonProps } from "../../../components/button";
import LogAppModal from "../../../components/LogAppModal";
import { ApplyVia, ToastStatus } from "../../../constants/constants";
import JobAppServices from "../../../graphql/services/jobApp.services";
import { ICompany, IJobListing } from "../../../schemas/job.schema";
import { mockDataLoader } from "../../App";
import * as JobAppTypes from "../../../graphql/types/jobApp.type";
import * as CompanyTypes from "../../../graphql/types/company.type";
import CompanyServices from "../../../graphql/services/company.services";
import { AutoCompleteOption } from "../../../components/autocomplete";
import useCurrentJobAppTermHook from "../../../hooks/useCurrentJobAppTerm";
import { currentJobAppTermVar } from "../../../cache/reactiveVars";
import { IJobApp } from "../../../schemas/app.schema";
import { OperationStatus } from "../../../graphql/types/common.type";
import JobAppTermServices from "../../../graphql/services/jobAppTerm.services";
import { JOB_APP_CORE_FRAGMENT } from "../../../graphql/fragments/jobApp.fragment";

interface LogNewJobModalProps {
  triggerBtnProps: ButtonProps;
  _company?: ICompany;
  _job?: IJobListing;
}

const LogNewJobModal: React.FC<LogNewJobModalProps> = ({ triggerBtnProps, _company, _job }) => {
  const toast = useToast();
  const { getCurrentJobAppTerm } = useCurrentJobAppTermHook(currentJobAppTermVar);

  const [selectedCompany, setSelectedCompany] = useState<ICompany | undefined>(_company);
  const [selectedJob, setSelectedJob] = useState<IJobListing | undefined>(_job);
  const [submittedOn, setSubmittedOn] = useState<string>(new Date().toISOString().split("T")[0]);
  const [applyVia, setApplyVia] = useState<ApplyVia>(ApplyVia.COMPANY);
  const [jobLink, setJobLink] = useState<string>("");

  const [companyList, setCompanyList] = useState<ICompany[]>([]);
  const [companyOptions, setCompanyOptions] = useState<AutoCompleteOption<ICompany>[]>([]);
  const [companyJobOptions, setCompanyJobOptions] = useState<AutoCompleteOption<IJobListing>[]>([]);

  let [getCompaniesQuery, { data }] = useLazyQuery<
    CompanyTypes.GetCompaniesData,
    CompanyTypes.GetCompaniesArgs
  >(CompanyServices.GET_COMPANIES, {
    variables: { verified: true, offset: 0, limit: 100 },
  });

  // let [getJoblistingsByCompanyQuery, {data}] = useLazyQuery<
  //   JobListingTypes.GetJobListingWithCompanyByIdData,
  //   JobListingTypes.GetJobListingWithCompanyByIdArgs
  // >(JobListingServices.GET_JOB_LISTINGS_WITH_COMPANY_BY_ID);

  useEffect(() => {
    getCompaniesQuery();
  }, []);

  useEffect(() => {
    if (selectedCompany) {
      // getJoblistingsByCompanyQuery({ variables: { id: company?._id || "" } }).then(({data}) => {
      //   if (data?.getJobListingWithCompanyById) {
      //     setJobListByCompany(data.getJobListingWithCompanyById)
      //   }
      // });
      const allJobs = mockDataLoader.getAllJobs();
      const defaultCompanyJobOptions = allJobs?.map((job) => {
        return { label: job.title, value: job } as AutoCompleteOption<IJobListing>;
      });
      setCompanyJobOptions(defaultCompanyJobOptions);
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (data?.getCompanies) {
      setCompanyList(data.getCompanies);
    }
  }, [data?.getCompanies]);

  useEffect(() => {
    const newCompanyOptions = companyList.map((c) => {
      return {
        label: c.name,
        value: c,
        rightImage: { alt: `${c.name}-logo`, src: c.logo },
      } as AutoCompleteOption<ICompany>;
    });
    setCompanyOptions(newCompanyOptions);
  }, [companyList]);

  const [createJobAppByJobId] = useMutation<
    JobAppTypes.CreateJobAppByJobIdData,
    JobAppTypes.CreateJobAppByJobIdArgs
  >(JobAppServices.CREATE_JOB_APP_BY_JOB_ID);

  const cache_createJobAppById = (
    cache: ApolloCache<any>,
    data: JobAppTypes.CreateJobAppByJobIdData | null | undefined
  ) => {
    const newJobAppResult = data?.createJobAppByJobId;
    if ((newJobAppResult as IJobApp)._id) {
      const newJobApp = newJobAppResult as IJobApp;
      toast({
        title: `Job application created successfully`,
        position: "bottom",
        status: ToastStatus.SUCCESS,
        isClosable: true,
      });
      const data: any = cache.readQuery({ query: JobAppTermServices.GET_JOB_APP_BY_TERM_ID });
      cache.modify({
        fields: {
          getJobAppsByTermId(existing: StoreObject[] = data?.getJobAppsByTermId, { readField }) {
            const newJobAppRef = cache.writeFragment({
              data: newJobApp,
              fragment: JOB_APP_CORE_FRAGMENT,
            });
            if (existing.some((ref) => readField("_id", ref) === newJobApp._id)) {
              return existing;
            }
            return [...existing, newJobAppRef];
          },
        },
      });
    } else {
      const newJobApp: OperationStatus = newJobAppResult as OperationStatus;
      toast({
        title: newJobApp?.errorTitle,
        description: newJobApp?.errorMessage,
        position: "bottom",
        status: ToastStatus.ERROR,
        variant: "subtle",
        isClosable: true,
      });
    }
  };

  const convertJobToApp = async (jobId: string) => {
    const newJobApp: JobAppTypes.CreateJobAppByJobIdInput = {
      termid: getCurrentJobAppTerm()?._id || "",
      jobLink: jobLink,
      applyVia: applyVia,
    };
    const newJobAppArgs: JobAppTypes.CreateJobAppByJobIdArgs = {
      jobId: jobId,
      newJobApp: newJobApp,
      submittedOn: new Date(submittedOn).valueOf(),
    };
    await createJobAppByJobId({
      variables: newJobAppArgs,
      update(cache, { data }) {
        cache_createJobAppById(cache, data);
      },
    }).catch((error) => {
      toast({
        title: "Failed to log new job application",
        description: `${error.message}`,
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
    });
  };

  const onSubmit = async (): Promise<boolean> => {
    if (selectedCompany && selectedJob) {
      await convertJobToApp(selectedJob._id);
    }
    return true;
  };

  return (
    <LogAppModal
      triggerBtnProps={triggerBtnProps}
      selectedCompany={{ data: selectedCompany, setData: setSelectedCompany }}
      selectedJob={{ data: selectedJob, setData: setSelectedJob }}
      submittedOn={{ data: submittedOn, setData: setSubmittedOn }}
      applyVia={{ data: applyVia, setData: setApplyVia }}
      jobLink={{ data: jobLink, setData: setJobLink }}
      companyOptions={companyOptions}
      companyJobOptions={companyJobOptions}
      onSubmit={onSubmit}
    />
  );
};

export default LogNewJobModal;
