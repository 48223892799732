import colors from "./colors";

export const LayerStyles = {
  navbar: "navbar",
  mainPanel: "mainPanel",
  mainPanelChild: "mainPanelChild",
  infoBar: "infoBar",
  cardLong: "cardLong",
  cardSquare: "cardSquare",
  cardSmall: "cardSmall",
  cardGeneral: "cardGeneral",
  netPromoter: "netPromoter",
  netPromoterBoard: "netPromoterBoard",
  greyLineSquare: "greyLineSquare",
  greyComponent: "greyComponent",
  primaryLineSquare: "primaryLineSquare",
  notificationBanner: "notificationBanner",
};

const mainLayoutStyles = {
  zIndex: 0,
  top: 0,
  bottom: 0,
  pt: "1.5rem",
  pb: "2rem",
};

const layerStyles = {
  base: {},
  [LayerStyles.mainPanel]: {
    width: "100%",
    bg: "grey.100",
    px: "3rem",
    ...mainLayoutStyles,
    pt: "0",
    pb: "0",
  },
  [LayerStyles.mainPanelChild]: {
    textAlign: "center",
    align: "center",
    justifyContent: "center",
    justifyItems: "center",
    width: "100%",
    pt: "1.5rem",
    pb: "2rem",
    margin: "0 auto",
  },
  [LayerStyles.navbar]: {
    bg: "white",
    px: "1.5rem",
    height: "100%",
    width: "max-content",
    position: "fixed",
    overflow: "auto",
    userSelect: "none",
    ...mainLayoutStyles,
  },
  [LayerStyles.infoBar]: {
    padding: "1.5rem",
    bg: "white",
    height: "100%",
    width: "max-content",
    position: "fixed",
    overflow: "auto",
    ...mainLayoutStyles,
  },
  [LayerStyles.cardSquare]: {
    display: "flex",
    flexDirection: "column",
    align: "center",
    justifyItems: "center",
    maxW: "14rem",
    width: "100%",
    height: "100%",
    background: "white",
    borderRadius: "1rem",
    borderWidth: "3px",
    borderColor: "transparent",
    boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.2)",
    padding: "1rem",
    _hover: {
      borderColor: "primary.solid",
      boxShadow: "none",
    },
    userSelect: "none",
  },
  [LayerStyles.cardSmall]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyItems: "center",
    maxW: "6rem",
    width: "100%",
    height: "100%",
    background: "white",
    borderRadius: "1rem",
    borderWidth: "3px",
    borderColor: "transparent",
    boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.2)",
    padding: "0.5rem 0.75rem",
    _hover: {
      borderColor: "primary.solid",
      boxShadow: "none",
    },
    userSelect: "none",
  },
  [LayerStyles.cardLong]: {
    display: "flex",
    flexDirection: "row",
    // align: "center",
    // justifyItems: "center",
    // width: "100%",
    // height: "100%",
    textAlign: "left",
    background: "white",
    borderRadius: "1rem",
    borderWidth: "3px",
    borderColor: "transparent",
    boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.2)",
    padding: "1rem 1.2rem",
    userSelect: "none",
  },
  [LayerStyles.cardGeneral]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    justifyItems: "center",
    width: "100%",
    background: "white",
    borderRadius: "0.75rem",
    border: "0px",
    boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.2)",
    py: "1rem",
    px: "1.5rem",
  },
  [LayerStyles.greyLineSquare]: {
    width: "100%",
    minWidth: "4rem",
    maxWidth: "8rem",
    boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.2)",
    px: "0.25rem",
    border: "1px solid",
    borderColor: "grey.500",
    background: "grey.100",
  },
  [LayerStyles.primaryLineSquare]: {
    width: "100%",
    minWidth: "4rem",
    maxWidth: "8rem",
    border: "2px solid",
    borderColor: "primary.solid",
    background: "white",
    boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.2)",
    px: "0.25rem",
  },
  [LayerStyles.greyComponent]: {
    background: "grey.100",
    borderRadius: "1rem",
    textAlign: "center",
    padding: "0.5rem 0.5rem",
  },

  input: {
    boxShadow: "none",
    borderWidth: "1px",
    borderRadius: "0.8rem",
    borderColor: "text",
    bg: "white",
    padding: "0.5rem 1rem",
    paddingBottom: "0.7rem",
    height: "100%",
    width: "5rem",
    _placeholder: { color: "grey.500" },
    _focusVisible: { borderColor: "primary.solid" },
  },
  inputUnderline: {
    borderBottom: "1px solid",
    borderColor: "transparent",
    _focus: {
      borderColor: "red",
    },
    _hover: {
      borderColor: "primary.solid",
    },
    _focusVisible: { borderColor: "primary.solid", outline: "none" },
  },
  inputBorder: {
    // marginBottom: "0.5rem",
    // width: "70%",
    maxWidth: "35rem",
    boxShadow: "none",
    borderWidth: "1.5px",
    borderRadius: "0.8rem",
    borderColor: "text",
    _placeholder: { color: "grey.500" },
    _focus: {
      borderColor: "primary.solid",
    },
    _hover: {
      borderColor: "primary.solid",
    },
    _focusVisible: { outline: "none" },
    padding: "0.5rem 0.7rem",
    height: "auto",
  },
  [LayerStyles.netPromoter]: {
    bottom: "0",
    right: "0",
    position: "fixed",
    margin: "5rem",
    zIndex: 5,
  },
  [LayerStyles.netPromoterBoard]: {
    margin: "2rem",
    width: "max-content",
    height: "max-content",
    padding: "2.5rem 2rem",
    align: "center",
    justifyContent: "center",
    borderRadius: "1rem",
    boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.2)",
    bg: "white",
  },
  [LayerStyles.notificationBanner]: {
    width: "100%",
    height: "100%",
    bg: "primary.gradient",
    opacity: "0.97",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: "1rem",
    margin: "auto",
  },
  note: {
    align: "center",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    borderRadius: "1rem",
    background: "white",
    boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.2)",
    padding: "1.2rem 2rem",
    marginBottom: "1rem",
  },
  neutral: {
    backgroundColor: colors.neutral[100],
    color: "neutral.300",
  },
};

export default layerStyles;
