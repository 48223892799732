const Link = {
  baseStyle: {
    _focus: { border: "none", boxShadow: "none" },
    _hover: { textDecoration: "none" },
  },
  variants: {
    navlink: {
      marginBottom: "1.5rem",
    },
  },
};

export default Link;
