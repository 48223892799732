import { Box, Flex, Link, Spacer, Text, OrderedList, ListItem, Tooltip } from "@chakra-ui/react";
import React from "react";
import SVGIcon from "../../assets/icons";
import Tabs, { TabsProps } from "../../components/tabs";
import { LayerStyles } from "../../theme/layerStyles";
import { TextStyles } from "../../theme/textStyles";
import { capitalizeStr, getMonthDateYear, getPopularity } from "../../utils";
import { IJobListing } from "../../schemas/job.schema";

interface JobDetailTabsProps {
  job: IJobListing;
}

const JobDetailTabs: React.FC<JobDetailTabsProps> = ({ job }) => {
  const JobSummary = (props: any) => {
    return (
      <Box layerStyle={LayerStyles.cardGeneral} {...props}>
        <Tooltip
          label="Job Type · Job Level · Job Season"
          aria-label="Job type, job level, job season"
        >
          <Flex direction="row" mb="1rem">
            <SVGIcon name="briefcase-line" fill="text" size="1.25rem" />
            <Text textStyle={TextStyles.h6} pl="0.75rem">
              {job?.type && capitalizeStr(job.type)}
              {job?.type && job?.level && <>&nbsp;&nbsp;&middot;&nbsp;&nbsp;</>}
              {job?.level && capitalizeStr(job.level.replace("_", " "))}
              {job?.level && job?.season && <>&nbsp;&nbsp;&middot;&nbsp;&nbsp;</>}
              {job?.season && capitalizeStr(job.season)}
            </Text>
          </Flex>
        </Tooltip>
        <Flex direction="row">
          <Flex direction="column">
            <Tooltip label="Paid or unpaid job" aria-label="Paid or unpaid job">
              <Flex direction="row" mb="1rem">
                <SVGIcon name="money-dollar-circle-line" fill="text" size="1.25rem" />
                <Text textStyle={TextStyles.h6} pl="0.75rem">
                  {job?.xPaid ? "Paid" : "Unpaid"}
                </Text>
              </Flex>
            </Tooltip>
            <Tooltip label="Work mode" aria-label="Work mode">
              <Flex direction="row">
                <SVGIcon name="user-location-line" fill="text" size="1.25rem" />
                <Text
                  textStyle={TextStyles.h6}
                  color={job?.workMode ? "" : "grey.500"}
                  pl="0.75rem"
                >
                  {job?.workMode ? capitalizeStr(job?.workMode) : "unknown"}
                </Text>
              </Flex>
            </Tooltip>
          </Flex>
          <Spacer />
          <Flex direction="column">
            <Tooltip label="H1B Sponsorship" aria-label="H1B Sponsorship">
              <Flex direction="row" mb="1rem">
                <SVGIcon name="passport-line" fill="text" size="1.25rem" />
                <Text noOfLines={1} textStyle={TextStyles.h6} pl="0.75rem">
                  {job?.xSponsor ? "H1B Sponsored" : "H1B Not Sponsored"}
                </Text>
              </Flex>
            </Tooltip>
            <Tooltip label="First seen on" aria-label="First seen date">
              <Flex direction="row">
                <SVGIcon name="calendar" fill="text" size="1.25rem" />
                <Text textStyle={TextStyles.h6} pl="0.75rem">
                  {getMonthDateYear(job?._createdAt)}
                </Text>
              </Flex>
            </Tooltip>
          </Flex>
        </Flex>
      </Box>
    );
  };

  const JobLinks = (props: any) => {
    return (
      <Box layerStyle={LayerStyles.cardGeneral} {...props}>
        <Text textStyle={TextStyles.h6} mb="0.5rem">
          Popular Job Links
        </Text>
        {job.links.length > 0 ? (
          <OrderedList textStyle={TextStyles.thin}>
            {job.links.slice(0, Math.min(3, job.links.length)).map((link, key) => (
              <ListItem key={key} pb="0.25rem">
                <Flex direction="row" width="100%">
                  <Link isExternal maxW="18.5rem" isTruncated href={link.link}>
                    {link.link.replace(/^https?:\/\//, "")}
                  </Link>
                  <Spacer />
                  <Text fontWeight={500}>~ {getPopularity(link.freq)}</Text>
                </Flex>
              </ListItem>
            ))}
          </OrderedList>
        ) : (
          <Text textStyle={TextStyles.greyText}>None available</Text>
        )}
      </Box>
    );
  };

  const JobDetailTab = () => {
    return (
      <Flex direction="column" textAlign="left" width="100%">
        <Flex direction="row" width="100%" mb="1.5rem">
          <JobSummary maxW="20rem" />
          <Spacer />
          <JobLinks maxW="25rem" />
        </Flex>
        <Flex
          direction="row"
          layerStyle={LayerStyles.cardGeneral}
          alignItems="flex-start"
          mb="1.5rem"
        >
          <SVGIcon name="map-pin" size="1.25rem" />
          <Text
            textStyle={TextStyles.h6}
            color={job?.locations?.length > 0 ? "" : "grey.500"}
            pl="0.75rem"
            noOfLines={2}
          >
            {job?.locations?.length > 0
              ? job?.locations?.join(` \xa0| \xa0`).toString()
              : "locations unknown"}
          </Text>
        </Flex>
        <Box layerStyle={LayerStyles.cardGeneral} px="2rem" py="1.5rem">
          <Text textStyle={TextStyles.h2} mb="0.5rem">
            Job Description
          </Text>
          <Text
            whiteSpace="pre-wrap"
            textStyle={TextStyles.text}
            color={job?.description ? "" : "grey.500"}
          >
            {job?.description ? job.description : "Unknown"}
          </Text>
        </Box>
      </Flex>
    );
  };

  interface JobStatisticsProps {
    title: string;
    description?: string;
    stats: { title: string; data: number }[];
  }
  const statistics: JobStatisticsProps[] = [
    {
      title: "💡 Insights on All Candidates",
      stats: [
        { title: "#Applied", data: 3005 },
        { title: "#Assessment", data: 1540 },
        { title: "#Interview", data: 850 },
        { title: "#Offer", data: 108 },
      ],
    },
    {
      title: "✨ Exclusive Insight on Northeastern University",
      description: "This data is based upon your alumni's",
      stats: [
        { title: "#Applied", data: 600 },
        { title: "#Assessment", data: 378 },
        { title: "#Interview", data: 101 },
        { title: "#Offer", data: 12 },
      ],
    },
  ];

  const JobStatisticsTab = () => {
    return (
      <Box>
        {statistics?.map((stat, key) => (
          <Box
            key={key}
            layerStyle={LayerStyles.cardGeneral}
            alignItems="center"
            px="4rem"
            mb="1.5rem"
            maxW="36rem"
          >
            <Box mb="0.75rem">
              <Text textStyle={TextStyles.h3}>{stat.title}</Text>
              {stat?.description && (
                <Text mt="0.25rem" textStyle={TextStyles.thin} color="grey.500">
                  {stat.description}
                </Text>
              )}
            </Box>
            <Flex direction="row" alignItems="center" justifyContent="center" width="100%">
              {stat.stats?.map((statItem, key) => (
                <Box key={key} width="100%">
                  <Flex direction="column">
                    <Text textStyle={TextStyles.greyText} mb="0.25rem">
                      {statItem.title}
                    </Text>
                    <Text textStyle={TextStyles.h3} color="grey.700">
                      {getPopularity(statItem.data)}
                    </Text>
                  </Flex>
                  {key !== stat.stats.length - 1 && <Spacer />}
                </Box>
              ))}
            </Flex>
          </Box>
        ))}
      </Box>
    );
  };
  const tabs: TabsProps = {
    tabs: [{ text: "Job Detail" }],
    tabPanels: [<JobDetailTab />],
  };
  return <Tabs {...tabs} />;
};

export default JobDetailTabs;
