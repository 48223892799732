import { MenuItem, Button, Flex, Box, Text } from "@chakra-ui/react";
import SVGIcon from "../../assets/icons";
import { ReactUseState } from "../../containers/App";
import { capitalizeStr } from "../../utils";
import { OptionProps } from ".";

export interface MenuItemProps {
  variant: string;
  textStyle: string;
  textStyleSelected: string;
  props?: {
    [key: string]: any;
  };
}

interface DropdownMenuItemProps {
  menuItemProps: MenuItemProps;
  option: OptionProps;
  selectedMenuItem: ReactUseState<OptionProps>;
  [key: string]: any;
}

const DropdownMenuItem: React.FC<DropdownMenuItemProps> = ({
  menuItemProps,
  option,
  selectedMenuItem,
  ...props
}) => {
  return (
    <MenuItem
      as={Button}
      onClick={() => selectedMenuItem?.setData(option)}
      variant={menuItemProps.variant}
      {...props}
      {...menuItemProps.props}
    >
      <Flex dir="row" align="center">
        {option?.leftIcon && (
          <Box pr="0.4rem">
            <SVGIcon {...option.leftIcon} />
          </Box>
        )}
        <Text
          textStyle={
            option.text === selectedMenuItem?.data.text
              ? menuItemProps.textStyleSelected
              : menuItemProps.textStyle
          }
        >
          {capitalizeStr(option.text)}
        </Text>
        {option?.rightIcon && (
          <Box pl="0.4rem">
            <SVGIcon {...option.rightIcon} />
          </Box>
        )}
      </Flex>
    </MenuItem>
  );
};

export default DropdownMenuItem;
