import { Box, Text, useToast } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { TextStyles } from "../../../theme/textStyles";
import Form, { InputProps } from "../../../components/form";
import Button from "../../../components/button";
import { timestamp2ISO, validateEmailAddress } from "../../../utils";
import { LayerStyles } from "../../../theme/layerStyles";
import { ApplyVia, ToastStatus } from "../../../constants/constants";
import * as JobAppTypes from "../../../graphql/types/jobApp.type";
import { ApolloCache, useMutation } from "@apollo/client";
import JobAppServices from "../../../graphql/services/jobApp.services";
import {
  JobAppAppliedTabUpdateFields,
  JOB_APP_APPLIED_TAB_UPDATE_FIELDS_FRAGMENT,
} from "../../../graphql/fragments/jobApp.fragment";
import { JobAppToastType, showToast } from "../../../utils/updateHelper";
import { JOB_APP_STATUS_HISTORY_UPDATE_FIELD_FRAGMENT } from "../../../graphql/fragments/jobAppStatusHistory";

export interface AppliedTabProps {
  appId: string;
  _associatedEmail: string;
  _appliedOn: string;
  _applyVia: ApplyVia;
  _jobLink: string;
  _recruiter: string;
}

const AppliedTab: React.FC<AppliedTabProps> = ({
  appId,
  _associatedEmail,
  _appliedOn,
  _applyVia,
  _jobLink,
  _recruiter,
}) => {
  const toast = useToast();
  const TODAY_ISO = timestamp2ISO(Date.now());

  const [associatedEmail, setAssociatedEmail] = useState<string>(_associatedEmail);
  const [appliedOn, setAppliedOn] = useState<string>(_appliedOn);
  const [applyVia, setApplyVia] = useState<ApplyVia>(_applyVia);
  const [jobLink, setJobLink] = useState<string>(_jobLink);
  const [recruiter, setRecruiter] = useState<string>(_recruiter);

  const [isAssociatedEmailDirty, setIsAssociatedEmailDirty] = useState(false);
  const [isAppliedOnDirty, setIsAppliedOnDirty] = useState(false);
  const [isApplyViaDirty, setIsApplyViaDirty] = useState(false);
  const [isJobLinkDirty, setIsJobLinkDirty] = useState(false);
  const [isRecruiterDirty, setIsRecruiterDirty] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const appliedInfo: InputProps[] = [
    { label: "Associated Email", value: { data: associatedEmail, setData: setAssociatedEmail } },
    {
      label: "Applied On",
      value: { data: appliedOn, setData: setAppliedOn },
      type: "date",
      isRequired: true,
      max: TODAY_ISO,
    },
    { label: "Applied Via", value: { data: applyVia, setData: setApplyVia }, isRequired: true },
    { label: "Job Link", value: { data: jobLink, setData: setJobLink } },
    { label: "Recruiter", value: { data: recruiter, setData: setRecruiter } },
  ];

  const [updateJobAppByAppliedTab] = useMutation<
    JobAppTypes.UpdateJobAppByAppliedTabData,
    JobAppTypes.UpdateJobAppByAppliedTabArgs
  >(
    JobAppServices.UPDATE_JOB_APP({
      associatedEmail: isAssociatedEmailDirty,
      appliedOn: isAppliedOnDirty,
      applyVia: isApplyViaDirty,
      jobLink: isJobLinkDirty,
      recruiter: isRecruiterDirty,
    })
  );

  const clearState = () => {
    setIsAppliedOnDirty(false);
    setIsAssociatedEmailDirty(false);
    setIsApplyViaDirty(false);
    setIsJobLinkDirty(false);
    setIsRecruiterDirty(false);
    setIsDirty(false);
  };

  const cache_updateJobApp = (
    cache: ApolloCache<any>,
    data: JobAppTypes.UpdateJobAppByAppliedTabData | null | undefined
  ) => {
    const updateData: JobAppAppliedTabUpdateFields = {};
    const entityToast: JobAppToastType = {};
    if (isAppliedOnDirty) {
      if (data?.updateJobAppByAppliedOn?.ok) {
        entityToast.appliedOn = true;
        cache.writeFragment({
          id: `JobApp:${appId}`,
          fragment: JOB_APP_STATUS_HISTORY_UPDATE_FIELD_FRAGMENT("applied"),
          data: {
            statusHistory: {
              applied: new Date(appliedOn).valueOf(),
            },
          },
        });
      } else {
        entityToast.appliedOn = false;
      }
    }
    if (isApplyViaDirty) {
      if (data?.updateJobAppByApplyVia?.ok) {
        updateData.applyVia = applyVia;
        entityToast.applyVia = true;
      } else {
        entityToast.applyVia = false;
      }
    }
    if (isAssociatedEmailDirty) {
      if (data?.updateJobAppByAssociatedEmail?.ok) {
        updateData.associatedEmail = associatedEmail;
        entityToast.associatedEmail = true;
      } else {
        entityToast.associatedEmail = false;
      }
    }
    if (isJobLinkDirty) {
      if (data?.updateJobAppByJobLink?.ok) {
        updateData.jobLink = jobLink;
        entityToast.jobLink = true;
      } else {
        entityToast.jobLink = false;
      }
    }
    if (isRecruiterDirty) {
      if (data?.updateJobAppByRecruiter?.ok) {
        updateData.recruiter = recruiter;
        entityToast.recruiter = true;
      } else {
        entityToast.recruiter = false;
      }
    }
    // update cache for the updated job app
    if (Object.entries(updateData).length > 0) {
      cache.writeFragment({
        id: `JobApp:${appId}`,
        fragment: JOB_APP_APPLIED_TAB_UPDATE_FIELDS_FRAGMENT(updateData),
        data: {
          ...updateData,
        },
      });
    }
    // show error or success toast to indicate operation status
    showToast("Job application", entityToast, toast);
  };

  const onSubmit = async () => {
    const updateJobAppArgs: JobAppTypes.UpdateJobAppByAppliedTabArgs = {};
    if (isAppliedOnDirty) {
      updateJobAppArgs.updateJobAppByAppliedOn = {
        _id: appId,
        applied: new Date(appliedOn).valueOf(),
      };
    }
    if (isApplyViaDirty) {
      updateJobAppArgs.updateJobAppByApplyVia = {
        _id: appId,
        applyVia: applyVia,
      };
    }
    if (isAssociatedEmailDirty) {
      updateJobAppArgs.updateJobAppByAssociatedEmail = {
        _id: appId,
        associatedEmail: associatedEmail,
      };
    }
    if (isJobLinkDirty) {
      updateJobAppArgs.updateJobAppByJobLink = {
        _id: appId,
        jobLink: jobLink,
      };
    }
    if (isRecruiterDirty) {
      updateJobAppArgs.updateJobAppByRecruiter = {
        _id: appId,
        recruiter: recruiter,
      };
    }
    await updateJobAppByAppliedTab({
      variables: updateJobAppArgs,
      update(cache, { data }) {
        cache_updateJobApp(cache, data);
        clearState();
      },
    }).catch((error) => {
      toast({
        description: `${error.message}`,
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
    });
  };

  const updateApplyInfoHandler = async () => {
    if (appliedOn > TODAY_ISO) {
      toast({
        description: "Please enter an application date in the past",
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
      return;
    }
    if (!validateEmailAddress(associatedEmail)) {
      toast({
        description: "Please enter a valid email address",
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
      return;
    }
    await onSubmit();
  };

  useEffect(() => {
    setIsDirty(
      isAppliedOnDirty ||
        isApplyViaDirty ||
        isAssociatedEmailDirty ||
        isJobLinkDirty ||
        isRecruiterDirty
    );
  }, [isAppliedOnDirty, isApplyViaDirty, isAssociatedEmailDirty, isJobLinkDirty, isRecruiterDirty]);

  useEffect(() => {
    setIsAssociatedEmailDirty(associatedEmail !== _associatedEmail);
  }, [associatedEmail, _associatedEmail]);

  useEffect(() => {
    setIsAppliedOnDirty(appliedOn !== _appliedOn);
  }, [appliedOn, _associatedEmail]);

  useEffect(() => {
    setIsApplyViaDirty(applyVia !== _applyVia);
  }, [applyVia, _applyVia]);

  useEffect(() => {
    setIsJobLinkDirty(jobLink !== _jobLink);
  }, [jobLink, _jobLink]);

  useEffect(() => {
    setIsRecruiterDirty(recruiter !== _recruiter);
  }, [recruiter, _recruiter]);

  return (
    <Box layerStyle={LayerStyles.mainPanelChild} maxW="24rem">
      <Text textStyle={TextStyles.h2} as="h2" mb="1.5rem">
        Application Info
      </Text>
      <Form inputs={appliedInfo} />
      <Button
        margin="auto"
        text="Update"
        onClick={updateApplyInfoHandler}
        isDisabled={!isDirty}
        mt="1.75rem"
      />
    </Box>
  );
};

export default AppliedTab;
