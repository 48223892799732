import mockCompanies from "./data/companies.mock.json";
import mockJobAppTerms from "./data/jobAppTerms.mock.json";
import mockJobMarks from "./data/jobMarks.mock.json";
import mockUser from "./data/user.mock.json";
import { ICompany, IJobListing } from "../schemas/job.schema";
import { IJobApp, IJobAppEvent } from "../schemas/app.schema";
import { IJobAppTerm } from "../schemas/user.schema";

class MockDataLoader {
  // testUser: IUser;
  allTerms: IJobAppTerm[];
  allCompanies: ICompany[];
  allJobs: IJobListing[];
  allJobApps: IJobApp[];
  allJobAppEvents: IJobAppEvent[];
  allJobMarks: string[];
  currentTerm: IJobAppTerm;

  constructor() {
    // Terms
    this.allTerms = mockJobAppTerms;
    // User
    const terms: Set<string> = new Set<string>(mockUser.terms);
    // this.testUser = { ...mockUser, terms: this.allTerms.filter((term) => terms.has(term._id)) };
    // Companies
    this.allCompanies = mockCompanies;
    // Jobs with the Company
    this.allJobs = [];
    // mockJobs.map((job) => {
    //   return { ...job, company: this.allCompanies.find((item) => item._id === job.companyId) };
    // });
    // Companies with ActiveJobs
    this.allCompanies = this.allCompanies.map((com) => {
      const activeJobsid = new Set<string>(com.activeJobsid);
      const archivedJobsid = new Set<string>(com.archivedJobsid);
      return {
        ...com,
        activeJobs: this.allJobs.filter((job) => activeJobsid?.has(job._id)),
        archivedJobs: this.allJobs.filter((job) => archivedJobsid?.has(job._id)),
      };
    });
    // JobApps with the Job
    this.allJobApps = [];
    // this.allJobApps = mockJobApps.map((app) => {
    //   return {
    //     ...app,
    //     job: this.allJobs.find((job) => job._id === app.jobid) || this.allJobs[0],
    //     events: [],
    //   };
    // });
    // Terms with Apps
    this.allTerms = this.allTerms.map((term) => {
      const appsid = new Set(term.appsid);
      return { ...term, apps: this.allJobApps.filter((app) => appsid.has(app._id)) };
    });

    this.allJobAppEvents = [];
    // JobMarks
    this.allJobMarks = mockJobMarks;

    //current term, default to last created term
    this.currentTerm = this.allTerms[0];
  }

  // getTestUser(): IUser {
  //   return this.testUser;
  // }

  getAllTerms(): IJobAppTerm[] {
    return this.allTerms;
  }
  getAllCompanies(): ICompany[] {
    return this.allCompanies;
  }

  getAllJobs(): IJobListing[] {
    return this.allJobs;
  }

  getJobListingsByCompanyId(companyId: string): IJobListing[] {
    return [];
    // this.allJobs.filter((job) => job.companyId === companyId);
  }

  getAllJobApps(): IJobApp[] {
    return this.allJobApps;
  }

  getAllJobAppEvents(): IJobAppEvent[] {
    return this.allJobAppEvents;
  }

  getAllJobMarks(): string[] {
    return this.allJobMarks;
  }

  getCurrentTerm(): IJobAppTerm {
    return this.currentTerm;
  }

  setCurrentTerm(newCurrentTerm: IJobAppTerm): void {
    this.currentTerm = newCurrentTerm;
  }
}

export default MockDataLoader;
