import React from "react";
import { Textarea, Text, Box } from "@chakra-ui/react";
import { ReactUseState } from "../../containers/App";
import { TextStyles } from "../../theme/textStyles";

export interface JobAppOfferDescriptionProps {
  description: ReactUseState<string>;
}

const JobAppOfferDescription: React.FC<JobAppOfferDescriptionProps> = ({ description }) => {
  return (
    <Box>
      <Text textStyle={TextStyles.noteSubHeader} mb="0.6rem">
        Description
      </Text>
      <Textarea
        size="xs"
        variant="unstyled"
        placeholder="Do you have competing offers? Have you negotiated for this offer?"
        value={description.data}
        onChange={(e) => description.setData(e.target.value)}
        mb="0.75rem"
      >
        {description.data}
      </Textarea>
    </Box>
  );
};

export default JobAppOfferDescription;
