import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { PayBasis } from "../../constants/constants";
import { ReactUseState } from "../../containers/App";
import { TextStyles } from "../../theme/textStyles";
import JobOfferInput from "./jobAppOfferForm";
import VestingSchedulesForm from "./vestingSchedulesForm";

export interface JobAppOfferCompensationsProps {
  payRate: ReactUseState<number>;
  payBasis: ReactUseState<PayBasis>;
  stock: ReactUseState<number>;
  vestingSchedules: ReactUseState<number[]>;
  signOnBonus: ReactUseState<number>;
  performanceBonus: ReactUseState<number>;
  otherCompensation: ReactUseState<number>;
  schedulesError: ReactUseState<boolean>;
}

const JobAppOfferCompensations: React.FC<JobAppOfferCompensationsProps> = ({
  payRate,
  payBasis,
  stock,
  vestingSchedules,
  signOnBonus,
  performanceBonus,
  otherCompensation,
  schedulesError,
}) => {
  const MAX_PERSENTAGE = 100;
  const PERSENTAGE_PRECISION = 2;
  return (
    <>
      <Text textStyle={TextStyles.noteSubHeader} mb="0.75rem">
        Compensation Details
      </Text>
      <Flex dir="row" alignContent="center">
        <JobOfferInput
          input={{
            label: "Base Rate / Basis",
            value: payRate,
            type: "number",
            isRequired: true,
          }}
          inputLeftElement="$"
          dropDownSelect={payBasis}
        />
      </Flex>
      <JobOfferInput
        input={{
          label: "Stock / Vesting",
          value: stock,
          type: "number",
        }}
        inputLeftElement="$"
      />
      {stock.data !== 0 && (
        <VestingSchedulesForm schedules={vestingSchedules} isError={schedulesError} />
      )}
      <JobOfferInput
        input={{
          label: "Sign-on Bonus",
          value: signOnBonus,
          type: "number",
        }}
        inputLeftElement="$"
      />
      <JobOfferInput
        input={{
          label: "Performance Bonus",
          value: performanceBonus,
          type: "number",
          max: MAX_PERSENTAGE,
          precision: PERSENTAGE_PRECISION,
        }}
        inputRightElement="%"
      />
      <JobOfferInput
        input={{
          label: "Other Compensation",
          value: otherCompensation,
          type: "number",
        }}
        inputLeftElement="$"
      />
    </>
  );
};

export default JobAppOfferCompensations;
