import { gql } from "@apollo/client";
import { JobAppOfferStatus, PayBasis } from "../../constants/constants";

export const JOB_APP_OFFER_CORE_FRAGMENT = gql`
  fragment JobAppOfferCoreFragment on JobAppOffer {
    _id
    jobAppId
    status
    deadline
    startDate
    endDate
    position
    payBasis
    payRate
    otherCompensation
    stock
    vestingSchedules
    signOnBonus
    performanceBonus
    relocation
    location
    benefits
  }
`;

export interface JobAppOfferUpdateFields {
  status?: JobAppOfferStatus;
  position?: string;
  deadline?: number;
  startDate?: number;
  endDate?: number;
  payRate?: number;
  payBasis?: PayBasis;
  performanceBonus?: number;
  stock?: number;
  vestingSchedules?: number[];
  signOnBonus?: number;
  relocation?: number;
  location?: string;
  benefits?: string[];
  otherCompensation?: number;
  description?: string;
}
export const JOB_APP_OFFER_UPDATE_FIELDS_FRAGMENT = (fields: JobAppOfferUpdateFields) => {
  const fieldNames = Object.entries(fields).filter(([key, value]) => value !== undefined);
  const fieldsStr = fieldNames.map(([key, value]) => key).join("\n");
  return gql`
    fragment JobAppOfferUpdateFieldsFragment on JobAppOffer {
      ${fieldsStr}
    }
  `;
};
