import React, { useEffect, useState } from "react";
import { Box, Text, Flex, Spacer, useToast } from "@chakra-ui/react";
import { ToastStatus } from "../../constants/constants";
import AppRoutes from "../AppRoutes.constant";
import StepBar from "../../components/stepBar";
import Form, { InputProps } from "../../components/form";
import { TextStyles } from "../../theme/textStyles";
import Button from "../../components/button";
import { ButtonVariants } from "../../theme/components/button";
import { ReactUseState } from "../App";
import { useHistory } from "react-router-dom";

export interface EnterNewPasswordPorps {
  step: ReactUseState<number>;
}

const EnterNewPassword: React.FC<EnterNewPasswordPorps> = ({ step }) => {
  const history = useHistory();
  const toast = useToast();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isFormFilled, setIsFormFilled] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (password && confirmPassword) {
      setIsFormFilled(true);
    } else {
      setIsFormFilled(false);
    }
  }, [password, confirmPassword]);

  const emailSubmitHandler = () => {
    if (password !== confirmPassword) {
      toast({
        description: "Password not matched",
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
    } else {
      //email request verification
      //submit change
      history.push(AppRoutes.myApps.route);
    }
  };

  const newPasswordInput: InputProps[] = [
    {
      label: "Password",
      value: { data: password, setData: setPassword },
      isRequired: true,
      type: "password",
      placeholder: "Enter password",
    },
    {
      label: "Confirm Password",
      value: { data: confirmPassword, setData: setConfirmPassword },
      isRequired: true,
      type: "password",
      placeholder: "Confirm password",
    },
  ];

  return (
    <Box>
      <StepBar steps={3} currentStep={3} />
      <Text mb="1.5rem" textStyle={TextStyles.text} color="grey.600">
        Enter your new password
      </Text>
      <Form inputs={newPasswordInput} mb="1.5rem" />
      <Flex mb="2rem">
        <Button
          variant={ButtonVariants.text}
          fontWeight="400"
          text="Back"
          onClick={() => step.setData(2)}
          leftIcon={{ name: "arrow-left-line", fill: "black" }}
          pl="0"
        />
        <Spacer />
        <Button
          variant={ButtonVariants.primaryFillSquare}
          text="Submit"
          textStyle={TextStyles.primaryFillBtn_lg}
          margin="auto"
          onClick={emailSubmitHandler}
          isDisabled={!isFormFilled}
          padding="0.25rem 0.5rem"
          justify="end"
        />
      </Flex>
    </Box>
  );
};

export default EnterNewPassword;
