import { gql } from "@apollo/client";
import { ApplyVia } from "../../constants/constants";
import { JOB_APP_STATUS_HISTORY_CORE_FRAGMENT } from "./jobAppStatusHistory";
import { JOB_LISTING_CARD_DISPLAY_FRAGMENT } from "./jobListing.fragment";

export const JOB_APP_CARD_DISPLAY_FRAGMENT = gql`
  ${JOB_LISTING_CARD_DISPLAY_FRAGMENT}
  fragment JobAppCardDisplayFragment on JobApp {
    _id
    _updatedAt
    status
    job {
      ...JobListingCardDisplayFragment
    }
  }
`;

export const JOB_APP_CORE_FRAGMENT = gql`
  ${JOB_APP_STATUS_HISTORY_CORE_FRAGMENT}
  ${JOB_LISTING_CARD_DISPLAY_FRAGMENT}
  fragment JobAppCoreFragment on JobApp {
    _id
    _createdAt
    termid
    status
    statusHistory {
      ...JobAppStatusHistoryCoreFragment
    }
    job {
      ...JobListingCardDisplayFragment
    }
    jobLink
    applyVia
    associatedEmail
    recruiter
  }
`;

export const JOB_APP_UPDATE_STATUS_FRAGMENT = gql`
  fragment JobAppUpdateStatusFragment on JobApp {
    status
  }
`;

export interface JobAppAppliedTabUpdateFields {
  applyVia?: ApplyVia;
  jobLink?: string;
  associatedEmail?: string;
  recruiter?: string;
}
export const JOB_APP_APPLIED_TAB_UPDATE_FIELDS_FRAGMENT = (
  fields: JobAppAppliedTabUpdateFields
) => {
  const fieldNames = Object.entries(fields).filter(([key, value]) => value !== undefined);
  const fieldsStr = fieldNames.map(([key, value]) => key).join("\n");
  return gql`
    fragment JobAppAppliedTabUpdateFieldsFragment on JobApp {
      ${fieldsStr}
    }
  `;
};
