import { Box, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import GridLayout from "../../../layouts/gridLayout";
import { IJobApp } from "../../../schemas/app.schema";
import JobAppCardDisplay, { JobAppCardShape } from "./jobAppCardDisplay";
import JobAppServices from "../../../graphql/services/jobApp.services";
import * as JobAppTypes from "../../../graphql/types/jobApp.type";
import { JobAppStatus, MyAppCategory, ToastStatus } from "../../../constants/constants";
import { useQuery } from "@apollo/client";
import { OperationStatus } from "../../../graphql/types/common.type";
import { JobAppList } from "../../../graphql/types/jobApp.type";
import { ReactUseState } from "../../App";
import { ITab } from "../../pages/myApps";
import { OptionProps } from "../../../components/dropdown";

interface ViewJobAppsGridProps {
  jobAppsByTerm: IJobApp[];
  termId: string;
  tabs: ReactUseState<ITab[]>;
  selectedTab: ReactUseState<ITab>;
  selectedFilters: ReactUseState<OptionProps[]>;
  jobMarks: Set<string>;
}

const ViewJobAppsGrid: React.FC<ViewJobAppsGridProps> = ({
  jobAppsByTerm,
  termId,
  tabs,
  selectedTab,
  selectedFilters,
  jobMarks,
}) => {
  const toast = useToast();
  const [allApps, setAllApps] = useState<IJobApp[]>(jobAppsByTerm);
  const [displayApps, setDisplayApps] = useState<IJobApp[]>([]);

  // const { data, error } = useQuery<
  //   JobAppTypes.GetJobAppsByTermIdData,
  //   JobAppTypes.GetJobAppsByTermIdArgs
  // >(JobAppServices.GET_JOB_APPS_BY_TERM_ID, {
  //   variables: { id: termId },
  // });

  const filterAppByJobType = (apps: IJobApp[], selectedFilters: OptionProps[]) => {
    let jobTypeSet = new Set();
    selectedFilters.map((option) => {
      if (option["checkbox"]) {
        jobTypeSet.add(option["text"]);
      }
    });
    if (jobTypeSet.has("Marked")) {
      apps = apps.filter((item) => jobMarks.has(item.job._id));
    }
    jobTypeSet.delete("Marked");
    if (jobTypeSet.size > 0) {
      apps = apps.filter((item) => jobTypeSet.has(item.job.type));
    }
    return apps;
  };

  const filterAppByStatusAndJobType = (
    apps: IJobApp[],
    selectedFilters: OptionProps[],
    status: MyAppCategory
  ) => {
    if (status === MyAppCategory.ALL) {
      return filterAppByJobType(apps, selectedFilters);
    } else if (status === MyAppCategory.ARCHIVED) {
      apps = apps.filter(
        (app) => app.status === JobAppStatus.REJECT || app.status === JobAppStatus.WITHDRAW
      );
    } else {
      apps = apps.filter((app) => `${app.status}` === `${status}`);
    }
    return filterAppByJobType(apps, selectedFilters);
  };

  const filterAppByStatus = (allApps: IJobApp[], category: MyAppCategory): IJobApp[] => {
    const statusSet: Set<JobAppStatus> = new Set();
    if (category === MyAppCategory.ALL) {
      return allApps;
    }
    switch (category) {
      case MyAppCategory.APPLIED:
        statusSet.add(JobAppStatus.APPLIED);
        break;
      case MyAppCategory.ASSESSMENT:
        statusSet.add(JobAppStatus.ASSESSMENT);
        break;
      case MyAppCategory.INTERVIEW:
        statusSet.add(JobAppStatus.INTERVIEW);
        break;
      case MyAppCategory.OFFER:
        statusSet.add(JobAppStatus.OFFER);
        break;
      case MyAppCategory.ARCHIVED:
        statusSet.add(JobAppStatus.REJECT);
        statusSet.add(JobAppStatus.WITHDRAW);
        break;
      default:
        break;
    }
    const res = allApps.filter((app) => statusSet.has(app.status as JobAppStatus));
    return res;
  };

  const updateTabs = (jobApps: IJobApp[]) => {
    let newTabs = tabs.data.map((tab) => {
      let apps = filterAppByStatus(jobApps, tab.status);
      return { ...tab, noOfApps: apps.length, apps: apps };
    });
    tabs.setData(newTabs);
  };

  useEffect(() => {
    // const jobAppList = (data?.getJobAppsByTermId as JobAppList)?.jobApps;
    // if (data?.getJobAppsByTermId && jobAppList) {
    //   setAllApps(jobAppList);
    //   setDisplayApps(jobAppList);
    //   updateTabs(jobAppList);
    // }
    setAllApps(jobAppsByTerm);
    setDisplayApps(jobAppsByTerm);
    updateTabs(jobAppsByTerm);
  }, [jobAppsByTerm]);

  useEffect(() => {
    filterAppByStatusAndJobType(allApps, selectedFilters.data, selectedTab.data.status);
    setDisplayApps(selectedTab.data?.apps || allApps);
  }, [selectedTab.data, selectedFilters.data]);

  const DataNotFound = () => {
    return <Box>Data not found</Box>;
  };

  // if (error) {
  //   const error_message = error?.message;
  //   toast({
  //     title: "Error",
  //     description: error_message,
  //     position: "bottom",
  //     status: ToastStatus.ERROR,
  //     isClosable: true,
  //   });
  //   return <DataNotFound />;
  // }

  // if (data?.getJobAppsByTermId && (data.getJobAppsByTermId as OperationStatus)?.errorMessage) {
  //   const error_message = (data.getJobAppsByTermId as OperationStatus).errorMessage;
  //   toast({
  //     title: "Failed to load job apps data",
  //     description: error_message,
  //     position: "bottom",
  //     status: ToastStatus.ERROR,
  //     isClosable: true,
  //   });
  //   return <DataNotFound />;
  // }

  return (
    <GridLayout
      columns={3}
      spacing={6}
      items={displayApps.map((app) => {
        if (!app) {
          return <></>;
        }
        return (
          <JobAppCardDisplay
            key={app._id}
            shape={JobAppCardShape.SQUARE}
            item={{ app: app, job: app?.job }}
            viewApp={true}
          />
        );
      })}
    />
  );
};

export default ViewJobAppsGrid;
