import { DocumentNode, gql } from "@apollo/client";
import { OPERATION_STATUS_FRAGMENT } from "../../fragments/common.fragment";
import { USER_CORE_FRAGMENT } from "../../fragments/user.fragment";
import {
  UpdateMutationParamQueryPairProps,
  UpdateMutationHelper,
  UpdateMutationHelperProps,
  UpdateMutationPlaceholderProps,
} from "../serviceHelper";

const CREATE_USER_BY_REGISTER = gql`
  ${USER_CORE_FRAGMENT}
  ${OPERATION_STATUS_FRAGMENT}
  mutation CreateUserByRegister(
    $newUser: CreateUserInput!
    $password: String!
    $invitationCode: String!
  ) {
    createUserByRegister(newUser: $newUser, password: $password, invitationCode: $invitationCode) {
      ... on User {
        ...UserCoreFragment
      }
      ... on OperationStatus {
        ...OperationStatusFragment
      }
    }
  }
`;

const CREATE_USER_BY_GOOGLE_IDENTITY = gql`
  ${USER_CORE_FRAGMENT}
  ${OPERATION_STATUS_FRAGMENT}
  mutation CreateUserByGoogleIdentity($newUser: CreateUserInput!, $googleToken: String!) {
    createUserByGoogleIdentity(newUser: $newUser, googleToken: $googleToken) {
      ... on User {
        ...UserCoreFragment
      }
      ... on OperationStatus {
        ...OperationStatusFragment
      }
    }
  }
`;

const GET_USER_BY_LOGIN = gql`
  ${USER_CORE_FRAGMENT}
  mutation GetUserByLogin($login: GetUserByLoginInput!) {
    getUserByLogin(login: $login) {
      ...UserCoreFragment
    }
  }
`;

const GET_USER_BY_GOOGLE_IDENTITY = gql`
  ${USER_CORE_FRAGMENT}
  mutation GetUserByGoogleIdentity($loginGoogle: GetUserByGoogleIdentityInput!) {
    getUserByGoogleIdentity(loginGoogle: $loginGoogle) {
      ...UserCoreFragment
    }
  }
`;

const UPDATE_USER_CREDENTIAL_BY_PASSWORD = gql`
  ${OPERATION_STATUS_FRAGMENT}
  mutation GetUserByGoogleIdentity(
    $oldCredential: UpdateUserCredentialByPasswordInput!
    $newPassword: String!
  ) {
    updateUserCredentialByPassword(oldCredential: $oldCredential, newPassword: $newPassword) {
      ...OperationStatusFragment
    }
  }
`;

const UPDATE_USER_BY_LAST_OPEN_TERM = gql`
  ${OPERATION_STATUS_FRAGMENT}
  mutation UpdateUserByLastOpenTerm($updateUserByLastOpenTerm: UpdateUserByLastOpenTermInput!) {
    updateUserByLastOpenTerm(updateUser: $updateUserByLastOpenTerm) {
      ...OperationStatusFragment
    }
  }
`;

const UPDATE_USER_BY_FIRST_NAME = `
  updateUserByFirstName(updateUser: $updateUserByFirstName) {
    ...OperationStatusFragment
  }
`;

const UPDATE_USER_BY_LAST_NAME = `
  updateUserByLastName(updateUser: $updateUserByLastName) {
    ...OperationStatusFragment
  }
`;

const UPDATE_USER_BY_USER_NAME = `
  updateUserByUsername(updateUser: $updateUserByUsername) {
    ...OperationStatusFragment
  }
`;

const UPDATE_USER_BY_AUTO_ARCHIVE = `
  updateUserByAutoArchive(updateUser: $updateUserByAutoArchive) {
    ...OperationStatusFragment
  }
`;

const UPDATE_USER_BY_DAYS_TO_ARCHIVE = `
  updateUserByDaysToArchive(updateUser: $updateUserByDaysToArchive) {
    ...OperationStatusFragment
  }
`;

const UPDATE_USER_BY_SHARE_PROGRESS = `
  updateUserByShareProgress(updateUser: $updateUserByShareProgress) {
    ...OperationStatusFragment
  }
`;

const UPDATE_USER_BY_SHARE_OFFER = `
  updateUserByShareOffer(updateUser: $updateUserByShareOffer) {
    ...OperationStatusFragment
  }
`;

export interface UpdaterUserMutationProps {
  firstName?: boolean;
  lastName?: boolean;
  username?: boolean;
  autoArchive?: boolean;
  daysToArchive?: boolean;
  shareProgress?: boolean;
  shareOffer?: boolean;
}

const updateUserMutationParamQueryPair: UpdateMutationParamQueryPairProps = {
  firstName: {
    param: "$updateUserByFirstName: UpdateUserByFirstNameInput!",
    query: UPDATE_USER_BY_FIRST_NAME,
  },
  lastName: {
    param: "$updateUserByLastName: UpdateUserByLastNameInput!",
    query: UPDATE_USER_BY_LAST_NAME,
  },
  username: {
    param: "$updateUserByUsername: UpdateUserByUsernameInput!",
    query: UPDATE_USER_BY_USER_NAME,
  },
  autoArchive: {
    param: "$updateUserByAutoArchive: UpdateUserByAutoArchiveInput!",
    query: UPDATE_USER_BY_AUTO_ARCHIVE,
  },
  daysToArchive: {
    param: "$updateUserByDaysToArchive : UpdateUserByDaysToArchiveInput!",
    query: UPDATE_USER_BY_DAYS_TO_ARCHIVE,
  },
  shareProgress: {
    param: "$updateUserByShareProgress: UpdateUserByShareProgressInput!",
    query: UPDATE_USER_BY_SHARE_PROGRESS,
  },
  shareOffer: {
    param: "$updateUserByShareOffer: UpdateUserByShareOfferInput!",
    query: UPDATE_USER_BY_SHARE_OFFER,
  },
};

const UPDATE_USER = (props: UpdaterUserMutationProps): DocumentNode => {
  const mutationName = "UpdateUserMutation";
  const placeholder: UpdateMutationPlaceholderProps = {
    params: `$placeholder: UpdateUserByFirstNameInput!`,
    queries: `placeholder(placeholder: $placeholder) {_id}`,
  };
  return UpdateMutationHelper(
    mutationName,
    placeholder,
    props as UpdateMutationHelperProps,
    updateUserMutationParamQueryPair
  );
};

const UserMutations = {
  CREATE_USER_BY_REGISTER,
  CREATE_USER_BY_GOOGLE_IDENTITY,
  GET_USER_BY_LOGIN,
  GET_USER_BY_GOOGLE_IDENTITY,
  UPDATE_USER_CREDENTIAL_BY_PASSWORD,
  UPDATE_USER_BY_LAST_OPEN_TERM,
  UPDATE_USER,
};

export default UserMutations;
