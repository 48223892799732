import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { ReactUseState } from "../../containers/App";
import { TextStyles } from "../../theme/textStyles";

import JobOfferInput, { JobAppOfferFormProps } from "./jobAppOfferForm";

export interface JobAppOfferDetailsProps {
  startDate: ReactUseState<string>;
  endDate: ReactUseState<string>;
  position: ReactUseState<string>;
  location: ReactUseState<string>;
}

const JobAppOfferDetails: React.FC<JobAppOfferDetailsProps> = ({
  startDate,
  endDate,
  position,
  location,
}) => {
  const inputs: JobAppOfferFormProps[] = [
    {
      input: {
        label: "Start Date",
        value: startDate,
        type: "date",
      },
    },
    {
      input: {
        label: "End Date",
        value: endDate,
        type: "date",
      },
    },
    {
      input: {
        label: "Postition Title, Level",
        value: position,
        placeholder: "Enter position title",
        maxLength: 35,
      },
    },
    {
      input: {
        label: "Location",
        value: location,
        placeholder: "Enter primary office location",
        maxLength: 35,
      },
    },
  ];
  return (
    <Box>
      <Text textStyle={TextStyles.noteSubHeader} mb="0.75rem">
        Offer Details
      </Text>
      {inputs.map((input, key) => (
        <JobOfferInput key={key} {...input} />
      ))}
    </Box>
  );
};

export default JobAppOfferDetails;
