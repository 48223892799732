import React, { ReactNode } from "react";
import { Tab, TabList, TabPanel, TabPanels, Tabs as ChakraTabs } from "@chakra-ui/react";

export interface TabsProps {
  tabs: { text: string; props?: any }[];
  tabPanels: ReactNode[];
}

const Tabs: React.FC<TabsProps> = ({ tabs, tabPanels }) => {
  return (
    <ChakraTabs isFitted align="center">
      <TabList maxW="12rem" width="100%" mb="1rem" border="none">
        {tabs.map((tab, key) => (
          <Tab
            key={key}
            color="grey.400"
            borderColor="grey.grey"
            _selected={{ color: "primary.solid", borderColor: "primary.solid" }}
            _focus={{ boxShadow: "none" }}
            mr="0.25rem"
            {...tab.props}
          >
            {tab.text}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        {tabPanels.map((panel, key) => (
          <TabPanel key={key}>{panel}</TabPanel>
        ))}
      </TabPanels>
    </ChakraTabs>
  );
};

export default Tabs;
