import { useQuery } from "@apollo/client";
import { Box, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Sorting, ToastStatus } from "../../../constants/constants";
import GridLayout from "../../../layouts/gridLayout";
import JobAppCardDisplay, { JobAppCardShape } from "../jobApp/jobAppCardDisplay";
import JobListingServices from "../../../graphql/services/jobListing.services";
import * as JobListingTypes from "../../../graphql/types/jobListing.type";
import { IMergedJobApp, initMergedJobApps, mergeJobsWithApps } from "../../../utils/mergedJobApp";
import { ReactUseState } from "../../App";
import SectionHeader from "../../../components/sectionHeader";
import { IJobApp } from "../../../schemas/app.schema";
import CardSquareSkeleton from "../../../components/skeletons/cardSquare";

interface ViewJobListingsGridProps {
  sorting?: Sorting;
  nJobs: ReactUseState<number>;
  jobAppsByTerm: IJobApp[];
}

const ViewJobListingsGrid: React.FC<ViewJobListingsGridProps> = ({ nJobs, jobAppsByTerm }) => {
  const LIMIT_PER_QUERY = 100;
  const toast = useToast();

  let { data, loading, error } = useQuery<
    JobListingTypes.GetJobListingsData,
    JobListingTypes.GetJobListingsArgs
  >(JobListingServices.GET_JOB_LISTINGS, {
    variables: { offset: 0, limit: LIMIT_PER_QUERY },
  });

  const [mergedJobsApps, setMergedJobsApps] = useState<IMergedJobApp[]>(
    initMergedJobApps(data?.getJobListings)
  );
  const [mergedToday, setMergedToday] = useState<IMergedJobApp[]>([]);
  const [mergedPast, setMergedPast] = useState<IMergedJobApp[]>([]);

  const getTimestampForStartOfDay = (): number => {
    const d = new Date();
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d.valueOf();
  };

  const divideMergedJobsApps = (list: IMergedJobApp[]) => {
    const timestamp = getTimestampForStartOfDay();
    const today = list.filter(
      (merged) => merged.job?._createdAt && merged.job._createdAt >= timestamp
    );
    const past = list.filter(
      (merged) => merged.job?._createdAt && merged.job._createdAt < timestamp
    );
    setMergedToday(today);
    setMergedPast(past);
  };

  useEffect(() => {
    if (data?.getJobListings) {
      const newMergedJobApps = mergeJobsWithApps(jobAppsByTerm, data?.getJobListings || []);
      setMergedJobsApps(newMergedJobApps);
      nJobs.setData(newMergedJobApps.length);
    }
  }, [data?.getJobListings, jobAppsByTerm]);

  useEffect(() => {
    divideMergedJobsApps(mergedJobsApps);
  }, [mergedJobsApps]);

  const DataNotFound = () => {
    return <Box textAlign="center">Data not found</Box>;
  };

  if (error) {
    toast({
      title: "Failed to load JobListings data",
      description: error.message,
      position: "bottom",
      status: ToastStatus.ERROR,
      isClosable: true,
    });
    return <DataNotFound />;
  }

  if (loading) {
    return <CardSquareSkeleton loading={loading} />;
  }

  return (
    <>
      {mergedToday.length > 0 && (
        <>
          <SectionHeader text="Today" textStyle="sectionHeader" />
          <GridLayout
            columns={3}
            spacing={6}
            items={mergedToday?.map((merged) => {
              if (!merged) {
                return <></>;
              }
              return (
                <JobAppCardDisplay
                  key={merged.job._id}
                  shape={JobAppCardShape.SQUARE}
                  item={merged}
                  viewApp={false}
                />
              );
            })}
          />
        </>
      )}
      {mergedPast.length > 0 && (
        <>
          <SectionHeader text="Previous" textStyle="sectionHeader" />
          <GridLayout
            columns={3}
            spacing={6}
            items={mergedPast?.map((merged) => {
              if (!merged) {
                return <></>;
              }
              return (
                <JobAppCardDisplay
                  key={merged.job._id}
                  shape={JobAppCardShape.SQUARE}
                  item={merged}
                  viewApp={false}
                />
              );
            })}
          />
        </>
      )}
    </>
  );
};

export default ViewJobListingsGrid;
