import React from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import Button from "./button";
import { ButtonVariants } from "../theme/components/button";
import { TextStyles } from "../theme/textStyles";

interface SearchHeaderRowProps {
  goBack?: boolean;
}

const SearchHeaderRow: React.FC<SearchHeaderRowProps> = ({ goBack = false }) => {
  const history = useHistory();

  return (
    <Grid
      templateColumns="repeat(5, 1fr)"
      gap={6}
      mx="auto"
      mb="0rem"
      alignItems="center"
      position="sticky"
      top="0"
      zIndex={3}
      width="100%"
      background="grey.100"
      pt={goBack ? "2rem" : "1rem"}
      pb={goBack ? "1rem" : "1rem"}
    >
      {goBack ? (
        <Button
          variant={ButtonVariants.text}
          textStyle={TextStyles.text}
          text="Back"
          onClick={() => history.goBack()}
          leftIcon={{ name: "arrow-left-line", fill: "black" }}
          pl="0"
        />
      ) : (
        <GridItem colSpan={1} />
      )}
      {/* <GridItem colSpan={3}>
        <SearchBar maxWidth="26rem" width="100%" />
      </GridItem> */}
    </Grid>
  );
};

export default SearchHeaderRow;
