import React, { ReactNode } from "react";
import {
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import ButtonIcon, { ButtonIconProps } from "./buttonIcon";
import Button, { ButtonProps } from "./button";
import { TextStyles } from "../theme/textStyles";
import AlertDialog from "./alertDialog";

interface ModalProps {
  btnProps?: ButtonProps;
  btnIconProps?: ButtonIconProps;
  preHeader?: ReactNode;
  header: string;
  description?: string;
  body: ReactNode;
  goBack?: ButtonProps;
  nextBtn?: ButtonProps;
  onSubmit?(): Promise<boolean>;
  onDelete?(): Promise<boolean>;
}

const Modal: React.FC<ModalProps> = ({
  btnProps,
  btnIconProps,
  preHeader,
  header,
  description,
  body,
  goBack,
  nextBtn,
  onSubmit,
  onDelete,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const xLoading = false;
  const closeHandler = async () => {
    if (onSubmit) {
      if (await onSubmit()) {
        onClose();
      }
    } else {
      onClose();
    }
  };

  const goBackHandler = async () => {
    if (onDelete) {
      if (await onDelete()) {
        onClose();
      }
    }
    onClose();
  };

  const TriggerBtn = () => {
    if (btnIconProps) {
      return <ButtonIcon {...btnIconProps} onClick={onOpen} />;
    } else if (btnProps) {
      return <Button {...btnProps} onClick={onOpen} />;
    } else {
      return <></>;
    }
  };

  return (
    <>
      <TriggerBtn />
      <ChakraModal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        blockScrollOnMount={false}
        closeOnOverlayClick={false}
        // scrollBehavior="inside"
        autoFocus={true}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent alignItems="center" padding="2.5rem 5rem" borderRadius="1rem">
          {preHeader}
          <ModalHeader
            textStyle={TextStyles.h3}
            width="100%"
            textAlign="center"
            pb={description ? "0.5rem" : "1rem"}
          >
            {header}
          </ModalHeader>
          {description && (
            <Text textStyle={TextStyles.thin} pb="0.5rem">
              {description}
            </Text>
          )}
          <ModalCloseButton margin="0.5rem" fontSize={["sm", "md", "lg"]} />
          <ModalBody px="0" width="80%">
            {body}
          </ModalBody>
          <ModalFooter px="0" width="80%">
            {goBack && (
              <>
                {onDelete ? (
                  <AlertDialog
                    triggerBtnProps={{
                      ...goBack,
                      onClick: () => goBackHandler(),
                    }}
                    header={{ text: "Delete Item" }}
                    body={<Text textStyle={TextStyles.text}>Are you sure to delete it?</Text>}
                    deleteHandler={() => goBackHandler()}
                  />
                ) : (
                  <Button {...goBack} onClick={goBackHandler}></Button>
                )}

                <Spacer />
              </>
            )}
            {nextBtn && (
              <Button {...nextBtn} width="60%" isLoading={xLoading} onClick={closeHandler} />
            )}
          </ModalFooter>
        </ModalContent>
      </ChakraModal>
    </>
  );
};

export default Modal;
