import { gql } from "@apollo/client";
import { JobAppStatus } from "../../constants/constants";

export const JOB_APP_EVENT_CALENDAR_DISPLAY_FRAGMENT = gql`
  fragment JobAppEventCalendarDisplayFragment on JobAppEvent {
    _id
    jobAppId
    status
    xCompleted
    title
    time
    duration
  }
`;

export const JOB_APP_EVENT_CORE_FRAGMENT = gql`
  fragment JobAppEventCoreFragment on JobAppEvent {
    _id
    _createdAt
    jobAppId
    status
    xCompleted
    title
    time
    duration
    invitation
  }
`;

export interface JobAppEventUpdateFields {
  xCompleted?: boolean;
  title?: string;
  time?: number;
  invitation?: number;
  duration?: number;
}
export const JOB_APP_EVENT_UPDATE_FIELDS_FRAGMENT = (fields: JobAppEventUpdateFields) => {
  const fieldNames = Object.entries(fields).filter(([key, value]) => value !== undefined);
  const fieldsStr = fieldNames.map(([key, value]) => key).join("\n");
  return gql`
    fragment JobAppEventUpdateFieldsFragment on JobAppEvent {
      ${fieldsStr}
    }
  `;
};
