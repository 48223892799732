import { Box, Flex, Menu, MenuButton, MenuItem, MenuList, Spacer, Text } from "@chakra-ui/react";
import React from "react";
import { ApplyVia } from "../constants/constants";
import { TextStyles } from "../theme/textStyles";
import { ReactUseState } from "../containers/App";
import SVGIcon from "../assets/icons";
import { capitalizeStr } from "../utils";

interface ApplyViaMenuProps {
  selected: ReactUseState<ApplyVia>;
  [key: string]: any;
}

const applyViaMenu: React.FC<ApplyViaMenuProps> = ({ selected, ...props }) => {
  return (
    <Menu matchWidth>
      <MenuButton height="60px" pt="5" maxWidth="14rem" justifyContent="start" {...props}>
        <Flex dir="row" alignItems="end" minWidth="8rem" pl="1rem">
          <Text isTruncated width="100%" textStyle="text" textAlign="left">
            {capitalizeStr(selected.data)}
          </Text>
          <Spacer />
          <Box pt="0.2rem">
            <SVGIcon name="arrow-down-line" size="1.25rem" />
          </Box>
        </Flex>
      </MenuButton>
      <MenuList zIndex="3">
        {Object.keys(ApplyVia).map((item = "", key) => (
          <MenuItem key={key} onClick={() => selected.setData(item as ApplyVia)}>
            <Text noOfLines={1} textStyle={TextStyles.text}>
              {capitalizeStr(item)}
            </Text>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default applyViaMenu;
