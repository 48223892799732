import { Button } from "@chakra-ui/react";
import React from "react";
import SVGIcon, { SVGIconProps } from "../assets/icons";
import { ButtonVariants } from "../theme/components/button";

export interface ButtonIconProps {
  icon: SVGIconProps;
  onClick?(): any;
  [key: string]: any;
}

const ButtonIcon: React.FC<ButtonIconProps> = ({ icon, onClick, ...props }) => {
  return (
    <Button variant={ButtonVariants.icon} onClick={() => (onClick ? onClick() : {})} {...props}>
      <SVGIcon {...icon} />
    </Button>
  );
};

export default ButtonIcon;
