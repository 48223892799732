import React, { useEffect, useState } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { Box, Text, Link, useToast } from "@chakra-ui/react";
import AppRoutes from "../AppRoutes.constant";
import { DocTitleSuffix, ToastStatus } from "../../constants/constants";
import { Helmet } from "react-helmet";
import { LayerStyles } from "../../theme/layerStyles";
import { TextStyles } from "../../theme/textStyles";
import AccountInfo from "../registerSubs/accountInfo";
import PersonalInfo from "../registerSubs/personalInfo";
import StudentVerification from "../registerSubs/studentVerification";
import { useHistory } from "react-router-dom";
import { ApolloCache, useMutation } from "@apollo/client";
import * as UserTypes from "../../graphql/types/user.type";
import UserServices from "../../graphql/services/user.services";
import { loggedInUserVar } from "../../cache/reactiveVars";
import { useLogIn } from "../../hooks/useLogIn";
import { IUser } from "../../schemas/user.schema";
import { OperationStatus } from "../../graphql/types/common.type";
import { ReactUseState } from "../App";

interface RegisterProps {
  xLogin: ReactUseState<boolean>;
}

const Register: React.FC<RegisterProps> = ({ xLogin }) => {
  const history = useHistory();

  const toast = useToast();
  const { login } = useLogIn(loggedInUserVar);
  const [email, setEmail] = useState("");
  const [invitationCode, setInvitationCode] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [step, setStep] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [createUserByRegister] = useMutation<
    UserTypes.CreateUserByRegisterData,
    UserTypes.CreateUserByRegisterArgs
  >(UserServices.CREATE_USER_BY_REGISTER);

  const cache_createUserByRegister = async (
    cache: ApolloCache<any>,
    data: UserTypes.CreateUserByRegisterData | null | undefined
  ) => {
    const userResult = data?.createUserByRegister;
    if ((userResult as IUser)?._id) {
      const newUser = userResult as IUser;
      // cache.writeFragment({
      //   data: newUser,
      //   fragment: USER_CORE_FRAGMENT,
      // });
      await login(newUser);
    } else {
      const newUser: OperationStatus = userResult as OperationStatus;
      toast({
        title: newUser?.errorTitle,
        description: newUser?.errorMessage,
        position: "bottom",
        status: ToastStatus.ERROR,
        variant: "subtle",
        isClosable: true,
      });
    }
  };

  const onSubmit = async () => {
    const createUserByRegisterArgs: UserTypes.CreateUserByRegisterArgs = {
      newUser: {
        email: email,
        firstName: firstName,
        lastName: lastName,
      },
      password: password,
      invitationCode: invitationCode,
    };
    await createUserByRegister({
      variables: createUserByRegisterArgs,
      update(cache, { data }) {
        cache_createUserByRegister(cache, data);
      },
    })
      .then(() => {
        xLogin.setData(true);
        history.push(AppRoutes.profile.route); // TESTING
        // history.push(AppRoutes.myApps.route);
      })
      .catch((error) => {
        toast({
          title: "Failed to create account",
          description: `${error.message}`,
          position: "bottom",
          status: ToastStatus.ERROR,
          isClosable: true,
        });
      });
  };

  const onCreateHandler = () => {
    if (email && password && firstName && lastName) {
      onSubmit();
    } else {
      toast({
        description: "Please enter all required fields",
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
    }
  };

  return (
    <Box layerStyle={LayerStyles.mainPanelChild} mt="3.5rem" maxW="24rem">
      <Helmet>
        <title>
          {AppRoutes.register.title}
          {DocTitleSuffix}
        </title>
      </Helmet>
      <Text as="h1" textStyle={TextStyles.h1}>
        Register
      </Text>
      {step === 1 && (
        <AccountInfo
          email={{ data: email, setData: setEmail }}
          invitationCode={{ data: invitationCode, setData: setInvitationCode }}
          password={{ data: password, setData: setPassword }}
          step={{ data: step, setData: setStep }}
        />
      )}
      {step === 2 && (
        <PersonalInfo
          firstName={{ data: firstName, setData: setFirstName }}
          lastName={{ data: lastName, setData: setLastName }}
          step={{ data: step, setData: setStep }}
        />
      )}
      {step === 3 && (
        <StudentVerification step={{ data: step, setData: setStep }} onSubmit={onCreateHandler} />
      )}
      <Text textStyle={TextStyles.text}>
        Already have an account?&nbsp;
        <Link color="primary.solid" as={ReactRouterLink} to={AppRoutes.login.route}>
          Log In Here
        </Link>
      </Text>
    </Box>
  );
};

export default Register;
