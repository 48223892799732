import { IUser } from "../schemas/user.schema";
import { ReactiveVar } from "@apollo/client";
import { LoggedInUserVarType } from "../cache/reactiveVars";

export function useLogIn(loggedInUserVar: ReactiveVar<LoggedInUserVarType>) {
  const login = (user: IUser): Promise<any> => {
    return new Promise(() => {
      loggedInUserVar(user);
      localStorage.setItem("uid", user._id);
    });
  };

  const logout = (): void => {
    loggedInUserVar(undefined);
  };

  const isLoggedIn = (): boolean => {
    return loggedInUserVar() ? true : false;
  };

  const getUser = (): LoggedInUserVarType => {
    return loggedInUserVar();
  };

  const getUid = (): string | undefined => {
    return loggedInUserVar()?._id;
  };

  const updateUser = () => {};

  return {
    login,
    logout,
    isLoggedIn,
    getUser,
    getUid,
    updateUser,
  };
}
