import React, { useEffect, useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import { LayerStyles } from "../../theme/layerStyles";
import { Helmet } from "react-helmet";
import AppRoutes from "../AppRoutes.constant";
import { DocTitleSuffix } from "../../constants/constants";
import SearchHeaderRow from "../../components/searchHeaderRow";
import { IJobApp } from "../../schemas/app.schema";
import { mockDataLoader } from "../App";
import { useParams } from "react-router-dom";
import PageNotFound from "./pageNotFound";
import * as JobListingTypes from "../../graphql/types/jobListing.type";
import JobListingServices from "../../graphql/services/jobListing.services";
import JobDetailTabs from "./jobDetailTabs";
import { useQuery } from "@apollo/client";
import { IJobListing } from "../../schemas/job.schema";
import JobAppCardDisplay, { JobAppCardShape } from "../adapters/jobApp/jobAppCardDisplay";
import { IMergedJobApp, mergeJobWithApp } from "../../utils/mergedJobApp";

interface JobDetailProps {
  jobAppsByTerm: IJobApp[];
}

const JobDetail: React.FC<JobDetailProps> = ({ jobAppsByTerm }) => {
  const { jobId } = useParams();
  const { data, loading } = useQuery<
    JobListingTypes.GetJobListingByIdData,
    JobListingTypes.GetJobListingByIdArgs
  >(JobListingServices.GET_JOB_LISTING_BY_ID, { variables: { id: jobId } });

  const [job, setJob] = useState<IJobListing | undefined>();
  const [app, setApp] = useState<IJobApp | undefined>();
  const [mergedJobApp, setMergedJobApp] = useState<IMergedJobApp | undefined>(undefined);

  useEffect(() => {
    window.scrollTo(0, 0);
    const currentApp = mockDataLoader.allJobApps.find((app) => app.jobid === jobId);
    setApp(currentApp);
  }, []);

  useEffect(() => {
    if (data?.getJobListingById) {
      const jobObj = data.getJobListingById;
      setJob(jobObj);
      const foundAppIndex = jobAppsByTerm.findIndex((app) => app.job._id === jobObj._id);
      if (foundAppIndex >= 0) {
        setApp(jobAppsByTerm[foundAppIndex]);
      } else {
        setApp(undefined);
      }
    }
  }, [data?.getJobListingById]);

  useEffect(() => {
    if (job) {
      if (app) {
        setMergedJobApp(mergeJobWithApp(app, job));
      } else {
        setMergedJobApp({ job: job });
      }
    }
  }, [job, app]);

  if (!mergedJobApp) {
    return <PageNotFound />;
  }

  if (loading) {
    return <Text>Loading</Text>;
  }

  return (
    <Box layerStyle={LayerStyles.mainPanelChild} pt="0">
      <Helmet>
        <title>
          {AppRoutes.job.title}
          {DocTitleSuffix}
        </title>
      </Helmet>
      <SearchHeaderRow goBack={true} />
      <JobAppCardDisplay
        shape={JobAppCardShape.LONG}
        item={mergedJobApp}
        viewApp={false}
        props={{ marginBottom: "1.5rem" }}
      />
      <JobDetailTabs job={mergedJobApp.job} />
    </Box>
  );
};

export default JobDetail;
