import {
  FormControl,
  Grid,
  GridItem,
  FormLabel,
  Center,
  Divider,
  Flex,
  InputGroup,
  NumberInput,
  NumberInputField,
  FormHelperText,
  FormErrorMessage,
  Text,
} from "@chakra-ui/react";
import { FC, useEffect } from "react";
import { ReactUseState } from "../../containers/App";
import colors from "../../theme/colors";
import { TextStyles } from "../../theme/textStyles";
import ButtonIcon from "../buttonIcon";

export interface VestingSchedulesFormProps {
  schedules: ReactUseState<number[]>;
  isError: ReactUseState<boolean>;
}

const VestingSchedulesForm: FC<VestingSchedulesFormProps> = ({ schedules, isError }) => {
  const updateSchedules = (num: number, key: number) => {
    const updated = schedules.data.map((n, k) => {
      if (k === key) {
        return num;
      } else {
        return n;
      }
    });
    schedules.setData(updated);
  };

  const pushSchedule = () => {
    schedules.setData([...schedules.data, 0]);
  };

  const popSchedule = () => {
    schedules.data.pop();
    schedules.setData([...schedules.data]);
  };

  const sumOfSchedules = () => {
    let sum = 0;
    for (let i = 0; i < schedules.data.length; i++) {
      sum += schedules.data[i];
    }
    return sum;
  };

  useEffect(() => {
    if (sumOfSchedules() == 100.0 || sumOfSchedules() == 0) {
      isError.setData(false);
    } else {
      isError.setData(true);
    }
  });

  const PERSENTAGE_PRECISION = 2;
  const MAX_PERSENTAGE = 100;
  const MIN_NUMBER_INPUT = 0;

  return (
    <FormControl
      maxWidth="30rem"
      mb="0.75rem"
      alignContent="center"
      isInvalid={isError.data}
      mt="-1rem"
    >
      <Grid templateColumns="repeat(7, 1fr)" gap={0} alignContent="center" alignItems="center">
        <GridItem colSpan={2}>
          <FormLabel htmlFor={"vestingSchedule"} display="contents" py="0.5rem">
            <Text textStyle={TextStyles.thin}>Vesting Schedule %</Text>
          </FormLabel>
        </GridItem>
        <GridItem>
          <Center height="15px">
            <Divider orientation="vertical" borderColor="grey.700" />
          </Center>
        </GridItem>
        <GridItem colStart={4} colEnd={8}>
          <Flex width="100%" dir="row" alignItems="center" pt="1rem">
            {schedules.data.map((n, key) => {
              return (
                <InputGroup
                  key={key}
                  p="0.5rem"
                  _hover={{ bg: "grey.200", borderRadius: "0.2rem" }}
                >
                  <NumberInput
                    size="xs"
                    variant="unstyled"
                    value={n ? n : 0}
                    precision={PERSENTAGE_PRECISION}
                    min={MIN_NUMBER_INPUT}
                    max={MAX_PERSENTAGE}
                    onChange={(valueAsString: string, valueAsNumber: number) =>
                      updateSchedules(valueAsNumber, key)
                    }
                    height="18px"
                  >
                    <NumberInputField pr="0.3rem" width="2.8rem" />
                  </NumberInput>
                  {key !== schedules.data.length - 1 && (
                    <Text textStyle={TextStyles.thin} textColor="black">
                      /
                    </Text>
                  )}
                </InputGroup>
              );
            })}
            {schedules.data.length > 2 && (
              <ButtonIcon
                icon={{ name: "substract-circle-line", size: "1.3rem", fill: colors.primary.solid }}
                onClick={() => popSchedule()}
              />
            )}
            {schedules.data.length < 5 && (
              <ButtonIcon
                icon={{ name: "add-circle-line", size: "1.3rem", fill: colors.primary.solid }}
                onClick={() => pushSchedule()}
              />
            )}
          </Flex>
          {!isError.data ? (
            <FormHelperText py="0" my="0" pl="0.3rem" fontSize="12px">
              Enter schedules sum up to 100%
            </FormHelperText>
          ) : (
            <FormErrorMessage py="0" my="0" pl="0.3rem" fontSize="12px">
              Your vesting schedules are not valid
            </FormErrorMessage>
          )}
        </GridItem>
      </Grid>
    </FormControl>
  );
};

export default VestingSchedulesForm;
