import React, { useEffect, useState } from "react";
import { Box, Flex, FormLabel, Spacer, Text, useToast } from "@chakra-ui/react";
import Form, { InputProps } from "../../../components/form";
import StudentVerificationModal from "../../../components/studentVerificationModal";
import { TextStyles } from "../../../theme/textStyles";
import studentVerification from "../../registerSubs/studentVerification";
import Button from "../../../components/button";
import { IStudentVerificaton } from "../../../schemas/user.schema";
import { ToastStatus } from "../../../constants/constants";

interface AccountProfileProps {
  uid: string;
  _firstName: string;
  _lastName: string;
  _username: string;
  _email: string;
  _currentStudentVerfication: IStudentVerificaton | undefined;
}

const AccountProfile: React.FC<AccountProfileProps> = ({
  uid,
  _firstName,
  _lastName,
  _username,
  _email,
  _currentStudentVerfication,
}) => {
  const toast = useToast();

  const [firstName, setFirstName] = useState(_firstName);
  const [lastName, setLastName] = useState(_lastName);
  const [username, setUsername] = useState(_username);
  const [email, setEmail] = useState(_email);
  const [studentVerification, setStudentVerification] = useState(_currentStudentVerfication);
  const [isDirty, setIsDirty] = useState(false);

  const profileInfoInputs: InputProps[] = [
    { label: "First Name", value: { data: firstName, setData: setFirstName }, isRequired: true },
    { label: "Last Name", value: { data: lastName, setData: setLastName }, isRequired: true },
    {
      label: "Email",
      value: { data: email, setData: setEmail },
      isDisabled: true,
      type: "email",
      isRequired: true,
    },
  ];

  useEffect(() => {
    const modified = firstName !== _firstName || lastName !== _lastName;
    setIsDirty(modified);
  }, [firstName, lastName]);

  // ❓ TODO: mutation updateUser

  const updateProfileHandler = () => {
    if (!firstName || !lastName) {
      toast({
        description: "Please filled out the required form",
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
    } else {
      toast({
        description: "Profile updated successfully",
        position: "bottom",
        status: ToastStatus.SUCCESS,
        isClosable: true,
      });
      // profile = { firstName, lastName, email };
      setIsDirty(false);
    }
  };

  return (
    <Box mb="1.5rem">
      <Text as="h2" textStyle={TextStyles.h2} mb="1.5rem">
        Personal Info
      </Text>
      <Form inputs={profileInfoInputs} />
      <Flex direction="row" width="100%" justifyContent="center" mb="1.75rem">
        <FormLabel htmlFor="verify-btn">Student Verification:</FormLabel>
        <Spacer />
        <StudentVerificationModal
          studentVerification={{ data: studentVerification, setData: setStudentVerification }}
        />
      </Flex>
      <Button
        text="Update Profile"
        margin="auto"
        onClick={updateProfileHandler}
        isDisabled={!isDirty}
      />
    </Box>
  );
};

export default AccountProfile;
