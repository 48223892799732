import colors from "../colors";

const Tag = {
  baseStyle: {
    boxShadow: "none",
    borderRadius: "1.2rem",
    borderWidth: "1px",
  },
  variants: {
    primaryLine: {
      borderColor: "primary.solid",
    },
    primaryFill: {
      backgroundColor: "primary.gadient",
    },
    neutral: {
      backgroundColor: colors.neutral[100],
      color: "neutral.300",
    },
    positive: {},
    negative: {},
  },
};

export default Tag;
