import { ISample } from "../../schemas/sample.schema";
import { ReactUseState } from "../../containers/App";
import {
  Box,
  Checkbox,
  Flex,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Spacer,
  Text,
} from "@chakra-ui/react";
import Button from "../button";
import AlertDialog from "../alertDialog";
import { ButtonVariants } from "../../theme/components/button";
import { TextStyles } from "../../theme/textStyles";

interface SampleDetailProps {
  sample: ISample;
  name: ReactUseState<string>;
  age: ReactUseState<number>;
  isStudent: ReactUseState<boolean>;
  isNameDirty: boolean;
  isAgeDirty: boolean;
  isIsStudentDirty: boolean;
  onDeleteHandler(): void;
  onUpdateHandler(): void;
}

const SampleDetail: React.FC<SampleDetailProps> = ({
  sample,
  name,
  age,
  isStudent,
  isNameDirty,
  isAgeDirty,
  isIsStudentDirty,
  onDeleteHandler,
  onUpdateHandler,
}) => {
  return (
    <Box>
      <Flex direction="column">
        <Text mb="1rem">ID: {sample._id}</Text>
        <Spacer />
        <Text mb="1rem">Deleted: {sample._deleted ? "❌Deleted" : "✔Active"}</Text>
        <Spacer />
        <Flex></Flex>
        <Text mb="1rem">Created At: {sample._createdAt}</Text>
        <Spacer />
        <Flex direction="row" justifyContent="center" alignItems="center" mb="1rem">
          <label htmlFor="sample-name">Name:</label>
          <Input
            id="sample-name"
            value={name.data}
            onChange={(e) => name.setData(e.target.value)}
            placeholder="Enter a name"
            ml="1rem"
            maxWidth="15rem"
          />
        </Flex>
        <Spacer />
        <Flex direction="row" justifyContent="center" alignItems="center" mb="1rem">
          <label htmlFor="sample-age">Age: </label>
          <NumberInput
            ml="0.5rem"
            id="sample-age"
            size="sm"
            maxW={100}
            value={age.data}
            onChange={(e) => age.setData(parseInt(e))}
            min={0}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Flex>
        <Spacer />
        <Checkbox
          justifyContent="center"
          mb="2rem"
          isChecked={isStudent.data}
          onChange={(e) => isStudent.setData(e.target.checked)}
        >
          Is Student
        </Checkbox>
      </Flex>
      <Button
        margin="auto"
        text="Update"
        mb="1rem"
        onClick={() => onUpdateHandler()}
        isDisabled={!isNameDirty && !isAgeDirty && !isIsStudentDirty}
      />
      <AlertDialog
        triggerBtnProps={{
          text: "Delete",
          variant: ButtonVariants.alertLineSquare,
          textStyle: TextStyles.thinText,
          px: "0.25rem",
          py: "0.25rem",
          onClick: () => onDeleteHandler(),
          margin: "auto",
        }}
        header={{ text: "Delete Sample" }}
        body={<Text textStyle={TextStyles.text}>Are you sure to delete this sample?</Text>}
        deleteHandler={() => onDeleteHandler()}
      />
    </Box>
  );
};

export default SampleDetail;
