import { DocumentNode, gql } from "@apollo/client";
import { OPERATION_STATUS_FRAGMENT } from "../../fragments/common.fragment";
import { SAMPLE_CORE_FRAGMENT } from "../../fragments/sample.fragment";
import {
  UpdateMutationParamQueryPairProps,
  UpdateMutationHelper,
  UpdateMutationHelperProps,
  UpdateMutationPlaceholderProps,
} from "../serviceHelper";

const CREATE_SAMPLE = gql`
  ${SAMPLE_CORE_FRAGMENT}
  ${OPERATION_STATUS_FRAGMENT}
  mutation CreateSampleMutation($newSample: CreateSampleInput!) {
    createSample(newSample: $newSample) {
      ... on Sample {
        ...SampleCoreFragment
      }
      ... on OperationStatus {
        ...OperationStatusFragment
      }
    }
  }
`;

const UPDATE_SAMPLE_BY_NAME = `
  updateSampleByName(updateSample: $updateSampleByName) {
    ...OperationStatusFragment
  }
`;

const UPDATE_SAMPLE_BY_AGE = `
  updateSampleByAge(updateSample: $updateSampleByAge) {
    ...OperationStatusFragment
  }
`;

const UPDATE_SAMPLE_BY_IS_STUDENT = `
  updateSampleByIsStudent(updateSample: $updateSampleByIsStudent) {
    ...OperationStatusFragment
  }
`;

export interface UpdaterSampleMutationProps {
  name?: boolean;
  age?: boolean;
  isStudent?: boolean;
}

const updateSampleMutationParamQueryPair: UpdateMutationParamQueryPairProps = {
  name: {
    param: `$updateSampleByName: UpdateSampleByNameInput!`,
    query: UPDATE_SAMPLE_BY_NAME,
  },
  age: {
    param: `$updateSampleByAge: UpdateSampleByAgeInput!`,
    query: UPDATE_SAMPLE_BY_AGE,
  },
  isStudent: {
    param: `$updateSampleByIsStudent: UpdateSampleByIsStudentInput!`,
    query: UPDATE_SAMPLE_BY_IS_STUDENT,
  },
};

const UPDATE_SAMPLE = (props: UpdaterSampleMutationProps): DocumentNode => {
  const mutationName = "UpdateSampleMutation";
  const placeholder: UpdateMutationPlaceholderProps = {
    params: `$placeholder: UpdateSampleByNameInput!`,
    queries: `placeholder(placeholder: $placeholder) {_id}`,
  };
  return UpdateMutationHelper(
    mutationName,
    placeholder,
    props as UpdateMutationHelperProps,
    updateSampleMutationParamQueryPair
  );
};

const DELETE_SAMPLE = gql`
  ${OPERATION_STATUS_FRAGMENT}
  mutation DeleteSampleMutation($id: ObjectId!) {
    deleteSample(_id: $id) {
      ...OperationStatusFragment
    }
  }
`;

const SampleMutations = {
  CREATE_SAMPLE,
  UPDATE_SAMPLE,
  DELETE_SAMPLE,
};

export default SampleMutations;
