import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Switch,
  FormControl,
  FormLabel,
  useToast,
  FormHelperText,
  Flex,
} from "@chakra-ui/react";
import LabelInput from "../../components/labelInput";
import Button from "../../components/button";
import { DocTitleSuffix, ToastStatus } from "../../constants/constants";
import { Helmet } from "react-helmet";
import AppRoutes from "../AppRoutes.constant";
import { TextStyles } from "../../theme/textStyles";

const Setting = () => {
  const toast = useToast();
  const [allowSubmit, setAllowSubmit] = useState(false);
  let data = { xAutoArchive: true, days: 30 };
  const [xAutoArchive, setXAutoArchive] = useState(data.xAutoArchive);
  const [days, setDays] = useState(data.days);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (xAutoArchive !== data.xAutoArchive || days !== data.days) {
      setAllowSubmit(true);
    } else {
      setAllowSubmit(false);
    }
  }, [data.xAutoArchive, data.days, xAutoArchive, days]);

  const handleChange = () => {
    // send update request
    toast({
      description: "Setting updated successfully",
      position: "bottom",
      status: ToastStatus.SUCCESS,
      isClosable: true,
    });
    data = { xAutoArchive, days };
    setAllowSubmit(false);
  };
  return (
    <Box>
      <Helmet>
        <title>
          {AppRoutes.setting.title}
          {DocTitleSuffix}
        </title>
      </Helmet>
      <Text as="h1" textStyle={TextStyles.h1}>
        Setting
      </Text>
      <Box layerStyle="mainPanelChild">
        <FormControl display="flex" flexDirection="column" alignItems="left" mb="1rem">
          <Flex direction="row" width="100%">
            <FormLabel htmlFor="auto-archive" mb="0">
              Enable Auto-Archive?
            </FormLabel>
            <Switch
              variant="switch"
              colorScheme="primary"
              id="auto-archive"
              isChecked={xAutoArchive}
              onChange={() => setXAutoArchive(!xAutoArchive)}
            />
          </Flex>
          <FormHelperText>
            Auto-Archive can help you manage stagnated applications by migrating them into Archived
            Section.
          </FormHelperText>
        </FormControl>
        <LabelInput
          label="Days to archive an application"
          input_num={{ value: { data: days, setData: setDays } }}
          isRequired
          isDisabled={!xAutoArchive}
          mb="1rem"
        />
        <Button text="Update Setting" onClick={handleChange} isDisabled={!allowSubmit} />
      </Box>
    </Box>
  );
};

export default Setting;
