import { Badge, Flex, Spacer, Text } from "@chakra-ui/react";
import React from "react";
import SVGIcon from "../../assets/icons";
import Pill, { PillProps, PillStyles } from "../../components/pill";
import { TextStyles } from "../../theme/textStyles";
import { ButtonVariants } from "../../theme/components/button";
import Button from "../button";
import AlertDialog from "../alertDialog";
import JobOfferInput, { JobAppOfferFormProps } from "../jobAppOffer/jobAppOfferForm";
import { ReactUseState } from "../../containers/App";
import { getDaysCountDown } from "../../utils";

export interface JobAppEventHeaderProps {
  title: string;
  xCompleted: boolean;
  time: string;
  _draft: boolean;
}

export const JobAppEventHeader: React.FC<JobAppEventHeaderProps> = ({
  title,
  xCompleted,
  time,
  _draft,
}) => {
  const styling = xCompleted ? { ...PillStyles.positive } : { ...PillStyles.neutral };
  const pillProps: PillProps = {
    text: xCompleted ? "Completed" : "Ongoing",
    textStyle: "pill",
    borderWidth: "0",
    ...styling,
  };
  const daysCountDown: number = getDaysCountDown(time);

  return (
    <Flex direction="row" width="100%" pr="0.5rem" alignItems="center">
      <SVGIcon name="notification-fill" size="1rem" />
      <Text pl="0.75rem" textStyle={TextStyles.h3}>
        {title}
      </Text>
      {!xCompleted && (
        <Text pl="0.5rem" textStyle={TextStyles.thin}>
          in {daysCountDown} day{daysCountDown > 1 ? "s" : ""}
        </Text>
      )}
      {_draft && (
        <Badge variant="outline" colorScheme="green" ml="1rem">
          Draft
        </Badge>
      )}
      <Spacer />
      <Pill {...pillProps} />
    </Flex>
  );
};

export interface JobAppEventPanelProps {
  isDirty: boolean;
  title: ReactUseState<string>;
  time: ReactUseState<string>;
  duration: ReactUseState<number>;
  invitation: ReactUseState<string>;
  xCompleted: ReactUseState<boolean>;
  handlers: {
    delete(): void;
    save(): void;
  };
}

export const JobAppEventPanel: React.FC<JobAppEventPanelProps> = ({
  isDirty,
  title,
  time,
  duration,
  invitation,
  xCompleted,
  handlers,
}) => {
  const inputs: JobAppOfferFormProps[] = [
    {
      input: {
        label: "Title",
        labelDescriptionTooltip: "Event title",
        value: title,
        placeholder: "Enter event title",
        maxLength: 35,
        isRequired: true,
      },
    },
    {
      input: {
        label: "Start Time",
        labelDescriptionTooltip: "Event start time",
        value: time,
        type: "datetime-local",
        isRequired: true,
      },
    },
    {
      input: {
        label: "Duration",
        labelDescriptionTooltip: "Event duration in minutes",
        value: duration,
        type: "number",
        isRequired: true,
      },
      inputRightElement: "mins",
    },
    {
      input: {
        label: "Invitation Date",
        labelDescriptionTooltip: "Date of invitation (optional)",
        value: invitation,
        type: "date",
      },
    },
  ];

  return (
    <Flex direction="column" width="100%">
      <Flex direction="row" mb="0.75rem">
        <Text textStyle={TextStyles.noteSubHeader}>Event Reminder</Text>
        <Spacer />
        <Button
          variant={ButtonVariants.text}
          textStyle={TextStyles.thin}
          textDecoration="underline"
          textAlign="end"
          text={xCompleted ? "Back to Ongoing" : "Complete Event"}
          px="0px"
          py="0px"
          onClick={() => xCompleted.setData(!xCompleted.data)}
        />
      </Flex>
      {inputs.map((input, key) => (
        <JobOfferInput key={key} {...input} />
      ))}
      <Flex direction="row" alignItems="center" justifyContent="end">
        <AlertDialog
          triggerBtnProps={{
            text: "Delete",
            variant: ButtonVariants.alertLineSquare,
            textStyle: TextStyles.thinText,
            px: "0.25rem",
            py: "0.25rem",
            onClick: () => handlers.delete(),
            mr: "1rem",
          }}
          header={{ text: "Delete Event" }}
          body={<Text textStyle={TextStyles.text}>Are you sure to delete this event?</Text>}
          deleteHandler={() => handlers.delete()}
        />
        <Button
          text="Save"
          variant={isDirty ? ButtonVariants.primaryLineSquare : ButtonVariants.greyLineSquare}
          textStyle={TextStyles.thinText}
          px="0.75rem"
          py="0.25rem"
          isDisabled={!isDirty}
          onClick={() => handlers.save()}
        />
      </Flex>
    </Flex>
  );
};
