import JobAppTermServices from "../../../graphql/services/jobAppTerm.services";
import * as JobAppTermTypes from "../../../graphql/types/jobAppTerm.type";
import { IJobAppTerm } from "../../../schemas/user.schema";
import { useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ApolloCache, StoreObject, useMutation } from "@apollo/client";
import { ToastStatus } from "../../../constants/constants";
import JobTermEditModal from "../../../components/jobTermEditModal";
import { JOB_APP_TERM_UPDATE_FIELDS_FRAGMENT } from "../../../graphql/fragments/jobAppTerm.fragment";
import { safe_delete_cached_data } from "../../../cache/helpers";
import { ReactUseState } from "../../App";

interface JobAppTermEditModalProps {
  editingTerm: IJobAppTerm;
  deletable: boolean;
  termTitleMaxLength: number;
  selectedTerm: ReactUseState<IJobAppTerm>;
  selectLatestAvailableTerm(): void;
}

const JobAppTermEditModal: React.FC<JobAppTermEditModalProps> = ({
  editingTerm,
  deletable,
  termTitleMaxLength,
  selectedTerm,
  selectLatestAvailableTerm,
}) => {
  const toast = useToast();

  const [title, setTitle] = useState(editingTerm.title);
  const [xCompleted, setXCompleted] = useState(editingTerm.xCompleted);
  const [isTitleDirty, setIsTitleDirty] = useState(false);
  const [isXCompletedDirty, setIsXCompletedDirty] = useState(false);

  useEffect(() => {
    setIsTitleDirty(title !== editingTerm.title);
  }, [title, editingTerm.title]);

  useEffect(() => {
    setIsXCompletedDirty(xCompleted !== editingTerm.xCompleted);
  }, [xCompleted, editingTerm.xCompleted]);

  type JobAppTermToastType = {
    title?: boolean;
    xCompleted?: boolean;
  };

  const showToast = (jobAppTermToast: JobAppTermToastType) => {
    let error_message = `Failed to update field(s) - `;
    const error_fields: string[] = [];
    const objEntries = Object.entries(jobAppTermToast);
    objEntries.forEach(([key, value]) => {
      if (value === false) {
        error_fields.push(key);
      }
    });
    if (error_fields.length === 0) {
      toast({
        title: `Sample updated successfully`,
        position: "bottom",
        status: ToastStatus.SUCCESS,
        isClosable: true,
      });
    } else {
      error_message += error_fields.join(", ");
      toast({
        title: "Failed to update data",
        description: error_message,
        position: "bottom",
        status: ToastStatus.ERROR,
        variant: "subtle",
        isClosable: true,
      });
    }
  };

  const [updateJobAppTerm] = useMutation<
    JobAppTermTypes.UpdateJobAppTermData,
    JobAppTermTypes.UpdateJobAppTermArgs
  >(JobAppTermServices.UPDATE_JOB_APP_TERM({ title: isTitleDirty, xCompleted: isXCompletedDirty }));

  const [deleteJobAppTerm] = useMutation<
    JobAppTermTypes.DeleteJobAppTermData,
    JobAppTermTypes.DeleteJobAppTermArgs
  >(JobAppTermServices.DELETE_JOB_APP_TERM);

  const cache_updateJobAppTerm = (
    cache: ApolloCache<any>,
    data: JobAppTermTypes.UpdateJobAppTermData | null | undefined
  ) => {
    const updateJobAppTerm: Partial<IJobAppTerm> = {};
    const jobAppTermToast: JobAppTermToastType = {};
    if (isTitleDirty) {
      if (data?.updateJobAppTermByTitle?.ok) {
        updateJobAppTerm.title = title;
        jobAppTermToast.title = true;
      } else {
        jobAppTermToast.title = false;
      }
    }
    if (isXCompletedDirty) {
      if (data?.updateJobAppTermByXCompleted?.ok) {
        updateJobAppTerm.xCompleted = xCompleted;
        jobAppTermToast.xCompleted = true;
      } else {
        jobAppTermToast.xCompleted = false;
      }
    }
    cache.writeFragment({
      id: `JobAppTerm:${editingTerm._id}`,
      fragment: JOB_APP_TERM_UPDATE_FIELDS_FRAGMENT,
      data: {
        title: title,
        xCompleted: xCompleted,
      },
    });
    selectedTerm.setData({ ...selectedTerm.data, ...updateJobAppTerm });
    showToast(jobAppTermToast);
  };

  const onUpdateSubmit = async (): Promise<boolean> => {
    const updateJobAppTermArgs: JobAppTermTypes.UpdateJobAppTermArgs = {};
    if (isTitleDirty) {
      updateJobAppTermArgs.updateJobAppTermByTitle = { _id: editingTerm._id, title: title };
    }
    if (isXCompletedDirty) {
      updateJobAppTermArgs.updateJobAppTermByXCompleted = {
        _id: editingTerm._id,
        xCompleted: xCompleted,
      };
    }
    await updateJobAppTerm({
      variables: updateJobAppTermArgs,
      update(cache, { data }) {
        cache_updateJobAppTerm(cache, data);
      },
    }).catch((error) => {
      toast({
        description: `${error.message}`,
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
      return false;
    });
    return true;
  };

  const updateHandler = async (): Promise<boolean> => {
    if (!title) {
      toast({
        description: "Please enter term title.",
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
      return false;
    }
    return await onUpdateSubmit();
  };

  const cache_deleteJobAppTerm = (
    cache: ApolloCache<any>,
    data: JobAppTermTypes.DeleteJobAppTermData | null | undefined
  ) => {
    const deleted = data?.deleteJobAppTerm;
    if (deleted?.ok === true) {
      cache.modify({
        fields: {
          getAllTermsByUid(existing: StoreObject[] = [], { readField }) {
            return existing.filter((termRef) => editingTerm._id !== readField("_id", termRef));
          },
        },
      });
      safe_delete_cached_data(cache, editingTerm._id, "JobAppTerm");
    }
  };

  const onDelete = async (termId: string): Promise<boolean> => {
    await deleteJobAppTerm({
      variables: { id: termId },
      update(cache, { data }) {
        cache_deleteJobAppTerm(cache, data);
        selectLatestAvailableTerm();
      },
    }).catch((error) => {
      toast({
        title: "Failed to delete term",
        description: error.message,
        position: "bottom",
        status: ToastStatus.ERROR,
        variant: "subtle",
        isClosable: true,
      });
      return false;
    });
    return true;
  };

  const deleteHandler = async (): Promise<boolean> => {
    if (!deletable) {
      toast({
        description: "Unable to delete - There must be at least one job term.",
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
      return false;
    }
    return await onDelete(editingTerm._id);
  };

  return (
    <JobTermEditModal
      title={{ data: title, setData: setTitle }}
      xCompleted={{ data: xCompleted, setData: setXCompleted }}
      deletable={deletable}
      termTitleMaxLength={termTitleMaxLength}
      handlers={{ update: updateHandler, delete: deleteHandler }}
    />
  );
};

export default JobAppTermEditModal;
