import { Box, Text } from "@chakra-ui/react";
import { TextStyles } from "../theme/textStyles";

const Footer = () => {
  const copyright = "Copyright © 2022 Forwardy.app. All Rights Reserved.";

  return (
    <Box textAlign="center">
      <Text textStyle={TextStyles.footer}>{copyright}</Text>
    </Box>
  );
};

export default Footer;
