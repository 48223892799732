import { Badge, Flex, Spacer, Text } from "@chakra-ui/react";
import SVGIcon from "../../assets/icons";
import { JobAppOfferStatus } from "../../constants/constants";
import { TextStyles } from "../../theme/textStyles";
import { capitalizeStr } from "../../utils";
import Pill, { PillStyles, PillProps } from "../pill";

export interface JobAppOfferHeaderProps {
  title: string;
  status?: JobAppOfferStatus;
  _draft: boolean;
}

export const JobAppOfferHeader: React.FC<JobAppOfferHeaderProps> = ({ title, status, _draft }) => {
  const PendingCountDown = () => {
    if (status === JobAppOfferStatus.PENDING) {
      const pendingMessage = `in ${0} days`;
      return (
        <Text pl="0.5rem" textStyle={TextStyles.thin}>
          {pendingMessage}
        </Text>
      );
    } else {
      return <></>;
    }
  };

  const StatusPill = () => {
    if (status) {
      let pillText = capitalizeStr(status);
      let pillStyling = { ...PillStyles.neutral };
      switch (status) {
        case JobAppOfferStatus.ACCEPT:
          pillStyling = { ...PillStyles.positive };
          break;
        case JobAppOfferStatus.DECLINE:
          pillStyling = { ...PillStyles.negative };
          break;
        case JobAppOfferStatus.PENDING:
          pillStyling = { ...PillStyles.neutral };
          break;
        default:
          pillStyling = { ...PillStyles.neutral };
          break;
      }
      const pillProps: PillProps = {
        text: pillText,
        textStyle: "pill",
        borderWidth: "0",
        ...pillStyling,
      };
      return <Pill {...pillProps} />;
    } else {
      return <></>;
    }
  };

  return (
    <Flex direction="row" width="100%" pr="0.5rem" alignItems="center">
      <SVGIcon name="notification-fill" size="1rem" />
      <Text pl="0.75rem" textStyle={TextStyles.h6}>
        {title}
      </Text>
      <PendingCountDown />
      {_draft && (
        <Badge variant="outline" colorScheme="green" ml="1rem">
          Draft
        </Badge>
      )}
      <Spacer />
      <StatusPill />
    </Flex>
  );
};

export default JobAppOfferHeader;
