import React, { useEffect, useState } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { LinkBox, Flex, Image, Spacer, Text, LinkOverlay, Tooltip, Link } from "@chakra-ui/react";
import Pill from "../pill";
import ButtonIcon from "../buttonIcon";
import { PillProps } from "../pill";
import { ICompany, IJobListing, IJobListingLink } from "../../schemas/job.schema";
import SVGIcon from "../../assets/icons";
import colors from "../../theme/colors";
import { ButtonVariants } from "../../theme/components/button";
import StatusDropdown from "../statusDropdown";
import { TextStyles } from "../../theme/textStyles";
import { JobAppStatus } from "../../constants/constants";
import { LayerStyles } from "../../theme/layerStyles";
import LogNewJobModal from "../../containers/adapters/jobListing/logNewJobModal";
import JobTypeBadgeForCard from "./jobTypeBadge";

export interface CardSquareProps {
  jobId: string;
  jobAppId?: string;
  company: ICompany;
  job: IJobListing;
  xMark: boolean;
  viewApp: boolean;
  appStatus?: string;
  pill?: PillProps;
  handlers: {
    setXMark(_id: string): void;
    updateJobAppStatus(_id: string, status: JobAppStatus): void;
  };
  props?: {
    [key: string]: any;
  };
}

const CardSquare: React.FC<CardSquareProps> = ({
  jobId,
  jobAppId,
  company,
  job,
  xMark,
  viewApp,
  appStatus = "",
  pill,
  handlers,
  props,
}) => {
  const [xArchived, setXArchived] = useState(false);
  const [jobLinks, setJobLinks] = useState<Array<IJobListingLink>>(new Array());

  useEffect(() => {
    setJobLinks(job.links.sort((a, b) => (a.freq < b.freq ? 1 : -1)));
  }, [job.links]);

  useEffect(() => {
    setXArchived(appStatus === JobAppStatus.REJECT || appStatus === JobAppStatus.WITHDRAW);
  }, [appStatus]);

  const updateXMark = () => {
    handlers.setXMark(jobId);
  };

  const updateStatus = (status: JobAppStatus) => {
    if (jobAppId) {
      handlers.updateJobAppStatus(jobAppId, status);
    }
  };

  return (
    <LinkBox layerStyle={LayerStyles.cardSquare} {...props}>
      <LinkOverlay as={ReactRouterLink} to={viewApp ? `/app/${jobAppId}` : `/job/${jobId}`} />
      <JobTypeBadgeForCard type={job.type} />
      <Flex direction="row" width="100%" align="center" mb="0.5rem">
        <Image src={company?.logo} alt={company?.name + "-logo"} boxSize={"1.5rem"} mr="0.5rem" />
        <Text textStyle="h5" align="left" maxWidth="100%" isTruncated>
          {company?.name}
        </Text>
        <Spacer />
        <ButtonIcon
          icon={{ name: "bookmark-" + (xMark ? "fill" : "line"), size: "1.5rem" }}
          onClick={() => updateXMark()}
        />
      </Flex>
      <Tooltip label={job.title} aria-label="tooltip-job-title">
        <Text
          textStyle={TextStyles.text}
          mb="0.25rem"
          align="left"
          width="100%"
          height="100%"
          noOfLines={2}
        >
          {job.title}
        </Text>
      </Tooltip>
      <Flex direction="row" width="100%" alignItems="center" mb="0.75rem">
        <SVGIcon name="map-pin" fill={colors.grey[700]} size="0.75rem" />
        <Text textStyle="tiny" mx="0.2rem">
          {!job.locations || job.locations.length === 0
            ? "unknown"
            : job.locations.length === 1
            ? job.locations[0]
            : job.locations[0] + "+"}
        </Text>
        {jobLinks.length === 0 ? (
          <></>
        ) : (
          <Link href={jobLinks[0].link} isExternal>
            <SVGIcon name="external-link-line" fill={colors.grey[700]} size="0.75rem" />
          </Link>
        )}
      </Flex>
      <Flex align-self="flex-end">
        {pill ? <Pill {...pill} /> : <></>}
        <Spacer />
        {jobAppId ? (
          <StatusDropdown
            onStatus={appStatus}
            variant={xArchived ? ButtonVariants.greyFillSquare : ButtonVariants.primaryFillSquare}
            xArchived={xArchived}
            updateStatus={updateStatus}
          />
        ) : (
          <LogNewJobModal
            triggerBtnProps={{
              textStyle: "btn_sm",
              text: "Log",
              zIndex: 1,
            }}
            _company={company}
            _job={job}
          />
        )}
      </Flex>
    </LinkBox>
  );
};

export default CardSquare;
