import React from "react";
import { FormControl, FormLabel, Input, Box, Tooltip } from "@chakra-ui/react";
import { ReactUseState } from "../containers/App";
import { TextStyles } from "../theme/textStyles";

export interface InputProps {
  value: ReactUseState<any>;
  label: string;
  labelDescriptionTooltip?: string;
  placeholder?: string;
  type?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  isInvalid?: boolean;
  maxLength?: number;
  [key: string]: any;
}

export interface InputNullableProps extends InputProps {
  value: ReactUseState<any | undefined>;
}

export interface FormProps {
  inputs: InputProps[];
  [key: string]: any;
}

const Form: React.FC<FormProps> = ({ inputs, ...props }) => {
  return (
    <Box {...props}>
      {inputs.map((input, key) => {
        return (
          <FormControl
            key={key}
            variant="floating"
            isRequired={input?.isRequired}
            isDisabled={input?.isDisabled}
            isInvalid={input?.isInvalid}
            mb="0.5rem"
          >
            <Input
              textStyle={TextStyles.text}
              type={input?.type}
              value={input.value.data}
              placeholder={input?.placeholder}
              isDisabled={input?.isDisabled}
              onChange={(e) => input.value.setData(e.target.value)}
              maxLength={input?.maxLength}
              max={input?.max}
            />
            <Tooltip label={input?.labelDescriptionTooltip}>
              <FormLabel textStyle={TextStyles.thin}>{input.label}</FormLabel>
            </Tooltip>
          </FormControl>
        );
      })}
    </Box>
  );
};

export default Form;
