import React, { useEffect, useState } from "react";
import { Box, Circle, Flex, Text } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { DocTitleSuffix } from "../../constants/constants";
import AppRoutes from "../AppRoutes.constant";
import { LayerStyles } from "../../theme/layerStyles";
import { TextStyles } from "../../theme/textStyles";
import SearchHeaderRow from "../../components/searchHeaderRow";
import { IJobAppTerm } from "../../schemas/user.schema";
import { useLazyQuery } from "@apollo/client";
import JobAppTermServices from "../../graphql/services/jobAppTerm.services";
import ViewJobListingsGrid from "../adapters/jobListing/viewJobListingsGrid";
import { IJobApp } from "../../schemas/app.schema";

interface ExploreProps {
  currentTerm: IJobAppTerm | undefined;
  jobAppsByTerm: IJobApp[];
}

const Explore: React.FC<ExploreProps> = ({ currentTerm, jobAppsByTerm }) => {
  const uid = localStorage.getItem("uid") || "";

  // PLACEHOLDER
  const [getJobs, { client, data }] = useLazyQuery<null, null>(
    JobAppTermServices.GET_ALL_TERMS_BY_UID
  );

  const [nJobs, setNJobs] = useState(0);

  // const options: OptionProps[] = [
  //   // { text: "Marked", checkbox: false },
  //   // { text: "INTERN", checkbox: false },
  //   // { text: "NG", checkbox: false },
  //   // { text: "EXPERIENCED", checkbox: false },
  // ];

  // const [sorting, setSorting] = useState<Sorting>(Sorting.POPULARITY);
  // const [filteredJobsApps, setFilteredJobsApps] = useState(mergedJobsApps);
  // const [_filters, _setFilters] = useState(options);
  // const [filters, setFilters] = useState<Set<JobFilterType>>(new Set());

  // const filterJobsApps = (jobsApps: MergedJobApp[], selectedCheckBox: OptionProps[]) => {
  //   let jobTypeSet = new Set();
  //   selectedCheckBox.map((option) => {
  //     if (option["checkbox"]) {
  //       jobTypeSet.add(option["text"]);
  //     }
  //   });
  //   // if (jobTypeSet.size !== 0 && jobTypeSet.size !== 4) {
  //   //   jobsApps = jobsApps.filter(
  //   //     (item) =>
  //   //       jobTypeSet.has(item.job.type) ||
  //   //       (jobTypeSet.has("Marked") && jobMarks.find((mark) => mark === item.job._id)
  //   //         ? true
  //   //         : false)
  //   //   );
  //   // }
  //   setFilteredJobsApps(jobsApps);
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   filterJobsApps(mergedJobsApps, _filters);
  // }, [_filters]);

  return (
    <Box layerStyle={LayerStyles.mainPanelChild} pt="0">
      <Helmet>
        <title>
          {AppRoutes.explore.title}
          {DocTitleSuffix}
        </title>
      </Helmet>
      <Box>
        <SearchHeaderRow />
        <Text as="h1" textStyle={TextStyles.h1} mb="0.75rem">
          Explore New Jobs
        </Text>
        <Text textStyle={TextStyles.text} color="grey.700" mb="0.6rem">
          Browse {nJobs} active job{nJobs > 1 ? "s" : ""}
        </Text>
        {currentTerm && (
          <Flex dir="row" align="center" justifyContent="center" mb="1rem">
            <Text textStyle={TextStyles.thin}>Viewing</Text>
            <Circle size="0.4rem" bg={currentTerm.xCompleted ? "grey" : "active"} mx="0.4rem" />
            <Text textStyle={TextStyles.thin}>{currentTerm.title}</Text>
          </Flex>
        )}
        {/* <Flex dir="row" alignItems="center" mb="1.75rem" px="0.5rem">
          <SortJobListingMenu sorting={{ data: sorting, setData: setSorting }} />
          <Spacer />
          <FilterJobListingMenu filters={{ data: filters, setData: setFilters }} />
        </Flex> */}
        <ViewJobListingsGrid
          nJobs={{ data: nJobs, setData: setNJobs }}
          jobAppsByTerm={jobAppsByTerm}
        />
      </Box>
    </Box>
  );
};

export default Explore;
