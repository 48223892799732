import React, { useEffect, useState } from "react";
import { JobAppEventHeader, JobAppEventPanel } from "../../../components/eventNote/jobAppEvent";
import { IJobAppEvent } from "../../../schemas/app.schema";
import AccordionItem from "../../../components/accordion/accordionItem";
import JobAppEventServices from "../../../graphql/services/jobAppEvent.services";
import * as JobAppEventTypes from "../../../graphql/types/jobAppEvent.type";
import { ApolloCache, StoreObject, useMutation } from "@apollo/client";
import { useToast } from "@chakra-ui/react";
import { ToastStatus } from "../../../constants/constants";
import { JobAppEventToastType, showToast } from "../../../utils/updateHelper";
import {
  JobAppEventUpdateFields,
  JOB_APP_EVENT_UPDATE_FIELDS_FRAGMENT,
} from "../../../graphql/fragments/jobAppEvent.fragment";
import { safe_delete_cached_data } from "../../../cache/helpers";

interface ViewEventDetailProps {
  _id: string;
  _title: string;
  _time: string;
  _duration: number;
  _invitation: string;
  _xCompleted: boolean;
  event: IJobAppEvent;
  itemProps?: any;
  [key: string]: any;
}

const ViewEventDetail: React.FC<ViewEventDetailProps> = ({
  _id,
  _title,
  _time,
  _duration,
  _invitation,
  _xCompleted,
  event,
  itemProps,
  ...props
}) => {
  const toast = useToast();

  const [title, setTitle] = useState<string>(_title);
  const [time, setTime] = useState<string>(_time);
  const [duration, setDuration] = useState<number>(_duration);
  const [invitation, setInvitation] = useState<string>(_invitation);
  const [xCompleted, setXCompleted] = useState<boolean>(_xCompleted);

  const [isDirty, setIsDirty] = useState(false);
  const [isXCompletedDirty, setIsXCompletedDirty] = useState(false);
  const [isTitleDirty, setIsTitleDirty] = useState(false);
  const [isTimeDirty, setIsTimeDirty] = useState(false);
  const [isDurationDirty, setIsDurationDirty] = useState(false);
  const [isInvitationDirty, setIsInvitationDirty] = useState(false);

  useEffect(() => {
    setIsXCompletedDirty(xCompleted !== _xCompleted);
  }, [_xCompleted, xCompleted]);

  useEffect(() => {
    setIsTitleDirty(title !== _title);
  }, [_title, title]);

  useEffect(() => {
    setIsTimeDirty(time !== _time);
  }, [_time, time]);

  useEffect(() => {
    setIsDurationDirty(duration !== _duration);
  }, [_duration, duration]);

  useEffect(() => {
    setIsInvitationDirty(invitation !== _invitation);
  }, [_invitation, invitation]);

  useEffect(() => {
    setIsDirty(
      isXCompletedDirty || isTitleDirty || isTimeDirty || isDurationDirty || isInvitationDirty
    );
  }, [isXCompletedDirty, isTitleDirty, isTimeDirty, isDurationDirty, isInvitationDirty]);

  const [updateJobAppEvent] = useMutation<
    JobAppEventTypes.UpdateJobAppEventData,
    JobAppEventTypes.UpdateJobAppEventArgs
  >(
    JobAppEventServices.UPDATE_JOB_APP_EVENT({
      xCompleted: isXCompletedDirty,
      title: isTitleDirty,
      time: isTimeDirty,
      duration: isDurationDirty,
      invitation: isInvitationDirty,
    })
  );

  const [deleteJobAppEvent] = useMutation<
    JobAppEventTypes.DeleteJobAppEventData,
    JobAppEventTypes.DeleteJobAppEventArgs
  >(JobAppEventServices.DELETE_JOB_APP_EVENT);

  const clearState = () => {
    setIsXCompletedDirty(false);
    setIsTitleDirty(false);
    setIsTimeDirty(false);
    setIsDurationDirty(false);
    setIsInvitationDirty(false);
    setIsDirty(false);
  };

  const cache_updateJobAppEvent = (
    cache: ApolloCache<any>,
    data: JobAppEventTypes.UpdateJobAppEventData | null | undefined
  ) => {
    const updateData: JobAppEventUpdateFields = {};
    const entityToast: JobAppEventToastType = {};
    if (isXCompletedDirty) {
      if (data?.updateJobAppEventByXCompleted?.ok) {
        updateData.xCompleted = xCompleted;
        entityToast.xCompleted = true;
      } else {
        entityToast.xCompleted = false;
      }
    }
    if (isTitleDirty) {
      if (data?.updateJobAppEventByTitle?.ok) {
        updateData.title = title;
        entityToast.title = true;
      } else {
        entityToast.title = false;
      }
    }
    if (isTimeDirty) {
      if (data?.updateJobAppEventByTime?.ok) {
        updateData.time = new Date(time).valueOf();
        entityToast.time = true;
      } else {
        entityToast.time = false;
      }
    }
    if (isDurationDirty) {
      if (data?.updateJobAppEventByDuration?.ok) {
        updateData.duration = duration;
        entityToast.duration = true;
      } else {
        entityToast.duration = false;
      }
    }
    if (isInvitationDirty) {
      if (data?.updateJobAppEventByInvitation?.ok) {
        updateData.invitation = new Date(invitation).valueOf();
        entityToast.invitation = true;
      } else {
        entityToast.invitation = false;
      }
    }
    // update cache for the updated job app event
    if (Object.entries(updateData).length > 0) {
      cache.writeFragment({
        id: `JobAppEvent:${_id}`,
        fragment: JOB_APP_EVENT_UPDATE_FIELDS_FRAGMENT(updateData),
        data: {
          ...updateData,
        },
      });
    }
    // show error or success toast to indicate operation status
    showToast("Event", entityToast, toast);
  };

  const cache_deleteJobAppEvent = (
    cache: ApolloCache<any>,
    data: JobAppEventTypes.DeleteJobAppEventData | null | undefined
  ) => {
    const result = data?.deleteJobAppEvent;
    if (result?.ok === true) {
      cache.modify({
        fields: {
          getJobAppEventsByJobAppId(existing: StoreObject[] = [], { readField }) {
            return existing.filter((eventRef) => _id !== readField("_id", eventRef));
          },
        },
      });
      // delete entire object
      safe_delete_cached_data(cache, _id, "JobAppEvent");
    }
  };

  const onSave = async () => {
    const updateArgs: JobAppEventTypes.UpdateJobAppEventArgs = {};
    if (isXCompletedDirty) {
      updateArgs.updateJobAppEventByXCompleted = {
        _id: _id,
        xCompleted: xCompleted,
      };
    }
    if (isTitleDirty) {
      updateArgs.updateJobAppEventByTitle = {
        _id: _id,
        title: title,
      };
    }
    if (isDurationDirty) {
      updateArgs.updateJobAppEventByDuration = {
        _id: _id,
        duration: duration,
      };
    }
    if (isTimeDirty) {
      updateArgs.updateJobAppEventByTime = {
        _id: _id,
        time: new Date(time).valueOf(),
      };
    }
    if (isInvitationDirty) {
      updateArgs.updateJobAppEventByInvitation = {
        _id: _id,
        invitation: new Date(invitation).valueOf(),
      };
    }
    await updateJobAppEvent({
      variables: updateArgs,
      update(cache, { data }) {
        cache_updateJobAppEvent(cache, data);
        clearState();
      },
    }).catch((error) => {
      toast({
        description: `${error.message}`,
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
    });
  };

  const onDelete = async () => {
    await deleteJobAppEvent({
      variables: { id: _id },
      update(cache, { data }) {
        cache_deleteJobAppEvent(cache, data);
      },
    }).catch((error) => {
      toast({
        description: `${error.message}`,
        position: "bottom",
        status: ToastStatus.ERROR,
        isClosable: true,
      });
    });
  };

  return (
    <AccordionItem
      header={
        <JobAppEventHeader title={title} xCompleted={xCompleted} time={time} _draft={isDirty} />
      }
      panel={
        <JobAppEventPanel
          isDirty={isDirty}
          title={{ data: title, setData: setTitle }}
          time={{ data: time, setData: setTime }}
          duration={{ data: duration, setData: setDuration }}
          invitation={{ data: invitation, setData: setInvitation }}
          xCompleted={{ data: xCompleted, setData: setXCompleted }}
          handlers={{ delete: onDelete, save: onSave }}
        />
      }
      itemProps={itemProps}
      {...props}
    />
  );
};

export default ViewEventDetail;
