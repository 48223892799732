import React, { useEffect, useState } from "react";
import { Image, Spacer, Text, LinkBox } from "@chakra-ui/react";
import { LayerStyles } from "../../theme/layerStyles";
import { Link as ReactRouterLink } from "react-router-dom";
import { TextStyles } from "../../theme/textStyles";
import { getActiveJobs } from "../../utils";

export interface CardSmallProps {
  _id: string;
  title: string;
  image: string;
  numberOfJobs: number;
  selectMode: boolean;
  selected?: boolean;
  onClick(_id: string): any;
}

const CardSmall: React.FC<CardSmallProps> = ({
  _id,
  title,
  image,
  numberOfJobs,
  selectMode = false,
  selected = false,
  onClick,
  ...props
}) => {
  const [linkProps, setLinkProps] = useState({});
  useEffect(() => {
    if (!selectMode) {
      setLinkProps({
        ...linkProps,
        as: ReactRouterLink,
        to: `/company/${_id}`,
      });
    } else {
      setLinkProps({});
    }
  }, [selectMode]);

  return (
    <LinkBox
      layerStyle={LayerStyles.cardSmall}
      borderColor={selected ? "primary.solid" : "transparent"}
      boxShadow={selected ? "none" : "0 2px 3px 0 rgba(0, 0, 0, 0.2)"}
      onClick={() => onClick(_id)}
      {...linkProps}
      {...props}
    >
      <Image
        boxSize="1.75rem"
        src={image}
        alt={title + "-logo"}
        mb="0.5rem"
        borderRadius="0.25rem"
      />
      <Spacer />
      <Text textStyle={TextStyles.h6} mb="0.25rem" noOfLines={1}>
        {title}
      </Text>
      <Spacer />
      <Text textStyle={TextStyles.tiny}>{getActiveJobs(numberOfJobs)}</Text>
    </LinkBox>
  );
};

export default CardSmall;
