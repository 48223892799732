import React, { useEffect } from "react";
import { Flex, Text } from "@chakra-ui/react";
import DotDivider from "../../components/dotDivider";
import Button from "../../components/button";
import { useHistory } from "react-router-dom";
import AppRoutes from "../AppRoutes.constant";
import { DocTitleSuffix } from "../../constants/constants";
import { Helmet } from "react-helmet";

const PageNotFound = () => {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {AppRoutes.about.title}
          {DocTitleSuffix}
        </title>
      </Helmet>
      <Flex direction="column" alignItems="center">
        <Text as="h1" fontSize="80px" fontWeight="600">
          404
        </Text>
        <Text as="h2" fontSize="32px" fontWeight="600">
          Opps...
        </Text>
        <Text as="h2" fontSize="32px" fontWeight="600">
          You've entered a mysterious land 🏝️
        </Text>
        <DotDivider />
        <Button
          // onClick={history.goBack()}
          onClick={() => history.push(AppRoutes.home.route)}
          variant="primaryLine"
          textStyle="primaryLineBtn"
          borderWidth="4px"
          text="Explore New Jobs"
          sx={{ fontSize: "26px", marginBottom: "3rem" }}
        ></Button>
      </Flex>
    </>
  );
};

export default PageNotFound;
