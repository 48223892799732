import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import { JobAppOfferStatus, JobAppStatus, PayBasis } from "../../../constants/constants";
import AppliedTab, { AppliedTabProps } from "./appliedTab";
import ViewJobAppOffers from "../jobAppOffer/viewJobAppOffers";
import EventNoteTab from "../jobAppEvent/eventNoteTab";
import ProgressBar from "../../../components/progressBar";
import { IJobAppEvent, IJobAppOffer, IJobApptatusHistory } from "../../../schemas/app.schema";
import * as JobAppOfferTypes from "../../../graphql/types/jobAppOffer.type";
import * as JobAppEventTypes from "../../../graphql/types/jobAppEvent.type";
import { useQuery } from "@apollo/client";
import JobAppEventServices from "../../../graphql/services/jobAppEvent.services";
import JobAppOfferServices from "../../../graphql/services/jobAppOffer.services";
import { ReactUseState } from "../../App";
import { getUpcomingEventReminder, IReminder } from "../../../utils/jobApp";

export type StageProps = {
  index: number;
  title: JobAppStatus;
  date?: Date;
  noOfEvents: number;
};

interface JobAppDetailTabsProps {
  appId: string;
  status: JobAppStatus;
  statusHistory: IJobApptatusHistory;
  appliedTab: AppliedTabProps;
  upcomingReminder: ReactUseState<IReminder | undefined>;
}

const JobAppDetailTabs: React.FC<JobAppDetailTabsProps> = ({
  appId,
  status,
  statusHistory,
  appliedTab,
  upcomingReminder,
}) => {
  const default_stages: StageProps[] = [
    { index: 0, title: JobAppStatus.APPLIED, noOfEvents: 0 },
    { index: 1, title: JobAppStatus.ASSESSMENT, noOfEvents: 0 },
    { index: 2, title: JobAppStatus.INTERVIEW, noOfEvents: 0 },
    { index: 3, title: JobAppStatus.OFFER, noOfEvents: 0 },
  ];

  const { data: eventsData } = useQuery<
    JobAppEventTypes.GetJobAppEventsByJobAppIdData,
    JobAppEventTypes.GetJobAppEventsByJobAppIdArgs
  >(JobAppEventServices.GET_JOB_APP_EVENTS_JOB_APP_ID, { variables: { jobAppId: appId } });
  const { data: offersData } = useQuery<
    JobAppOfferTypes.GetJobAppOffersByJobAppIdData,
    JobAppOfferTypes.GetJobAppOffersByJobAppIdArgs
  >(JobAppOfferServices.GET_JOB_APP_OFFERS_JOB_APP_ID, { variables: { jobAppId: appId } });

  const [events, setEvents] = useState<IJobAppEvent[]>([]);
  const [assessmentEvents, setAssessmentEvents] = useState<IJobAppEvent[]>([]);
  const [interviewEvents, setInterviewEvents] = useState<IJobAppEvent[]>([]);
  const [offers, setOffers] = useState<IJobAppOffer[]>([]);

  const [openStage, setOpenStage] = useState<JobAppStatus>(status);
  const [stages, setStages] = useState<StageProps[]>(default_stages);

  useEffect(() => {
    if (eventsData?.getJobAppEventsByJobAppId) {
      const events = eventsData.getJobAppEventsByJobAppId;
      setEvents(events);
      setAssessmentEvents(events.filter((event) => event.status === JobAppStatus.ASSESSMENT));
      setInterviewEvents(events.filter((event) => event.status === JobAppStatus.INTERVIEW));
    }
  }, [eventsData?.getJobAppEventsByJobAppId]);

  useEffect(() => {
    if (offersData?.getJobAppOffersByJobAppId) {
      setOffers(offersData.getJobAppOffersByJobAppId);
    }
  }, [offersData?.getJobAppOffersByJobAppId]);

  useEffect(() => {
    upcomingReminder.setData(getUpcomingEventReminder(events, offers));
  }, [events, offers]);

  const updateStagePropsByTitle = (title: JobAppStatus, noOfEvents?: number, date?: number) => {
    const newStages = stages.map((stage) => {
      if (stage.title === title) {
        if (noOfEvents) {
          stage.noOfEvents = noOfEvents;
        }
        if (date) {
          stage.date = new Date(date);
        }
      }
      return stage;
    });
    setStages(newStages);
  };

  useEffect(() => {
    updateStagePropsByTitle(JobAppStatus.APPLIED, undefined, statusHistory.applied);
  }, [statusHistory.applied]);

  useEffect(() => {
    updateStagePropsByTitle(
      JobAppStatus.ASSESSMENT,
      assessmentEvents.length,
      statusHistory.assessment
    );
  }, [statusHistory.assessment, assessmentEvents]);

  useEffect(() => {
    updateStagePropsByTitle(
      JobAppStatus.INTERVIEW,
      interviewEvents.length,
      statusHistory.interview
    );
  }, [statusHistory.interview, interviewEvents]);

  useEffect(() => {
    updateStagePropsByTitle(JobAppStatus.OFFER, offers.length, statusHistory.offer);
  }, [statusHistory.offer, offers]);

  const switchTab = (openStage: JobAppStatus) => {
    switch (openStage) {
      case JobAppStatus.APPLIED:
        return <AppliedTab {...appliedTab} />;
      case JobAppStatus.ASSESSMENT:
        return (
          <EventNoteTab
            appId={appId}
            stage={JobAppStatus.ASSESSMENT}
            events={{ data: assessmentEvents, setData: setAssessmentEvents }}
          />
        );
      case JobAppStatus.INTERVIEW:
        return (
          <EventNoteTab
            appId={appId}
            stage={JobAppStatus.INTERVIEW}
            events={{ data: interviewEvents, setData: setInterviewEvents }}
          />
        );
      case JobAppStatus.OFFER:
        return <ViewJobAppOffers appId={appId} offers={{ data: offers, setData: setOffers }} />;
      default:
        return <></>;
    }
  };

  return (
    <Box px="3rem">
      <ProgressBar
        stages={stages}
        onStage={status}
        openStageTab={{ data: openStage, setData: setOpenStage }}
      />
      {switchTab(openStage)}
    </Box>
  );
};

export default JobAppDetailTabs;
