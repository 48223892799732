import { useQuery } from "@apollo/client";
import { Box, CircularProgress, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { ToastStatus } from "../../../constants/constants";
import SampleServices from "../../../graphql/services/sample.service";
import * as SampleTypes from "../../../graphql/types/sample.type";
import Samples from "../../../components/sample/samplesDisplay";

const ViewSamples = () => {
  const LIMIT_PER_QUERY = 3;
  const toast = useToast();

  const { data, loading, error, fetchMore } = useQuery<
    SampleTypes.GetSamplesData,
    SampleTypes.GetSamplesArgs
  >(SampleServices.GET_SAMPLES, { variables: { offset: 0, limit: LIMIT_PER_QUERY } });

  const [samplesLength, setSamplesLength] = useState(data?.getSamples?.length || 0);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const hasMoreHandler = (resArrayLength: number = 0) => {
    setHasMore(resArrayLength === LIMIT_PER_QUERY);
    setSamplesLength(samplesLength + resArrayLength);
  };

  const fetchMoreHandler = () => {
    fetchMore({
      variables: {
        offset: data?.getSamples?.length,
      },
    }).then((res) => {
      hasMoreHandler(res.data.getSamples.length);
    });
  };

  if (loading) {
    return (
      <Box mb="1rem">
        <Box alignItems="center" mb="1rem">
          <CircularProgress isIndeterminate color="green.300" />
        </Box>
      </Box>
    );
  }

  const DataNotFound = () => {
    return <Box>Data not found</Box>;
  };

  if (error) {
    toast({
      title: "Failed to load samples data",
      description: error.message,
      position: "bottom",
      status: ToastStatus.ERROR,
      isClosable: true,
    });
    return <DataNotFound />;
  }

  return (
    <Box mb="1rem">
      <Box alignItems="center" mb="1rem">
        {data?.getSamples ? (
          <Samples
            samples={data.getSamples}
            hasMore={hasMore}
            fetchMoreHandler={fetchMoreHandler}
          />
        ) : (
          <DataNotFound />
        )}
      </Box>
    </Box>
  );
};

export default ViewSamples;
