import React, { useEffect, useState } from "react";
import { Box, useToast, Text } from "@chakra-ui/react";
import Form, { InputProps } from "../../../components/form";
import Button from "../../../components/button";
import { TextStyles } from "../../../theme/textStyles";
import UserServices from "../../../graphql/services/user.services";
import * as UserTypes from "../../../graphql/types/user.type";
import { useMutation } from "@apollo/client";
import { ToastStatus } from "../../../constants/constants";
interface AccountCredentialsProps {
  uid: string;
  _email: string;
}

const AccountCredentials: React.FC<AccountCredentialsProps> = ({ uid, _email }) => {
  const toast = useToast();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isPwDirty, setIsPwDirty] = useState(false);

  const [updateUserCredentialByPassword] = useMutation<
    UserTypes.UpdateUserCredentialByPasswordData,
    UserTypes.UpdateUserCredentialByPasswordArgs
  >(UserServices.UPDATE_USER_CREDENTIAL_BY_PASSWORD);

  const credentialInfo: InputProps[] = [
    {
      label: "Current Password",
      value: { data: currentPassword, setData: setCurrentPassword },
      type: "password",
    },
    {
      label: "New Password",
      value: { data: newPassword, setData: setNewPassword },
      type: "password",
    },
  ];

  useEffect(() => {
    const modified: boolean = currentPassword && newPassword ? true : false;
    setIsPwDirty(modified);
  }, [currentPassword, newPassword]);

  const onUpdateSubmit = async () => {
    const updateUserCredentialByPasswordArgs: UserTypes.UpdateUserCredentialByPasswordArgs = {
      oldCredential: { email: _email, password: currentPassword },
      newPassword: newPassword,
    };
    await updateUserCredentialByPassword({
      variables: updateUserCredentialByPasswordArgs,
    })
      .then(({ data }) => {
        if (!data?.updateUserCredentialByPassword.ok) {
          toast({
            description: `Incorrect Password`,
            position: "bottom",
            status: ToastStatus.ERROR,
            isClosable: true,
          });
          return;
        }
        toast({
          description: `Password Updated Successfully`,
          position: "bottom",
          status: ToastStatus.INFO,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          description: `${error.message}`,
          position: "bottom",
          status: ToastStatus.ERROR,
          isClosable: true,
        });
      });
  };

  const clearInputs = () => {
    setCurrentPassword("");
    setNewPassword("");
    setIsPwDirty(false);
  };

  const updatePasswordHandler = async () => {
    await onUpdateSubmit();
    clearInputs();
  };

  return (
    <Box>
      <Text as="h2" textStyle={TextStyles.h2} mb="1.5rem">
        Credential
      </Text>
      <Form inputs={credentialInfo} />
      <Button
        margin="auto"
        text="Update Password"
        onClick={updatePasswordHandler}
        isDisabled={!isPwDirty}
        mt="1.75rem"
      />
    </Box>
  );
};

export default AccountCredentials;
