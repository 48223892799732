import React, { useEffect, useState } from "react";
import { Box, Img, Input, InputProps, List, ListItem, Spacer, Text } from "@chakra-ui/react";
import { matchSorter } from "match-sorter";
import { TextStyles } from "../theme/textStyles";
import { ReactUseState } from "../containers/App";
import SVGIcon from "../assets/icons";

export type AutoCompleteOption<T> = {
  label: string;
  value: T;
  rightImage?: {
    src: string;
    alt: string;
    size?: string;
    fallbackSrc?: string;
    [key: string]: any;
  };
  [key: string]: any;
};

export interface AutoCompleteProps<T> extends InputProps {
  options: AutoCompleteOption<T>[]; // Options to be displayed in the autocomplete
  data: ReactUseState<T | undefined>;
  inputFieldValue: string;
  placeholder?: string;
  bgHoverColor?: string;
  allowCreation?: boolean;
  notFoundText?: string;
  addNewText?: string;
}

const AutoComplete = <T extends unknown>({
  options,
  data,
  inputFieldValue,
  bgHoverColor,
  allowCreation = true,
  notFoundText = "Not found",
  addNewText,
  ...props
}: AutoCompleteProps<T>) => {
  const [inputValue, setInputValue] = useState<string>(inputFieldValue);
  const [optionsCopy, setOptionsCopy] = useState<AutoCompleteOption<T>[]>(options);
  const [partialResults, setPartialResults] = useState<AutoCompleteOption<T>[]>();
  const [displayOptions, setDisplayOptions] = useState<boolean>(false);

  useEffect(() => {
    if (inputValue !== inputFieldValue) {
      filterOptions(inputValue);
      autoMatchOption(inputValue);
    }
  }, [inputValue]);

  useEffect(() => {
    if (inputFieldValue !== undefined) {
      setInputValue(inputFieldValue);
    }
  }, [inputFieldValue]);

  const filterOptions = (inputValue: string) => {
    setPartialResults(matchSorter(optionsCopy, inputValue, { keys: ["label", "value"] }));
    setDisplayOptions(true);
    // } else {
    //   setPartialResult([]);
    //   setDisplayOptions(false);
    // }
    // data.setData(inputValue);
  };

  const autoMatchOption = (inputValue: string) => {
    const matched = partialResults?.find(
      (op) => op.label.toLowerCase() === inputValue?.toLowerCase()
    );
    if (matched) {
      data.setData(matched.value);
    } else {
      data.setData(undefined);
    }
  };

  const selectOption = (option: AutoCompleteOption<T>) => {
    data.setData(option.value);
  };

  const selectOptionFromList = (option: AutoCompleteOption<T>) => {
    selectOption(option);
    setDisplayOptions(false);
  };

  const createNew = () => {
    // TODO
    setDisplayOptions(false);
  };

  return (
    <Box>
      <Input value={inputValue} onChange={(e) => setInputValue(e.currentTarget.value)} {...props} />
      {displayOptions && (
        <List
          position="absolute"
          borderWidth="1px"
          borderColor="gray.200"
          borderRadius="md"
          py="0.5rem"
          px="0.5rem"
          width="100%"
          justifyContent="start"
          backgroundColor="white"
          zIndex={3}
        >
          {partialResults?.map((option, key) => {
            return (
              <ListItem
                key={key}
                display="flex"
                alignItems="center"
                _hover={{ bg: bgHoverColor || "grey.100" }}
                textStyle={TextStyles.text}
                cursor="pointer"
                my={1}
                px={2}
                py={1}
                onClick={() => selectOptionFromList(option)}
              >
                <Text> {option.label}</Text>
                {option?.rightImage && (
                  <>
                    <Spacer />
                    <Img
                      alt={option.rightImage.alt}
                      src={option.rightImage.src}
                      // fallbackSrc={rightImage?.fallbackSrc || "https://via.placeholder.com/150"}
                      boxSize={option.rightImage?.size || "1.5rem"}
                    />
                  </>
                )}
              </ListItem>
            );
          })}
          {addNewText && (
            <ListItem
              _hover={{ bg: bgHoverColor || "grey.100" }}
              display="flex"
              flexDirection="row"
              alignItems="center"
              cursor="pointer"
              my={1}
              px={2}
              py={1}
              onClick={() => createNew()}
              color="primary.solid"
            >
              <SVGIcon name="add-line" size="0.7rem" />
              <Text textStyle={TextStyles.text} ml="0.5rem">
                {addNewText}
              </Text>
            </ListItem>
          )}
        </List>
      )}
    </Box>
  );
};

export default AutoComplete;
