import { gql } from "@apollo/client";

export const JOB_APP_STATUS_HISTORY_CORE_FRAGMENT = gql`
  fragment JobAppStatusHistoryCoreFragment on JobAppStatusHistory {
    applied
    assessment
    interview
    offer
    withdraw
    reject
  }
`;

export type JobAppStatusHistoryField =
  | "applied"
  | "assessment"
  | "interview"
  | "offer"
  | "withdraw"
  | "reject";
export const JOB_APP_STATUS_HISTORY_UPDATE_FIELD_FRAGMENT = (
  field: JobAppStatusHistoryField
) => gql`
  fragment JobAppStatusHistoryUpdateFieldFragment on JobApp {
    statusHistory {
      ${field}
    }
  }
`;
