import React, { useEffect, useState } from "react";
import { Flex, Image, Text, Spacer, LinkOverlay, LinkBox } from "@chakra-ui/react";
import SVGIcon from "../../assets/icons";
import { TextStyles } from "../../theme/textStyles";
import { JobAppStatus } from "../../constants/constants";
import { ButtonVariants } from "../../theme/components/button";
import { ICompany, IJobListing } from "../../schemas/job.schema";
import ButtonIcon from "../buttonIcon";
import colors from "../../theme/colors";
import { IJobAppEvent } from "../../schemas/app.schema";
import { LayerStyles } from "../../theme/layerStyles";
import Button from "../button";
import { Link as ReactRouterLink } from "react-router-dom";
import LogNewJobModal from "../../containers/adapters/jobListing/logNewJobModal";
import { IReminder } from "../../utils/jobApp";

export interface CardLongProps {
  jobId: string;
  jobAppId?: string;
  company: ICompany;
  job: IJobListing;
  xMark: boolean;
  viewApp: boolean;
  appStatus?: string;
  event?: IJobAppEvent;
  upcomingReminder?: IReminder;
  handlers: {
    setXMark(_id: string): void;
    updateJobAppStatus(_id: string, status: JobAppStatus): void;
  };
  props?: {
    [key: string]: any;
  };
}

const CardLong: React.FC<CardLongProps> = ({
  jobId,
  jobAppId,
  job,
  company,
  xMark,
  viewApp,
  appStatus = "",
  upcomingReminder,
  handlers,
  props,
}) => {
  const [xArchived, setXArchived] = useState(false);
  const reminderDate = new Date(upcomingReminder ? upcomingReminder.time : "");
  const reminderTimeStr = `${reminderDate
    .toDateString()
    .slice(0, -4)} \xa0|\xa0 ${reminderDate.toLocaleTimeString("default", {
    timeZoneName: "short",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    hour12: true,
    hour: "2-digit",
    minute: "2-digit",
  })}`;

  useEffect(() => {
    setXArchived(appStatus === JobAppStatus.REJECT || appStatus === JobAppStatus.WITHDRAW);
  }, [appStatus]);

  const updateXMark = () => {
    handlers.setXMark(jobId);
  };

  const updateStatus = (status: JobAppStatus) => {
    if (jobAppId) {
      handlers.updateJobAppStatus(jobAppId, status);
    }
  };

  return (
    <LinkBox
      layerStyle={LayerStyles.cardLong}
      _hover={
        jobAppId
          ? {
              borderColor: "primary.solid",
              boxShadow: "none",
            }
          : {}
      }
      {...props}
    >
      {jobAppId && (
        <LinkOverlay as={ReactRouterLink} to={viewApp ? `/job/${jobId}` : `/app/${jobAppId}`} />
      )}
      <Image src={company.logo} alt={company.name + "-logo"} boxSize="1.75rem" mr="0.5rem" />
      <Flex direction="column" maxWidth="28em">
        <Text textStyle={TextStyles.h2} noOfLines={1} pb="0.25rem">
          {company.name}
        </Text>
        <Spacer />
        <Text textStyle={TextStyles.text} noOfLines={2} pb="0.5rem">
          {job.title}
        </Text>
        <Spacer />
        <Flex direction="row">
          <SVGIcon name="map-pin" fill={colors.grey[700]} size="1rem" />
          <Text textStyle={TextStyles.thin} mx="0.2rem">
            {!job.locations || job.locations.length === 0
              ? "unknown"
              : job.locations.length === 1
              ? job.locations[0]
              : job.locations[0] + "+"}
          </Text>
        </Flex>
      </Flex>
      <Spacer />
      <Flex direction="column" width="max-content">
        <Flex direction="row" width="100%" alignItems="center" justifyContent="end">
          {/* {jobAppId && (
            <StatusDropdown
              onStatus={appStatus}
              variant={xArchived ? ButtonVariants.greyFillSquare : ButtonVariants.primaryFillSquare}
              xArchived={xArchived}
              updateStatus={updateStatus}
            />
          )} */}
          {jobAppId ? (
            <Button
              text={appStatus}
              variant={xArchived ? ButtonVariants.greyFillSquare : ButtonVariants.primaryFillSquare}
              textStyle={TextStyles.primaryFillBtn_sm}
              isDisabled
            />
          ) : (
            <LogNewJobModal
              triggerBtnProps={{
                textStyle: "btn_sm",
                text: "Log",
                zIndex: 1,
              }}
              _company={company}
              _job={job}
            />
          )}
          <ButtonIcon
            icon={{ name: "bookmark-" + (xMark ? "fill" : "line"), size: "1.5rem" }}
            onClick={updateXMark}
          />
        </Flex>
        <Spacer />
        {jobAppId && !viewApp && (
          <ReactRouterLink to={`/app/${jobAppId}`}>
            <Flex direction="row" alignItems="center">
              <Text textStyle={TextStyles.thin} color="text" pr="0.25rem">
                Go to my application
              </Text>
              <SVGIcon name="arrow-right-line" size="1rem" />
            </Flex>
          </ReactRouterLink>
        )}
        {jobAppId && upcomingReminder && (
          <Flex direction="row">
            <SVGIcon name="notification-fill" size="1rem" />
            <Flex direction="column" pl="0.25rem">
              <Text textStyle={TextStyles.thin} color="text" mb="0.25rem">
                {upcomingReminder.text}
              </Text>
              <Text textStyle={TextStyles.thin}>{reminderTimeStr}</Text>
            </Flex>
          </Flex>
        )}
      </Flex>
    </LinkBox>
  );
};

export default CardLong;
