import { gql } from "@apollo/client";
import { SAMPLE_CORE_FRAGMENT } from "../../fragments/sample.fragment";

const GET_SAMPLES = gql`
  ${SAMPLE_CORE_FRAGMENT}
  query GetSamples($offset: Int!, $limit: Int!) {
    getSamples(offset: $offset, limit: $limit) {
      ...SampleCoreFragment
      birthYear @client
    }
  }
`;

const GET_SAMPLE_BY_ID = gql`
  ${SAMPLE_CORE_FRAGMENT}
  query GetSampleById($id: ObjectId!) {
    getSampleById(id: $id) {
      ...SampleCoreFragment
    }
  }
`;

const SampleQueries = {
  GET_SAMPLES,
  GET_SAMPLE_BY_ID,
};

export default SampleQueries;
