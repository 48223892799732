import { gql } from "@apollo/client";
import { JOB_LISTING_DAILY_APPLY_CORE_FRAGMENT } from "./jobListingDailyApply.fragment";

export const JOB_LISTING_LINK_CORE_FRAGMENT = gql`
  fragment JobListingLinkCoreFragment on JobListingLink {
    link
    freq
  }
`;

export const JOB_LISTING_CARD_DISPLAY_FRAGMENT = gql`
  ${JOB_LISTING_LINK_CORE_FRAGMENT}
  fragment JobListingCardDisplayFragment on JobListing {
    _id
    _createdAt
    company {
      name
      logo
    }
    active
    externalJobId
    title
    type
    locations
    links {
      ...JobListingLinkCoreFragment
    }
    totalApplied
  }
`;

export const JOB_LISTING_CORE_FRAGMENT = gql`
  ${JOB_LISTING_LINK_CORE_FRAGMENT}
  ${JOB_LISTING_DAILY_APPLY_CORE_FRAGMENT}
  fragment JobListingCoreFragment on JobListing {
    _id
    _createdAt
    companyId
    active
    country
    externalJobId
    title
    description
    category
    type
    level
    season
    locations
    workMode
    links {
      ...JobListingLinkCoreFragment
    }
    xPaid
    xSponsor
    totalApplied
    dailyApply {
      ...JobListingDailyApplyCoreFragment
    }
  }
`;

export const JOB_LISTING_WITH_COMPANY_CORE_FRAGMENT = gql`
  ${JOB_LISTING_LINK_CORE_FRAGMENT}
  ${JOB_LISTING_DAILY_APPLY_CORE_FRAGMENT}
  fragment JobListingWithCompanyCoreFragment on JobListing {
    _id
    _createdAt
    company {
      name
      logo
    }
    active
    country
    externalJobId
    title
    description
    category
    type
    level
    season
    locations
    workMode
    links {
      ...JobListingLinkCoreFragment
    }
    xPaid
    xSponsor
    totalApplied
    dailyApply {
      ...JobListingDailyApplyCoreFragment
    }
  }
`;
