import { Box, Flex, Skeleton, SkeletonCircle, SkeletonText } from "@chakra-ui/react";
import React from "react";

interface SkeletonProps {
  loading: boolean;
}

const CardSquareSkeleton: React.FC<SkeletonProps> = ({ loading }) => {
  return (
    <Box padding="6" boxShadow="lg" bg="white">
      {/* <Skeleton isLoaded={!loading}> */}
      <Flex direction="row">
        <SkeletonCircle size="10" maxW="3rem" />
        <SkeletonText mt="4" spacing="4" />
      </Flex>
      <SkeletonText mt="4" noOfLines={4} spacing="4" />
      {/* </Skeleton> */}
    </Box>
  );
};

export default CardSquareSkeleton;
