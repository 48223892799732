import React, { ReactNode, useEffect, useState } from "react";
import { Box, Flex } from "@chakra-ui/react";

interface DotDividerProps {
  numOfDots?: number;
}

const DotDivider: React.FC<DotDividerProps> = ({ numOfDots = 3 }) => {
  const [dots, setDots] = useState<ReactNode[]>();

  useEffect(() => {
    const newDots = [];
    for (let i = 0; i < numOfDots; i++) {
      newDots.push(
        <Box
          key={i}
          display="flex"
          borderRadius="50%"
          backgroundColor="primary.solid"
          width="34px"
          height="34px"
          mr="2rem"
        ></Box>
      );
    }
    setDots(newDots);
  }, [numOfDots]);

  return (
    <Flex py="3rem" alignContent="center" justifyContent="center">
      {dots}
    </Flex>
  );
};

export default DotDivider;
