const Heading = {
  baseStyle: {
    fontWeight: "500",
    fontSize: "md",
    fontFamily: "heading",
    color: "text",
  },
};

export default Heading;
