import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import CardSmall from "../../../components/cards/cardSmall";
import { Sorting } from "../../../constants/constants";
import GridLayout from "../../../layouts/gridLayout";
import { ReactUseState } from "../../App";
import { ToastStatus } from "../../../constants/constants";
import CompanyServices from "../../../graphql/services/company.services";
import * as CompanyTypes from "../../../graphql/types/company.type";
import { Box, useToast } from "@chakra-ui/react";
import useActiveHiringCompaniesHook from "../../../hooks/useActiveHiringCompanies";
import { ICompany } from "../../../schemas/job.schema";

interface ViewCompaniesGridProps {
  selectMode: ReactUseState<boolean>;
  selectedCompanyIds: ReactUseState<Set<string>>;
  sorting: Sorting;
}

const ViewCompaniesGrid: React.FC<ViewCompaniesGridProps> = ({
  selectMode,
  selectedCompanyIds,
  sorting,
}) => {
  const LIMIT_PER_QUERY = 100;
  const toast = useToast();
  let { data, error, fetchMore } = useQuery<
    CompanyTypes.GetCompaniesData,
    CompanyTypes.GetCompaniesArgs
  >(CompanyServices.GET_COMPANIES, {
    variables: { verified: true, offset: 0, limit: LIMIT_PER_QUERY },
  });

  const { setNumberOfActiveHiringCompanies } = useActiveHiringCompaniesHook();

  const [sortedCompanies, setSortedCompanies] = useState<ICompany[]>([]);

  useEffect(() => {
    fetchMoreHandler();
  }, [sorting]);

  const sortCompanies = (companies: ICompany[]) => {
    // const sorted = companies.sort((a, b) => a.numberOfActiveJobs > 0);
    // setSortedCompanies(sorted);
  };

  useEffect(() => {
    if (data?.getCompanies) {
      setNumberOfActiveHiringCompanies(
        data.getCompanies.filter((comp) => comp.numberOfActiveJobs > 0).length || 0
      );
      sortCompanies(data.getCompanies);
    }
  }, [data?.getCompanies]);

  const fetchMoreHandler = async () => {
    await fetchMore({
      variables: {
        sort: sorting,
      },
    });
  };

  const handleSelectCompany = (_id: string) => {
    const newData = new Set<string>(selectedCompanyIds.data);
    newData.has(_id) ? newData.delete(_id) : newData.add(_id);
    selectedCompanyIds.setData(newData);
  };

  const onClickCard = (_id: string) => {
    if (selectMode.data) {
      handleSelectCompany(_id);
    }
  };

  const DataNotFound = () => {
    return <Box>Data not found</Box>;
  };

  if (error) {
    toast({
      title: "Failed to load companies data",
      description: error.message,
      position: "bottom",
      status: ToastStatus.ERROR,
      isClosable: true,
    });
    return <DataNotFound />;
  }

  return (
    <GridLayout
      columns={5}
      spacing={6}
      items={data?.getCompanies.map((item) => {
        return (
          <CardSmall
            key={item._id}
            _id={item._id}
            image={item.logo}
            numberOfJobs={item?.activeJobs?.length || 0}
            title={item.name}
            onClick={() => onClickCard(item._id)}
            selectMode={selectMode.data}
            selected={selectedCompanyIds.data.has(item._id)}
          />
        );
      })}
    />
  );
};

export default ViewCompaniesGrid;
function fetchMore(arg0: { variables: { offset: any } }) {
  throw new Error("Function not implemented.");
}
