export const DocTitleSuffix: string = " | Forwardy.app";

export enum ToastStatus {
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
}

export enum JobAppStatus {
  APPLIED = "APPLIED",
  ASSESSMENT = "ASSESSMENT",
  INTERVIEW = "INTERVIEW",
  OFFER = "OFFER",
  REJECT = "REJECT",
  WITHDRAW = "WITHDRAW",
}

export enum MyAppCategory {
  ALL = "ALL",
  APPLIED = "APPLIED",
  ARCHIVED = "ARCHIVED",
  ASSESSMENT = "ASSESSMENT",
  INTERVIEW = "INTERVIEW",
  OFFER = "OFFER",
}

export enum JobAppOfferStatus {
  ACCEPT = "ACCEPT",
  PENDING = "PENDING",
  DECLINE = "DECLINE",
}

export enum Month {
  JAN = "JAN",
  FEB = "FEB",
  MAR = "MAR",
  APR = "APR",
  MAY = "MAY",
  JUN = "JUN",
  JUL = "JUL",
  AUG = "AUG",
  SEP = "SEP",
  OCT = "OCT",
  NOV = "NOV",
  DEC = "DEC",
}

export enum ApplyVia {
  COMPANY = "COMPANY",
  REFER = "REFER",
  CONFERENCE = "CONFERENCE",
  // GHC = "GHC",
  // TAPIA = "TAPIA",
  // RTC = "RTC",
  LINKEDIN = "LINKEDIN",
  INDEED = "INDEED",
  GLASSDOOR = "GLASSDOOR",
  OTHER = "OTHER",
}

export enum JobListingSeason {
  FALL = "FALL",
  SUMMER = "SUMMER",
  SPRING = "SPRING",
  ROLLING = "ROLLING",
}

export enum JobListingType {
  NEWGRAD = "NEWGRAD",
  INTERNSHIP = "INTERNSHIP",
  EXPERIENCED = "EXPERIENCED",
}

export enum JobListingLevel {
  FULL_TIME = "FULL_TIME",
  PART_TIME = "PART_TIME",
  CONTRACT = "CONTRACT",
  TEMPORARY = "TEMPORARY",
}

export enum JobListingWorkMode {
  HYBRID = "HYBRID",
  REMOTE = "REMOTE",
  ONSITE = "ONSITE",
}

export enum PayBasis {
  HOURLY = "HOURLY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
}

export enum Sorting {
  CREATED_DATE = "CREATED_DATE",
  NAME_UP = "NAME_UP",
  NAME_DOWN = "NAME_DOWN",
  POPULARITY = "POPULARITY",
}

export enum JobAppEventType {
  REMINDER = "REMINDER",
  EXPERIENCE = "EXPERIENCE",
  NOTE = "NOTE",
}

export enum JobFilterType {
  IS_MARKED = "IS_MARKED",
  NEWGRAD = "NEWGRAD",
  INTERNSHIP = "INTERNSHIP",
  EXPERIENCED = "EXPERIENCED",
}

export enum CountryCode {
  USA = "USA",
  CAN = "CAN",
  CHN = "CHN",
  GBR = "GBR",
}
