import React, { useState, useEffect } from "react";
import { Box, Text, Image, VStack, Link } from "@chakra-ui/react";
import { LayerStyles } from "../../theme/layerStyles";
import { Helmet } from "react-helmet";
import AppRoutes from "../AppRoutes.constant";
import { DocTitleSuffix } from "../../constants/constants";
import SVGIcon from "../../assets/icons";
import SectionHeader from "../../components/sectionHeader";
import SearchHeaderRow from "../../components/searchHeaderRow";
import { useParams } from "react-router-dom";
import { TextStyles } from "../../theme/textStyles";
import PageNotFound from "./pageNotFound";
import ViewJobListingsByCompany from "../adapters/company/viewJobListingsByCompany";
import { IMergedJobApp, mergeJobsWithApps } from "../../utils/mergedJobApp";
import CompanyServices from "../../graphql/services/company.services";
import * as CompanyTypes from "../../graphql/types/company.type";
import { useQuery } from "@apollo/client";
import { IJobApp } from "../../schemas/app.schema";
import { ICompany } from "../../schemas/job.schema";

interface CompanyProps {
  jobAppsByTerm: IJobApp[];
}

const Company: React.FC<CompanyProps> = ({ jobAppsByTerm }) => {
  const { companyId } = useParams();

  const { data } = useQuery<CompanyTypes.GetCompanyByIdData, CompanyTypes.GetCompanyByIdArgs>(
    CompanyServices.GET_COMPANY_BY_ID,
    { variables: { id: companyId } }
  );

  const [company, setCompany] = useState<ICompany | undefined>();
  const [mergedJobsApps, setMergedJobsApps] = useState<IMergedJobApp[]>([]);

  useEffect(() => {
    if (data?.getCompanyById) {
      const companyResult = data.getCompanyById;
      setCompany(companyResult);
      setMergedJobsApps(mergeJobsWithApps(jobAppsByTerm, companyResult?.activeJobs || []));
    }
  }, [jobAppsByTerm, data?.getCompanyById]);

  if (!company) {
    return <PageNotFound />;
  } else {
    return (
      <Box layerStyle={LayerStyles.mainPanelChild} pt="0">
        <Helmet>
          <title>
            {AppRoutes.company.title}
            {DocTitleSuffix}
          </title>
        </Helmet>
        <SearchHeaderRow goBack={true} />
        <VStack align="center" spacing={2} mb="1.5rem">
          <Image
            boxSize="3rem"
            src={company.logo}
            alt={company.name + "-logo"}
            borderRadius="0.25rem"
            border="1px solid"
            borderColor="grey.300"
          />
          <Box position="relative">
            <Text as="h1" textStyle={TextStyles.h1} mb="0">
              {company.name}
            </Text>
            <Box position="absolute" left="105%" top="0">
              <SVGIcon name="exclamation-mark" size="0.75rem" />
            </Box>
          </Box>
          <Text display="flex" dir="row" textStyle={TextStyles.thin} margin="auto" mb="0.5rem">
            Industry: {company.industry}&nbsp;&nbsp;|&nbsp;&nbsp; Headquater:&nbsp;
            {company.headquater}&nbsp;&nbsp;|&nbsp;&nbsp;
            <Link
              display="flex"
              dir="row"
              alignContent="center"
              href={company.linkedin}
              isExternal
              margin="0"
            >
              <SVGIcon name="linkedin-logo" size="0.9rem" />
              &nbsp;/{company.name} &nbsp;
              <SVGIcon name="external-link-line" size="0.9rem" />
            </Link>
          </Text>
        </VStack>
        <SectionHeader text={`${mergedJobsApps.length} active jobs`} />
        <ViewJobListingsByCompany mergedJobsApps={mergedJobsApps} />
      </Box>
    );
  }
};

export default Company;
