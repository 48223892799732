import { gql } from "@apollo/client";

export const JOB_LISTING_DAILY_APPLY_CORE_FRAGMENT = gql`
  fragment JobListingDailyApplyCoreFragment on JobListingDailyApply {
    _id
    year
    month
    date
    totalApplied
  }
`;
