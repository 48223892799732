import { useReactiveVar } from "@apollo/client";
import { activeHiringCompaniesVar, ActiveHiringCompaniesVarType } from "../cache/reactiveVars";

const useActiveHiringCompaniesHook = () => {
  const numberOfCompanies = useReactiveVar(activeHiringCompaniesVar);

  const getNumberOfActiveHiringCompanies = (): ActiveHiringCompaniesVarType => {
    return numberOfCompanies;
  };

  const setNumberOfActiveHiringCompanies = (num: number) => {
    activeHiringCompaniesVar(num);
  };

  return { getNumberOfActiveHiringCompanies, setNumberOfActiveHiringCompanies };
};

export default useActiveHiringCompaniesHook;
