import React from "react";
import { Box, Button, Flex, Slide, Text, useDisclosure, useToast } from "@chakra-ui/react";
import SVGIcon from "../assets/icons";
import { LayerStyles } from "../theme/layerStyles";
import { TextStyles } from "../theme/textStyles";

const NetPromoterSlider = ({ ...props }) => {
  const { isOpen, onToggle } = useDisclosure();
  const toast = useToast();

  const submitScore = () => {
    toast({
      title: "Thank you for your feedback!",
      description: "We'll continue to deliever better forwardy for you.",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
    onToggle();
  };
  return (
    <Box layerStyle={LayerStyles.netPromoter} {...props}>
      <Slide direction="bottom" in={isOpen} style={{ width: "max-content", left: "35%" }}>
        <Flex layerStyle={LayerStyles.netPromoterBoard} direction="column">
          <Text textStyle={TextStyles.h3} mb="1.5rem">
            How do you like forwardy?
          </Text>
          <Flex direction="row" width="100%">
            {scores.map((score, key) => {
              return (
                <Button
                  variant="netPromoter"
                  key={key}
                  ml={key === 0 ? "0" : "0.5rem"}
                  onClick={() => submitScore()}
                >
                  <Text textStyle="netPromoterIcon">{score.icon}</Text>
                  <Text textStyle={TextStyles.text}>{score.text}</Text>
                </Button>
              );
            })}
          </Flex>
        </Flex>
      </Slide>
      <Button variant="iconFill" onClick={onToggle}>
        <SVGIcon name="thumb-up-fill" fill="white" size="2rem" />
      </Button>
    </Box>
  );
};

const scores = [
  { icon: "🙁", text: "not for me" },
  { icon: "😐", text: "it's ok" },
  { icon: "🙂", text: "smooth" },
  { icon: "🥰", text: "loving it!" },
];

export default NetPromoterSlider;
