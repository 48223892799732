import { useEffect, useState } from "react";
import { Box, SkeletonCircle, SkeletonText } from "@chakra-ui/react";
import { ApplyVia, DocTitleSuffix } from "../../constants/constants";
import { Helmet } from "react-helmet";
import { LayerStyles } from "../../theme/layerStyles";
import AppRoutes from "../AppRoutes.constant";
import SearchHeaderRow from "../../components/searchHeaderRow";
import { useParams } from "react-router-dom";
import { IJobApp, IJobAppEvent, IJobAppOffer } from "../../schemas/app.schema";
import PageNotFound from "./pageNotFound";
import JobAppDetailTabs from "../adapters/jobApp/jobAppDetailTabs";
import JobAppCardDisplay, { JobAppCardShape } from "../adapters/jobApp/jobAppCardDisplay";
import { useQuery } from "@apollo/client";
import * as JobAppTypes from "../../graphql/types/jobApp.type";
import JobAppServices from "../../graphql/services/jobApp.services";
import { IMergedJobApp, mergeJobWithApp } from "../../utils/mergedJobApp";
import { timestamp2ISO } from "../../utils";
import { AppliedTabProps } from "../adapters/jobApp/appliedTab";
import { IReminder, getUpcomingEventReminder } from "../../utils/jobApp";

const MyAppDetail = () => {
  const { appId } = useParams();
  const { data: jobAppData, loading } = useQuery<
    JobAppTypes.GetJobAppByIdData,
    JobAppTypes.GetJobAppByIdArgs
  >(JobAppServices.GET_JOB_APP_BY_ID, { variables: { id: appId } });

  const [app, setApp] = useState<IJobApp | undefined>();
  const [mergedJobApp, setMergedJobApp] = useState<IMergedJobApp | undefined>();
  const [appliedTab, setAppliedTab] = useState<AppliedTabProps>({
    appId: appId,
    _associatedEmail: "",
    _appliedOn: "",
    _applyVia: ApplyVia.COMPANY,
    _jobLink: "",
    _recruiter: "",
  });
  const [upcomingReminder, setUpcomingReminder] = useState<IReminder | undefined>(undefined);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (jobAppData?.getJobAppById) {
      setApp(jobAppData.getJobAppById);
    }
  }, [jobAppData?.getJobAppById]);

  useEffect(() => {
    if (app) {
      setMergedJobApp(mergeJobWithApp(app, app.job));
    }
  }, [app]);

  useEffect(() => {
    const newAppliedTab: AppliedTabProps = { ...appliedTab };
    if (app?.associatedEmail) {
      newAppliedTab._associatedEmail = app.associatedEmail;
    }
    if (app?.applyVia) {
      newAppliedTab._applyVia = app.applyVia;
    }
    if (app?.statusHistory.applied) {
      newAppliedTab._appliedOn = timestamp2ISO(app.statusHistory.applied);
    }
    if (app?.jobLink) {
      newAppliedTab._jobLink = app.jobLink;
    }
    if (app?.recruiter) {
      newAppliedTab._recruiter = app.recruiter;
    }
    setAppliedTab({ ...newAppliedTab });
  }, [
    app?.associatedEmail,
    app?.applyVia,
    app?.statusHistory.applied,
    app?.jobLink,
    app?.recruiter,
  ]);

  if (loading) {
    return (
      <Box padding="6" boxShadow="lg" bg="white">
        <SkeletonCircle size="10" />
        <SkeletonText mt="4" noOfLines={4} spacing="4" />
      </Box>
    );
  }

  if (!mergedJobApp || !app) {
    return <PageNotFound />;
  }

  return (
    <Box layerStyle={LayerStyles.mainPanelChild} pt="0">
      <Helmet>
        <title>
          {AppRoutes.myAppDetail.title}
          {DocTitleSuffix}
        </title>
      </Helmet>
      <SearchHeaderRow goBack={true} />
      <JobAppCardDisplay
        shape={JobAppCardShape.LONG}
        item={mergedJobApp}
        reminder={upcomingReminder}
        viewApp={true}
        props={{ marginBottom: "1.5rem" }}
      />
      <JobAppDetailTabs
        appId={appId}
        status={app.status}
        appliedTab={appliedTab}
        statusHistory={app.statusHistory}
        upcomingReminder={{ data: upcomingReminder, setData: setUpcomingReminder }}
      />
    </Box>
  );
};

export default MyAppDetail;
