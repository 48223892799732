import React, { useEffect, useState } from "react";
import { JobAppStatus } from "../constants/constants";
import { ButtonVariants } from "../theme/components/button";
import { LayerStyles } from "../theme/layerStyles";
import { TextStyles } from "../theme/textStyles";
import Dropdown, { DropdownProps, OptionProps } from "./dropdown/";

interface StatusDropdownProps {
  onStatus: string;
  updateStatus(status: string): void;
  xArchived: boolean;
  variant: string;
}

const StatusDropdown: React.FC<StatusDropdownProps> = ({
  onStatus,
  xArchived,
  variant,
  updateStatus,
}) => {
  const options: OptionProps[] = [
    { text: JobAppStatus.APPLIED, value: JobAppStatus.APPLIED },
    { text: JobAppStatus.ASSESSMENT, value: JobAppStatus.ASSESSMENT },
    { text: JobAppStatus.INTERVIEW, value: JobAppStatus.INTERVIEW },
    { text: JobAppStatus.OFFER, value: JobAppStatus.OFFER },
    { text: JobAppStatus.REJECT, value: JobAppStatus.REJECT },
    { text: JobAppStatus.WITHDRAW, value: JobAppStatus.WITHDRAW },
  ];

  const [selected, setSelected] = useState(
    options.find((op) => op.text === onStatus) || options[0]
  );
  const [menuBtnVariant, setMenuBtnVariant] = useState(
    xArchived ? ButtonVariants.greyFillSquare : ButtonVariants.primaryFillSquare
  );

  useEffect(() => {
    setMenuBtnVariant(xArchived ? ButtonVariants.greyFillSquare : ButtonVariants.primaryFillSquare);
  }, [xArchived]);

  useEffect(() => {
    if (selected.text !== onStatus) {
      updateStatus(selected.text);
    }
  }, [selected]);

  const statusDropdown: DropdownProps = {
    options: options,
    menuButtonProps: {
      variant: variant,
      //menuBtnVariant,
      textStyle: TextStyles.primaryFillBtn_sm,
      rightIcon: { name: "arrow-down-line", size: "1rem", fill: "white" },
      props: {
        height: "max-content",
        width: "max-content",
        pl: "0.75rem",
        pr: "0.5rem",
      },
    },
    menuListProps: { layerStyle: LayerStyles.primaryLineSquare, zIndex: "2" },
    menuItemProps: {
      variant: ButtonVariants.text,
      textStyle: TextStyles.thin,
      textStyleSelected: TextStyles.primaryLinePill,
      props: {
        _focus: { outline: "none", boxShadow: "none" },
        _hover: { outline: "none", boxShadow: "none" },
        height: "100%",
        padding: "0.45rem 0.5rem",
        justifyContent: "start",
      },
    },
    selectedMenuItem: { data: selected, setData: setSelected },
  };

  return <Dropdown {...statusDropdown} />;
};

export default StatusDropdown;
