import { Checkbox, Menu, MenuList, Stack } from "@chakra-ui/react";
import { SVGIconProps } from "../../assets/icons";
import React from "react";
import { ReactUseState } from "../../containers/App";
import DropdownMenuButton, { MenuButtonProps } from "./dropdownMenuButton";
import DropdownMenuItem, { MenuItemProps } from "./dropdownMenuItem";
import { capitalizeStr } from "../../utils";

export interface OptionProps {
  text: string;
  leftIcon?: SVGIconProps;
  rightIcon?: SVGIconProps;
  checkbox?: boolean;
  value: string;
}

export interface MenuListProps {
  minW?: string;
  w?: string;
  layerStyle: string;
  [key: string]: any;
}

export interface CheckBoxProps {
  buttonText: string;
  colorScheme: string;
  isCheckbox: boolean;
}

export interface DropdownProps {
  options: OptionProps[];
  menuButtonProps: MenuButtonProps;
  checkBoxProps?: CheckBoxProps;
  menuListProps: MenuListProps;
  menuItemProps: MenuItemProps;
  selectedMenuItem: ReactUseState<OptionProps> | ReactUseState<OptionProps[]>;
  [key: string]: any;
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  menuButtonProps,
  checkBoxProps,
  menuListProps,
  menuItemProps,
  selectedMenuItem,
  terms,
  ...props
}) => {
  const checkboxMode: boolean = checkBoxProps?.isCheckbox ? checkBoxProps.isCheckbox : false;
  let selectedCheckedBoxItem: ReactUseState<OptionProps[]>;

  if (checkboxMode) {
    selectedCheckedBoxItem = selectedMenuItem as ReactUseState<OptionProps[]>;
  }

  const handleOnChange = (selectedKey: number) => {
    const updatedOption: OptionProps[] = selectedCheckedBoxItem?.data.map((option, key) =>
      key === selectedKey ? { ...option, checkbox: !option["checkbox"] } : option
    ) as OptionProps[];
    {
      updatedOption
        ? selectedCheckedBoxItem?.setData(updatedOption)
        : selectedCheckedBoxItem?.setData(selectedCheckedBoxItem.data);
    }
  };

  return (
    <Menu isLazy {...props}>
      <DropdownMenuButton
        menuButtonProps={menuButtonProps}
        checkBoxProps={checkBoxProps}
        selectedMenuItem={
          checkboxMode ? undefined : (selectedMenuItem as ReactUseState<OptionProps>)
        }
      />
      <MenuList {...menuListProps}>
        <Stack spacing="0.25rem">
          {options.map((option, key) =>
            checkboxMode && selectedCheckedBoxItem.data ? (
              <Checkbox
                key={key}
                colorScheme={checkBoxProps?.colorScheme}
                value={option.text}
                isChecked={selectedCheckedBoxItem.data[key]["checkbox"]}
                onChange={() => handleOnChange(key)}
              >
                {capitalizeStr(option.text)}
              </Checkbox>
            ) : (
              <DropdownMenuItem
                key={key}
                menuItemProps={menuItemProps}
                option={option}
                selectedMenuItem={selectedMenuItem as ReactUseState<OptionProps>}
              />
            )
          )}
        </Stack>
      </MenuList>
    </Menu>
  );
};

export default Dropdown;
