import { gql } from "@apollo/client";
import { JOB_APP_TERM_DISPLAY_FRAGMENT } from "./jobAppTerm.fragment";
import { USER_DAILY_EVENT_CORE_FRAGMENT } from "./userDailyEvent.fragment";

export const USER_UPDATE_LAST_OPEN_TERM_FRAGMENT = gql`
  fragment UserUpdateLastOpenTermFragment on User {
    lastOpenTerm
  }
`;

export const USER_ACCOUNT_FRAGMENT = gql`
  fragment UserAccountCoreFragment on User {
    _id
    firstName
    lastName
    username
    email
    studentVerifications {
      expirationDate
    }
    lastOpenTerm
    autoArchive
    daysToArchive
    shareProgress
    shareOffer
  }
`;

export const USER_CORE_FRAGMENT = gql`
  ${JOB_APP_TERM_DISPLAY_FRAGMENT}
  ${USER_DAILY_EVENT_CORE_FRAGMENT}
  fragment UserCoreFragment on User {
    _id
    firstName
    lastName
    username
    email
    studentVerifications {
      expirationDate
    }
    terms {
      ...JobAppTermDisplayFragment
    }
    lastOpenTerm
    calendar {
      ...UserDailyEventCoreFragment
    }
    autoArchive
    daysToArchive
    shareProgress
    shareOffer
  }
`;
