import { gql } from "@apollo/client";
import { JOB_APP_OFFER_CORE_FRAGMENT } from "../../fragments/jobAppOffer.fragment";

const GET_JOB_APP_OFFERS_JOB_APP_ID = gql`
  ${JOB_APP_OFFER_CORE_FRAGMENT}
  query GetJobAppOffersByJobAppId($jobAppId: ObjectId!) {
    getJobAppOffersByJobAppId(jobAppId: $jobAppId) {
      ...JobAppOfferCoreFragment
    }
  }
`;

const JobAppOfferQueries = {
  GET_JOB_APP_OFFERS_JOB_APP_ID,
};

export default JobAppOfferQueries;
