import React from "react";
import GridLayout from "../../../layouts/gridLayout";
import { IMergedJobApp } from "../../../utils/mergedJobApp";
import JobAppCardDisplay, { JobAppCardShape } from "../jobApp/jobAppCardDisplay";

interface ViewJobListingsByCompanyProps {
  mergedJobsApps: IMergedJobApp[];
}

const ViewJobListingsByCompany: React.FC<ViewJobListingsByCompanyProps> = ({ mergedJobsApps }) => {
  return (
    <GridLayout
      columns={3}
      spacing={6}
      items={mergedJobsApps.map((item, key) => {
        if (!item) {
          return <></>;
        }
        return (
          <JobAppCardDisplay key={key} shape={JobAppCardShape.SQUARE} item={item} viewApp={false} />
        );
      })}
    />
  );
};

export default ViewJobListingsByCompany;
