import React from "react";
import { Tag, TagLabel } from "@chakra-ui/react";
import SVGIcon, { SVGIconProps } from "../assets/icons";
import { ButtonProps } from "./button";
import colors from "../theme/colors";
import { TextStyles } from "../theme/textStyles";

export interface PillProps extends ButtonProps {
  variant?: string;
  textStyle?: string;
  icon?: SVGIconProps;
  text?: string;
  borderWidth?: string | string[];
}

export const PillStyles = {
  neutral: {
    backgroundColor: colors.neutral[100],
    color: "neutral.300",
  },
  positive: {
    backgroundColor: colors.positive[100],
    color: "positive.300",
  },
  negative: {
    backgroundColor: colors.grey[100],
    color: "grey.300",
  },
};

const Pill: React.FC<PillProps> = ({
  variant = "primaryLine",
  textStyle = TextStyles.primaryLinePill,
  text,
  icon,
  borderWidth = "1.2px",
  ...props
}) => {
  return (
    <Tag
      size="md"
      variant={variant}
      boxShadow="none"
      borderWidth={borderWidth}
      borderRadius="0.75rem"
      borderColor="primary.solid"
      {...props}
    >
      {icon && <SVGIcon {...icon} size="0.75rem" />}
      <TagLabel textStyle={textStyle}>{text}</TagLabel>
    </Tag>
  );
};

export default Pill;
